/**
 * Note: This form is using card from Stripe Elements https://stripe.com/docs/stripe-js#elements
 * Card is not a Final Form field so it's not available through Final Form.
 * It's also handled separately in handleSubmit function.
 */
import { Box } from '@material-ui/core';
import React, { Component } from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { isEmpty, sortBy } from 'lodash';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config, { defaultTreetStyles } from '../../shopConfig/config';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, ensurePaymentMethodCard, ensureStripeCustomer } from '../../util/data';
import { Button, Form, FormattedMessage, IconSpinner, TypographyWrapper } from '../../components';
import { REPLACE_CARD } from '../../components/SavedCardDetails/SavedCardDetails';
import { saveAddress } from '../../ducks/user.duck';
import { createPaymentIntent, handleCardCharge } from '../../ducks/stripe.duck';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import AppContext from '../../context/AppContext';
import { RequestStatus } from '../../types/requestStatus';
import { withViewport } from '../../util/contextHelpers';
import { MAX_SMALL_SCREEN_WIDTH } from '../../util/window';
import { SHIPPING_ADDRESS_PREFIX } from './ShippingAddressFields';
import { constructAddressMutatorFn, markFieldsAsTouched } from '../../util/address';
import { PAYMENT_ADDRESS_FIELD_ID, SHIPPING_ADDRESS_FIELD_ID } from '../../util/constants';
import StripePaymentFormMobile from './StripePaymentFormMobile';
import StripePaymentFormDesktop from './StripePaymentFormDesktop';
import css from './StripePaymentForm.module.css';

/**
 * Translate a Stripe API error object.
 *
 * To keep up with possible keys from the Stripe API, see:
 *
 * https://stripe.com/docs/api#errors
 *
 * Note that at least at moment, the above link doesn't list all the
 * error codes that the API returns.
 *
 * @param {Object} intl - react-intl object from injectIntl
 * @param {Object} stripeError - error object from Stripe API
 *
 * @return {String} translation message for the specific Stripe error,
 * or the given error message (not translated) if the specific error
 * type/code is not defined in the translations
 *
 */

const stripeErrorTranslation = (intl, stripeError) => {
  const { message, code, type } = stripeError;

  if (!code || !type) {
    // Not a proper Stripe error object
    return intl.formatMessage({ id: 'StripePaymentForm.genericError' });
  }

  const translationId =
    type === 'validation_error'
      ? `StripePaymentForm.stripe.validation_error.${code}`
      : `StripePaymentForm.stripe.${type}`;

  return intl.formatMessage({
    id: translationId,
    defaultMessage: message,
  });
};

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
const USE_SAVED_CARD = 'USE_SAVED_CARD';

const CARD_ERROR = 'card';

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) =>
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
    ? PAY_AND_SAVE_FOR_LATER_USE
    : ONETIME_PAYMENT;
const stripeElementsOptions = {
  fonts: [
    {
      family: 'sofiapro',
      fontSmoothing: 'antialiased',
      src: 'local("sofiapro"), local("SofiaPro"), local("Sofia Pro"), url("https://assets-sharetribecom.sharetribe.com/webfonts/sofiapro/sofiapro-medium-webfont.woff2") format("woff2")',
    },
  ],
};

const cardStyles = {
  base: {
    fontFamily: '"sofiapro", Helvetica, Arial, sans-serif',
    fontSize: '18px',
    fontSmoothing: 'antialiased',
    lineHeight: '24px',
    letterSpacing: '-0.1px',
    color: '#4A4A4A',
    '::placeholder': {
      color: '#B2B2B2',
    },
  },
};

const getPaymentMethod = (selectedPaymentMethod, hasDefaultPaymentMethod) =>
  selectedPaymentMethod == null && hasDefaultPaymentMethod
    ? 'defaultCard'
    : selectedPaymentMethod == null
    ? 'onetimeCardPayment'
    : selectedPaymentMethod;

export const freezeSeelReturnInsuranceToggle = async (shouldFreeze) => {
  if (typeof window !== 'undefined' && window.SeelSDK) {
    window.SeelSDK.seelSDK.freezeToggle(shouldFreeze);
  }
};

const initialState = {
  error: null,
  cardValueValid: false,
  // The mode can be 'onetimePayment', 'defaultCard', or 'replaceCard'
  // Check SavedCardDetails component for more information
  paymentMethod: null,
  submitting: false,
  isMobile: null,
};

/**
 * Payment form that asks for credit card info using Stripe Elements.
 *
 * When the card is valid and the user submits the form, a request is
 * sent to the Stripe API to handle payment. `stripe.handleCardPayment`
 * may ask more details from cardholder if 3D security steps are needed.
 *
 * See: https://stripe.com/docs/payments/payment-intents
 *      https://stripe.com/docs/elements
 */
class StripePaymentFormComponent extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleCardValueChange = this.handleCardValueChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleExpressCheckoutButtonClick = this.handleExpressCheckoutButtonClick.bind(this);
    this.getDefaultPaymentMethod = this.getDefaultPaymentMethod.bind(this);
    this.scrollToFirstError = this.scrollToFirstError.bind(this);
    this.paymentForm = this.paymentForm.bind(this);
    this.initializeStripeElement = this.initializeStripeElement.bind(this);
    this.handleStripeElementRef = this.handleStripeElementRef.bind(this);
    this.changePaymentMethod = this.changePaymentMethod.bind(this);
    this.finalFormAPI = null;
    this.cardContainer = null;
  }

  componentDidMount() {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for StripePaymentForm');
    }

    if (config.stripe.publishableKey) {
      this.stripe = window.Stripe(config.stripe.publishableKey);
    }

    const { initialValues, handleShippingCountryChange } = this.props;
    const country = initialValues?.shippingAddress?.country;
    if (country) {
      // Call handleShippingCountryChange if there is already a country value saved
      handleShippingCountryChange(country);
    }
  }

  componentDidUpdate(prevProps) {
    const { handleShippingCountryChange, initialValues, shipToCountry, viewport } = this.props;

    if (prevProps.viewport.width !== viewport.width) {
      const isMobileLayout = viewport.width <= MAX_SMALL_SCREEN_WIDTH;
      // It's okay to set state here since we won't infinite loop--we're only updating
      // state if the previous viewport is different from the current viewport
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isMobile: isMobileLayout });
    }

    // Sometimes the current user might load in after we've already mounted this component,
    // in which case we miss updating the shipToCountry. We do this check again to make sure
    // the shipToCountry gets updated appropriately.
    if (!isEqual(prevProps.initialValues, initialValues) && !shipToCountry) {
      const country = initialValues?.shippingAddress?.country;
      if (country) {
        // Call handleShippingCountryChange if there is already a country value saved
        handleShippingCountryChange(country);
      }
    }
  }

  componentWillUnmount() {
    if (this.card) {
      this.card.removeEventListener('change', this.handleCardValueChange);
      this.card.unmount();
      this.card = null;
    }
  }

  handleStripeElementRef(el) {
    this.cardContainer = el;
    if (this.stripe && el) {
      this.initializeStripeElement(el);
    }
  }

  handleCardValueChange(event) {
    const { intl } = this.props;
    const { error, complete } = event;

    const { postalCode } = event.value;
    if (this.finalFormAPI) {
      this.finalFormAPI.change('postal', postalCode);
    }

    this.setState(() => ({
      error: error ? stripeErrorTranslation(intl, error) : null,
      cardValueValid: complete,
    }));
  }

  handleSubmit(values) {
    const { defaultPaymentMethod, currentUser, shouldEnableSeel, onSaveAddress, onPaymentSubmit } =
      this.props;
    const { cardValueValid, paymentMethod, submitting } = this.state;

    const billingDetailsKnown = !!defaultPaymentMethod;
    const onetimePaymentNeedsAttention = !billingDetailsKnown && !cardValueValid;
    const isPaymentInvalid =
      (!defaultPaymentMethod || paymentMethod === REPLACE_CARD) && !cardValueValid;

    if (submitting) {
      // Already submitting
      return;
    }
    if (onetimePaymentNeedsAttention || isPaymentInvalid) {
      // Card value incomplete/invalid
      if (!this.error) {
        // Manually set an error message if there's no existing stripe error message. This can
        // happen if the Stripe request is not made due to incomplete/invalid data.
        this.setState(() => ({
          error: 'Card details incomplete or invalid.',
        }));
      }
      // eslint-disable-next-line consistent-return
      return { [CARD_ERROR]: true };
    }

    this.setState({ submitting: true });

    const ensuredPaymentMethod = getPaymentMethod(
      paymentMethod,
      ensurePaymentMethodCard(defaultPaymentMethod).id
    );

    const {
      name: billingAddressName,
      // in case it is not filled out (should only not be filled out if we are using a saved card)
      billingAddress = {},
      shippingAddress,
      saveAfterOnetimePayment,
      shouldSaveAddress,
    } = values;

    const { firstName, lastName, ...rest } = shippingAddress;
    const formattedShippingAddress = {
      ...rest,
      name: `${firstName} ${lastName}`,
    };

    if (shouldSaveAddress) {
      onSaveAddress({ address: shippingAddress });
    }

    const { addressLine1, addressLine2, postal, city, state, country } = billingAddress;

    // convert form to shippo address format
    const shippoAddressTo = {
      // Only support shipping in US right now
      ...formattedShippingAddress,
      email: currentUser.attributes.email,
      // TODO (SY): Retrieve user phone
    };

    // Billing address is recommended.
    // However, let's not assume that <addressform> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city,
              country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state,
            },
          }
        : {};
    const billingDetails = {
      name: billingAddressName,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const selectedPaymentFlow = paymentFlow(ensuredPaymentMethod, !!saveAfterOnetimePayment);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);

    const enteredPaymentMethod = {
      card: this.card,
      billing_details: billingDetails,
    };
    const paymentMethodToUse =
      selectedPaymentFlow !== USE_SAVED_CARD
        ? enteredPaymentMethod
        : this.getDefaultPaymentMethod(ensuredStripeCustomer);
    const shouldSavePayment = selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE;

    const onSubmitComplete = () => {
      if (shouldEnableSeel) {
        freezeSeelReturnInsuranceToggle(false);
      }
      this.setState({ submitting: false });
    };

    onPaymentSubmit(
      shippoAddressTo,
      paymentMethodToUse,
      ensuredStripeCustomer,
      shouldSavePayment,
      this.stripe,
      onSubmitComplete,
      values
    );
  }

  handleExpressCheckoutButtonClick(event) {
    const { isMultiSellerCheckout } = this.props;
    const { errors } = this.finalFormAPI.getState();

    if (!isEmpty(errors) && isMultiSellerCheckout) {
      this.finalFormAPI.mutators.touchFields();
      this.scrollToFirstError();
      return;
    }

    this.props.onExpressCheckoutButtonClick(event);
  }

  getDefaultPaymentMethod(ensuredStripeCustomer) {
    const { fetchStripeCustomerStatus } = this.props;

    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );
    const isStripeCustomerFetched = fetchStripeCustomerStatus === RequestStatus.Success;
    const hasDefaultPaymentMethod = !!(
      isStripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    return stripePaymentMethodId;
  }

  scrollToFirstError = async () => {
    const { errors, submitErrors } = this.finalFormAPI.getState();
    const { shippingAddress: shippingAddressErrors } = errors;

    const allErrors = { ...shippingAddressErrors, ...submitErrors };
    // Need to do a small delay here so that the validation errors can finish
    // loading before we scroll to them. Otherwise, the scroll won't work on the
    // first click.
    await new Promise((resolve) => setTimeout(resolve, 200));

    if (!allErrors || isEmpty(allErrors)) {
      return null;
    }

    const allErrorElements = Object.keys(allErrors).map((error) => {
      const elementId =
        error === CARD_ERROR
          ? `${PAYMENT_ADDRESS_FIELD_ID}-${error}`
          : `${SHIPPING_ADDRESS_FIELD_ID}.${error}`;
      const element = document.getElementById(elementId);
      return { element, offsetTop: element?.offsetTop };
    });

    const { element } = sortBy(allErrorElements, ['offsetTop'])[0];

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
    return null;
  };

  initializeStripeElement(element) {
    const elements = this.stripe.elements(stripeElementsOptions);

    if (!this.card) {
      this.card = elements.create('card', { style: cardStyles });
      this.card.mount(element || this.cardContainer);
      this.card.addEventListener('change', this.handleCardValueChange);
      // EventListener is the only way to simulate breakpoints with Stripe.
      window.addEventListener('resize', () => {
        if (this.card) {
          if (window.innerWidth < 1024) {
            this.card.update({ style: { base: { fontSize: '18px', lineHeight: '24px' } } });
          } else {
            this.card.update({ style: { base: { fontSize: '20px', lineHeight: '32px' } } });
          }
        }
      });
    }
  }

  changePaymentMethod(changedTo) {
    if (this.card && changedTo === 'defaultCard') {
      this.card.removeEventListener('change', this.handleCardValueChange);
      this.card.unmount();
      this.card = null;
    }
    this.setState({ paymentMethod: changedTo });
  }

  paymentForm(formRenderProps) {
    const {
      className,
      rootClassName,
      loadingData,
      paymentInfo,
      intl,
      lineItems,
      handleCardPaymentError,
      handleSubmit,
      form,
      defaultPaymentMethod,
      handleShippingCountryChange,
    } = formRenderProps;
    const { cardValueValid, error, isMobile, submitting } = this.state;

    this.finalFormAPI = form;

    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(defaultPaymentMethod);
    const submitDisabled = submitting;
    const hasPaymentErrors = handleCardPaymentError;
    const classes = classNames(rootClassName || css.root, className);

    // TODO: handleCardPayment can create all kinds of errors.
    // Currently, we provide translation support for one:
    // https://stripe.com/docs/error-codes
    const piAuthenticationFailure = 'payment_intent_authentication_failure';
    const paymentErrorMessage =
      handleCardPaymentError && handleCardPaymentError.code === piAuthenticationFailure
        ? intl.formatMessage({ id: 'StripePaymentForm.handleCardPaymentError' })
        : handleCardPaymentError
        ? handleCardPaymentError.message
        : intl.formatMessage({ id: 'StripePaymentForm.genericError' });

    const hasStripeKey = config.stripe.publishableKey;
    const showPaymentMethodSelector = ensuredDefaultPaymentMethod.id;
    const selectedPaymentMethod = getPaymentMethod(
      this.state.paymentMethod,
      showPaymentMethodSelector
    );

    if (!hasStripeKey) {
      return (
        <div className="{css.missingStripeKey}">
          <formattedmessage id="StripePaymentForm.missingStripeKey"></formattedmessage>
        </div>
      );
    }

    if (isMobile === null) {
      return (
        <box display="flex" justifyContent="center" className="{css.loading}">
          <iconspinner></iconspinner>
        </box>
      );
    }

    const {
      children,
      allowedShipToCountryCodes,
      shouldEnableSeel,
      isStripeExpressCheckoutEnabled,
      isMultiSellerCheckout,
      onExpressCheckoutOnConfirm,
      onExpressCheckoutShippingAddressChange,
    } = this.props;

    const { values, errors, touched } = form.getState();

    const addressFormFieldErrorsState = errors?.shippingAddress || {};
    const addressFormFieldTouchedState = touched || {};
    const hasAnyAddressFormFieldBeenTouched = Object.keys(addressFormFieldTouchedState)
      .filter((key) => key.startsWith('shippingAddress'))
      .some((key) => addressFormFieldTouchedState[key] === true);
    const hasAddressFormFieldError = !isEmpty(addressFormFieldErrorsState);
    // Display this message to ensure users provide address info, which is required for multi-seller express checkout.
    const shouldDisplayIncompleteAddressErrorMessage =
      isMultiSellerCheckout && hasAnyAddressFormFieldBeenTouched && hasAddressFormFieldError;

    return (
      <form className="{classes}" onSubmit="{(v)" ==""> {
          handleSubmit(v);
          this.scrollToFirstError();
        }}
      >
        {children}
        {isMobile && (
          <stripepaymentformmobile defaultPaymentMethod="{defaultPaymentMethod}" error="{error}" form="{form}" handleChangePaymentMethod="{this.changePaymentMethod}" handleShippingCountryChange="{handleShippingCountryChange}" handleStripeElementRef="{this.handleStripeElementRef}" allowedShipToCountryCodes="{allowedShipToCountryCodes}" isCardValueValid="{cardValueValid}" isLoading="{loadingData}" isSubmitting="{submitting}" lineItems="{lineItems}" selectedPaymentMethod="{selectedPaymentMethod}" shouldEnableSeel="{shouldEnableSeel}" isMultiSellerCheckout="{isMultiSellerCheckout}" isStripeExpressCheckoutEnabled="{isStripeExpressCheckoutEnabled}" handleExpressCheckoutButtonClick="{this.handleExpressCheckoutButtonClick}" onConfirm="{onExpressCheckoutOnConfirm(values)}" onShippingAddressChange="{onExpressCheckoutShippingAddressChange}" shouldDisplayIncompleteAddressErrorMessage="{shouldDisplayIncompleteAddressErrorMessage}"></stripepaymentformmobile>
        )}
        {!isMobile && (
          <stripepaymentformdesktop defaultPaymentMethod="{defaultPaymentMethod}" error="{error}" form="{form}" handleChangePaymentMethod="{this.changePaymentMethod}" handleShippingCountryChange="{handleShippingCountryChange}" handleStripeElementRef="{this.handleStripeElementRef}" allowedShipToCountryCodes="{allowedShipToCountryCodes}" isCardValueValid="{cardValueValid}" isLoading="{loadingData}" isSubmitting="{submitting}" selectedPaymentMethod="{selectedPaymentMethod}" shouldEnableSeel="{shouldEnableSeel}" isMultiSellerCheckout="{isMultiSellerCheckout}" isStripeExpressCheckoutEnabled="{isStripeExpressCheckoutEnabled}" handleExpressCheckoutButtonClick="{this.handleExpressCheckoutButtonClick}" onConfirm="{onExpressCheckoutOnConfirm(values)}" onShippingAddressChange="{onExpressCheckoutShippingAddressChange}" shouldDisplayIncompleteAddressErrorMessage="{shouldDisplayIncompleteAddressErrorMessage}"></stripepaymentformdesktop>
        )}
        <div className="{css.submitContainer}">
          {!!hasPaymentErrors && (
            <span className="{css.errorMessage}">
              <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.red80="" }="" }}="">
                {paymentErrorMessage}
              </typographywrapper>
            </span>
          )}
          {submitting && (
            <div className="{css.submittingWarning}">
              <typographywrapper variant="body2" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.gray80="" }="" }}="">
                Your payment is being submitted. Please do not leave or refresh this page.
              </typographywrapper>
            </div>
          )}
          <button className="{css.submitButton}" type="submit" inProgress="{submitting}" disabled="{submitDisabled}">
            <formattedmessage id="StripePaymentForm.submitPaymentInfo"></formattedmessage>
          </button>
          <p className="{css.paymentInfo}">
            <typographywrapper variant="body2" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.gray40="" }="" }}="">
              {paymentInfo}
            </typographywrapper>
          </p>
        </div>
      </form>
    );
  }

  render() {
    return (
      <finalform onSubmit="{this.handleSubmit}" {...this.props}="" keepDirtyOnReinitialize="" Don't="" overwrite="" form="" values="" with="" initialvalues="" when="" items="" are="" removed="" from="" checkout="" mutators="{{" updateShippingAddress:="" constructAddressMutatorFn(SHIPPING_ADDRESS_PREFIX),="" touchFields:="" markFieldsAsTouched(SHIPPING_ADDRESS_PREFIX),="" }}="" render="{this.paymentForm}"></finalform>
    );
  }
}

StripePaymentFormComponent.defaultProps = {
  children: null,
  className: null,
  rootClassName: null,
  currentUser: null,
  loadingData: false,
  lineItems: [],
  defaultPaymentMethod: null,
  handleCardPaymentError: null,
  initialValues: null,
  isBrandDirect: false,
  shipFromCountries: [],
  allowedShipToCountryCodes: [],
  shipToCountry: null,
};

StripePaymentFormComponent.propTypes = {
  children: React.ReactNode,
  className: string,
  rootClassName: string,
  loadingData: bool,
  lineItems: array,
  handleCardPaymentError: object,
  paymentInfo: string.isRequired,
  initialValues: object,
  defaultPaymentMethod: propTypes.defaultPaymentMethod,
  handleShippingCountryChange: func.isRequired,
  isBrandDirect: bool,
  shouldEnableSeel: bool.isRequired,
  shipFromCountries: array,
  allowedShipToCountryCodes: array,
  shipToCountry: string,
  onPaymentSubmit: func.isRequired,
  onExpressCheckoutButtonClick: func.isRequired,
  onExpressCheckoutShippingAddressChange: func.isRequired,
  onExpressCheckoutOnConfirm: func.isRequired,
  isStripeExpressCheckoutEnabled: bool.isRequired,
  isMultiSellerCheckout: bool.isRequired,
  isExpressCheckoutError: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from state
  currentUser: propTypes.currentUser,
  fetchStripeCustomerStatus: string.isRequired,

  // from dispatch
  onSaveAddress: func.isRequired,
  onCreatePaymentIntent: func.isRequired,
  onHandleCardCharge: func.isRequired,
  onSavePaymentMethod: func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;
  const { fetchStripeCustomerStatus } = state.CheckoutPage;
  return { currentUser, fetchStripeCustomerStatus };
};

const mapDispatchToProps = (dispatch) => ({
  onSaveAddress: (params, transactionId) => dispatch(saveAddress(params, transactionId)),
  onCreatePaymentIntent: (params) => dispatch(createPaymentIntent(params)),
  onHandleCardCharge: (params) => dispatch(handleCardCharge(params)),
  onSavePaymentMethod: (stripeCustomerParam, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomerParam, stripePaymentMethodId)),
});

const StripePaymentForm = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(StripePaymentFormComponent);

export default StripePaymentForm;
</addressform>