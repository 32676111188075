/**
 * This component wraps React-Router's Redirect by providing name-based routing.
 * (Helps to narrow down the scope of possible format changes to routes.)
 */
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useRouteConfiguration } from '../../hooks/useRouteConfiguration';
import { pathByRouteName } from '../../util/routes';
import { buildNewSearchString } from '../../util/urlHelpers';

const NamedRedirect = (props) => {
  const { name, search, state, params, push } = props;

  const routes = useRouteConfiguration();
  const currentLocation = useLocation();

  const pathname = pathByRouteName(name, routes, params);
  const newSearchString = buildNewSearchString(currentLocation, search);
  return <redirect to="{{" pathname,="" search:="" newSearchString,="" state="" }}="" push="{push}"></redirect>;
};

const { bool, object, string } = PropTypes;

NamedRedirect.defaultProps = { search: '', state: {}, push: false, params: {} };

NamedRedirect.propTypes = {
  name: string.isRequired,
  search: string,
  state: object,
  push: bool,
  params: object,
};

export default NamedRedirect;
