import React, { Component } from 'react';
import { bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button, FormattedMessage, ModalInMobile, NamedLink, TypographyWrapper } from '..';
import { injectIntl, intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { defaultTreetStyles } from '../../shopConfig/config';

import css from './SearchFiltersMobile.module.css';

class SearchFiltersMobileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isFiltersOpenOnMobile: false, initialQueryParams: null };

    this.openFilters = this.openFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
  }

  // Open filters modal, set the initial parameters to current ones
  openFilters() {
    const { onOpenModal, urlQueryParams } = this.props;
    onOpenModal();
    this.setState({ isFiltersOpenOnMobile: true, initialQueryParams: urlQueryParams });
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    const { history, onCloseModal, treetId } = this.props;

    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(treetId),
        {},
        this.state.initialQueryParams
      )
    );
    onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false, initialQueryParams: null });
  }

  // Close the filter modal
  closeFilters() {
    this.props.onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false });
  }

  // Reset all filter query parameters
  resetAll(e) {
    this.props.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  render() {
    const {
      rootClassName,
      className,
      children,
      sortByComponent,
      listingsAreLoaded,
      resultsCount,
      searchInProgress,
      showAsModalMaxWidth,
      onManageDisableScrolling,
      selectedFiltersCount,
      intl,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const listItemLink = (
      <namedlink name="NewListingPage">
        <typography variant="body1" className="{css.link}">
          list your own item
        </typography>
      </namedlink>
    );

    const browseAllItemsLink = (
      <namedlink name="LandingPage" to="{{" search:="" 'mode="raw-query'" }}="">
        <typography variant="body1" className="{css.link}">
          browse all listings
        </typography>
      </namedlink>
    );

    const noResults =
      selectedFiltersCount > 0 ? (
        <typographywrapper variant="body1">
          <formattedmessage id="SearchFilters.noResultsWithFilters" values="{{" browseAllItemsLink="" }}=""></formattedmessage>
        </typographywrapper>
      ) : (
        <typographywrapper variant="body1">
          <formattedmessage id="SearchFiltersMobile.noResults" values="{{" listItemLink="" }}=""></formattedmessage>
        </typographywrapper>
      );
    const loadingResults = <formattedmessage id="SearchFiltersMobile.loadingResults"></formattedmessage>;
    const filtersHeading = intl.formatMessage({ id: 'SearchFiltersMobile.heading' });
    const modalCloseButtonMessage = intl.formatMessage({ id: 'SearchFiltersMobile.cancel' });

    const showListingsLabel = intl.formatMessage(
      { id: 'SearchFiltersMobile.showListings' },
      { count: resultsCount }
    );

    const filtersButtonClasses =
      selectedFiltersCount > 0 ? css.filtersButtonSelected : css.filtersButton;
    const hasResults = listingsAreLoaded && resultsCount > 0;
    const hasNoResults = listingsAreLoaded && resultsCount === 0;

    return (
      <div className="{classes}">
        <div className="{css.searchResultSummary}">
          {searchInProgress && (
            <typographywrapper variant="body1">{loadingResults}</typographywrapper>
          )}
          {hasNoResults && <typographywrapper variant="body1">{noResults}</typographywrapper>}
        </div>
        {hasResults && (
          <div className="{css.buttons}">
            <button rootClassName="{filtersButtonClasses}" onClick="{this.openFilters}">
              <formattedmessage id="SearchFiltersMobile.filtersButtonLabel" className="{css.mapIconText}"></formattedmessage>
            </button>
            {sortByComponent}
          </div>
        )}
        <modalinmobile id="SearchFiltersMobile.filters" isModalOpenOnMobile="{this.state.isFiltersOpenOnMobile}" onClose="{this.cancelFilters}" showAsModalMaxWidth="{showAsModalMaxWidth}" onManageDisableScrolling="{onManageDisableScrolling}" containerClassName="{css.modalContainer}" closeButtonMessage="{modalCloseButtonMessage}">
          <div className="{css.modalHeadingWrapper}">
            <span className="{css.modalHeading}">
              <typographywrapper variant="h2" typographyOverrides="{{" style:="" {="" fontWeight:="" 'bold'="" }="" }}="">
                {filtersHeading}
              </typographywrapper>
            </span>
            <button className="{css.resetAllButton}" onClick="{(e)" ==""> this.resetAll(e)} type="button">
              <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.gray40="" }="" }}="">
                <formattedmessage id="SearchFiltersMobile.clearAll"></formattedmessage>
              </typographywrapper>
            </button>
          </div>
          {this.state.isFiltersOpenOnMobile ? (
            <div className="{css.filtersWrapper}">{children}</div>
          ) : null}

          <div className="{css.showListingsContainer}">
            <button className="{css.showListingsButton}" onClick="{this.closeFilters}">
              {showListingsLabel}
            </button>
          </div>
        </modalinmobile>
      </div>
    );
  }
}

SearchFiltersMobileComponent.defaultProps = {
  rootClassName: null,
  className: null,
  sortByComponent: null,
  resultsCount: null,
  searchInProgress: false,
  selectedFiltersCount: 0,
  treetId: null,
};

SearchFiltersMobileComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  sortByComponent: node,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  showAsModalMaxWidth: number.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  resetAll: func.isRequired,
  selectedFiltersCount: number,

  // from injectIntl
  intl: intlShape.isRequired,

  // from state
  treetId: string,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const { treetId } = state.initial;
  return { treetId };
};

const SearchFiltersMobile = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(SearchFiltersMobileComponent);

export default SearchFiltersMobile;
