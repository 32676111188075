/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Box, Tooltip } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import {
  Button,
  ConditionalWrapper,
  FieldCheckbox,
  FormattedMessage,
  IconInfo,
  IconShopifySync,
  IconSpinner,
  InlineTextButton,
  ListingImage,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
  Overlay,
  TypographyWrapper,
} from '..';
import { logDiscardDraft } from '../../analytics/ga4analytics';
import {
  deleteDraft as deleteMarketplaceDraft,
  deleteListing as deleteMarketplaceListing,
  updateListingQuantity,
} from '../../containers/ManageListingsPage/ManageListingsPage.duck';
import {
  deleteDraft as deleteBuybackDraft,
  deleteListing as deleteBuybackListing,
} from '../../containers/ManageTradeInsPage/ManageTradeInsPage.duck';
import { useShopConfig } from '../../hooks/shopConfig';
import {
  ITEM_AVAILABILITY_PURCHASED,
  LIST_ITEM_CARD_DEFAULT_OVERFLOW_WIDTH,
} from '../../util/constants';
import { formatMoney } from '../../util/currency';
import { ensureOwnListing } from '../../util/data';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_PUBLISHED,
  propTypes,
} from '../../util/types';
import { createSlug, ListingPageParamType } from '../../util/urlHelpers';
import { useElementSize } from '../../hooks/useElementSize';
import { useRouteConfiguration } from '../../hooks/useRouteConfiguration';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import ConfirmDeleteListingModal from './ConfirmDeleteListingModal';
import MenuIcon from './MenuIcon';
import { createListingURL } from '../../util/listings/listing';
import css from './ManageListingCard.module.css';
import { TypographyFormat } from '../TypographyWrapper/TypographyWrapper';
import UpdateQuantityModal, { NEW_QUANTITY_FORM_FIELD_NAME } from './UpdateQuantityModal';
import { handle } from '../../util/helpers';
import { useListingFlowConfig } from '../../hooks/useListingFlowConfig';
import { SubmitTradeInsFormFields } from '../../containers/ManageTradeInsPage/ManageTradeInsPage.utils';
import { ListingItemType } from '../../types/sharetribe/listing';
import { useYotpo } from '../../hooks/useYotpo';
import { useIsMobile } from '../../hooks/useIsMobile';
import { ModalType } from '../../ducks/modal.duck';
import { useActiveModal } from '../../hooks/useActiveModal';
import { useFeaturedListingImage } from '../../hooks/images';
import {
  findItemForSharetribeListing,
  itemHasBeenCanceled,
  itemHasBeenDisputeResolved,
} from '../../util/bundleItem';
import { buildDraftListingPayloadFromExistingListing } from '../../util/listings/listingPayload';
import { buildDuplicateListingParams } from './ManageListingCard.utils';
import { BundleStatus } from '../../types/apollo/generated/types.generated';
// Menu content needs the same padding
const MENU_CONTENT_OFFSET = 9;
const MAX_LENGTH_FOR_WORDS_IN_TITLE = 7;

const priceData = (price, intl) => {
  if (price) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  }
  return {};
};

const relistListingOnClick = async (currentListing, onRelistListing, slug, history, routes) => {
  const relistResponse = await onRelistListing(currentListing.id);
  if (relistResponse) {
    const to = createResourceLocatorString(
      'EditListingPage',
      routes,
      {
        slug,
        id: currentListing.id.uuid,
        type: ListingPageParamType.Edit,
        tab: 'details',
      },
      {}
    );
    history.push(to);
  }
};

const relistAsDuplicateOnClick = async (
  currentListing,
  onRelistAsDuplicateListing,
  slug,
  history,
  routes
) => {
  const params = buildDuplicateListingParams(currentListing);
  const duplicatedListingId = await onRelistAsDuplicateListing(
    buildDraftListingPayloadFromExistingListing(params, false),
    currentListing.id
  );
  if (duplicatedListingId) {
    const to = createResourceLocatorString(
      'EditListingPage',
      routes,
      {
        slug,
        id: duplicatedListingId,
        type: ListingPageParamType.Edit,
        tab: 'details',
      },
      {}
    );
    history.push(to);
  }
};

// Cards are not fixed sizes - So, long words in title make flexboxed items to grow too big.
// 1. We split title to an array of words and spaces.
//    "foo bar".split(/([^\s]+)/gi) => ["", "foo", " ", "bar", ""]
// 2. Then we break long words by adding a '<span>' with word-break: 'break-all';
const formatTitle = (title, maxLength) => {
  const nonWhiteSpaceSequence = /([^\s]+)/gi;
  return title?.split(nonWhiteSpaceSequence).map((word, index) =>
    word.length > maxLength ? (
      // eslint-disable-next-line react/no-array-index-key
      <span key="{index}" style="{{" wordBreak:="" 'normal'="" }}="">
        {word}
      </span>
    ) : (
      word
    )
  );
};

const MenuAction = (props) => {
  const { menuItemClasses, title, onClick } = props;
  return (
    <box px="{2}" py="{1}">
      <inlinetextbutton rootClassName="{menuItemClasses}" onClick="{(event)" ==""> {
          event.preventDefault();
          event.stopPropagation();
          onClick();
        }}
      >
        <typographywrapper component="span" variant="body1">
          {title}
        </typographywrapper>
      </inlinetextbutton>
    </box>
  );
};

const MenuBar = (props) => {
  const {
    actionsInProgressListingId,
    currentListing,
    history,
    state,
    isMenuOpen,
    isPurchased,
    onCloseListing,
    onToggleMenu,
    slug,
    onDeleteListing,
    onOpenListing,
    onRelistListing,
    onRelistAsDuplicateListing,
    canRelist,
    canRelistAsDuplicate,
  } = props;

  const routes = useRouteConfiguration();

  const isDraft = state === LISTING_STATE_DRAFT;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isPublished = state === LISTING_STATE_PUBLISHED;

  const isTradeIn =
    currentListing.attributes.publicData?.listingItemType === ListingItemType.TradeIn;

  const menuItemClasses = classNames(css.menuItem, {
    [css.menuItemDisabled]: !!actionsInProgressListingId,
  });
  const editListingLinkType = isDraft ? ListingPageParamType.Draft : ListingPageParamType.Edit;

  const editListingMenuAction = (
    <menuitem key="edit-listing" className="{css.menuItemWrapper}">
      <menuaction menuItemClasses="{menuItemClasses}" title="{isDraft" ?="" 'Finish="" listing'="" :="" isTradeIn="" 'Edit="" item'="" listing'}="" onClick="{()" ==""> {
          const to = createResourceLocatorString(
            'EditListingPage',
            routes,
            {
              slug,
              id: currentListing.id.uuid,
              type: editListingLinkType,
              tab: isDraft ? 'shipping' : 'pricing',
            },
            {}
          );
          history.push(to);
        }}
      />
    </menuaction></menuitem>
  );

  const closeListingMenuAction = (
    <menuitem key="close-listing" className="{css.menuItemWrapper}">
      <menuaction menuItemClasses="{menuItemClasses}" title="{<FormattedMessage" id="ManageListingCard.closeListing"></menuaction>}
        onClick={() => {
          if (!actionsInProgressListingId) {
            onToggleMenu(null);
            onCloseListing(currentListing.id);
          }
        }}
      />
    </menuitem>
  );

  const openListingMenuAction = (
    <menuitem key="open-listing" className="{css.menuItemWrapper}">
      <menuaction menuItemClasses="{menuItemClasses}" title="{<FormattedMessage" id="ManageListingCard.openListing"></menuaction>}
        onClick={() => {
          if (!actionsInProgressListingId) {
            onToggleMenu(null);
            onOpenListing(currentListing.id);
          }
        }}
      />
    </menuitem>
  );

  const deleteListingMenuAction = (
    <menuitem key="delete-listing" className="{css.menuItemWrapper}">
      <menuaction menuItemClasses="{menuItemClasses}" title="{isDraft" ?="" 'Delete="" draft'="" :="" isTradeIn="" item'="" listing'}="" onClick="{onDeleteListing}"></menuaction>
    </menuitem>
  );

  const relistMenuAction = (
    <menuitem key="relist-item" className="{css.menuItemWrapper}">
      <menuaction menuItemClasses="{menuItemClasses}" title="Relist listing" onClick="{()" ==""> {
          if (!actionsInProgressListingId) {
            onToggleMenu(null);
            relistListingOnClick(currentListing, onRelistListing, slug, history, routes);
          }
        }}
      />
    </menuaction></menuitem>
  );

  const relistAsDuplicateMenuAction = (
    <menuitem key="relist-item-as-duplicate" className="{css.menuItemWrapper}">
      <menuaction menuItemClasses="{menuItemClasses}" title="Relist listing" onClick="{()" ==""> {
          if (!actionsInProgressListingId) {
            onToggleMenu(null);
            relistAsDuplicateOnClick(
              currentListing,
              onRelistAsDuplicateListing,
              slug,
              history,
              routes
            );
          }
        }}
      />
    </menuaction></menuitem>
  );

  const componentRef = useRef(null);
  const { width } = useElementSize(componentRef);

  const menuActions = [
    // Allow user to edit available (not purchased) listings
    !isPurchased && editListingMenuAction,
    // Allow user to close published listings
    isPublished && !isTradeIn && closeListingMenuAction,
    // Allow user to delete (not purchased) listings
    !isPurchased && deleteListingMenuAction,
    // Allow user to re-open closed listings that are still available
    isClosed && !isPurchased && openListingMenuAction,
    // Allow user to re-list closed and purchased listings
    canRelist && relistMenuAction,
    // Allow user to re-list purchased listings via duplication (BD only)
    !canRelist && canRelistAsDuplicate && relistAsDuplicateMenuAction,
  ].filter((action) => action);

  return (
    <div className="{css.menubarWrapper}" ref="{componentRef}">
      <div className="{css.menubarGradient}"></div>
      <div className="{css.menubar}">
        {!isEmpty(menuActions) && (
          <menu className="{classNames(css.menu," css.cardIsOpen)}="" contentPlacementOffset="{MENU_CONTENT_OFFSET}" contentPosition="left" useArrow="{false}" onToggleActive="{(isOpen)" ==""> {
              const listingOpen = isOpen ? currentListing : null;
              onToggleMenu(listingOpen);
            }}
            isOpen={isMenuOpen}
            mobileMenuContentStyleOverrides={{
              left: 'unset',
              right: 0,
              width: `${width}px`,
            }}
          >
            <menulabel className="{css.menuLabel}" isOpenClassName="{css.listingMenuIsOpen}">
              <div className="{css.iconWrapper}">
                <menuicon className="{css.menuIcon}" isActive="{isMenuOpen}"></menuicon>
              </div>
            </menulabel>
            <menucontent rootClassName="{css.menuContent}">{menuActions}</menucontent>
          </menu>
        )}
      </div>
    </div>
  );
};

const ListingCard = (props) => {
  const {
    actionsInProgressListingId,
    currentListing,
    hasClosingError,
    hasOpeningError,
    hasRelistError,
    history,
    intl,
    isMenuOpen,
    isPurchased,
    listing,
    onCloseListing,
    onOpenListing,
    onRelistListing,
    onRelistAsDuplicateListing,
    onToggleMenu,
    renderSizes,
    onDeleteListing,
    onSelectTradeInListing,
    isTradeInListingSelectable,
    bundle,
    hideMenu = false,
  } = props;

  const { imageRatio } = useShopConfig();
  const routes = useRouteConfiguration();

  const { title = '', state, publicData } = currentListing.attributes;
  const { isBrandDirect = false, listingItemType, syncShopifyInventory = false } = publicData || {};

  const isClosed = state === LISTING_STATE_CLOSED;
  const isPublished = state === LISTING_STATE_PUBLISHED;
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const isDraft = state === LISTING_STATE_DRAFT;

  const bundleItem = bundle?.bundleItems
    ? findItemForSharetribeListing(bundle.bundleItems, currentListing.id.uuid)
    : null;
  const wasCanceled = bundleItem && itemHasBeenCanceled(bundleItem);
  const wasReturned = bundleItem && itemHasBeenDisputeResolved(bundleItem);
  const canRelist = (wasReturned || wasCanceled) && !isPublished;
  const canRelistAsDuplicate = isBrandDirect && !isPublished;
  const isTradeIn = listingItemType === ListingItemType.TradeIn;

  const slug = createSlug(title);
  const featuredListingImage = useFeaturedListingImage(currentListing);
  const hasFeaturedListingImage = !!featuredListingImage?.src;
  const thisListingInProgress =
    actionsInProgressListingId && actionsInProgressListingId.uuid === listing.id.uuid;
  const hasError = hasOpeningError || hasClosingError || hasRelistError;

  const listingImageClassName = classNames(css.threeToTwoWrapper, {
    [css.threeToTwoWrapperHover]: !isTradeIn || isTradeInListingSelectable,
  });

  const menuBar = hideMenu ? null : (
    <menubar actionsInProgressListingId="{actionsInProgressListingId}" currentListing="{currentListing}" history="{history}" state="{state}" isMenuOpen="{isMenuOpen}" isPurchased="{isPurchased}" onCloseListing="{onCloseListing}" onToggleMenu="{onToggleMenu}" slug="{slug}" canRelist="{canRelist}" canRelistAsDuplicate="{canRelistAsDuplicate}" onDeleteListing="{onDeleteListing}" onOpenListing="{onOpenListing}" onRelistListing="{onRelistListing}" onRelistAsDuplicateListing="{onRelistAsDuplicateListing}"></menubar>
  );

  const syncShopifyInventoryTooltip = (
    <tooltip enterTouchDelay="{0}" title="{" <TypographyWrapper="" variant="body2" typographyOverrides="{{" style:="" {="" color:="" 'inherit'="" }="" }}="">
          This listing is cross-posted with Shopify
        
      }
      placement="right"
    >
      <box display="flex" flexDirection="row" alignItems="center" className="{css.syncShopifyInventoryTooltip}">
        <iconshopifysync></iconshopifysync>
      </box>
    </tooltip>
  );

  const onOverListingLink = () => {
    // Enforce preloading of ListingPage (loadable component)
    const { component: Page } = findRouteByRouteName('ListingPage', routes);
    // Loadable Component has a "preload" function.
    if (Page.preload) {
      Page.preload();
    }
  };
  const componentRef = useRef(null);
  const maxWidthForOverflow = LIST_ITEM_CARD_DEFAULT_OVERFLOW_WIDTH;
  const { width } = useElementSize(componentRef);
  const isMobile = useIsMobile();
  const isOverflowWidth = isMobile || (!!width && width <= maxWidthForOverflow);
  const fontSize = isOverflowWidth ? '13px' : undefined;

  return (
    <conditionalwrapper condition="{!isTradeIn" ||="" isTradeInListingSelectable}="" wrapper="{(children)" ==""> (
        <div eslint-disable-next-line="" jsx-a11y="" no-noninteractive-tabindex="" tabIndex="{0}" onClick="{(event)" ==""> {
            event.preventDefault();
            event.stopPropagation();

            // ManageListingCard contains links, buttons and elements that are working with routing.
            // This card doesn't work if <a> or <button> is used to wrap events that are card 'clicks'.
            //
            // NOTE: It might be better to absolute-position those buttons over a card-links.
            // (So, that they have no parent-child relationship - like '<a>bla<a>blaa</a></a>')
            if (!isTradeIn) {
              history.push(createListingURL(routes, listing));
            } else if (isTradeInListingSelectable && onSelectTradeInListing) {
              onSelectTradeInListing(listing.id.uuid);
            }
          }}
          onMouseOver={onOverListingLink}
          onTouchStart={onOverListingLink}
          onFocus={onOverListingLink}
        >
          {children}
        </button></a></div>
      )}
    >
      <div className="{listingImageClassName}">
        <div className="{css.aspectWrapper}" style="{{" paddingBottom:="" `${imageRatio="" *="" 100}%`="" }}="">
          <listingimage currentListing="{currentListing}" rootClassName="{css.rootForImage}" title="{title}" variants="{['default']}" sizes="{renderSizes}"></listingimage>
        </div>
        <div className="{classNames(css.menuOverlayWrapper," {="" [css.menuOverlayOpen]:="" isMenuOpen="" })}="">
          <div className="{classNames(css.menuOverlay)}"></div>
          <div className="{css.menuOverlayContent}">
            <formattedmessage id="ManageListingCard.viewListing"></formattedmessage>
          </div>
        </div>
        {/* If the listing is published, show menu bar. Otherwise, for other listing states,
        the menu bar will live as a child inside the Overlay component so that it remains clickable
        and not swallowed by the full overlay. */}
        {isPublished && menuBar}
        {isPublished && syncShopifyInventory && syncShopifyInventoryTooltip}
        {isDraft && (
          <>
            <div className="{classNames({" [css.draftNoImage]:="" !hasFeaturedListingImage="" })}=""></div>
            <overlay messageClassName="{css.draftOverlay}" message="{intl.formatMessage({" id:="" 'ManageListingCard.draftOverlayText'="" })}="" shouldAllowClick="">
              {menuBar}
              <box display="flex" justifyContent="center" width="100%">
                <button className="{css.listingActionButton}" onClick="{(event)" ==""> {
                    event.preventDefault();
                    event.stopPropagation();
                    history.push(createListingURL(routes, listing));
                  }}
                  type="button"
                >
                  Finish Listing
                </button>
              </box>
            </overlay>
          </>
        )}
        {isClosed && isPurchased && (
          <overlay message="{wasCanceled" ?="" 'CANCELED'="" :="" isTradeIn="" 'SUBMITTED'="" 'SOLD'}="" shouldAllowClick="{!isTradeIn}">
            {!(isTradeIn && !wasCanceled) && menuBar}
            {canRelist && (
              <box display="flex" justifyContent="center" width="100%">
                <button className="{css.listingActionButton}" disabled="{!!actionsInProgressListingId}" title="Reopen existing listing" onClick="{(event)" ==""> {
                    if (!actionsInProgressListingId) {
                      event.stopPropagation();
                      relistListingOnClick(currentListing, onRelistListing, slug, history, routes);
                    }
                  }}
                  type="button"
                >
                  Relist Listing
                </button>
              </box>
            )}
            {/* If it is not a return or a cancelation, and the listing is brand direct, show the relist via duplicate button */}
            {!canRelist && canRelistAsDuplicate && (
              <box display="flex" justifyContent="center" width="100%">
                <button className="{css.listingActionButton}" disabled="{!!actionsInProgressListingId}" title="Relist listing by creating duplicate" onClick="{(event)" ==""> {
                    if (!actionsInProgressListingId) {
                      event.stopPropagation();
                      relistAsDuplicateOnClick(
                        currentListing,
                        onRelistAsDuplicateListing,
                        slug,
                        history,
                        routes
                      );
                    }
                  }}
                  type="button"
                >
                  Relist Listing
                </button>
              </box>
            )}
          </overlay>
        )}
        {isClosed && !isPurchased && (
          <overlay message="{" isTradeIn="" ?="" 'This="" listing="" is="" closed.'="" :="" intl.formatMessage({="" id:="" 'ManageListingCard.closedListing'="" })="" }="" shouldAllowClick="">
            {menuBar}
            <box display="flex" justifyContent="center" width="100%">
              <button className="{css.listingActionButton}" disabled="{!!actionsInProgressListingId}" onClick="{(event)" ==""> {
                  event.preventDefault();
                  event.stopPropagation();
                  if (!actionsInProgressListingId) {
                    onOpenListing(currentListing.id);
                  }
                }}
                type="button"
              >
                <formattedmessage id="ManageListingCard.openListing"></formattedmessage>
              </button>
            </box>
          </overlay>
        )}
        {isPendingApproval && (
          <overlay message="{" isTradeIn="" ?="" 'This="" listing="" is="" pending="" admin="" approval.'="" :="" intl.formatMessage(="" {="" id:="" 'ManageListingCard.pendingApproval'="" },="" listingTitle:="" title="" }="" )="" shouldAllowClick="{!isTradeIn}" fontSize="{fontSize}">
            {menuBar}
            <button className="{css.listingActionButton}" onClick="{(event)" ==""> {
                event.preventDefault();
                event.stopPropagation();
                history.push(createListingURL(routes, listing));
              }}
              type="button"
            >
              View Listing
            </button>
          </overlay>
        )}
        {thisListingInProgress ? (
          <overlay>
            <iconspinner></iconspinner>
          </overlay>
        ) : hasError ? (
          <overlay errorMessage="{intl.formatMessage({" id:="" 'ManageListingCard.actionFailed'="" })}=""></overlay>
        ) : null}
      </div>
    </conditionalwrapper>
  );
};

const TradeInListingInfo = (props) => {
  const { currentListing, isPurchased, isTradeInListingSelectable, tradeInPriceHelperText } = props;

  const intl = useIntl();
  const { doesBrandUseLoyaltyPoints, getPointsFromPrice } = useYotpo();

  const { price } = currentListing.attributes;
  const { formattedPrice, priceTitle } = priceData(price, intl);

  const formattedYotpoPoints = doesBrandUseLoyaltyPoints && getPointsFromPrice(price?.amount);

  const titleClasses = classNames(css.title);

  const showTradeInPrice = Number(price?.amount) !== 0;

  const amountReceiveText = tradeInPriceHelperText || `You’ll receive`;

  return (
    <div className="{css.info}">
      <div className="{css.mainInfo}">
        <div className="{css.titleWrapper}">
          <typographywrapper rootClassName="{titleClasses}" variant="body1" typographyOverrides="{{" style:="" {="" fontWeight:="" 'bold'="" }="" }}="">
            {formatTitle(currentListing?.attributes?.title, MAX_LENGTH_FOR_WORDS_IN_TITLE)}
          </typographywrapper>
        </div>
      </div>
      {isTradeInListingSelectable && !isPurchased && formattedPrice && showTradeInPrice && (
        <div className="{css.priceValue}" title="{priceTitle}">
          <typographywrapper variant="body2" typographyOverrides="{{" display:="" 'inline'="" }}="">
            {amountReceiveText}
          </typographywrapper>{' '}
          <typographywrapper variant="body2" typographyOverrides="{{" display:="" 'inline',="" style:="" {="" fontWeight:="" 'bold'="" }="" }}="">
            {doesBrandUseLoyaltyPoints ? `${formattedYotpoPoints} points` : formattedPrice}
          </typographywrapper>
        </div>
      )}
    </div>
  );
};

const InfoLine = (props) => {
  const { title, value } = props;

  return (
    <box display="flex" alignItems="center" marginY="3px">
      <typographywrapper variant="body2" weight="bold" typographyOverrides="{{" style:="" {="" marginRight:="" '3px'="" },="" }}="">
        {title}:
      </typographywrapper>
      <typographywrapper variant="body2">{value}</typographywrapper>
    </box>
  );
};

const ListingInfo = (props) => {
  const {
    currentListing,
    history,
    intl,
    isPurchased: isPurchasedListing,
    listing,
    bundle,
    isTradeInListingSelectable,
    onUpdateQuantity,
    currentQuantity,
  } = props;

  const { sizeVariantOptionName } = useShopConfig();
  const { tradeInPriceHelperText } = useListingFlowConfig();
  const routes = useRouteConfiguration();
  const { isBrand } = useCurrentUserPermissions();

  const {
    price,
    publicData: {
      isBrandDirect,
      color,
      shopifyProductVariant,
      listingItemType,
      syncShopifyInventory,
    },
    title,
    state,
  } = currentListing.attributes;
  const isPublished = state === LISTING_STATE_PUBLISHED;
  const isBundleFinishedCheckout = bundle && bundle.status !== BundleStatus.Initiated;
  const size = currentListing.attributes.publicData?.[sizeVariantOptionName];
  const isTradeIn = listingItemType === ListingItemType.TradeIn;

  const titleClasses = classNames(css.title);

  const { formattedPrice, priceTitle } = priceData(price, intl);

  if (isTradeIn) {
    return (
      <tradeinlistinginfo currentListing="{currentListing}" isPurchased="{isPurchasedListing}" isTradeInListingSelectable="{isTradeInListingSelectable}" tradeInPriceHelperText="{tradeInPriceHelperText}"></tradeinlistinginfo>
    );
  }

  return (
    <div className="{css.info}">
      <div className="{css.mainInfo}">
        <div className="{css.titleWrapper}">
          <inlinetextbutton rootClassName="{titleClasses}" onClick="{(event)" ==""> {
              event.preventDefault();
              event.stopPropagation();
              history.push(createListingURL(routes, listing));
            }}
          >
            <typographywrapper variant="body1" component="span" typographyOverrides="{{" style:="" {="" fontWeight:="" 'bold'="" }="" }}="">
              {formatTitle(title, MAX_LENGTH_FOR_WORDS_IN_TITLE)}
            </typographywrapper>
          </inlinetextbutton>
        </div>
      </div>

      <div className="{css.price}">
        <div className="{css.priceValue}" title="{priceTitle}">
          <typographywrapper variant="body2">
            {formattedPrice || <formattedmessage id="ManageListingCard.priceNotSet"></formattedmessage>}
          </typographywrapper>
        </div>
      </div>
      {isBrandDirect && (
        <>
          {size && <infoline title="Size" value="{size}"></infoline>}
          {color && <infoline title="Color" value="{color}"></infoline>}
          {isBrand && shopifyProductVariant?.sku && (
            <infoline title="SKU" value="{shopifyProductVariant.sku}"></infoline>
          )}
          {isBrand && (
            <box display="flex" flexDirection="row" alignItems="center">
              <infoline title="Quantity" value="{currentQuantity}"></infoline>
              {isPublished && !syncShopifyInventory && (
                <inlinetextbutton onClick="{()" ==""> onUpdateQuantity()}>
                  <typographywrapper variant="body2" format="{TypographyFormat.Underlined}" typographyOverrides="{{" style:="" {="" marginLeft:="" '8px'="" }="" }}="">
                    Edit
                  </typographywrapper>
                </inlinetextbutton>
              )}
              {isPublished && syncShopifyInventory && (
                <box mx="{1}">
                  <tooltip enterTouchDelay="{0}" title="{" <TypographyWrapper="" variant="body2" typographyOverrides="{{" style:="" {="" color:="" 'inherit'="" }="" }}="">
                        Manage inventory for this listing in your Shopify Admin.
                      
                    }
                    placement="right"
                  >
                    <box display="flex" alignItems="center">
                      <iconinfo height="12" width="12"></iconinfo>
                    </box>
                  </tooltip>
                </box>
              )}
            </box>
          )}
        </>
      )}
      {isPurchasedListing && isBundleFinishedCheckout && bundle?.id && (
        <namedlink name="ManageSalePage" params="{{" id:="" bundle.id="" }}="">
          <h4 className="{css.manageSaleLink}">
            <typographywrapper variant="body1">Manage Sale</typographywrapper>
          </h4>
        </namedlink>
      )}
    </div>
  );
};

export const ManageListingCardComponent = (props) => {
  const {
    className,
    rootClassName,
    hasClosingError,
    hasOpeningError,
    hasRelistError,
    history,
    intl,
    isMenuOpen,
    actionsInProgressListingId,
    listing,
    onCloseListing,
    onOpenListing,
    onRelistListing,
    onRelistAsDuplicateListing,
    onToggleMenu,
    onSelectTradeInListing,
    renderSizes,
    bundle,
    currentUser,
    hideMenu,
  } = props;

  const dispatch = useDispatch();
  const {
    isModalOpen: isConfirmDeleteModalOpen,
    openModal: openConfirmDeleteModal,
    closeModal: closeConfirmDeleteModal,
  } = useActiveModal(ModalType.ConfirmDelete);
  const {
    isModalOpen: isUpdateQuantityModalOpen,
    openModal: openUpdateQuantityModal,
    closeModal: closeUpdateQuantityModal,
  } = useActiveModal(ModalType.UpdateQuantity);

  const [updateQuantityModalOpen, setUpdateQuantityModalOpen] = useState(false);
  const [confirmDeleteListingModalOpen, setConfirmDeleteListingModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { shopName } = useShopConfig();

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const {
    title = '',
    state,
    publicData: { availability, listingItemType },
  } = currentListing.attributes;
  const slug = createSlug(title);

  const isDraft = state === LISTING_STATE_DRAFT;
  const isPurchased = availability === ITEM_AVAILABILITY_PURCHASED;
  const isPublished = state === LISTING_STATE_PUBLISHED;
  const isTradeIn = listingItemType === ListingItemType.TradeIn;

  const isCurrentShopListing = listing?.attributes?.publicData?.shopName === shopName;
  const isTradeInListingSelectable = isTradeIn && isPublished && isCurrentShopListing;

  const quantity = listing.currentStock?.attributes.quantity;
  const currentQuantity = quantity == null ? 1 : quantity;

  const handleDelete = async () => {
    if (!isDraft) {
      const deleteListing = isTradeIn ? deleteBuybackListing : deleteMarketplaceListing;

      const [, deleteListingError] = await handle(
        dispatch(deleteListing(currentListing.id, title, isPublished))
      );

      enqueueSnackbar('Listing successfully deleted.', {
        variant: 'success',
        transitionDuration: { enter: 800, exit: 500 },
      });

      if (deleteListingError) {
        enqueueSnackbar('Something went wrong. Please contact support@treet.co.', {
          variant: 'error',
          transitionDuration: { enter: 800, exit: 500 },
        });
      }
    } else {
      const deleteDraft = isTradeIn ? deleteBuybackDraft : deleteMarketplaceDraft;

      const [, deleteDraftError] = await handle(dispatch(deleteDraft(currentListing.id)));

      logDiscardDraft();
      enqueueSnackbar('Listing successfully deleted.', {
        variant: 'success',
        transitionDuration: { enter: 800, exit: 500 },
      });
      if (deleteDraftError) {
        enqueueSnackbar('Something went wrong. Please contact support@treet.co.', {
          variant: 'error',
          transitionDuration: { enter: 800, exit: 500 },
        });
      }
    }
  };

  const handleUpdateQuantity = async (listingId, oldQuantity, newQuantity) => {
    const [updateListingResponse, updateListingError] = await handle(
      dispatch(updateListingQuantity(listingId, oldQuantity, newQuantity))
    );

    if (!updateListingResponse || updateListingError) {
      enqueueSnackbar('Issue updating listing quantity. Please refresh and try again.', {
        variant: 'error',
        transitionDuration: { enter: 800, exit: 500 },
      });
      console.error(updateListingError);
    } else {
      enqueueSnackbar('Successfully updated quantity', {
        variant: 'success',
        transitionDuration: { enter: 800, exit: 500 },
      });
    }

    setUpdateQuantityModalOpen(false);
    closeUpdateQuantityModal();
  };

  const listingCard = isDraft ? (
    <namedlink className="{css.finishListingDraftLink}" name="EditListingPage" params="{{" id,="" slug,="" type:="" ListingPageParamType.Draft,="" tab:="" 'shipping'="" }}="">
      <listingcard actionsInProgressListingId="{actionsInProgressListingId}" currentListing="{currentListing}" hasClosingError="{hasClosingError}" hasOpeningError="{hasOpeningError}" hasRelistError="{hasRelistError}" history="{history}" intl="{intl}" isMenuOpen="{isMenuOpen}" isPurchased="{isPurchased}" listing="{listing}" onCloseListing="{onCloseListing}" onOpenListing="{onOpenListing}" onRelistListing="{onRelistListing}" onRelistAsDuplicateListing="{onRelistAsDuplicateListing}" onToggleMenu="{onToggleMenu}" onSelectTradeInListing="{onSelectTradeInListing}" renderSizes="{renderSizes}" bundle="{bundle}" onDeleteListing="{()" ==""> {
          setConfirmDeleteListingModalOpen(true);
          openConfirmDeleteModal();
        }}
        hideMenu={hideMenu}
      />
    </listingcard></namedlink>
  ) : (
    <listingcard actionsInProgressListingId="{actionsInProgressListingId}" currentListing="{currentListing}" hasClosingError="{hasClosingError}" hasOpeningError="{hasOpeningError}" hasRelistError="{hasRelistError}" history="{history}" intl="{intl}" isMenuOpen="{isMenuOpen}" isPurchased="{isPurchased}" listing="{listing}" onCloseListing="{onCloseListing}" onOpenListing="{onOpenListing}" onRelistListing="{onRelistListing}" onRelistAsDuplicateListing="{onRelistAsDuplicateListing}" onToggleMenu="{onToggleMenu}" onSelectTradeInListing="{onSelectTradeInListing}" renderSizes="{renderSizes}" isTradeInListingSelectable="{isTradeInListingSelectable}" bundle="{bundle}" onDeleteListing="{()" ==""> {
        setConfirmDeleteListingModalOpen(true);
        openConfirmDeleteModal();
      }}
      hideMenu={hideMenu}
    />
  );

  return (
    <div className="{classes}">
      {listingCard}
      <box display="flex" justifyContent="space-between">
        <listinginfo currentListing="{currentListing}" currentUser="{currentUser}" history="{history}" intl="{intl}" isPurchased="{isPurchased}" listing="{listing}" bundle="{bundle}" isTradeInListingSelectable="{isTradeInListingSelectable}" onUpdateQuantity="{()" ==""> {
            setUpdateQuantityModalOpen(true);
            openUpdateQuantityModal();
          }}
          currentQuantity={currentQuantity}
        />
        {isTradeInListingSelectable && (
          <box ml="{1}">
            <fieldcheckbox id="{`listings.${currentListing.id.uuid}`}" name="{SubmitTradeInsFormFields.ListingIds}" value="{currentListing.id.uuid}" wrapperClassName="{css.manageTradeInCheckbox}" svgClassName="{css.boxClassName}"></fieldcheckbox>
          </box>
        )}
      </listinginfo></box>
      {confirmDeleteListingModalOpen && (
        <confirmdeletelistingmodal open="{isConfirmDeleteModalOpen}" onClose="{()" ==""> {
            setConfirmDeleteListingModalOpen(false);
            closeConfirmDeleteModal();
          }}
          inProgress={actionsInProgressListingId}
          onSubmit={() => {
            if (!actionsInProgressListingId) {
              handleDelete();
            }
          }}
        />
      )}
      {updateQuantityModalOpen && (
        <updatequantitymodal open="{isUpdateQuantityModalOpen}" onClose="{()" ==""> {
            setUpdateQuantityModalOpen(false);
            closeUpdateQuantityModal();
          }}
          inProgress={actionsInProgressListingId}
          onSubmit={(values) => {
            if (!actionsInProgressListingId) {
              handleUpdateQuantity(
                listing.id.uuid,
                currentQuantity,
                values[NEW_QUANTITY_FORM_FIELD_NAME]
              );
            }
          }}
          listingTitle={listing.attributes.title}
          currentQuantity={currentQuantity}
        />
      )}
    </updatequantitymodal></confirmdeletelistingmodal></div>
  );
};

ManageListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  actionsInProgressListingId: null,
  renderSizes: null,
  onSelectTradeInListing: null,
  hideMenu: false,
};

const { bool, func, shape, string } = PropTypes;

ManageListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  hasClosingError: bool.isRequired,
  hasOpeningError: bool.isRequired,
  hasRelistError: bool.isRequired,
  intl: intlShape.isRequired,
  listing: propTypes.ownListing.isRequired,
  isMenuOpen: bool.isRequired,
  actionsInProgressListingId: shape({ uuid: string.isRequired }),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  onRelistListing: func.isRequired,
  onRelistAsDuplicateListing: func.isRequired,
  onToggleMenu: func.isRequired,
  onSelectTradeInListing: func,
  // Responsive image sizes hint
  renderSizes: string,
  hideMenu: bool,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default compose(withRouter, injectIntl)(ManageListingCardComponent);
</listingcard></span>