import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';
import ISOHeader from './ISOHeader';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import { IconGemFilled, ShopifyProducts, ShopifyProductSearch } from '..';
import { ShopifyProductsSearchFormField } from '../Shopify/Shopify.utils';
import { required } from '../../util/validators';

const ISOSearchPane: FC = () => {
  const { values } = useFormState();

  const isSearchActive = values[ShopifyProductsSearchFormField.IsSearchActive];

  return (
    <box display="flex" flexDirection="column" height="100%">
      <isoheader headerCopy="Submit An Item Request"></isoheader>
      <box 5="" display="flex" flexDirection="column" alignItems="center" pt="{{" xs:="" 10,="" md:="" }}="" px="{2}">
        <box pb="{1}">
          <icongemfilled></icongemfilled>
        </box>
        <typographywrapper variant="body1" typographyOverrides="{{" align:="" 'center'="" }}="">
          We’ll find the exact item you have in mind and let you know when it lists!
        </typographywrapper>
      </box>
      <box 3="" 4="" display="flex" flexDirection="column" flexGrow="{1}" px="{{" xs:="" 2,="" sm:="" }}="" pt="{{" 5,="" md:="" pb="{{" 12,="" position="relative">
        <shopifyproductsearch></shopifyproductsearch>
        {!isSearchActive && <shopifyproducts></shopifyproducts>}
        <field TODO:="" May="" need="" to="" change="" a="" different="" field="" that="" is="" shared="" between="" shopify="" product="" AND="" cannot="" find="" my="" item="" flow="" id="shopifyProduct" name="shopifyProduct" input="{<input" type="hidden"></field>}
          validate={required('Please select an item.')}
          hidden
          component="input"
        />
      </box>
    </box>
  );
};

export default ISOSearchPane;
