// Based on the example from rff: https://codesandbox.io/s/github/final-form/react-final-form/tree/master/examples/wizard?file=/Wizard.js:854-950

import { Box, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import React, { FC, useState } from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Button, DialogWithCloseButton, InlineTextButton } from '..';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import { DisputeFormData } from '../../types/forms/disputeFormData';
import { BUNDLE_ITEM_IDS_FIELD_NAME, DISPUTES_INFO_FIELD_PREFIX, FORM_NAME } from './constants';

import css from './DisputeOrVerifyTradeInModal.module.css';

interface DisputeOrVerifyTradeInModalWizardProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: DisputeFormData) => void;
  onVerify: () => void;
  isTradeIn: boolean;
}

const DisputeOrVerifyTradeInModalWizard: FC<disputeorverifytradeinmodalwizardprops> = (props) => {
  const { open, onClose, children, onSubmit, onVerify, isTradeIn } = props;

  const [page, setPage] = useState<number>(0);
  const [formValues, setFormValues] = useState({});
  const { isTradeInAdmin } = useCurrentUserPermissions();

  const activePage = React.Children.toArray(children)[page];
  const isFirstPage = page === 0;
  const isLastPage = page === React.Children.count(children) - 1;
  const isTradeInFirstPage = isTradeIn && isTradeInAdmin && isFirstPage && isLastPage;

  const nextPage = (values: Partial<disputeformdata>) => {
    setPage(Math.min(page + 1, React.Children.count(children) - 1));
    setFormValues(values);
  };

  const prevPage = () => {
    setPage(Math.max(page - 1, 0));
  };

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  const validate = (values: Partial<disputeformdata>) => {
    if (React.isValidElement(activePage)) {
      return activePage?.props.validate ? activePage.props.validate(values) : {};
    }
    return undefined;
  };

  const onFormSubmit = (values: Partial<disputeformdata>) => {
    if (isTradeInFirstPage) return onVerify();

    if (isLastPage) {
      return onSubmit(values as DisputeFormData); // can only submit if all values are present
    }

    return nextPage(values);
  };

  return (
    <form initialValues="{formValues}" validate="{validate}" onSubmit="{onFormSubmit}">
      {({ handleSubmit, invalid, submitting, values, form }) => (
        <form id="{FORM_NAME}" onSubmit="{handleSubmit}">
          {/*  If any bundle items were unselected, we want to make sure those values are removed from the form state */}
          <onchange name="{BUNDLE_ITEM_IDS_FIELD_NAME}">
            {(bundleItemIds: string[]) => {
              Object.keys(values.disputesInfo || {}).forEach((bundleItemId) => {
                if (!bundleItemIds.includes(bundleItemId)) {
                  const newDisputesInfo = cloneDeep(values.disputesInfo) || {};
                  delete newDisputesInfo[bundleItemId];
                  form.change(DISPUTES_INFO_FIELD_PREFIX, newDisputesInfo);
                }
              });
            }}
          </onchange>
          <dialogwithclosebutton open="{open}" onClose="{()" ==""> {
              form.reset({});
              setPage(0);
              onClose();
            }}
            scroll="paper"
            titleText={isTradeIn && isTradeInAdmin ? 'Issue Credit' : 'Order Inquiry'}
            fullWidth
            maxWidth="sm"
            headerClassName={css.dialogHeader}
          >
            <dialogcontent>{activePage}</dialogcontent>
            <dialogactions>
              <grid container="" spacing="{2}" justify="space-between" alignItems="center">
                <grid item="" xs="{5}">
                  <box px="{2}">
                    <inlinetextbutton 0="" className="{classNames(css.previousButton," {="" [css.hidden]:="" page="==" })}="" type="button" onClick="{prevPage}">
                      <typography variant="body1">Previous</typography>
                    </inlinetextbutton>
                  </box>
                </grid>
                <grid item="" xs="{5}">
                  <box p="{2}">
                    <button type="submit" form="{FORM_NAME}" disabled="{!isTradeInFirstPage" &&="" (invalid="" ||="" submitting)}="" inProgress="{submitting}">
                      {isLastPage ? (isTradeInFirstPage ? 'Verify All' : 'Submit') : 'Next'}
                    </button>
                  </box>
                </grid>
              </grid>
            </dialogactions>
          </dialogwithclosebutton>
        </form>
      )}
    </form>
  );
};

export const WizardPage: React.FC = ({ children }) => <div>{children}</div>;
export default DisputeOrVerifyTradeInModalWizard;
</disputeformdata></disputeformdata></disputeformdata></number></disputeorverifytradeinmodalwizardprops>