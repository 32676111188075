import {
  Box,
  Card,
  CardActionArea,
  DialogActions,
  DialogContent,
  Grid,
  withStyles,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';
import PropTypes, { shape } from 'prop-types';
import React from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import {
  Button,
  DialogWithCloseButton,
  ExternalLink,
  Logo,
  NamedLink,
  TypographyWrapper,
} from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import { PayoutOptions } from '../../util/constants';
import {
  getBankAccountLast4Digits,
  getStripeAccountData,
  hasFutureRequirementsMissing,
  hasRequirementsMissing,
} from '../../util/stripe';
import { required } from '../../util/validators';
import css from './PayoutMethodModal.module.css';
import { isGiftCardOption } from '../../shopConfig/shops/default';
import { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { useYotpo } from '../../hooks/useYotpo';
import { defaultTreetStyles } from '../../shopConfig/config';

const styles = {
  card: {
    height: '100%',
  },
  cardActionArea: {
    height: '100%',
    padding: '20px',
  },
  cardActionAreaSelected: {
    border: `4px solid ${defaultTreetStyles.gray80}`,
    'box-shadow': '0px 4px 16px 0px rgba(0, 0, 0, 0.30)',
    'transition-property': 'border',
    'transition-duration': '0.2s',
  },
  cardActionAreaDisabled: {
    color: 'lightgray',
  },
  box: {
    height: '100%',
  },
};

/**
 *
 * @param {*} input The input that will change when a payoutoption is selected
 * @param {*} payoutOption PayoutOptions type
 * @param {*} classes styling
 * @param {*} titleEl string or element to dislay in the title of the card
 * @param {*} descriptionEl string or element to display in the description section of the card
 * @param {*} logoEl logo to show on the bottom of the card
 * @param {*} disabled disable selection of the payout option
 * @returns
 */
function renderPayoutOptionCard(
  input,
  payoutOption,
  classes,
  titleEl,
  descriptionEl,
  logoEl,
  disabled = false
) {
  const { creditPayoutOption } = useShopConfig();
  const { doesBrandUseLoyaltyPoints } = useYotpo();
  const isPayoutGiftCard = isGiftCardOption(creditPayoutOption);

  return (
    <grid item="" xs="{12}" sm="{6}">
      <card variant="outlined" className="{classes.card}">
        <cardactionarea className="{classNames(classes.cardActionArea," {="" [classes.cardActionAreaSelected]:="" input.value="==" payoutOption,="" [classes.cardActionAreaDisabled]:="" disabled,="" })}="" onClick="{()" ==""> input.onChange(payoutOption)}
          disabled={disabled}
        >
          <box display="flex" flexDirection="column" alignItems="center" alignContent="center" justifyContent="space-between" className="{classes.box}">
            <typographywrapper variant="body1" weight="{TypographyWeight.Bold}" typographyOverrides="{{" style:="" {="" margin:="" '4px="" 0'="" }="" }}="">
              {payoutOption === PayoutOptions.Credit ? 'Brand Credit' : 'Cash'}
            </typographywrapper>
            <typographywrapper variant="h2" typographyOverrides="{{" style:="" {="" margin:="" '4px="" 0'="" }="" }}="">
              {titleEl}
            </typographywrapper>
            <typographywrapper variant="body2" typographyOverrides="{{" style:="" {="" margin:="" '4px="" 0',="" textAlign:="" 'center'="" }="" }}="">
              {descriptionEl}
            </typographywrapper>
            {logoEl}
            {payoutOption === PayoutOptions.Credit && !doesBrandUseLoyaltyPoints && (
              <typographywrapper variant="body2" typographyOverrides="{{" style:="" {="" fontSize:="" '12px'="" }="" }}="">
                {isPayoutGiftCard
                  ? '*Issued via digital gift card.'
                  : '*Issued via discount code. Limited to one use.'}
              </typographywrapper>
            )}
          </box>
        </cardactionarea>
      </card>
    </grid>
  );
}

const LoyaltyPointsPayoutOptionCard = (props) => {
  const { input, classes, credit, creditAmount } = props;
  const { shopName } = useShopConfig();
  const { getPointsFromPrice } = useYotpo();
  return renderPayoutOptionCard(
    input,
    PayoutOptions.Credit,
    classes,
    <>
      <typographywrapper variant="h2" typographyOverrides="{{" display:="" 'inline'="" }}="">
        {getPointsFromPrice(creditAmount)} Points{' '}
      </typographywrapper>
      <typographywrapper variant="h2" typographyOverrides="{{" display:="" 'inline'="" }}="" weight="{TypographyWeight.Bold}">
        Worth {credit}*
      </typographywrapper>
    </>,
    <typographywrapper variant="body1" typographyOverrides="{{" align:="" 'center'="" }}="">
      Get {getPointsFromPrice(creditAmount)} points (worth {credit}) to use on your next purchase at{' '}
      {shopName}
    </typographywrapper>,
    <logo className="{css.logo}" type="original"></logo>
  );
};

LoyaltyPointsPayoutOptionCard.propTypes = {
  input: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  credit: PropTypes.string.isRequired,
  creditAmount: PropTypes.number.isRequired,
};

const BrandCreditPayoutOptionCard = (props) => {
  const { input, classes, credit, creditInBrandShopifyCurrency } = props;
  const { shopName, mainSite } = useShopConfig();
  const shopNameHyperlinked = (
    <externallink className="{css.externalLink}" href="{mainSite}">
      <typographywrapper component="span" variant="body2" typographyOverrides="{{" display:="" 'inline'="" }}="">
        {shopName}
      </typographywrapper>
    </externallink>
  );
  return renderPayoutOptionCard(
    input,
    PayoutOptions.Credit,
    classes,
    `Credit Value (${creditInBrandShopifyCurrency || credit})`,
    <>
      Get{' '}
      <typographywrapper variant="body2" component="span" weight="{TypographyWeight.Bold}" typographyOverrides="{{" display:="" 'inline'="" }}="">
        {credit}
      </typographywrapper>
      {creditInBrandShopifyCurrency ? ', issued as '.concat(creditInBrandShopifyCurrency, ',') : ''}{' '}
      off your next purchase on {shopNameHyperlinked} (Instant)*
    </>,
    <logo className="{css.logo}" type="original"></logo>
  );
};

BrandCreditPayoutOptionCard.propTypes = {
  input: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  credit: PropTypes.string.isRequired,
  creditInBrandShopifyCurrency: PropTypes.string.isRequired,
};

const CashPayoutOptionCard = (props) => {
  const { input, classes, cash } = props;

  const stripeAccount = useSelector((state) => state.stripeConnectAccount.stripeAccount);

  const stripeAccountData = getStripeAccountData(stripeAccount);
  const requirementsMissing = hasRequirementsMissing(stripeAccount);
  const futureRequirementsMissing = hasFutureRequirementsMissing(stripeAccount);
  const last4 = getBankAccountLast4Digits(stripeAccountData);

  const isStripePayoutDisabled = !last4 || requirementsMissing;
  const stripeAccountMissingError = (
    <>
      You must connect your bank account{' '}
      <namedlink className="{css.payoutLink}" name="StripePayoutPage" target="_blank">
        here
      </namedlink>{' '}
      first before choosing this option.
    </>
  );

  const stripeAccountNotVerifiedError = (
    <>
      You must verify your Stripe account{' '}
      <namedlink className="{css.payoutLink}" name="StripePayoutPage" target="_blank">
        here
      </namedlink>{' '}
      first before choosing this option.
    </>
  );

  const stripeAccountFutureRequirementsMissingWarning = (
    <>
      Note: We need additional information for your Stripe account. Please update your account{' '}
      <namedlink className="{css.payoutLink}" name="StripePayoutPage" target="_blank">
        here
      </namedlink>{' '}
      to ensure you get paid.
    </>
  );

  return renderPayoutOptionCard(
    input,
    PayoutOptions.Cash,
    classes,
    `Direct Deposit (${cash})`,
    <>
      Transfer{' '}
      <typographywrapper variant="body2" component="span" weight="{TypographyWeight.Bold}" typographyOverrides="{{" display:="" 'inline'="" }}="">
        {cash}
      </typographywrapper>{' '}
      to your bank account {last4 || ''} (Up to 7 days)
      {isStripePayoutDisabled && (
        <h4 className="{css.noStripeAccountWarning}">
          <erroricon style="{{" color:="" '#ff4c4c',="" fontSize:="" '15px',="" }}=""></erroricon>{' '}
          {!last4 && stripeAccountMissingError}
          {last4 && requirementsMissing && stripeAccountNotVerifiedError}
        </h4>
      )}
      {!isStripePayoutDisabled && futureRequirementsMissing && (
        <h4 className="{css.noStripeAccountWarning}">
          {stripeAccountFutureRequirementsMissingWarning}
        </h4>
      )}
    </>,
    <img className="{classNames(css.logo," isStripePayoutDisabled="" &&="" css.logoDisabled)}="" src="/static/logos/stripe.png" alt="stripe-logo">,
    isStripePayoutDisabled
  );
};

CashPayoutOptionCard.propTypes = {
  input: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  cash: PropTypes.string.isRequired,
};

const LoyaltyPointsDisclaimer = (props) => {
  const { credit, creditAmount } = props;

  const { shopName } = useShopConfig();
  const { getPointsFromPrice, linkToBrandLoyaltyPointsPage } = useYotpo();
  return (
    <box py="{2}">
      <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
        *Brand credit{' '}
      </typographywrapper>
      <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="" weight="{TypographyWeight.Bold}">
        will be in the form of {shopName} Points.{' '}
      </typographywrapper>
      <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
        You will receive {getPointsFromPrice(creditAmount)} {shopName} Points, which is worth{' '}
        {credit} and can be used across multiple purchases.{' '}
      </typographywrapper>
      <externallink className="{css.externalLink}" href="{linkToBrandLoyaltyPointsPage}">
        <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
          Learn more about {shopName} Points.
        </typographywrapper>
      </externallink>
    </box>
  );
};

LoyaltyPointsDisclaimer.propTypes = {
  credit: PropTypes.string.isRequired,
  creditAmount: PropTypes.number.isRequired,
};

export const PayoutMethodModalComponent = (props) => {
  const { open, onClose, onSubmit, inProgress, classes, payoutValues } = props;
  const { cash, credit, creditAmount, creditInBrandShopifyCurrency } = payoutValues;

  const { doesBrandUseLoyaltyPoints } = useYotpo();

  return (
    <finalform onSubmit="{onSubmit}" render="{(fieldRenderProps)" ==""> {
        const { handleSubmit, invalid, submitting } = fieldRenderProps;
        return (
          <div>
            <form id="payoutMethodModal" onSubmit="{handleSubmit}">
              <dialogwithclosebutton fullWidth="" maxWidth="md" open="{open}" onClose="{onClose}" titleText="Choose Payout Method">
                <dialogcontent dividers="">
                  <box pb="{1}">
                    <typographywrapper variant="body1" weight="{TypographyWeight.Bold}">
                      Please choose how you would like to be paid out.
                    </typographywrapper>
                  </box>
                  <field name="payoutOption" validate="{required(`Please" choose="" an="" option`)}="">
                    {({ input }) => (
                      <grid container="" spacing="{3}">
                        {credit && doesBrandUseLoyaltyPoints && (
                          <loyaltypointspayoutoptioncard input="{input}" classes="{classes}" credit="{credit}" creditAmount="{creditAmount}"></loyaltypointspayoutoptioncard>
                        )}
                        {credit && !doesBrandUseLoyaltyPoints && (
                          <brandcreditpayoutoptioncard input="{input}" classes="{classes}" credit="{credit}" creditInBrandShopifyCurrency="{creditInBrandShopifyCurrency}"></brandcreditpayoutoptioncard>
                        )}

                        {cash && (
                          <cashpayoutoptioncard input="{input}" classes="{classes}" cash="{cash}"></cashpayoutoptioncard>
                        )}
                      </grid>
                    )}
                  </field>
                  <box py="{1}">
                    {doesBrandUseLoyaltyPoints && (
                      <loyaltypointsdisclaimer credit="{credit}" creditAmount="{creditAmount}"></loyaltypointsdisclaimer>
                    )}
                    <typographywrapper variant="body1" weight="{TypographyWeight.Bold}">
                      NOTE: PAYOUT SELECTION IS FINAL AND CANNOT BE CHANGED FOR THIS SALE.
                    </typographywrapper>
                  </box>
                </dialogcontent>
                <dialogactions>
                  <button type="submit" className="{css.actionButton}" disabled="{invalid" ||="" submitting}="" inProgress="{submitting" inProgress}="" form="payoutMethodModal">
                    Get Paid
                  </button>
                </dialogactions>
              </dialogwithclosebutton>
            </form>
          </div>
        );
      }}
    />
  );
};

PayoutMethodModalComponent.defaultProps = {
  inProgress: false,
  classes: {},
};

PayoutMethodModalComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
  payoutValues: shape({
    cash: PropTypes.string,
    credit: PropTypes.string,
  }).isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(PayoutMethodModalComponent);
</finalform>