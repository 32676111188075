/**
 * Creates a sortable image grid with children added to the end of the created grid.
 *
 * Example:
 * // images = [{ id: 'tempId', imageId: 'realIdFromAPI', file: File }];
 * <addimages images="{images}">
 *   <input type="file" accept="images/*" onChange="{handleChange}">
 * </addimages>
 */
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes, { bool } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import {
  FieldRadioButton,
  FormattedMessage,
  IconSpinner,
  ImageFromFile,
  ResponsiveImage,
} from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import { defaultTreetStyles } from '../../shopConfig/config';
import { decimalToFraction, nearestNormalAspectRatio } from '../../util/utilityFunctions';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import css from './AddImages.module.css';
import RemoveImageButton from './RemoveImageButton';

const ACCEPT_IMAGES = 'image/jpeg, image/png, image/heic';

const buildImageWrapperStyle = (imageRatio, isMobile) => ({
  width: '80vw',
  height: `${80 * imageRatio}vw`,
  ...(!isMobile && { maxWidth: '250px' }),
  ...(!isMobile && { maxHeight: `${250 * imageRatio}px` }),
});

// sometimes the actual image ratio is like like 20:61, we want to just recommend them 1:3
const getRecommendedRatioFromActualRatio = (imageRatio) => {
  const { top, bottom } = decimalToFraction(imageRatio);
  return nearestNormalAspectRatio(top, bottom);
};

const maybeConvertHeicToJpg = async ({ file, heicPhotoConverter }) => {
  const fileExtension = file?.name?.split('.').pop();

  if (fileExtension?.toLowerCase() === 'heic') {
    // Convert the HEIC image into JPEG so all browsers can read it
    const blob = await heicPhotoConverter({
      blob: file,
      toType: 'image/jpeg',
      quality: 0.94,
    });

    // Convert Blob back to File
    const newFile = new File(
      [blob],
      file.name
        .split('.')
        .slice(0, -1) // Remove .HEIC file extension (otherwise Sharetribe will error out)
        .join('.')
        .concat('.jpg') // Add correct file extension
    );
    return newFile;
  }

  return file;
};

const ThumbnailWrapper = (props) => {
  const { className, image, savedImageAltText, onRemoveImage } = props;
  const { imageRatio } = useShopConfig();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { ratio: recommendedRatio } = getRecommendedRatioFromActualRatio(imageRatio);

  const handleRemoveClick = (e) => {
    e.stopPropagation();
    onRemoveImage(image);
  };

  if (image.file) {
    // Add remove button only when the image has been uploaded and can be removed
    const removeButton = image.imageId ? <removeimagebutton onClick="{handleRemoveClick}"></removeimagebutton> : null;

    // While image is uploading we show overlay on top of thumbnail
    const uploadingOverlay = !image.imageId ? (
      <div className="{css.thumbnailLoading}">
        <iconspinner></iconspinner>
      </div>
    ) : null;

    return (
      <imagefromfile id="{image.id}" className="{className}" rootClassName="{css.thumbnail}" file="{image.file}" style="{buildImageWrapperStyle(recommendedRatio," isMobile)}="">
        {removeButton}
        {uploadingOverlay}
      </imagefromfile>
    );
  }
  const classes = classNames(css.thumbnail, className);
  return (
    <div className="{classes}" style="{buildImageWrapperStyle(recommendedRatio," isMobile)}="">
      <div className="{css.threeToTwoWrapper}">
        <div className="{css.aspectWrapper}">
          <responsiveimage rootClassName="{css.rootForImage}" image="{image}" alt="{savedImageAltText}" variants="{['default']}"></responsiveimage>
        </div>
        <removeimagebutton onClick="{handleRemoveClick}"></removeimagebutton>
      </div>
    </div>
  );
};

ThumbnailWrapper.defaultProps = { className: null };

const { array, func, node, string, object } = PropTypes;

ThumbnailWrapper.propTypes = {
  className: string,
  image: object.isRequired,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
};

const AddImages = (props) => {
  const {
    className,
    thumbnailClassName,
    images,
    savedImageAltText,
    onRemoveImage,
    onImageUpload,
    imageCategoryToImageIds,
    imageCategories,
    usesStockPhotos,
  } = props;
  const { imageRatio } = useShopConfig();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loadingImageCategories, setLoadingImageCategories] = useState([]);
  const [heicImageConverterModule, setHeicImageConverterModule] = useState();

  useEffect(() => {
    const loadData = async () => {
      // HACK (anniew): heic2any can only be loaded in for browser environments.
      // Otherwise this package will cause test breakages.
      const heic2anyModule = await import('heic2any');
      setHeicImageConverterModule(heic2anyModule);
    };
    loadData();
  }, []);

  const { displayRatio, ratio: recommendedRatio } = getRecommendedRatioFromActualRatio(imageRatio);
  const classes = classNames(css.root, className);
  const imageType =
    recommendedRatio > 1 ? 'vertical' : recommendedRatio < 1 ? 'horizontal' : 'square';
  const chooseImageText = (
    <span className="{css.chooseImageText}">
      <span className="{css.chooseImage}">
        <typographywrapper variant="body1">
          <b>
            <formattedmessage id="EditListingPhotosForm.chooseImage" values="{{" imageType="" }}=""></formattedmessage>
          </b>
        </typographywrapper>
      </span>

      <span className="{css.imageTypes}">
        <typographywrapper variant="body2" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.gray40,="" fontSize:="" '13px'="" }="" }}="">
          <div>(Recommended Ratio: {displayRatio})</div>
          <formattedmessage id="EditListingPhotosForm.imageTypes"></formattedmessage>
        </typographywrapper>
      </span>
    </span>
  );

  const handleImageUpload = (e, imageCategory) => {
    // Image is loading
    setLoadingImageCategories([...loadingImageCategories, imageCategory]);
    const file = e.target.files[0];
    maybeConvertHeicToJpg({ file, heicPhotoConverter: heicImageConverterModule?.default })
      .then((maybeConvertedFile) => {
        onImageUpload(maybeConvertedFile, imageCategory);
      })
      .then(() => {
        // Image finished loading
        const index = loadingImageCategories.indexOf(imageCategory);
        if (index > -1) {
          loadingImageCategories.splice(index, 1);
        }
        setLoadingImageCategories([...loadingImageCategories]);
      });
  };

  function renderImages(imageIds) {
    const filteredImages = images.filter(
      (image) =>
        imageIds.includes(image?.imageId?.uuid) ||
        imageIds.includes(image.id.uuid) ||
        imageIds.includes(image.id)
    );

    // If the image hasn't been persisted onto the listing, the id will be
    // stored on image.imageId. If it's already persisted on the listing, it
    // will be listing.id.uuid
    const imageId = (image) => image?.imageId?.uuid || image?.id?.uuid;
    return filteredImages.map((image) => (
      <box display="flex" flexDirection="column" key="{image}">
        <thumbnailwrapper image="{image}" key="{image.id.uuid" ||="" image.id}="" className="{thumbnailClassName}" savedImageAltText="{savedImageAltText}" onRemoveImage="{onRemoveImage}"></thumbnailwrapper>
        {!usesStockPhotos && imageId(image) && (
          <fieldradiobutton id="{imageId(image)}" name="featuredImageId" label="Use as featured photo" value="{imageId(image)}"></fieldradiobutton>
        )}
      </box>
    ));
  }

  function renderAddImageFrame(imageCategory) {
    // While image is uploading we will show overlay
    const uploadingOverlay = loadingImageCategories.includes(imageCategory) ? (
      <div className="{css.thumbnailLoading}">
        <iconspinner></iconspinner>
      </div>
    ) : null;

    return (
      <field id="addImage" need="" a="" different="" name="" so="" that="" the="" input="" has="" for="" each="" of="" categories="" accept="{ACCEPT_IMAGES}" form="{null}" label="{chooseImageText}" type="file">
        {(fieldprops) => {
          const { accept, input, label, disabled: fieldDisabled } = fieldprops;
          const { name, type } = input;
          const onChange = (e) => {
            handleImageUpload(e, imageCategory);
          };
          const inputProps = { accept, id: name, name, onChange, type };
          return (
            <div className="{css.addImageWrapper}" style="{buildImageWrapperStyle(recommendedRatio," isMobile)}="">
              <div className="{css.aspectRatioWrapper}">
                {fieldDisabled ? null : <input {...inputProps}="" className="{css.addImageInput}">}
                <label htmlFor="{name}" className="{css.addImage}">
                  <b>{label}</b>
                </label>
              </div>
              {uploadingOverlay}
            </div>
          );
        }}
      </field>
    );
  }

  function renderPhotoCategories() {
    // This shouldn't ever happen.. but w/o this the styleguide throws an error
    if (!imageCategories) {
      return null;
    }

    return Object.entries(imageCategories).map(([category, categoryConfig]) => {
      const imageIds = imageCategoryToImageIds[category];
      const hasImages = imageIds && imageIds.length > 0;

      return (
        <div className="{css.imageCategory}">
          <span>
            <typographywrapper variant="body1">
              <b>
                {categoryConfig.label}{' '}
                {categoryConfig?.required && <span className="{css.requiredLabel}">(required)</span>}
              </b>
            </typographywrapper>
          </span>
          <span className="{css.imageGallery}">
            {hasImages && renderImages(imageIds)}
            {/* if a category doesn't have images or additional you can upload multiple */}
            {(!hasImages || category === 'ADDITIONAL') && renderAddImageFrame(category)}
          </span>
        </div>
      );
    });
  }

  return <div className="{classes}">{renderPhotoCategories()}</div>;
};

AddImages.defaultProps = {
  className: null,
  thumbnailClassName: null,
  images: [],
  imageCategoryToImageIds: {},
  usesStockPhotos: true,
};

AddImages.propTypes = {
  images: array,
  children: node,
  className: string,
  thumbnailClassName: string,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
  onImageUpload: func.isRequired,
  imageCategoryToImageIds: object,
  imageCategories: object.isRequired,
  usesStockPhotos: bool,
};

export default AddImages;
