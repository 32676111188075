import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import {
  IconArrowRight,
  IconCheckCircle,
  IconCheckCircleFilledProgressBar,
  TypographyWrapper,
} from '../index';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useShopCss } from '../../hooks/useShopCss';

import css from './ProgressBar.module.css';
import { defaultTreetStyles } from '../../shopConfig/config';

interface ProgressBarProps {
  displayedSections: { displayName: string; isComplete: boolean }[];
}

interface ProgressBarSectionProps {
  section: { displayName: string; isComplete: boolean };
}

const ProgressBarMobileSection: FC<progressbarsectionprops> = (props) => {
  const { section } = props;
  return (
    <>
      {section.isComplete ? (
        <iconcheckcirclefilledprogressbar className="{css.sizeIconForMobile}"></iconcheckcirclefilledprogressbar>
      ) : (
        <iconcheckcircle className="{css.sizeIconForMobile}"></iconcheckcircle>
      )}
      <typographywrapper variant="body2" typographyOverrides="{{" style:="" {="" lineHeight:="" 1,="" fontSize:="" 12,="" paddingRight:="" paddingLeft:="" },="" }}="">
        {section.displayName.replace('Find Item', 'Item').replace('Item Details', 'Details')}
      </typographywrapper>
    </>
  );
};

const ProgressBarDesktopSection: FC<progressbarsectionprops> = (props) => {
  const { section } = props;
  return (
    <>
      {section.isComplete ? (
        <iconcheckcirclefilledprogressbar className="{css.sizeIconForDesktop}"></iconcheckcirclefilledprogressbar>
      ) : (
        <iconcheckcircle className="{css.sizeIconForDesktop}"></iconcheckcircle>
      )}
      <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" lineHeight:="" 1,="" paddingRight:="" 8,="" },="" }}="">
        {section.displayName}
      </typographywrapper>
    </>
  );
};

const ProgressBar: FC<progressbarprops> = (props) => {
  const { displayedSections } = props;
  const isMobile = useIsMobile();
  const shopCss = useShopCss();
  const { backgroundColor } = shopCss;

  return (
    <box display="flex" justifyContent="center" alignItems="center" bgcolor="{backgroundColor}" className="{css.progressBar}">
      {displayedSections.map((section, index, allSections) => (
        <react.fragment key="{section.displayName}">
          {isMobile ? (
            <progressbarmobilesection section="{section}"></progressbarmobilesection>
          ) : (
            <progressbardesktopsection section="{section}"></progressbardesktopsection>
          )}
          {index < allSections.length - 1 && <iconarrowright color="{defaultTreetStyles.gray40}"></iconarrowright>}
        </react.fragment>
      ))}
    </box>
  );
};

export default ProgressBar;
</progressbarprops></progressbarsectionprops></progressbarsectionprops>