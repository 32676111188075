import React from 'react';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { Button, TypographyWrapper } from '..';
import DialogWithCloseButton from '../DialogWithCloseButton/DialogWithCloseButton';
import { ButtonVariant } from '../Button/Button';

const ConfirmDeleteListingModal = (props) => {
  const { open, onClose, onSubmit, inProgress } = props;

  return (
    <dialogwithclosebutton allowFullScreen="{false}" scroll="paper" open="{open}" onClose="{onClose}" titleText="Delete Listing">
      <dialogcontent dividers="">
        <dialogcontenttext>
          <h4>
            <typographywrapper variant="body1">
              Are you sure you want to delete this listing? Doing so will delete your listing
              permanently.
            </typographywrapper>
          </h4>
        </dialogcontenttext>
      </dialogcontent>
      <dialogactions>
        <button disabled="{inProgress}" inProgress="{inProgress}" onClick="{onSubmit}" variant="{ButtonVariant.Danger}">
          Delete
        </button>
      </dialogactions>
    </dialogwithclosebutton>
  );
};

ConfirmDeleteListingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
};

export default ConfirmDeleteListingModal;
