import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@material-ui/core';
import { FormattedMessage, SavedCardDetails, TypographyWrapper } from '../../components';
import OneTimePaymentWithCard from './OneTimePaymentWithCard';

import css from './StripePaymentForm.module.css';
import { REPLACE_CARD } from '../../components/SavedCardDetails/SavedCardDetails';
import { useShopConfig } from '../../hooks/shopConfig';
import { FontConfigSection } from '../../types/shopConfig/shopConfigV2';

interface PaymentMethodSelectorProps {
  cardClasses: string;
  changePaymentMethod: (paymentMethod: string) => void;
  defaultPaymentMethod: any;
  handleStripeElementRef: (el: any) => void;
  hasCardError: boolean;
  error?: string;
  paymentMethod: string;
}

const PaymentMethodSelector: FC<paymentmethodselectorprops> = (
  props: PaymentMethodSelectorProps
) => {
  const {
    cardClasses,
    changePaymentMethod,
    defaultPaymentMethod,
    handleStripeElementRef,
    hasCardError,
    error,
    paymentMethod,
  } = props;

  const { fontConfig } = useShopConfig();
  const intl = useIntl();

  const labelTypographyVariant = fontConfig[FontConfigSection.FieldLabel];

  const { last4Digits } = defaultPaymentMethod.attributes.card;
  const labelText = intl.formatMessage(
    { id: 'StripePaymentForm.replaceAfterOnetimePayment' },
    { last4Digits }
  );

  return (
    <>
      <box className="{css.paymentHeading}">
        <typographywrapper variant="{labelTypographyVariant}" typographyOverrides="{{" style:="" {="" fontWeight:="" 'bold'="" },="" display:="" 'inline'="" }}="">
          <formattedmessage id="StripePaymentForm.payWithHeading"></formattedmessage>
        </typographywrapper>
      </box>
      <savedcarddetails className="{css.paymentMethodSelector}" card="{defaultPaymentMethod.attributes.card}" onChange="{changePaymentMethod}"></savedcarddetails>
      {paymentMethod === REPLACE_CARD && (
        <onetimepaymentwithcard cardClasses="{cardClasses}" handleStripeElementRef="{handleStripeElementRef}" hasCardError="{hasCardError}" error="{error}" label="{labelText}"></onetimepaymentwithcard>
      )}
    </>
  );
};

export default PaymentMethodSelector;
</paymentmethodselectorprops>