import { Box, BoxProps, Typography } from '@material-ui/core';
import React, { FC, ReactElement } from 'react';
import { NamedLink } from '../../components';
import IconArrowTopRight from '../../components/Icons/IconArrowTopRight/IconArrowTopRight';
import IconLightningBolt from '../../components/Icons/IconLightningBolt/IconLightningBolt';
import IconSell from '../../components/Icons/IconSell/IconSell';
import IconShipment from '../../components/Icons/IconShipment/IconShipment';
import IconSparklesLeft from '../../components/Icons/IconSparklesLeft/IconSparklesLeft';
import IconSparklesRight from '../../components/Icons/IconSparklesRight/IconSparklesRight';
import { useShopConfig } from '../../hooks/shopConfig';
import { useIsMobile } from '../../hooks/useIsMobile';
import { defaultTreetStyles } from '../../shopConfig/config';
import { getDarkerBrandCssBackgroundColor } from '../../util/colors/colors';
import { formatPayoutPercentage } from '../../util/currency';
import css from './ManageClosetPage.module.css';

interface InfoCardContentProps {
  title: string;
  description: string;
  tagline: string;
  taglineIconLeft: ReactElement;
  taglineIconRight: ReactElement;
}

const InfoCardContent: FC<infocardcontentprops> = (props) => {
  const { title, description, taglineIconLeft, tagline, taglineIconRight } = props;
  const isMobile = useIsMobile();

  return (
    <>
      <typography 0="" variant="{isMobile" ?="" 'body1'="" :="" 'subtitle1'}="" style="{{" fontWeight:="" 'bold',="" padding:="" '0="" 8px="" 0'="" }}="">
        {title}
      </typography>
      <typography variant="body1" style="{{" whiteSpace:="" 'pre-line',="" textAlign:="" 'center',="" ...(isMobile="" &&="" {="" fontSize:="" '12px'="" }),="" }}="">
        {description}
      </typography>
      <box display="flex" alignItems="center" justifyContent="center" mt="{2}">
        {taglineIconLeft}
        <typography variant="body1" style="{{" fontSize:="" '12px',="" fontWeight:="" 'bold',="" textTransform:="" 'uppercase',="" padding:="" '0="" 8px',="" }}="">
          {tagline}
        </typography>
        {taglineIconRight}
      </box>
    </>
  );
};

interface InfoCardProps {
  title: string;
  description: string;
  tagline: string;
  icon: ReactElement;
  taglineIconLeft: ReactElement;
  taglineIconRight: ReactElement;
  boxOverrides?: Partial<boxprops>;
}

const InfoCard: FC<infocardprops> = (props) => {
  const { title, description, tagline, boxOverrides, icon, taglineIconLeft, taglineIconRight } =
    props;
  const isMobile = useIsMobile();
  const { css: brandCss } = useShopConfig();
  const darkerBrandCssBackgroundColor = getDarkerBrandCssBackgroundColor(brandCss);

  return (
    <namedlink name="NewListingPage" className="{css.infoCard}">
      <box className="{css.banner}" display="flex" flex="{1}" height="160px" {...(isMobile="" &&="" {="" px:="" '4px'="" })}="" alignItems="center" justifyContent="flex-end" style="{{" backgroundColor:="" darkerBrandCssBackgroundColor,="" border:="" `1px="" solid="" ${defaultTreetStyles.gray20}`,="" }}="" {...boxOverrides}="">
        {!isMobile && (
          <box display="flex" flex="{1}" justifyContent="left" mt="auto">
            {icon}
          </box>
        )}
        <box display="flex" flex="{4}" flexDirection="column" alignItems="center" justifyContent="center">
          <infocardcontent title="{title}" description="{description}" tagline="{tagline}" taglineIconLeft="{taglineIconLeft}" taglineIconRight="{taglineIconRight}"></infocardcontent>
        </box>
        {!isMobile && (
          <box display="flex" flex="{1}" mb="auto" justifyContent="right" pt="4px" pr="4px">
            <iconarrowtopright className="{css.infoCardsArrow}" color="{defaultTreetStyles.gray40}"></iconarrowtopright>
          </box>
        )}
      </box>
    </namedlink>
  );
};

interface SellOrTradeInInfoCardsProps {
  hasActiveTradeIn: boolean;
}

export const SellOrTradeInInfoCards: FC<sellortradeininfocardsprops> = (props) => {
  const { hasActiveTradeIn } = props;
  const isMobile = useIsMobile();
  const { creditPayoutPercentage } = useShopConfig();
  const formattedCreditPayout = formatPayoutPercentage(creditPayoutPercentage);

  return (
    <box 0="" mx="{isMobile" ?="" :="" 4}="" my="{2}" display="flex">
      <infocard 1="" title="Sell" description="{`Get" ${formattedCreditPayout}%="" brand="" credit\nof="" your="" item’s="" sale="" price="" when="" it="" sells`}="" tagline="Earn More" boxOverrides="{{" mr:="" }}="" icon="{<IconSell" className="{css.infoCardsIcon}"></infocard>}
        taglineIconLeft={<iconsparklesleft></iconsparklesleft>}
        taglineIconRight={<iconsparklesright></iconsparklesright>}
      />
      <infocard 1="" title="{hasActiveTradeIn" ?="" 'Add="" To="" Trade="" In'="" :="" 'Trade="" In'}="" description="{`Send" everything="" in="" one="" shipment\nand="" get="" paid="" once="" they’re="" received`}="" tagline="Fast Payout" boxOverrides="{{" ml:="" }}="" icon="{<IconShipment" className="{css.infoCardsIcon}"></infocard>}
        taglineIconLeft={<iconlightningbolt></iconlightningbolt>}
        taglineIconRight={<iconlightningbolt></iconlightningbolt>}
      />
    </box>
  );
};

export default SellOrTradeInInfoCards;
</sellortradeininfocardsprops></infocardprops></boxprops></infocardcontentprops>