/* eslint-disable camelcase */
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RequestStatus } from '../../types/requestStatus';
import { getSuppressions } from '../../util/api';
import * as log from '../../util/log';
import { StorableError } from '../../types/error';
import { storableError } from '../../util/errors';
import { updateGeneralSavedSearchEmailSubscription } from '../../ducks/user.duck';
import { UpdateGeneralSavedSearchEmailSubscriptionParams } from '../../types/models/savedSearch';
import { apolloClient } from '../../apollo';
import {
  IsoSavedSearchesByEmailDocument,
  SavedSearch,
} from '../../types/apollo/generated/types.generated';

interface Suppression {
  name: string;
  id: number;
  description: string;
  is_default: boolean;
  suppressed: boolean;
}

export type SavedSearchWithSearch = Pick<savedsearch, 'id'="" |="" 'search'="">;

// ================ Action types ================ //

export const GET_SUPPRESSIONS_REQUEST = 'app/NotificationSettingsPage/GET_SUPPRESSIONS_REQUEST';
export const GET_SUPPRESSIONS_SUCCESS = 'app/NotificationSettingsPage/GET_SUPPRESSIONS_SUCCESS';
export const GET_SUPPRESSIONS_ERROR = 'app/NotificationSettingsPage/GET_SUPPRESSIONS_ERROR';

export const UPDATE_NOTIFICATIONS_REQUEST =
  'app/NotificationSettingsPage/UPDATE_NOTIFICATIONS_REQUEST';
export const UPDATE_NOTIFICATIONS_SUCCESS =
  'app/NotificationSettingsPage/UPDATE_NOTIFICATIONS_SUCCESS';
export const UPDATE_NOTIFICATIONS_ERROR = 'app/NotificationSettingsPage/UPDATE_NOTIFICATIONS_ERROR';

const GET_ISO_SAVED_SEARCHES_REQUEST =
  'app/NotificationSettingsPage/GET_ISO_SAVED_SEARCHES_REQUEST';
const GET_ISO_SAVED_SEARCHES_SUCCESS =
  'app/NotificationSettingsPage/GET_ISO_SAVED_SEARCHES_SUCCESS';
const GET_ISO_SAVED_SEARCHES_ERROR = 'app/NotificationSettingsPage/GET_ISO_SAVED_SEARCHES_ERROR';
const INSERT_ISO_SAVED_SEARCH = 'app/NotificationSettingsPage/INSERT_ISO_SAVED_SEARCH';
const CANCEL_ISO_SAVED_SEARCH = 'app/NotificationSettingsPage/CANCEL_ISO_SAVED_SEARCH';

interface GetSuppressionsRequest {
  type: typeof GET_SUPPRESSIONS_REQUEST;
}

interface GetSuppressionsSuccess {
  type: typeof GET_SUPPRESSIONS_SUCCESS;
  suppressions: Suppression[];
}

interface GetSuppressionsError {
  type: typeof GET_SUPPRESSIONS_ERROR;
  error: StorableError;
}

interface UpdateNotificationsRequest {
  type: typeof UPDATE_NOTIFICATIONS_REQUEST;
}

interface UpdateNotificationsSuccess {
  type: typeof UPDATE_NOTIFICATIONS_SUCCESS;
}

interface UpdateNotificationsError {
  type: typeof UPDATE_NOTIFICATIONS_ERROR;
}

interface GetISOSavedSearchesRequest {
  type: typeof GET_ISO_SAVED_SEARCHES_REQUEST;
}

interface GetISOSavedSearchesSuccess {
  type: typeof GET_ISO_SAVED_SEARCHES_SUCCESS;
  savedSearches: SavedSearchWithSearch[];
}

interface GetISOSavedSearchesError {
  type: typeof GET_ISO_SAVED_SEARCHES_ERROR;
  error: StorableError;
}

interface InsertISOSavedSearch {
  type: typeof INSERT_ISO_SAVED_SEARCH;
  savedSearch: SavedSearchWithSearch;
}

interface CancelISOSavedSearch {
  type: typeof CANCEL_ISO_SAVED_SEARCH;
  savedSearchId: string;
}

type NotificationSettingsPageActionType =
  | GetSuppressionsRequest
  | GetSuppressionsSuccess
  | GetSuppressionsError
  | UpdateNotificationsRequest
  | UpdateNotificationsSuccess
  | UpdateNotificationsError
  | GetISOSavedSearchesRequest
  | GetISOSavedSearchesSuccess
  | GetISOSavedSearchesError
  | InsertISOSavedSearch
  | CancelISOSavedSearch;

// ================ Reducer ================ //

export interface NotificationSettingsPageState {
  getSuppressionsStatus: RequestStatus;
  getSuppressionsError: StorableError | null;
  suppressions: Suppression[];
  updateNotificationsStatus: RequestStatus;
  getISOSavedSearchesStatus: RequestStatus;
  isoSavedSearches: SavedSearchWithSearch[];
  getISOSavedSearchesError: StorableError | null;
}

const initialState: NotificationSettingsPageState = {
  getSuppressionsStatus: RequestStatus.Ready,
  getSuppressionsError: null,
  suppressions: [],
  updateNotificationsStatus: RequestStatus.Ready,
  getISOSavedSearchesStatus: RequestStatus.Ready,
  isoSavedSearches: [],
  getISOSavedSearchesError: null,
};

export default function NotificationSettingsPageReducer(
  state: NotificationSettingsPageState = initialState,
  action: NotificationSettingsPageActionType
): NotificationSettingsPageState {
  switch (action.type) {
    case GET_SUPPRESSIONS_REQUEST: {
      return { ...state, getSuppressionsStatus: RequestStatus.Pending, getSuppressionsError: null };
    }
    case GET_SUPPRESSIONS_SUCCESS: {
      return {
        ...state,
        getSuppressionsStatus: RequestStatus.Success,
        suppressions: action.suppressions,
      };
    }
    case GET_SUPPRESSIONS_ERROR: {
      return {
        ...state,
        getSuppressionsStatus: RequestStatus.Error,
        getSuppressionsError: action.error,
      };
    }
    case UPDATE_NOTIFICATIONS_REQUEST: {
      return { ...state, updateNotificationsStatus: RequestStatus.Pending };
    }
    case UPDATE_NOTIFICATIONS_SUCCESS: {
      return { ...state, updateNotificationsStatus: RequestStatus.Success };
    }
    case UPDATE_NOTIFICATIONS_ERROR: {
      return { ...state, updateNotificationsStatus: RequestStatus.Error };
    }
    case GET_ISO_SAVED_SEARCHES_REQUEST: {
      return {
        ...state,
        getISOSavedSearchesStatus: RequestStatus.Pending,
        getISOSavedSearchesError: null,
      };
    }
    case GET_ISO_SAVED_SEARCHES_SUCCESS: {
      return {
        ...state,
        getISOSavedSearchesStatus: RequestStatus.Success,
        isoSavedSearches: action.savedSearches,
      };
    }
    case GET_ISO_SAVED_SEARCHES_ERROR: {
      return {
        ...state,
        getISOSavedSearchesStatus: RequestStatus.Error,
        getISOSavedSearchesError: action.error,
      };
    }
    case INSERT_ISO_SAVED_SEARCH: {
      return {
        ...state,
        isoSavedSearches: [
          action.savedSearch,
          ...state.isoSavedSearches.filter((ss) => ss.id !== action.savedSearch.id),
        ],
      };
    }
    case CANCEL_ISO_SAVED_SEARCH: {
      return {
        ...state,
        isoSavedSearches: state.isoSavedSearches.filter((ss) => ss.id !== action.savedSearchId),
      };
    }
    default: {
      return state;
    }
  }
}

// ================ Action creators ================ //

const getSuppressionsRequest = () => ({ type: GET_SUPPRESSIONS_REQUEST });

const getSuppressionsSuccess = (suppressions: Suppression[]) => ({
  type: GET_SUPPRESSIONS_SUCCESS,
  suppressions,
});

const getSuppressionsError = (error: StorableError) => ({ type: GET_SUPPRESSIONS_ERROR, error });

const updateNotificationsRequest = () => ({ type: UPDATE_NOTIFICATIONS_REQUEST });

const updateNotificationsSuccess = () => ({ type: UPDATE_NOTIFICATIONS_SUCCESS });

const updateNotificationsError = () => ({ type: UPDATE_NOTIFICATIONS_ERROR });

const getISOSavedSearchesRequest = () => ({ type: GET_ISO_SAVED_SEARCHES_REQUEST });

const getISOSavedSearchesSuccess = (savedSearches: string[]) => ({
  type: GET_ISO_SAVED_SEARCHES_SUCCESS,
  savedSearches,
});

const getISOSavedSearchesError = (error: StorableError) => ({
  type: GET_ISO_SAVED_SEARCHES_ERROR,
  error,
});

export const insertISOSavedSearch = (savedSearch: SavedSearchWithSearch) => ({
  type: INSERT_ISO_SAVED_SEARCH,
  savedSearch,
});

export const cancelISOSavedSearch = (savedSearchId: string) => ({
  type: CANCEL_ISO_SAVED_SEARCH,
  savedSearchId,
});
// ================ Thunks ================ //

export const getSendgridSuppressions = (email: string) => async (dispatch: Dispatch) => {
  dispatch(getSuppressionsRequest());

  try {
    const response = await getSuppressions();
    dispatch(getSuppressionsSuccess(response.suppressions));
    return response;
  } catch (e) {
    const error = new Error('Failed to fetch Sengrid suppression groups for email');
    log.error(error, 'get-sendgrid-suppressions-for-email-failed', { email, error: e });
    dispatch(getSuppressionsError(storableError(e)));
    return null;
  }
};

export const updateNotifications =
  (params: UpdateGeneralSavedSearchEmailSubscriptionParams) =>
  async (dispatch: ThunkDispatch<any, any,="" any="">) => {
    dispatch(updateNotificationsRequest());

    try {
      const response = await dispatch(updateGeneralSavedSearchEmailSubscription(params));
      dispatch(updateNotificationsSuccess());
      return response;
    } catch (e) {
      // Error is already logged
      dispatch(updateNotificationsError());
      return null;
    }
  };

export const getISOSavedSearches =
  (treetId: string, email: string) => async (dispatch: ThunkDispatch<any, any,="" any="">) => {
    dispatch(getISOSavedSearchesRequest());

    try {
      const response = await apolloClient.query({
        query: IsoSavedSearchesByEmailDocument,
        variables: { treetId, email },
      });
      const savedSearches = response.data?.isoSavedSearches || [];
      dispatch(getISOSavedSearchesSuccess(savedSearches));
    } catch (e) {
      e.message = `[Get ISO Saved Searches] ${e}`;
      log.error(e, 'get-iso-saved-searches-failed', { treetId, email });
      dispatch(getISOSavedSearchesError(storableError(e)));
    }
  };
</any,></any,></savedsearch,>