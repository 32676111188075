import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { useIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  Avatar,
  Button,
  FieldTextInput,
  Form,
  FormattedMessage,
  IconSpinner,
  ImageFromFile,
  TypographyWrapper,
} from '../../components';
import css from './ProfileSettingsForm.module.css';
import { useShopConfig } from '../../hooks/shopConfig';

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset

const ProfileBio = () => {
  const intl = useIntl();
  const { profileBioInfoCopy } = useShopConfig();

  const bioLabel = intl.formatMessage({
    id: 'ProfileSettingsForm.bioLabel',
  });

  return (
    <div className="{classNames(css.sectionContainer," css.lastSection)}="">
      <h3 className="{css.sectionTitle}">
        <typographywrapper variant="body1">
          <b>
            <formattedmessage id="ProfileSettingsForm.bioHeading"></formattedmessage>
          </b>
        </typographywrapper>
      </h3>
      <fieldtextinput type="textarea" id="bio" name="bio" label="{bioLabel}" placeholder="{intl.formatMessage({" id:="" 'ProfileSettingsForm.bioPlaceholder',="" })}=""></fieldtextinput>
      <p className="{css.bioInfo}">
        <typographywrapper variant="body2">
          {profileBioInfoCopy || <formattedmessage id="ProfileSettingsForm.bioInfo"></formattedmessage>}
        </typographywrapper>
      </p>
    </div>
  );
};

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false };
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  render() {
    return (
      <finalform {...this.props}="" render="{(fieldRenderProps)" ==""> {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            onImageUpload,
            pristine,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // Last name
          const lastNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className="{css.uploadingImageOverlay}">
                <iconspinner></iconspinner>
              </div>
            ) : null;

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };
          const isAdmin = user.attributes.profile.publicData?.isAdmin;

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <imagefromfile id="{profileImage.id}" className="{errorClasses}" rootClassName="{css.uploadingImage}" aspectRatioClassName="{css.squareAspectRatio}" file="{profileImage.file}">
                {uploadingOverlay}
              </imagefromfile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <avatar className="{avatarClasses}" renderSizes="(max-width: 767px) 96px, 240px" user="{transientUser}" disableProfileLink=""></avatar>
            ) : null;

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className="{css.avatarContainer}">
                {imageFromFile}
                {avatarComponent}
                <div className="{css.changeAvatar}">
                  <formattedmessage id="ProfileSettingsForm.changeAvatar"></formattedmessage>
                </div>
              </div>
            ) : (
              <div className="{css.avatarPlaceholder}">
                <div className="{css.avatarPlaceholderText}">
                  <typographywrapper variant="body1">
                    <formattedmessage id="ProfileSettingsForm.addYourProfilePicture"></formattedmessage>
                  </typographywrapper>
                </div>
                <div className="{css.avatarPlaceholderTextMobile}">
                  <typographywrapper variant="body1">
                    <formattedmessage id="ProfileSettingsForm.addYourProfilePictureMobile"></formattedmessage>
                  </typographywrapper>
                </div>
              </div>
            );

          const submitError = updateProfileError ? (
            <div className="{css.error}">
              <formattedmessage id="ProfileSettingsForm.updateProfileFailed"></formattedmessage>
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress;

          return (
            <form className="{classes}" onSubmit="{(e)" ==""> {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className="{css.sectionContainer}">
                <h3 className="{css.sectionTitle}">
                  <typographywrapper variant="body1">
                    <b>
                      <formattedmessage id="ProfileSettingsForm.yourProfilePicture"></formattedmessage>
                    </b>
                  </typographywrapper>
                </h3>
                <field accept="{ACCEPT_IMAGES}" id="profileImage" name="profileImage" label="{chooseAvatarLabel}" type="file" form="{null}" uploadImageError="{uploadImageError}" disabled="{uploadInProgress}">
                  {(fieldProps) => {
                    /* eslint-disable-next-line @typescript-eslint/no-shadow */
                    const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                    const { name, type } = input;
                    const onChange = (e) => {
                      const file = e.target.files[0];
                      form.change(`profileImage`, file);
                      form.blur(`profileImage`);
                      if (file != null) {
                        const tempId = `${file.name}_${Date.now()}`;
                        onImageUpload({ id: tempId, file });
                      }
                    };

                    let error = null;

                    if (isUploadImageOverLimitError(uploadImageError)) {
                      error = (
                        <div className="{css.error}">
                          <formattedmessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge"></formattedmessage>
                        </div>
                      );
                    } else if (uploadImageError) {
                      error = (
                        <div className="{css.error}">
                          <formattedmessage id="ProfileSettingsForm.imageUploadFailed"></formattedmessage>
                        </div>
                      );
                    }

                    return (
                      <div className="{css.uploadAvatarWrapper}">
                        <label className="{css.label}" htmlFor="{id}">
                          {label}
                        </label>
                        <input accept="{accept}" id="{id}" name="{name}" className="{css.uploadAvatarInput}" disabled="{disabled}" onChange="{onChange}" type="{type}">
                        {error}
                      </div>
                    );
                  }}
                </field>
                <div className="{css.tip}">
                  <typographywrapper variant="body1">
                    <formattedmessage id="ProfileSettingsForm.tip"></formattedmessage>
                  </typographywrapper>
                </div>
                <div className="{css.fileInfo}">
                  <typographywrapper variant="body2">
                    <formattedmessage id="ProfileSettingsForm.fileInfo"></formattedmessage>
                  </typographywrapper>
                </div>
              </div>
              <div className="{css.sectionContainer}">
                <h3 className="{css.sectionTitle}">
                  <typographywrapper variant="body1">
                    <b>
                      <formattedmessage id="ProfileSettingsForm.yourName"></formattedmessage>
                    </b>
                  </typographywrapper>
                </h3>
                <div className="{css.nameContainer}">
                  <fieldtextinput className="{css.firstName}" type="text" id="firstName" name="firstName" label="{firstNameLabel}" placeholder="{firstNamePlaceholder}" validate="{firstNameRequired}"></fieldtextinput>
                  <fieldtextinput className="{css.lastName}" type="text" id="lastName" name="lastName" label="{lastNameLabel}" placeholder="{lastNamePlaceholder}" validate="{lastNameRequired}"></fieldtextinput>
                </div>
                {isAdmin && (
                  <div className="{css.nameContainer}">
                    <fieldtextinput className="{css.displayName}" type="text" id="displayName" name="displayName" label="Display Name" placeholder="How your name will be displayed to others" validate="{validators.required('Display" Name="" is="" required')}=""></fieldtextinput>
                  </div>
                )}
              </div>
              <profilebio></profilebio>
              {submitError}
              <button className="{css.submitButton}" type="submit" inProgress="{submitInProgress}" disabled="{submitDisabled}" ready="{pristineSinceLastSubmit}">
                <formattedmessage id="ProfileSettingsForm.saveChanges"></formattedmessage>
              </button>
            </form>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
</finalform>