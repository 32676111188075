import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconTrashProps {
  className?: string;
  color?: string;
}

const IconTrash: FC<icontrashprops> = (props: IconTrashProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg className="{className}" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9 10V26C9 26.5523 9.44772 27 10 27H22C22.5523 27 23 26.5523 23 26V10H25V26C25 27.6569 23.6569 29 22 29H10C8.34315 29 7 27.6569 7 26V10H9Z" fill="{fillColor}"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M13 5H19C19 3.89543 18.1046 3 17 3H15C13.8954 3 13 3.89543 13 5Z" fill="{fillColor}"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M24 7H8C7.44772 7 7 7.44772 7 8C7 8.55229 7.44772 9 8 9H24C24.5523 9 25 8.55228 25 8C25 7.44772 24.5523 7 24 7ZM8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11H24C25.6569 11 27 9.65685 27 8C27 6.34315 25.6569 5 24 5H8Z" fill="{fillColor}"></path>
      <path d="M11 14C11 13.4477 11.4477 13 12 13C12.5523 13 13 13.4477 13 14V24C13 24.5523 12.5523 25 12 25C11.4477 25 11 24.5523 11 24V14Z" fill="{fillColor}"></path>
      <path d="M15 14C15 13.4477 15.4477 13 16 13C16.5523 13 17 13.4477 17 14V24C17 24.5523 16.5523 25 16 25C15.4477 25 15 24.5523 15 24V14Z" fill="{fillColor}"></path>
      <path d="M19 14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V24C21 24.5523 20.5523 25 20 25C19.4477 25 19 24.5523 19 24V14Z" fill="{fillColor}"></path>
    </svg>
  );
};

export default IconTrash;
</icontrashprops>