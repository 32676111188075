import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import {
  isChangeEmailTakenError,
  isChangeEmailWrongPassword,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import {
  Button,
  FieldPhoneNumberInput,
  FieldTextInput,
  Form,
  FormattedMessage,
  TypographyWrapper,
} from '../../components';

import css from './ContactDetailsForm.module.css';
import { defaultTreetStyles } from '../../shopConfig/config';

const SHOW_EMAIL_SENT_TIMEOUT = 2000;

class ContactDetailsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { showVerificationEmailSentMessage: false };
    this.emailSentTimeoutId = null;
    this.handleResendVerificationEmail = this.handleResendVerificationEmail.bind(this);
    this.submittedValues = {};
  }

  componentWillUnmount() {
    window.clearTimeout(this.emailSentTimeoutId);
  }

  handleResendVerificationEmail() {
    this.setState({ showVerificationEmailSentMessage: true });

    this.props.onResendVerificationEmail().then(() => {
      // show "verification email sent" text for a bit longer.
      this.emailSentTimeoutId = window.setTimeout(() => {
        this.setState({ showVerificationEmailSentMessage: false });
      }, SHOW_EMAIL_SENT_TIMEOUT);
    });
  }

  render() {
    return (
      <finalform {...this.props}="" render="{(fieldRenderProps)" ==""> {
          const {
            rootClassName,
            className,
            saveEmailError,
            savePhoneNumberError,
            currentUser,
            formId,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            sendVerificationEmailError,
            sendVerificationEmailInProgress,
            values,
          } = fieldRenderProps;
          const { email, phoneNumber } = values;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          const { email: currentEmail, emailVerified, pendingEmail, profile } = user.attributes;

          // email

          // has the email changed
          const emailChanged = currentEmail !== email;

          const emailLabel = intl.formatMessage({
            id: 'ContactDetailsForm.emailLabel',
          });

          const emailPlaceholder = currentEmail || '';

          const emailRequiredMessage = intl.formatMessage({
            id: 'ContactDetailsForm.emailRequired',
          });
          const emailRequired = validators.required(emailRequiredMessage);
          const emailInvalidMessage = intl.formatMessage({
            id: 'ContactDetailsForm.emailInvalid',
          });
          const emailValid = validators.emailFormatValid(emailInvalidMessage);

          const tooManyVerificationRequests = isTooManyEmailVerificationRequestsError(
            sendVerificationEmailError
          );

          const emailTouched = this.submittedValues.email !== values.email;
          const emailTakenErrorText = isChangeEmailTakenError(saveEmailError)
            ? intl.formatMessage({ id: 'ContactDetailsForm.emailTakenError' })
            : null;

          let resendEmailMessage = null;
          if (tooManyVerificationRequests) {
            resendEmailMessage = (
              <span className="{css.tooMany}">
                <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
                  <formattedmessage id="ContactDetailsForm.tooManyVerificationRequests"></formattedmessage>
                </typographywrapper>
              </span>
            );
          } else if (
            sendVerificationEmailInProgress ||
            this.state.showVerificationEmailSentMessage
          ) {
            resendEmailMessage = (
              <span className="{css.emailSent}">
                <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
                  <formattedmessage id="ContactDetailsForm.emailSent"></formattedmessage>
                </typographywrapper>
              </span>
            );
          } else {
            /* eslint-disable jsx-a11y/no-static-element-interactions */
            resendEmailMessage = (
              <span className="{css.helperLink}" onClick="{this.handleResendVerificationEmail}" role="button">
                <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
                  <formattedmessage id="ContactDetailsForm.resendEmailVerificationText"></formattedmessage>
                </typographywrapper>
              </span>
            );
            /* eslint-enable jsx-a11y/no-static-element-interactions */
          }

          // Email status info: unverified, verified and pending email (aka changed unverified email)
          let emailVerifiedInfo = null;

          if (!emailVerified && !pendingEmail) {
            // Current email is unverified. This is the email given in sign up form

            emailVerifiedInfo = (
              <span className="{css.emailUnverified}">
                <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
                  <formattedmessage id="ContactDetailsForm.emailUnverified" values="{{" resendEmailMessage="" }}=""></formattedmessage>
                </typographywrapper>
              </span>
            );
          } else if (!emailVerified && pendingEmail) {
            // Current email has been tried to change, but the new address is not yet verified

            const pendingEmailStyled = <span className="{css.emailStyle}">{pendingEmail}</span>;
            const pendingEmailCheckInbox = (
              <span className="{css.checkInbox}">
                <typographywrapper variant="body1">
                  <formattedmessage id="ContactDetailsForm.pendingEmailCheckInbox" values="{{" pendingEmail:="" pendingEmailStyled="" }}=""></formattedmessage>
                </typographywrapper>
              </span>
            );

            emailVerifiedInfo = (
              <span className="{css.pendingEmailUnverified}">
                <typographywrapper variant="body1">
                  <formattedmessage id="ContactDetailsForm.pendingEmailUnverified" values="{{" pendingEmailCheckInbox,="" resendEmailMessage="" }}=""></formattedmessage>
                </typographywrapper>
              </span>
            );
          }

          // phone
          const protectedData = profile.protectedData || {};
          const currentPhoneNumber = protectedData.phoneNumber;

          // has the phone number changed
          const phoneNumberChanged = currentPhoneNumber !== phoneNumber;

          const phonePlaceholder = intl.formatMessage({
            id: 'ContactDetailsForm.phonePlaceholder',
          });
          const phoneLabel = intl.formatMessage({ id: 'ContactDetailsForm.phoneLabel' });

          // password
          const passwordLabel = intl.formatMessage({
            id: 'ContactDetailsForm.passwordLabel',
          });
          const passwordPlaceholder = intl.formatMessage({
            id: 'ContactDetailsForm.passwordPlaceholder',
          });
          const passwordRequiredMessage = intl.formatMessage({
            id: 'ContactDetailsForm.passwordRequired',
          });

          const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

          const passwordMinLengthMessage = intl.formatMessage(
            {
              id: 'ContactDetailsForm.passwordTooShort',
            },
            {
              minLength: validators.PASSWORD_MIN_LENGTH,
            }
          );

          const passwordMinLength = validators.minLength(
            passwordMinLengthMessage,
            validators.PASSWORD_MIN_LENGTH
          );

          const passwordValidators = emailChanged
            ? validators.composeValidators(passwordRequired, passwordMinLength)
            : null;

          const passwordFailedMessage = intl.formatMessage({
            id: 'ContactDetailsForm.passwordFailed',
          });
          const passwordTouched = this.submittedValues.currentPassword !== values.currentPassword;
          const passwordErrorText = isChangeEmailWrongPassword(saveEmailError)
            ? passwordFailedMessage
            : null;

          const confirmClasses = classNames(css.confirmChangesSection, {
            [css.confirmChangesSectionVisible]: emailChanged,
          });

          // generic error
          const isGenericEmailError = saveEmailError && !(emailTakenErrorText || passwordErrorText);

          let genericError = null;

          if (isGenericEmailError && savePhoneNumberError) {
            genericError = (
              <span className="{css.error}">
                <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.red80="" }="" }}="">
                  <formattedmessage id="ContactDetailsForm.genericFailure"></formattedmessage>
                </typographywrapper>
              </span>
            );
          } else if (isGenericEmailError) {
            genericError = (
              <span className="{css.error}">
                <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.red80="" }="" }}="">
                  <formattedmessage id="ContactDetailsForm.genericEmailFailure"></formattedmessage>
                </typographywrapper>
              </span>
            );
          } else if (savePhoneNumberError) {
            genericError = (
              <span className="{css.error}">
                <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.red80="" }="" }}="">
                  <formattedmessage id="ContactDetailsForm.genericPhoneNumberFailure"></formattedmessage>
                </typographywrapper>
              </span>
            );
          }

          const classes = classNames(rootClassName || css.root, className);
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid ||
            pristineSinceLastSubmit ||
            inProgress ||
            !(emailChanged || phoneNumberChanged);

          return (
            <form className="{classes}" onSubmit="{(e)" ==""> {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className="{css.contactDetailsSection}">
                <fieldtextinput type="email" name="email" id="{formId" ?="" `${formId}.email`="" :="" 'email'}="" label="{emailLabel}" placeholder="{emailPlaceholder}" validate="{validators.composeValidators(emailRequired," emailValid)}="" customErrorText="{emailTouched" null="" emailTakenErrorText}=""></fieldtextinput>
                {emailVerifiedInfo}
                <fieldphonenumberinput className="{css.phone}" name="phoneNumber" id="{formId" ?="" `${formId}.phoneNumber`="" :="" 'phoneNumber'}="" label="{phoneLabel}" placeholder="{phonePlaceholder}"></fieldphonenumberinput>
              </div>

              {emailChanged && (
                <div className="{confirmClasses}">
                  <h3 className="{css.confirmChangesTitle}">
                    <typographywrapper variant="h2">
                      <formattedmessage id="ContactDetailsForm.confirmChangesTitle"></formattedmessage>
                    </typographywrapper>
                  </h3>
                  <p className="{css.confirmChangesInfo}">
                    <typographywrapper variant="body1">
                      <formattedmessage id="ContactDetailsForm.confirmChangesInfo"></formattedmessage>
                    </typographywrapper>
                  </p>

                  <fieldtextinput className="{css.password}" type="password" name="currentPassword" id="{formId" ?="" `${formId}.currentPassword`="" :="" 'currentPassword'}="" autoComplete="current-password" label="{passwordLabel}" placeholder="{passwordPlaceholder}" validate="{passwordValidators}" customErrorText="{passwordTouched" null="" passwordErrorText}=""></fieldtextinput>
                </div>
              )}
              <div className="{css.bottomWrapper}">
                {genericError}
                <button type="submit" inProgress="{inProgress}" ready="{pristineSinceLastSubmit}" disabled="{submitDisabled}">
                  <formattedmessage id="ContactDetailsForm.saveChanges"></formattedmessage>
                </button>
              </div>
            </form>
          );
        }}
      />
    );
  }
}

ContactDetailsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  saveEmailError: null,
  savePhoneNumberError: null,
  inProgress: false,
  sendVerificationEmailError: null,
  sendVerificationEmailInProgress: false,
};

const { bool, func, string } = PropTypes;

ContactDetailsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  onResendVerificationEmail: func.isRequired,
  ready: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  sendVerificationEmailInProgress: bool,
};

const ContactDetailsForm = compose(injectIntl)(ContactDetailsFormComponent);

ContactDetailsForm.displayName = 'ContactDetailsForm';

export default ContactDetailsForm;
</finalform>