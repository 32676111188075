import classNames from 'classnames';
import React, { FC, useState } from 'react';
import {
  ConditionalWrapper,
  IconSpinner,
  IconSubtractFilled,
  ListingImage,
  NamedLink,
  Overlay,
} from '..';
import { ListingWithImages } from '../../types/sharetribe/listing';
import { createSlug } from '../../util/urlHelpers';
import css from './ListingCardPreview.module.css';

interface ListingProps {
  listing: ListingWithImages;
  allowListingRedirect?: boolean;
  isEditing?: boolean;
  onRemoveClick?: () => void;
  onClick?: () => void;
  listingImageClassName?: string;
}

export const ListingCardSmall: FC<listingprops> = (props: ListingProps) => {
  const {
    listing,
    allowListingRedirect = true,
    isEditing = false,
    onRemoveClick = () => {},
    onClick = () => {},
    listingImageClassName,
  } = props;

  const [isRemovingListing, setIsRemovingListing] = useState<boolean>(false);

  const handleRemoveClick = async () => {
    if (!onRemoveClick) return;
    setIsRemovingListing(true);
    await new Promise(() => onRemoveClick());
    setIsRemovingListing(false);
  };

  return (
    <div className="{css.listing}">
      <conditionalwrapper condition="{allowListingRedirect}" wrapper="{(children)" ==""> (
          <namedlink name="ListingPage" onClick="{onClick}" params="{{" id:="" listing.id.uuid,="" slug:="" createSlug(listing.attributes.title)="" }}="">
            {children}
          </namedlink>
        )}
      >
        <listingimage currentListing="{listing}" title="{listing.attributes.title}" className="{classNames(css.listingImage," listingImageClassName)}=""></listingimage>
      </conditionalwrapper>
      {isRemovingListing && (
        <overlay>
          <iconspinner></iconspinner>
        </overlay>
      )}
      {isEditing && (
        <button className="{css.removeListingButton}" type="button" onClick="{handleRemoveClick}">
          <iconsubtractfilled className="{css.removeIcon}"></iconsubtractfilled>
        </button>
      )}
    </div>
  );
};

export default ListingCardSmall;
</boolean></listingprops>