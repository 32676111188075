import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconSocialMediaTiktokProps {
  className?: string;
}

// SVG taken from
// https://www.iloveimg.com/download/c1swdcnArp76rz122fqh52fzfbp2gp8sgpqk633fhb0d3b83dAtp7sq030AcrA02mdk5svddhbyqvytd00AbArk1gmw3czcAcwwkrxbrt4w35pnAngkph9hj7y1xxjvs2k5y257A9ljx9nxhczjq1b5d9jmlmslAxty20m3clhl4cwq0nfl1/1
const IconSocialMediaTiktok: FC<iconsocialmediatiktokprops> = (props) => {
  const { className } = props;
  const { iconColor } = useIconColor();

  return (
    <svg className="{className}" width="17pt" height="17pt" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
      <path d="M 13.601562 7.679688 L 13.601562 5.667969 C 12.65625 5.667969 11.9375 5.414062 11.476562 4.925781 C 11 4.367188 10.730469 3.664062 10.71875 2.929688 L 10.71875 2.785156 L 8.820312 2.738281 C 8.820312 2.738281 8.820312 2.820312 8.820312 2.929688 L 8.820312 10.910156 C 8.683594 11.636719 8.109375 12.207031 7.378906 12.335938 C 6.648438 12.460938 5.914062 12.117188 5.542969 11.476562 C 5.171875 10.832031 5.242188 10.027344 5.714844 9.457031 C 6.191406 8.886719 6.972656 8.671875 7.671875 8.921875 L 7.671875 6.972656 C 7.472656 6.941406 7.269531 6.925781 7.070312 6.925781 C 5.042969 6.925781 3.398438 8.566406 3.398438 10.59375 C 3.398438 12.621094 5.042969 14.261719 7.070312 14.261719 C 9.09375 14.261719 10.738281 12.621094 10.738281 10.59375 C 10.738281 10.441406 10.726562 10.292969 10.710938 10.144531 L 10.710938 6.902344 C 11.574219 7.441406 12.582031 7.710938 13.601562 7.679688 Z M 13.601562 7.679688 " fillRule="evenodd" fill="{iconColor}"></path>
    </svg>
  );
};

export default IconSocialMediaTiktok;
</iconsocialmediatiktokprops>