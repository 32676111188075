import Box from '@material-ui/core/Box';
import React, { FC, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { IconSpinner, ListingCardPreview, Overlay } from '..';
import { logSelectItem } from '../../analytics/ga4analytics';
import { ListingWithImages } from '../../types/sharetribe/listing';
import { resetActiveModal } from '../../ducks/modal.duck';
import css from './Bundle.module.css';

interface BundleProps {
  listings: ListingWithImages[];
  referrerForLogging: string;
  isEditing?: boolean;
  isLoading?: boolean;
  heading?: ReactElement;
  actionButton?: ReactElement;
  listingsInlineButton?: ReactElement;
  allowListingRedirect?: boolean;
  handleRemoveListings?: (listingIds: string[]) => void;
}

const Bundle: FC<bundleprops> = ({
  listings,
  referrerForLogging,
  isEditing = false,
  isLoading = false,
  heading,
  actionButton,
  listingsInlineButton,
  allowListingRedirect = true,
  handleRemoveListings = () => {},
}: BundleProps) => {
  const dispatch = useDispatch();
  return (
    <box display="flex" flexDirection="column" p="20px" className="{css.root}">
      {heading}
      <box display="flex" flexDirection="row" className="{css.listingImages}">
        {listings.map((l) => (
          <listingcardpreview allowListingRedirect="{allowListingRedirect}" isEditing="{isEditing}" listing="{l}" onRemoveClick="{()" ==""> handleRemoveListings([l.id.uuid])}
            key={l.id.uuid}
            onClick={() => {
              logSelectItem(l, referrerForLogging);
              dispatch(resetActiveModal());
            }}
          />
        ))}
        {listingsInlineButton}
      </listingcardpreview></box>
      {isLoading && (
        <overlay>
          <iconspinner></iconspinner>
        </overlay>
      )}
      {actionButton}
    </box>
  );
};

export default Bundle;
</bundleprops>