import Linkify from 'react-linkify';
import { Typography, TypographyProps, TypographyVariant } from '@material-ui/core';
import { Builder } from '@builder.io/react';
import { isEmpty } from 'lodash';
import React, { ElementType, useState } from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ConditionalWrapper } from '..';
import { useShopCss } from '../../hooks/useShopCss';
import { builderTypographyInputs } from './BuilderTypographyInputs';

const UNDERLINE_FORMATTING_STYLES = {
  textDecoration: 'underline',
  textDecorationColor: 'inherit',
  textDecorationThickness: '1px',
  textUnderlineOffset: '2px',
};

const isHeader = (variant: string) =>
  variant === 'h1' ||
  variant === 'h2' ||
  variant === 'h3' ||
  variant === 'h4' ||
  variant === 'h5' ||
  variant === 'h6';

export enum TypographyWeight {
  Bold = 'bold',
  Medium = 500,
}

export enum TypographyFormat {
  Underlined = 'UNDERLINED',
  HoverUnderlined = 'HOVER_UNDERLINED',
}

export interface TypographyWrapperProps {
  variant: TypographyVariant;
  format?: TypographyFormat;
  weight?: TypographyWeight;
  typographyOverrides?: TypographyProps;
  children?: React.ReactElement | string | (string | React.ReactElement)[];
  applyNested?: boolean;
  component?: ElementType<any>;
}

// If we are using Shop Config V2's CSS, wrap the text in the correct Typography variant
const TypographyWrapper: React.FC<typographywrapperprops> = (props) => {
  const {
    variant,
    typographyOverrides,
    applyNested = true,
    children,
    format,
    weight,
    component,
  } = props;
  const { style: styleOverrides, ...rest } = typographyOverrides || {};
  const shopCss = useShopCss();

  const [isHovered, setIsHovered] = useState(false);

  const isHeaderVariant = isHeader(variant);
  let formattingStyles: CSSProperties = isHeaderVariant ? { textWrap: 'balance' } : {};

  if (format === TypographyFormat.Underlined) {
    formattingStyles = { ...formattingStyles, ...UNDERLINE_FORMATTING_STYLES };
  }

  if (format === TypographyFormat.HoverUnderlined) {
    formattingStyles = {
      ...formattingStyles,
      ...(isHovered && UNDERLINE_FORMATTING_STYLES),
    };
  }

  if (weight) {
    formattingStyles = { ...formattingStyles, fontWeight: weight };
  }

  const hasStyleOverrides = !isEmpty(formattingStyles) || !isEmpty(styleOverrides);
  const propOverrides = {
    ...(component && { component }),
    ...rest,
    ...(hasStyleOverrides && { style: { ...formattingStyles, ...styleOverrides } }),
  };

  return (
    <conditionalwrapper Check="" if="" the="" child="" is="" a="" string="" because="" we="" don't="" want="" to="" apply="" this="" in="" nested="" manner="" condition="{!!shopCss?.[variant]" &&="" (applyNested="" ?="" true="" :="" typeof="" children="==" 'string')}="" wrapper="{(c)" ==""> (
        <typography onMouseEnter="{()" ==""> setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          variant={variant}
          {...propOverrides}
        >
          {c}
        </typography>
      )}
    >
      <linkify componentDecorator="{(href," text,="" key)=""> (
          <a key="{key}" href="{href}" style="{{" color:="" 'inherit',="" ...UNDERLINE_FORMATTING_STYLES="" }}="">
            {text}
          </a>
        )}
      >
        {children}
      </linkify>
    </conditionalwrapper>
  );
};

Builder.registerComponent(TypographyWrapper, {
  name: 'TypographyWrapper',
  inputs: builderTypographyInputs,
});

export default TypographyWrapper;
</typographywrapperprops></any>