import classNames from 'classnames';
import React from 'react';
import { IconButton, IconFavorite, IconFavoriteFilled } from '..';
import { useIconColor } from '../../hooks/useIconColor';
import { useShopCss } from '../../hooks/useShopCss';
import { useShopConfig } from '../../hooks/shopConfig';
import { areColorsValidAndDistinct, isValidHex } from '../../util/colors/colors';

interface FavoriteButtonProps {
  className?: string;
  iconClassName?: string;
  isSelected: boolean;
  hasBorder?: boolean;
  onClick: () => void;
}

// Threshold var used for color similarity determination
const EUCLIDEAN_DISTANCE_THRESHOLD = 50;

// TODO: (HM|TREET-7685) Make multiple favorites buttons if more use cases come up and this component becomes unruly
const FavoriteButton: React.FC<favoritebuttonprops> = (props) => {
  const { className, iconClassName, isSelected, onClick, hasBorder } = props;
  const { iconColor } = useIconColor();
  const { primaryButton } = useShopCss();
  const { css } = useShopConfig();
  const classes = classNames(css.root, className);
  const brandBackgroundColor = css.backgroundcolor || '#FFFFFF';

  const primaryButtonBorder = primaryButton?.border || '';
  const splitPrimaryButtonBorderString = primaryButtonBorder.split(' ');
  const primaryButtonBorderColor = splitPrimaryButtonBorderString.find((string: string) =>
    string.includes('#')
  );
  const primaryButtonBackgroundColor = primaryButton?.backgroundColor;
  const primaryButtonTextColor = primaryButton?.font?.color;
  const primaryButtonBorderWidth = splitPrimaryButtonBorderString.find((string: string) =>
    string.includes('px')
  );

  let iconButtonColor = iconColor;

  if (isValidHex(brandBackgroundColor)) {
    if (
      primaryButtonBorderColor &&
      areColorsValidAndDistinct({
        firstHexColor: primaryButtonBorderColor,
        secondHexColor: brandBackgroundColor,
        euclideanDistanceThreshold: EUCLIDEAN_DISTANCE_THRESHOLD,
      })
    ) {
      iconButtonColor = primaryButtonBorderColor;
    } else if (
      primaryButtonBackgroundColor &&
      areColorsValidAndDistinct({
        firstHexColor: primaryButtonBackgroundColor,
        secondHexColor: brandBackgroundColor,
        euclideanDistanceThreshold: EUCLIDEAN_DISTANCE_THRESHOLD,
      })
    ) {
      iconButtonColor = primaryButtonBackgroundColor;
    } else if (
      primaryButtonTextColor &&
      areColorsValidAndDistinct({
        firstHexColor: primaryButtonTextColor,
        secondHexColor: brandBackgroundColor,
        euclideanDistanceThreshold: EUCLIDEAN_DISTANCE_THRESHOLD,
      })
    ) {
      iconButtonColor = primaryButtonTextColor;
    }
  }

  const iconButtonBorderWidth = primaryButtonBorderWidth || '2px';
  const iconButtonBorderRadius = primaryButton.borderRadius || '';
  const iconButtonStyleOveride = hasBorder
    ? {
        border: `${iconButtonBorderWidth} solid ${iconButtonColor}`,
        borderRadius: `${iconButtonBorderRadius}`,
      }
    : {};

  // if the hasBorder prop is not passed we want the default color behavior
  iconButtonColor = hasBorder ? iconButtonColor : '';

  let icon;

  if (isSelected) {
    icon = <iconfavoritefilled className="{iconClassName}" color="{iconButtonColor}"></iconfavoritefilled>;
  } else {
    icon = <iconfavorite className="{iconClassName}" color="{iconButtonColor}"></iconfavorite>;
  }

  return (
    <iconbutton styleOverride="{iconButtonStyleOveride}" className="{classes}" icon="{icon}" onClick="{onClick}"></iconbutton>
  );
};

export default FavoriteButton;
</favoritebuttonprops>