import { Feature, isFeatureEnabled } from './featureFlags';
import { apolloClient } from '../apollo';
import {
  CartItemsDocument,
  CreateCartItemDocument,
  RemoveCartItemDocument,
} from '../types/apollo/generated/types.generated';
import { handle } from './helpers';
import * as log from './log';

export const formatPGCartItems = (cartItems: any) =>
  cartItems?.map(
    (cartItem: { listing: { sharetribeListingId: 'String' } }) =>
      cartItem.listing.sharetribeListingId
  );

export const getCartListingsFromPG = async (
  currentUser: { id: { uuid: string } },
  treetId: string
): Promise<string[]> => {
  let formattedCartItems = [];
  if (isFeatureEnabled(Feature.PGFavoritesCart, treetId) && currentUser) {
    const cartItemsPromise = apolloClient.query({
      query: CartItemsDocument,
      fetchPolicy: 'network-only',
    });

    const [pgCartItems, pgCartItemsError] = await handle(cartItemsPromise);

    if (pgCartItemsError) {
      log.error(
        new Error(`Error attempting to query PG cart: ${pgCartItemsError}`),
        'query-pg-cart-items-failed',
        { sharetribeUserId: currentUser.id.uuid }
      );
    } else {
      formattedCartItems = formatPGCartItems(pgCartItems?.data.cartItems);
    }
  }
  return formattedCartItems;
};

export const getCartListingsFromState = (userState: any, treetId: string) => {
  let cartListingIds;
  if (isFeatureEnabled(Feature.PGFavoritesCart, treetId)) {
    cartListingIds = userState.cartListingIds || [];
  } else {
    cartListingIds =
      userState.currentUser?.attributes?.profile?.privateData?.shoppingBagListingIds || [];
  }
  return cartListingIds;
};

export const setCartListingInPG = (cartItemParams: any) =>
  apolloClient.mutate({
    mutation: CreateCartItemDocument,
    variables: {
      input: cartItemParams,
    },
  });

export const removeCartListingsInPG = (cartItemParams: any) =>
  apolloClient.mutate({
    mutation: RemoveCartItemDocument,
    variables: {
      input: cartItemParams,
    },
  });
</string[]>