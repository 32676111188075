import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Field, useForm, useFormState } from 'react-final-form';
import ListingFeedbackHeader from './ListingFeedbackHeader';
import TypographyWrapper, {
  TypographyFormat,
  TypographyWeight,
} from '../TypographyWrapper/TypographyWrapper';
import ListingFeedbackToggleButton from './ListingFeedbackToggleButton';
import css from './ListingFeedbackModal.module.css';
import {
  buttonDetails,
  ListingFeedbackCategory,
  ListingFeedbackFormField,
} from './ListingFeedback.utils';
import { required } from '../../util/validators';
import { InlineTextButton } from '..';

const ListingFeedbackCategoryPane: FC = () => {
  const form = useForm();
  const { values } = useFormState();
  const category = values[ListingFeedbackFormField.Category];

  if (category === ListingFeedbackCategory.Other) {
    form.change(ListingFeedbackFormField.Category);
  }

  const handleChange = (_event: any, selectedCategory: ListingFeedbackCategory) => {
    form.change(ListingFeedbackFormField.Category, selectedCategory);
  };

  const itemDetailsButtons = [
    buttonDetails[ListingFeedbackCategory.Photos],
    buttonDetails[ListingFeedbackCategory.Sizing],
    buttonDetails[ListingFeedbackCategory.Quirks],
    buttonDetails[ListingFeedbackCategory.Material],
    buttonDetails[ListingFeedbackCategory.Pets],
    buttonDetails[ListingFeedbackCategory.Smoking],
  ];

  return (
    <box display="flex" flexDirection="column">
      <listingfeedbackheader></listingfeedbackheader>
      <box display="flex" justifyContent="center" className="{css.paneBody}">
        <togglebuttongroup orientation="vertical" value="{category}" exclusive="" onChange="{handleChange}">
          <box mt="{4}" mb="{1}" ml="{2}">
            <typographywrapper variant="body1" weight="{TypographyWeight.Bold}">
              Item Details
            </typographywrapper>
          </box>
          {itemDetailsButtons.map(({ structuredText, Icon, key }) => (
            <listingfeedbacktogglebutton key="{key}" Icon="{Icon}" structuredText="{structuredText}" value="{key}" aria-label="{key}"></listingfeedbacktogglebutton>
          ))}
          <box mb="{1}" ml="{2}" className="{css.categoriesHeader}">
            <typographywrapper variant="body1" weight="{TypographyWeight.Bold}">
              Pricing
            </typographywrapper>
          </box>
          <listingfeedbacktogglebutton Icon="{buttonDetails[ListingFeedbackCategory.Price].Icon}" structuredText="{buttonDetails[ListingFeedbackCategory.Price].structuredText}" value="{buttonDetails[ListingFeedbackCategory.Price].key}" aria-label="{buttonDetails[ListingFeedbackCategory.Price].key}"></listingfeedbacktogglebutton>
          <box ml="{2}" className="{css.categoriesHeader}">
            <typographywrapper variant="body1">Not seeing what you want to say?</typographywrapper>
          </box>
          <inlinetextbutton onClick="{()" ==""> {
              form.change(ListingFeedbackFormField.Category, ListingFeedbackCategory.Other);
              form.submit();
            }}
          >
            <box mb="{4}" ml="{2}" width="fit-content">
              <typographywrapper variant="body1" weight="{TypographyWeight.Bold}" format="{TypographyFormat.Underlined}">
                Ask another question
              </typographywrapper>
            </box>
          </inlinetextbutton>
        </togglebuttongroup>
      </box>
      <field id="{ListingFeedbackFormField.Category}" name="{ListingFeedbackFormField.Category}" input="{<input" type="hidden"></field>}
        // this is a hidden field, but the required validator still needs a message
        validate={required('Please select a category.')}
        hidden
        component="input"
      />
    </box>
  );
};

export default ListingFeedbackCategoryPane;
