import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { DialogWithCloseButton, ShoppingBag } from '..';
import { resetActiveModal } from '../../ducks/modal.duck';
import css from './ShoppingBagModal.module.css';

interface ShoppingBagModalProps {
  isOpen: boolean;
}

const ShoppingBagModal: FC<shoppingbagmodalprops> = (props: ShoppingBagModalProps) => {
  const { isOpen } = props;

  const dispatch = useDispatch();

  return (
    <dialogwithclosebutton open="{isOpen}" allowFullScreen="{false}" headerClassName="{css.dialogHeader}" closeButtonClassName="{css.dialogCloseButton}" fullWidth="" maxWidth="sm" onClose="{()" ==""> dispatch(resetActiveModal())}
    >
      <shoppingbag isOpen="" className="{css.shoppingBag}"></shoppingbag>
    </dialogwithclosebutton>
  );
};

export default ShoppingBagModal;
</shoppingbagmodalprops>