import React, { FC } from 'react';

const IconBrowse: FC = () => (
  <svg width="100" height="100" viewBox="0 0 544 544" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="272" cy="272" r="272" fill="#F8F8F8"></circle>
    <path d="M132 189C132 180.163 139.163 173 148 173H396C404.837 173 412 180.163 412 189V361H132V189Z" fill="#D1D1D1"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M396 181H148C143.582 181 140 184.582 140 189V353H404V189C404 184.582 400.418 181 396 181ZM148 173C139.163 173 132 180.163 132 189V361H412V189C412 180.163 404.837 173 396 173H148Z" fill="#454545"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M419 373H125C120.582 373 117 369.418 117 365V361H427V365C427 369.418 423.418 373 419 373ZM125 381C116.163 381 109 373.837 109 365V353H435V365C435 373.837 427.837 381 419 381H125Z" fill="#454545"></path>
    <path d="M177.971 230.847L174 221.781L188.691 216C189.397 217.445 191.947 220.336 196.5 220.336C201.053 220.336 203.603 217.445 204.309 216L219 221.781L215.029 230.847H208.809V252H184.191V230.847H177.971Z" fill="#454545"></path>
    <path d="M252.971 230.847L249 221.781L263.691 216C264.397 217.445 266.947 220.336 271.5 220.336C276.053 220.336 278.603 217.445 279.309 216L294 221.781L290.029 230.847H283.809V252H259.191V230.847H252.971Z" fill="#454545"></path>
    <path d="M327.971 230.847L324 221.781L338.691 216C339.397 217.445 341.947 220.336 346.5 220.336C351.053 220.336 353.603 217.445 354.309 216L369 221.781L365.029 230.847H358.809V252H334.191V230.847H327.971Z" fill="#454545"></path>
    <path d="M177.971 300.847L174 291.781L188.691 286C189.397 287.445 191.947 290.336 196.5 290.336C201.053 290.336 203.603 287.445 204.309 286L219 291.781L215.029 300.847H208.809V322H184.191V300.847H177.971Z" fill="#454545"></path>
    <path d="M252.971 300.847L249 291.781L263.691 286C264.397 287.445 266.947 290.336 271.5 290.336C276.053 290.336 278.603 287.445 279.309 286L294 291.781L290.029 300.847H283.809V322H259.191V300.847H252.971Z" fill="#454545"></path>
    <path d="M327.971 300.847L324 291.781L338.691 286C339.397 287.445 341.947 290.336 346.5 290.336C351.053 290.336 353.603 287.445 354.309 286L369 291.781L365.029 300.847H358.809V322H334.191V300.847H327.971Z" fill="#454545"></path>
  </svg>
);

export default IconBrowse;
