import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import ListingFeedbackHeader from './ListingFeedbackHeader';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import css from './ListingFeedbackModal.module.css';
import ListingFeedbackToggleButton from './ListingFeedbackToggleButton';
import {
  buttonDetails,
  ListingFeedbackCategory,
  ListingFeedbackFormField,
} from './ListingFeedback.utils';
import { FieldTextInput } from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import { composeValidators, maxLength, required } from '../../util/validators';

const ListingFeedbackFreeformPane: FC = () => {
  const { values } = useFormState();
  const { shopName } = useShopConfig();

  const selectedCategory =
    values[ListingFeedbackFormField.Category] || ListingFeedbackCategory.Other;
  const selectedCategoryButtonDetails = buttonDetails[selectedCategory as ListingFeedbackCategory];

  const notesRequired = required('Notes are required.');
  const notesMaxLength = maxLength('Exceeded Max Length of 1000 Characters.', 1000);
  let notesValidators;
  if (selectedCategory === ListingFeedbackCategory.Other) {
    notesValidators = composeValidators(
      notesMaxLength,
      selectedCategory === ListingFeedbackCategory.Other ? notesRequired : null
    );
  } else {
    notesValidators = notesMaxLength;
  }

  return (
    <box display="flex" flexDirection="column">
      <listingfeedbackheader></listingfeedbackheader>
      <box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className="{css.paneBody}">
        <box mt="{2}">
          <listingfeedbacktogglebutton Icon="{selectedCategoryButtonDetails.Icon}" structuredText="{selectedCategoryButtonDetails.structuredText}" value="{selectedCategoryButtonDetails.key}" aria-label="{selectedCategoryButtonDetails.key}" className="Mui-selected" style="{{" cursor:="" 'default'="" }}=""></listingfeedbacktogglebutton>
        </box>
        <box width="75%">
          <box my="{2}">
            <typographywrapper variant="body1" typographyOverrides="{{" align:="" 'center'="" }}="">
              Any other details? Please specify the information you’d like the seller to update
              their listing with.
            </typographywrapper>
          </box>
          <fieldtextinput id="{ListingFeedbackFormField.Note}" name="{ListingFeedbackFormField.Note}" g="" type="textarea" placeholder="eg. Can you take more photos of the item quirks?" className="{css.freeformTextArea}" style="{{" border:="" 'none',="" minHeight:="" '140px'="" }}="" validate="{notesValidators}"></fieldtextinput>
        </box>
        <box my="{2}" width="65%">
          <typographywrapper variant="body1" weight="{TypographyWeight.Bold}" typographyOverrides="{{" align:="" 'center',="" style:="" {="" fontSize:="" '12px'="" }="" }}="">
            Your feedback will be sent to a verified {shopName} customer, and will be moderated by
            the Treet team
          </typographywrapper>
        </box>
      </box>
    </box>
  );
};

export default ListingFeedbackFreeformPane;
