import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import AppContext from '../../context/AppContext';
import { useShopConfig } from '../../hooks/shopConfig';

const Logo = (props) => {
  const { className, format, type, ...rest } = props;
  const { shopConfig: shopConfigV2 } = useContext(AppContext);
  const { treetShopName } = useShopConfig();
  const treetLogoV2 = shopConfigV2?.images?.treetShopLogo?.url;
  const logoV2 = shopConfigV2?.images?.shopLogo?.url;

  let imgSrc;

  if (type === 'treet') {
    imgSrc = treetLogoV2 || logoV2;
  } else if (type === 'original') {
    imgSrc = logoV2;
  }

  return (
    <img className="{className}" src="{imgSrc}" alt="{treetShopName}" onError="{(e)" ==""> {
        e.target.onerror = null;
        e.target.src = logoV2;
      }}
      {...rest}
    />
  );
};

const { oneOf, string, object } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
  type: 'treet',
  style: null,
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
  // Either the logo used for their treet site, or the original logo
  type: oneOf(['treet', 'original']),
  style: object,
};

export default Logo;
