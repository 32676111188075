import React, { FC } from 'react';
import { Box, withStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { TextField } from 'mui-rff';
import ListingFeedbackHeader from './ListingFeedbackHeader';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import * as validators from '../../util/validators';
import { defaultTreetStyles } from '../../shopConfig/config';
import { IconEmail } from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import { getDarkerBrandCssBackgroundColor } from '../../util/colors/colors';
import { useIsMobile } from '../../hooks/useIsMobile';

const TEXT_INPUT_COLOR = defaultTreetStyles.gray80;

const inputStyles = {
  root: {
    '& label': {
      color: TEXT_INPUT_COLOR,
      fontWeight: 'bold',
    },
    '& label.Mui-focused': {
      color: TEXT_INPUT_COLOR,
    },
    '& .MuiSelect-icon': {
      color: TEXT_INPUT_COLOR,
      top: 'unset',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 15px) scale(1)',
    },

    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    // This is needed so that the autocomplete backfill fills the whole input
    '& input': {
      padding: '0px 14px',
      height: '100%',
    },
    '& .MuiOutlinedInput-root': {
      color: TEXT_INPUT_COLOR,
      fontWeight: 'bold',
      width: '100%',
      height: '48px',

      '& fieldset': {
        borderWidth: '1px',
        borderColor: TEXT_INPUT_COLOR,
        color: TEXT_INPUT_COLOR,
      },
      '&:hover fieldset': {
        borderColor: TEXT_INPUT_COLOR,
      },
      '&.Mui-focused fieldset': {
        borderColor: TEXT_INPUT_COLOR,
      },
    },
  },
};

const EmailTextField = withStyles(inputStyles)(TextField);

const ListingFeedbackEmailPane: FC = () => {
  const intl = useIntl();
  const { css: brandCss } = useShopConfig();
  const backgroundColor = getDarkerBrandCssBackgroundColor(brandCss);

  const emailRequiredMessage = intl.formatMessage({ id: 'SignupForm.emailRequired' });
  const emailRequired = validators.required(emailRequiredMessage);
  const emailInvalidMessage = intl.formatMessage({ id: 'SignupForm.emailInvalid' });
  const emailValid = validators.emailFormatValid(emailInvalidMessage);
  const emailValidators = validators.composeValidators(emailRequired, emailValid);
  const isMobile = useIsMobile();

  return (
    <box display="flex" flexDirection="column" height="100%">
      <listingfeedbackheader></listingfeedbackheader>
      <box 5="" display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%" pt="{{" xs:="" 10,="" md:="" }}="" pb="{{" 24,="" px="{2}">
        <box pb="{4}">
          <box bgcolor="{backgroundColor}" sx="{{" display:="" 'flex',="" alignItems:="" 'center',="" justifyContent:="" borderRadius:="" '50%',="" width:="" 120,="" height:="" }}="">
            <iconemail></iconemail>
          </box>
        </box>
        <box pb="{4}" padding="{isMobile" ?="" '0="" 30px'="" :="" 70px'}="">
          <typographywrapper variant="body1" typographyOverrides="{{" align:="" 'center',="" }}="">
            <typographywrapper variant="body1" weight="{TypographyWeight.Bold}" typographyOverrides="{{" display:="" 'inline'="" }}="">
              Enter your email
            </typographywrapper>{' '}
            to get notified when the seller responds to your feedback:
          </typographywrapper>
        </box>
        <box width="70%" alignItems="center" display="flex" flexDirection="column" pb="{10}">
          <emailtextfield id="email-listing-feedback" name="email" label="your email" variant="outlined" required="" fieldProps="{{" validate:="" emailValidators="" }}=""></emailtextfield>
        </box>
      </box>
    </box>
  );
};

export default ListingFeedbackEmailPane;
