import { Box, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import React, { FC } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Button, FieldTextInput, Form, TypographyWrapper } from '..';
import DialogWithCloseButton from '../DialogWithCloseButton/DialogWithCloseButton';

interface UpdateQuantityModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: any) => void;
  inProgress?: boolean;
  listingTitle: string;
  currentQuantity: number;
}
const OLD_QUANTITY_FORM_FIELD_NAME = 'oldQuantity';
export const NEW_QUANTITY_FORM_FIELD_NAME = 'newQuantity';

const UpdateQuantityModal: FC<updatequantitymodalprops> = (props) => {
  const { listingTitle, currentQuantity, open, onClose, onSubmit, inProgress } = props;

  return (
    <dialogwithclosebutton allowFullScreen="{false}" scroll="paper" open="{open}" onClose="{onClose}" titleText="{`Update" Quantity="" for="" ${listingTitle}`}="">
      <finalform onSubmit="{onSubmit}" initialValues="{{" [OLD_QUANTITY_FORM_FIELD_NAME]:="" currentQuantity="" }}="" render="{(formRenderProps)" ==""> {
          const { handleSubmit } = formRenderProps;

          return (
            <form id="updateQuantityForm" onSubmit="{handleSubmit}">
              <dialogcontent dividers="">
                <dialogcontenttext>
                  <typographywrapper variant="body1">
                    What would you like to update the quantity to?
                  </typographywrapper>
                  <box mt="{2}">
                    <fieldtextinput id="{OLD_QUANTITY_FORM_FIELD_NAME}" name="{OLD_QUANTITY_FORM_FIELD_NAME}" label="Old Quantity" disabled=""></fieldtextinput>
                  </box>
                  <box mt="{2}">
                    <fieldtextinput id="{NEW_QUANTITY_FORM_FIELD_NAME}" name="{NEW_QUANTITY_FORM_FIELD_NAME}" label="New Quantity" type="number" step="1" min="1" showError="" autoFocus=""></fieldtextinput>
                  </box>
                </dialogcontenttext>
              </dialogcontent>
              <dialogactions>
                <button disabled="{inProgress}" inProgress="{inProgress}" type="submit">
                  Update
                </button>
              </dialogactions>
            </form>
          );
        }}
      />
    </finalform></dialogwithclosebutton>
  );
};

export default UpdateQuantityModal;
</updatequantitymodalprops>