import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { FC } from 'react';
import { Builder } from '@builder.io/react';
import { Button, ConditionalWrapper, ExternalLink, IconComingSoon, NamedLink } from '..';
import { useShopConfig, useShopConfigV2 } from '../../hooks/shopConfig';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useIsShopSideLaunched } from '../../hooks/useIsShopSideLaunched';
import { useStyleType } from '../../hooks/useStyleType';
import { HeroAction, HeroActionItem } from '../../types/shopConfig/shopConfigV2';
import { ButtonVariant } from '../Button/Button';
import MultiButtonWrapper from '../MultiButtonWrapper/MultiButtonWrapper';
import ComingSoonModal from './ComingSoonModal';

import css from './HeroActions.module.css';
import { ModalType } from '../../ducks/modal.duck';
import { useActiveModal } from '../../hooks/useActiveModal';

interface ButtonProps {
  buttonVariant: ButtonVariant;
  isOnImage: boolean;
}

const ShopButton: FC<buttonprops &="" {="" searchParams?:="" string="" }=""> = (props) => {
  const { buttonVariant, isOnImage, searchParams } = props;

  const isShopSideLaunched = useIsShopSideLaunched();
  const {
    copy: { shopButtonText },
  } = useShopConfig();
  const { css: shopCss } = useShopConfigV2();

  const { isModalOpen, openModal, closeModal } = useActiveModal(ModalType.ComingSoon);

  return (
    <>
      <conditionalwrapper condition="{isShopSideLaunched}" wrapper="{(children)" ==""> (
          <namedlink name="LandingPage" style="{{" textDecoration:="" 'none'="" }}="" to="{{" state:="" {="" scrollOnRender:="" true="" },="" ...(searchParams="" ?="" search:="" searchParams="" }="" :="" 'mode="raw-query'" }),="">
            {children}
          </namedlink>
        )}
      >
        <button className="{classNames({" [css.buttonOnImage]:="" isOnImage="" })}="" variant="{buttonVariant}" onClick="{isShopSideLaunched" ?="" undefined="" :="" openModal}="">
          {!isShopSideLaunched && (
            <iconcomingsoon className="{css.comingSoonImg}" color="{shopCss?.comingSoonBannerColor" ||="" 'black'}=""></iconcomingsoon>
          )}
          {shopButtonText}
        </button>
      </conditionalwrapper>
      <comingsoonmodal isOpen="{isModalOpen}" onClose="{closeModal}"></comingsoonmodal>
    </>
  );
};

const SellButton: FC<buttonprops> = (props) => {
  const { buttonVariant, isOnImage } = props;
  const {
    copy: { sellButtonText },
  } = useShopConfig();
  const { isCondensedStyle } = useStyleType();
  const { isListTradeInOnly } = useEnabledCustomerExperiences();

  return (
    <namedlink name="{isListTradeInOnly" ?="" 'ManageTradeInsPage'="" :="" 'NewListingPage'}="" style="{{" textDecoration:="" 'none',="" display:="" 'flex',="" justifyContent:="" 'center'="" }}="">
      <button className="{classNames({" [css.buttonOnImage]:="" isOnImage,="" [css.mobileButtonCondensed]:="" isCondensedStyle,="" })}="" variant="{buttonVariant}">
        {sellButtonText}
      </button>
    </namedlink>
  );
};

interface ExternalLinkButtonProps extends ButtonProps {
  linkConfig?: { label?: string; url?: string };
}

const ExternalLinkButton: FC<externallinkbuttonprops> = (props) => {
  const { buttonVariant, isOnImage, linkConfig } = props;
  const { isCondensedStyle } = useStyleType();

  if (isEmpty(linkConfig)) return null;

  return (
    <externallink href="{linkConfig!.url" as="" string}="">
      <button className="{classNames({" [css.buttonOnImage]:="" isOnImage,="" [css.mobileButtonCondensed]:="" isCondensedStyle,="" })}="" variant="{buttonVariant}">
        {linkConfig!.label}
      </button>
    </externallink>
  );
};

interface HeroActionsProps {
  isOnImage?: boolean;
}

const HeroActions: FC<heroactionsprops> = (props) => {
  const { isOnImage = true } = props;
  const { externalHeroActions } = useShopConfig();
  const { allowList, isShopClosed, allowBuy } = useEnabledCustomerExperiences();

  const heroActions = [
    ...(allowBuy ? [{ type: HeroAction.Shop }] : []),
    ...(allowList ? [{ type: HeroAction.Sell }] : []),
    ...(externalHeroActions || []),
  ];

  if (isShopClosed) return null;

  const buttonVariant = isOnImage ? ButtonVariant.Secondary : ButtonVariant.Primary;

  const actions = {
    [HeroAction.Shop]: <shopbutton buttonVariant="{buttonVariant}" isOnImage="{isOnImage}"></shopbutton>,
    [HeroAction.Sell]: <sellbutton buttonVariant="{buttonVariant}" isOnImage="{isOnImage}"></sellbutton>,
    [HeroAction.ExternalLink]: (
      <externallinkbutton buttonVariant="{buttonVariant}" isOnImage="{isOnImage}" linkConfig="{" externalHeroActions?.find(="" (action:="" HeroActionItem)=""> action.type === HeroAction.ExternalLink
          )?.config
        }
      />
    ),
  };

  return (
    <multibuttonwrapper>
      {heroActions.map((action: HeroActionItem) => (
        <div key="{action.type}">{actions[action.type]}</div>
      ))}
    </multibuttonwrapper>
  );
};

Builder.registerComponent(HeroActions, { name: 'Hero Actions' });
Builder.registerComponent(ShopButton, {
  name: 'Shop Button',
  inputs: [
    {
      name: 'isOnImage',
      type: 'boolean',
    },
    {
      name: 'buttonVariant',
      type: 'string',
      enum: Object.values(ButtonVariant),
    },
    {
      name: 'searchParams',
      friendlyName: 'Link To Search Params',
      helperText:
        'Useful for linking to specific filters, e.g. mode=filter-change&pub_category=accessories',
      type: 'string',
    },
  ],
});

export default HeroActions;
</externallinkbutton></heroactionsprops></externallinkbuttonprops></buttonprops></buttonprops>