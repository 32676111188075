import React, { FC } from 'react';

interface Icon50OffProps {
  className?: string;
}

const Icon50Off: FC<icon50offprops> = (props: Icon50OffProps) => {
  const { className } = props;

  return (
    <svg className="{className}" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="31.5" fill="#962A2A" stroke="#D1D1D1"></circle>
      <path d="M17.5358 26.0181L20.3566 25.7763C20.3888 25.9214 20.4533 26.0664 20.5339 26.1793C20.6789 26.3888 20.9207 26.5822 21.3237 26.5822C21.8878 26.5822 22.3714 26.0987 22.3714 25.4539C22.3714 24.8092 21.8878 24.3256 21.3237 24.3256C21.0013 24.3256 20.7595 24.4707 20.6145 24.648C20.5339 24.7447 20.4533 24.8575 20.4049 24.9704H17.7776L18.5029 18.8453H24.628V21.2631H20.7595L20.5983 22.3914C20.7112 22.3269 20.8401 22.2786 20.9852 22.2302C21.2431 22.1496 21.5977 22.069 22.049 22.069C23.9026 22.069 25.3533 23.5197 25.3533 25.5345C25.3533 27.6299 23.8221 29.1612 21.4043 29.1612C19.0671 29.1612 17.6164 27.7105 17.5358 26.0181Z" fill="white"></path>
      <path d="M30.8361 26.421C31.7388 26.421 32.5286 25.6151 32.5286 23.9227C32.5286 22.2302 31.7388 21.4243 30.8361 21.4243C29.9335 21.4243 29.1437 22.2302 29.1437 23.9227C29.1437 25.6151 29.9335 26.421 30.8361 26.421ZM30.8361 29.1612C28.3378 29.1612 26.3229 27.1464 26.3229 23.9227C26.3229 20.699 28.3378 18.6841 30.8361 18.6841C33.3345 18.6841 35.3493 20.699 35.3493 23.9227C35.3493 27.1464 33.3345 29.1612 30.8361 29.1612Z" fill="white"></path>
      <path d="M37.201 29L43.4067 18.8453H45.5827L39.3771 29H37.201ZM37.9264 21.0213C37.9264 21.4243 38.2488 21.7467 38.6517 21.7467C39.0386 21.7467 39.3771 21.4243 39.3771 21.0213C39.3771 20.6184 39.0547 20.296 38.6517 20.296C38.2488 20.296 37.9264 20.6184 37.9264 21.0213ZM38.6517 18.7647C40.0218 18.7647 40.9889 19.7318 40.9889 21.0213C40.9889 22.3108 40.0218 23.2779 38.6517 23.2779C37.2816 23.2779 36.3145 22.3108 36.3145 21.0213C36.3145 19.7318 37.2816 18.7647 38.6517 18.7647ZM43.4067 26.824C43.4067 27.227 43.729 27.5493 44.132 27.5493C44.5189 27.5493 44.8573 27.227 44.8573 26.824C44.8573 26.421 44.535 26.0987 44.132 26.0987C43.729 26.0987 43.4067 26.421 43.4067 26.824ZM41.7948 26.824C41.7948 25.5345 42.7619 24.5674 44.132 24.5674C45.5021 24.5674 46.4692 25.5345 46.4692 26.824C46.4692 28.1135 45.5021 29.0806 44.132 29.0806C42.7619 29.0806 41.7948 28.1135 41.7948 26.824Z" fill="white"></path>
      <path d="M25.7097 39.9227C25.7097 38.4559 24.662 37.4243 23.3725 37.4243C22.083 37.4243 21.0353 38.4559 21.0353 39.9227C21.0353 41.3894 22.083 42.421 23.3725 42.421C24.662 42.421 25.7097 41.3894 25.7097 39.9227ZM18.2146 39.9227C18.2146 36.9569 20.4712 34.6841 23.3725 34.6841C26.2738 34.6841 28.5304 36.9569 28.5304 39.9227C28.5304 42.8885 26.2738 45.1612 23.3725 45.1612C20.4712 45.1612 18.2146 42.8885 18.2146 39.9227Z" fill="white"></path>
      <path d="M29.9036 45V34.8453H37.2376V37.4243H32.7244V39.1167H36.351V41.6957H32.7244V45H29.9036Z" fill="white"></path>
      <path d="M38.5296 45V34.8453H45.8635V37.4243H41.3503V39.1167H44.977V41.6957H41.3503V45H38.5296Z" fill="white"></path>
    </svg>
  );
};

export default Icon50Off;
</icon50offprops>