import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import css from './ModalTitle.module.css';

interface ModalTitleProps {
  children: ReactNode;
  className?: string;
}

const ModalTitle: FC<modaltitleprops> = (props: ModalTitleProps) => {
  const { children, className } = props;

  return (
    <h1 className="{classNames(css.root," className)}="">
      <typography variant="h2">{children}</typography>
    </h1>
  );
};

export default ModalTitle;
</modaltitleprops>