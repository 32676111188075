import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import ISOHeader from './ISOHeader';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { CurrentUser } from '../../types/sharetribe/currentUser';
import { SizeNotifications } from '..';
import css from './ISOModal.module.css';
import useFetchUserSizeSubscribeInfo from '../../hooks/useFetchUserSizeSubscribeInfo';
import { Cadence } from '../../types/apollo/generated/types.generated';
import { useShopConfig } from '../../hooks/shopConfig';

const NotifyMeBodyContent: FC = () => (
  <box>
    <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
      Also notify me when
    </typographywrapper>
    <typographywrapper variant="body1" weight="{TypographyWeight.Bold}" typographyOverrides="{{" display:="" 'inline'="" }}="">
      &nbsp;any items&nbsp;
    </typographywrapper>
    <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
      in these sizes list!
    </typographywrapper>
  </box>
);

const ISOSizePane: FC = () => {
  const { values } = useFormState();
  const { email } = values;

  // Hide toggle if generic size subscriptions are disabled for the shop.
  const { marketingSettingsConfig: { hideSizesFromSubscribe = false } = {} } = useShopConfig();

  const currentUser = useSelector<any>((state) => state.user.currentUser) as
    | CurrentUser
    | undefined;
  const emailToSubscribe = email || currentUser?.attributes.email;

  const { savedSearchLoading, savedSearchData } = useFetchUserSizeSubscribeInfo(emailToSubscribe);

  const shouldShowNotifyToggle =
    !hideSizesFromSubscribe &&
    !savedSearchLoading &&
    (!savedSearchData?.savedSearch || savedSearchData.savedSearch.cadence === Cadence.Never);

  return (
    <box display="flex" flexDirection="column" height="100%">
      <isoheader headerCopy="Select Sizes"></isoheader>
      <box 5="" py="{{" xs:="" 10,="" md:="" }}="">
        <sizenotifications formId="ISOSizeNotifications" className="{css.sizeNotificationsWrapper}" showToggle="{shouldShowNotifyToggle}" notifyMeBodyContent="{<NotifyMeBodyContent"></sizenotifications>}
          shouldAlwaysShowSizes
        />
      </box>
    </box>
  );
};

export default ISOSizePane;
</any>