import React, { FC } from 'react';

interface Icon30OffProps {
  className?: string;
}

const Icon30Off: FC<icon30offprops> = (props: Icon30OffProps) => {
  const { className } = props;

  return (
    <svg className="{className}" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="31.5" fill="#962A2A" stroke="#D1D1D1"></circle>
      <path d="M16.255 25.77L19.23 25.515C19.247 25.702 19.315 25.855 19.4 25.991C19.553 26.246 19.808 26.45 20.335 26.45C20.93 26.45 21.355 26.042 21.355 25.515C21.355 24.988 21.015 24.665 20.25 24.665H19.23V22.37H20.25C20.845 22.37 21.185 22.03 21.185 21.605C21.185 21.18 20.862 20.84 20.335 20.84C19.91 20.84 19.672 21.061 19.553 21.265C19.468 21.384 19.417 21.537 19.4 21.69L16.425 21.435C16.51 19.65 18.023 18.12 20.42 18.12C22.885 18.12 24.33 19.565 24.33 21.18C24.33 21.945 23.973 22.506 23.582 22.863C23.361 23.067 23.123 23.237 22.851 23.39C23.174 23.56 23.463 23.764 23.718 24.002C24.143 24.41 24.585 25.056 24.585 25.94C24.585 27.64 23.038 29.17 20.505 29.17C17.87 29.17 16.34 27.623 16.255 25.77Z" fill="white"></path>
      <path d="M30.3697 26.28C31.3217 26.28 32.1547 25.43 32.1547 23.645C32.1547 21.86 31.3217 21.01 30.3697 21.01C29.4177 21.01 28.5847 21.86 28.5847 23.645C28.5847 25.43 29.4177 26.28 30.3697 26.28ZM30.3697 29.17C27.7347 29.17 25.6097 27.045 25.6097 23.645C25.6097 20.245 27.7347 18.12 30.3697 18.12C33.0047 18.12 35.1297 20.245 35.1297 23.645C35.1297 27.045 33.0047 29.17 30.3697 29.17Z" fill="white"></path>
      <path d="M37.0827 29L43.6277 18.29H45.9227L39.3777 29H37.0827ZM37.8477 20.585C37.8477 21.01 38.1877 21.35 38.6127 21.35C39.0207 21.35 39.3777 21.01 39.3777 20.585C39.3777 20.16 39.0377 19.82 38.6127 19.82C38.1877 19.82 37.8477 20.16 37.8477 20.585ZM38.6127 18.205C40.0577 18.205 41.0777 19.225 41.0777 20.585C41.0777 21.945 40.0577 22.965 38.6127 22.965C37.1677 22.965 36.1477 21.945 36.1477 20.585C36.1477 19.225 37.1677 18.205 38.6127 18.205ZM43.6277 26.705C43.6277 27.13 43.9677 27.47 44.3927 27.47C44.8007 27.47 45.1577 27.13 45.1577 26.705C45.1577 26.28 44.8177 25.94 44.3927 25.94C43.9677 25.94 43.6277 26.28 43.6277 26.705ZM41.9277 26.705C41.9277 25.345 42.9477 24.325 44.3927 24.325C45.8377 24.325 46.8577 25.345 46.8577 26.705C46.8577 28.065 45.8377 29.085 44.3927 29.085C42.9477 29.085 41.9277 28.065 41.9277 26.705Z" fill="white"></path>
      <path d="M24.415 40.645C24.415 39.098 23.31 38.01 21.95 38.01C20.59 38.01 19.485 39.098 19.485 40.645C19.485 42.192 20.59 43.28 21.95 43.28C23.31 43.28 24.415 42.192 24.415 40.645ZM16.51 40.645C16.51 37.517 18.89 35.12 21.95 35.12C25.01 35.12 27.39 37.517 27.39 40.645C27.39 43.773 25.01 46.17 21.95 46.17C18.89 46.17 16.51 43.773 16.51 40.645Z" fill="white"></path>
      <path d="M28.8383 46V35.29H36.5733V38.01H31.8133V39.795H35.6383V42.515H31.8133V46H28.8383Z" fill="white"></path>
      <path d="M37.936 46V35.29H45.671V38.01H40.911V39.795H44.736V42.515H40.911V46H37.936Z" fill="white"></path>
    </svg>
  );
};

export default Icon30Off;
</icon30offprops>