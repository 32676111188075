import React from 'react';

interface IconPromoteProps {
  className?: string;
}

const IconPromote: React.FC<iconpromoteprops> = (props) => {
  const { className } = props;

  return (
    <svg className="{className}" width="70" height="46" viewBox="0 0 70 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M28.0386 8.93327C26.228 17.5302 26.4946 26.4616 28.815 34.9352L46.8021 39.4345C43.7399 37.8002 41.2545 30.4493 40.9891 21.5591C40.7236 12.6689 42.766 5.18288 45.7253 3.36876L28.0386 8.93327Z" fill="#A7A7A7"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M43.3155 6.7089C42.257 9.95662 41.6602 14.4756 41.8098 19.4855C41.9594 24.4953 42.8247 28.9707 44.0751 32.1494C44.7018 33.7426 45.4071 34.9635 46.1295 35.7642C46.8551 36.5685 47.5108 36.8598 48.066 36.8433C48.6211 36.8267 49.2583 36.4968 49.9346 35.6506C50.6079 34.8082 51.2392 33.5474 51.7697 31.9197C52.8282 28.672 53.4249 24.153 53.2753 19.1431C53.1257 14.1333 52.2604 9.65793 51.01 6.47915C50.3834 4.88604 49.678 3.66511 48.9556 2.86438C48.23 2.06008 47.5744 1.76877 47.0192 1.78535C46.464 1.80193 45.8269 2.13184 45.1505 2.978C44.4772 3.8204 43.846 5.08124 43.3155 6.7089ZM43.7721 1.87618C44.6228 0.811796 45.6951 0.0593919 46.9665 0.0214291C48.2379 -0.0165337 49.3532 0.670555 50.2659 1.68228C51.1819 2.69758 51.9824 4.13031 52.6523 5.83317C53.9949 9.24635 54.8852 13.9322 55.0392 19.0905C55.1932 24.2487 54.5841 28.9793 53.4475 32.4666C52.8805 34.2063 52.1668 35.6843 51.3131 36.7524C50.4623 37.8168 49.39 38.5692 48.1186 38.6072C46.8472 38.6451 45.732 37.958 44.8192 36.9463C43.9032 35.931 43.1027 34.4983 42.4329 32.7954C41.0903 29.3823 40.1999 24.6964 40.0459 19.5381C39.8919 14.3799 40.5011 9.64926 41.6376 6.16205C42.2047 4.42226 42.9183 2.94432 43.7721 1.87618Z" fill="#292929"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M40.4054 14.7414C40.5949 14.5839 40.8417 14.5128 41.086 14.5454L47.3471 15.3796C47.7756 15.4367 48.0997 15.7959 48.1126 16.2279L48.2891 22.1407C48.302 22.5728 48 22.9506 47.5757 23.0331L41.3583 24.2428C41.1157 24.29 40.8644 24.2333 40.6656 24.0865C40.4668 23.9397 40.3387 23.7163 40.3124 23.4705C40.1769 22.2031 40.0862 20.8876 40.0459 19.5381C40.0033 18.11 40.0192 16.7165 40.0882 15.3747C40.1009 15.1285 40.2159 14.899 40.4054 14.7414ZM41.8111 16.4223C41.7799 17.4183 41.7787 18.4418 41.8099 19.4855C41.8388 20.4529 41.8944 21.4013 41.9744 22.3251L46.5028 21.4441L46.371 17.0299L41.8111 16.4223Z" fill="#292929"></path>
      <path d="M45.3344 19.2699C45.2835 17.565 46.0814 16.158 47.1164 16.1271C48.1515 16.0961 49.0319 17.4531 49.0828 19.158C49.1337 20.8628 48.3358 22.2699 47.3008 22.3008C46.2657 22.3317 45.3853 20.9747 45.3344 19.2699Z" fill="#A7A7A7"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M47.8314 20.9219C48.0617 20.5155 48.2222 19.9025 48.2007 19.1843C48.1793 18.4661 47.9826 17.8637 47.7283 17.4718C47.4639 17.0643 47.2339 17.0063 47.1427 17.009C47.0515 17.0117 46.8253 17.0834 46.5856 17.5059C46.3553 17.9123 46.1949 18.5253 46.2163 19.2435C46.2377 19.9617 46.4344 20.5641 46.6887 20.956C46.9531 21.3635 47.1831 21.4215 47.2743 21.4188C47.3655 21.4161 47.5917 21.3445 47.8314 20.9219ZM49.3665 21.7923C48.9611 22.5072 48.2709 23.1545 47.327 23.1827C46.3831 23.2109 45.6555 22.6059 45.2082 21.9164C44.7508 21.2113 44.4818 20.2828 44.4524 19.2962C44.4229 18.3096 44.636 17.3667 45.0505 16.6355C45.4559 15.9206 46.1461 15.2733 47.09 15.2451C48.0339 15.2169 48.7615 15.8219 49.2088 16.5114C49.6662 17.2165 49.9352 18.145 49.9646 19.1316C49.9941 20.1182 49.781 21.0611 49.3665 21.7923Z" fill="#292929"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.56562 29.4458C4.28848 24.0102 4.08536 17.2076 7.03235 11.5861L15.8719 11.3222C13.4902 17.0648 13.685 23.5917 16.4052 29.1819L7.56562 29.4458ZM18.3574 29.1236C18.6755 29.7153 19.0253 30.2942 19.4069 30.8578L6.6187 31.2396C2.29312 24.852 2.04333 16.4863 5.98009 9.85207L18.7683 9.47023C18.421 10.0555 18.1063 10.6542 17.8241 11.2639C15.1896 16.9554 15.3879 23.5994 18.3574 29.1236Z" fill="#292929"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.3118 13.4329L2.00135 13.5018C-0.654519 17.948 -0.487133 23.5539 2.42928 27.8337L4.73974 27.7647C2.83598 23.213 2.68301 18.09 4.3118 13.4329Z" fill="#292929"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M46.4669 0.95569C46.6516 1.3612 46.505 1.84078 46.1251 2.07367C45.5664 2.41619 44.9759 3.07879 44.4111 4.10446C43.8519 5.11982 43.3508 6.4314 42.9397 7.98832C42.1179 11.1013 41.679 15.1087 41.8097 19.4854C41.9404 23.8622 42.6176 27.8363 43.6237 30.8947C44.1269 32.4243 44.7054 33.7036 45.3241 34.6838C45.9491 35.674 46.5781 36.3001 47.1563 36.6087C47.5494 36.8185 47.7243 37.2885 47.5642 37.7043C47.404 38.1201 46.959 38.3513 46.5267 38.2431L17.8951 31.0812C17.6727 31.0255 17.4806 30.8855 17.3597 30.6907C13.4197 24.3472 13.1802 16.3262 16.7347 9.75891C16.8438 9.55727 17.0271 9.40603 17.2458 9.33722L45.3991 0.479741C45.8242 0.346013 46.2822 0.55018 46.4669 0.95569ZM18.121 10.9119C15.0965 16.758 15.3068 23.8019 18.6747 29.4571L43.9267 35.7737C43.8949 35.7248 43.8632 35.6755 43.8318 35.6258C43.115 34.4902 42.4815 33.0697 41.9473 31.4461C40.8788 28.1979 40.1806 24.0515 40.0458 19.5381C39.9111 15.0247 40.3607 10.844 41.2335 7.53787C41.6698 5.88525 42.2175 4.42953 42.8652 3.2532C42.8936 3.20168 42.9223 3.15057 42.9511 3.0999L18.121 10.9119Z" fill="#292929"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.72314 29.4411L15.4958 29.209C15.9196 29.1963 16.2925 29.4869 16.3837 29.901L19.8037 45.4112L14.2671 45.5765L7.72314 29.4411ZM10.3121 31.1293L15.4412 43.7759L17.6217 43.7108L14.8178 30.9947L10.3121 31.1293Z" fill="#292929"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.9467 26.4759L15.741 26.3328L15.6884 24.5689L10.4214 24.7261C10.5693 25.3156 10.7445 25.8996 10.9467 26.4759ZM10.0635 22.9713L14.9743 22.8247L14.9216 21.0608L9.86497 21.2117C9.90523 21.8005 9.97143 22.3876 10.0635 22.9713ZM9.82199 19.4475L14.8689 19.2968L14.8163 17.5329L9.93483 17.6787C9.87114 18.2668 9.83355 18.857 9.82199 19.4475ZM10.2077 15.905L16.0865 15.7295L16.0339 13.9656L10.6495 14.1264C10.4746 14.7137 10.3274 15.3073 10.2077 15.905Z" fill="#292929"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M60.8689 21.7767L68.8732 24.2658L68.3492 25.9509L60.3449 23.4618L60.8689 21.7767Z" fill="#292929"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M59.7388 24.7751L66.8262 29.251L65.8839 30.743L58.7966 26.2671L59.7388 24.7751Z" fill="#292929"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M67.5838 10.1693L60.1639 14.0692L59.3429 12.5071L66.7628 8.60721L67.5838 10.1693Z" fill="#292929"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M69.229 15.3011L61.0526 17.1476L60.6639 15.4263L68.8403 13.5797L69.229 15.3011Z" fill="#292929"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M69.4901 20.6839L61.1144 20.3513L61.1844 18.588L69.5602 18.9206L69.4901 20.6839Z" fill="#292929"></path>
    </svg>
  );
};

export default IconPromote;
</iconpromoteprops>