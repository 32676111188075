import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  BundlePanel,
  IconSpinner,
  ItemizedListingBreakdown,
  LineItemBreakdown,
  TypographyWrapper,
} from '..';
import {
  BundleStatus,
  BundleType,
  Participant,
} from '../../types/apollo/generated/types.generated';
import { BundleInfo } from '../../types/models/bundle';
import { CheckoutLineItem } from '../../types/models/lineItem';
import { getPayoutValues } from './manageBundlesContainerUtils';
import { getBundleListings, isBundleTradeInPayoutFlatAmount } from '../../util/bundles';
import { ListingWithAuthorAndImages } from '../../types/sharetribe/listing';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { useShopConfigV2 } from '../../hooks/shopConfig';
import { useYotpo } from '../../hooks/useYotpo';
import { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { pluralize } from '../../util/strings';
import css from './ManageBundlesContainer.module.css';

interface IndividualBundleContainerProps {
  isLoading: boolean;
  bundle?: BundleInfo;
  otherBundlesInSameOrder?: BundleInfo[];
  participant: Participant;
  onVerifyClick?: (bundle: BundleInfo) => void;
  onDisputeClick?: (bundle: BundleInfo) => void;
  onGetPaidClick?: (bundle: BundleInfo) => void;
  onMarkAsFulfilledClick?: (bundle: BundleInfo) => void;
  onMarkAsDeliveredClick?: (bundle: BundleInfo) => void;
  onEditShippingAddressSubmit?: (values: any, addressId: string, onSuccess: () => void) => void;
}

const IndividualBundleContainer: FC<individualbundlecontainerprops> = (props) => {
  const {
    isLoading,
    bundle,
    otherBundlesInSameOrder,
    participant,
    onVerifyClick,
    onDisputeClick,
    onGetPaidClick,
    onMarkAsFulfilledClick,
    onMarkAsDeliveredClick,
    onEditShippingAddressSubmit,
  } = props;

  const intl = useIntl();
  const brandCountryConfig = useBrandCountryConfig();
  const { additionalPayoutSettings = {} } = useShopConfigV2();
  const { doesBrandUseLoyaltyPoints } = useYotpo();
  const { creditCodeHelperText } = additionalPayoutSettings;

  const rootState = useSelector<any>((state) => state) as any;

  if (!bundle) {
    // TODO (sonia-y) - empty state/error state
    return null;
  }

  if (isLoading) {
    return (
      <box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex="{1}" py="{2}">
        <iconspinner></iconspinner>
      </box>
    );
  }

  const isTradeInBundle = bundle?.type === BundleType.TradeIn;
  const isSales = participant === Participant.Seller;

  const payoutValues = getPayoutValues(bundle, intl, brandCountryConfig, rootState);
  const potentialCreditPayout = payoutValues.credit;
  const potentialCashPayout = payoutValues.cash;
  const potentialCreditPayoutInShopifyCurrency = payoutValues.creditInBrandShopifyCurrency
    ? `, issued as est. ${payoutValues.creditInBrandShopifyCurrency}`
    : '';

  const formattedPotentialPayout = potentialCreditPayout
    ? `${potentialCreditPayout} (in brand credit${potentialCreditPayoutInShopifyCurrency})`
    : `${potentialCashPayout} (in cash)`;

  const shouldShowPotentialEarnings =
    // TODO (anniew|TREET-2534) Refactor potential earnings to use listings instead of txns
    !isTradeInBundle &&
    isSales &&
    [BundleStatus.Open, BundleStatus.Shipped, BundleStatus.Delivered].includes(bundle.status);
  const listings = getBundleListings(bundle, rootState) as ListingWithAuthorAndImages[];

  // For trade-ins, the listing author is the "customer" since the brand is the entity
  // purchasing the item (no transactions present). For marketplace, the user who is
  // purchasing the item is the "customer" (transaction present).
  const tradeInCustomerName = isSales
    ? undefined
    : listings?.[0]?.author?.attributes?.profile?.displayName;
  const marketplaceCustomerName = isSales ? bundle?.buyer?.displayName : undefined;
  const customerName = isTradeInBundle ? tradeInCustomerName : marketplaceCustomerName;

  const sellerName = listings?.[0]?.author?.attributes?.profile?.displayName;

  const shouldShowLineItemBreakdown = !isBundleTradeInPayoutFlatAmount(bundle, listings);

  return (
    <>
      <bundlepanel bundle="{bundle}" otherBundlesInSameOrder="{otherBundlesInSameOrder}" customerName="{customerName}" participant="{participant}" onVerifyClick="{onVerifyClick}" onDisputeClick="{onDisputeClick}" onGetPaidClick="{onGetPaidClick}" onMarkAsFulfilledClick="{onMarkAsFulfilledClick}" onMarkAsDeliveredClick="{onMarkAsDeliveredClick}" onEditShippingAddressSubmit="{onEditShippingAddressSubmit}"></bundlepanel>
      <itemizedlistingbreakdown Buyback="" Trade-in="" items="" may="" not="" immediately="" have="" transactions,="" so="" in="" those="" cases,="" listings="" must="" be="" passed="" directly="" to="" the="" itemized="" breakdown.="" These="" listing-based="" breakdowns="" won't="" display="" data="" that="" are="" tied="" such="" as="" canceled="" and="" disputed="" state="" for="" line="" items.="" lineItems="{bundle.lineItems" CheckoutLineItem[]}="" bundleItems="{bundle.bundleItems}" title="{" <h3="">
            <typographywrapper variant="h2" typographyOverrides="{{" display:="" 'inline'="" }}="" weight="{TypographyWeight.Bold}">
              {pluralize('Item', listings.length, true)}{' '}
            </typographywrapper>
            <typographywrapper variant="h2" typographyOverrides="{{" display:="" 'inline'="" }}="">
              from {sellerName}
            </typographywrapper>
          
        }
        referrerForLogging={isSales ? 'Manage Sales' : 'Manage Purchases'}
        participant={participant}
      />
      {/* TODO (sonia-y | TREET-628): Make sure line items showing are only for relevant people */}

      {shouldShowLineItemBreakdown && (
        <lineitembreakdown lineItems="{bundle.lineItems" as="" CheckoutLineItem[]}="" refunds="{bundle.refunds}" participant="{participant}" bundleType="{bundle.type" BundleType}=""></lineitembreakdown>
      )}
      {shouldShowPotentialEarnings && (
        <h5 className="{css.potentialPayoutText}">
          <typographywrapper variant="body2">
            {`Get paid up to ${formattedPotentialPayout} ${
              doesBrandUseLoyaltyPoints ? 'in loyalty points ' : ''
            }once the buyer receives and verifies this bundle${
              creditCodeHelperText && potentialCreditPayout ? '. '.concat(creditCodeHelperText) : ''
            }`}
          </typographywrapper>
        </h5>
      )}
    </>
  );
};

export default IndividualBundleContainer;
</itemizedlistingbreakdown></any></individualbundlecontainerprops>