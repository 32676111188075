import React from 'react';
import { bool } from 'prop-types';
import PriceFilterPlain from './PriceFilterPlain';
import PriceFilterPopup from './PriceFilterPopup';
import { propTypes } from '../../util/types';

const PriceFilter = (props) => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? <pricefilterpopup {...rest}=""></pricefilterpopup> : <pricefilterplain {...rest}=""></pricefilterplain>;
};

PriceFilter.defaultProps = {
  showAsPopup: false,
};

PriceFilter.propTypes = {
  showAsPopup: bool,
  currencyConfig: propTypes.currencyConfig.isRequired,
};

export default PriceFilter;
