/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <formattedmessage id="some.translation.key.here"></formattedmessage>
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import { FilterId, SearchFilterType, SearchNavBarSections } from '../../types/filters/filters';

// TODO (anniew|TREET-1275) Clean up + refactor marketplace configs
export const keywordFilterConfig = {
  id: FilterId.Keyword,
  label: 'Search',
  type: SearchFilterType.KeywordFilter,
  group: 'primary',
  // Note: KeywordFilter is fixed filter,
  // you can't change "queryParamNames: ['keywords'],"
  queryParamNames: ['keywords'],

  // NOTE: If you are ordering search results by distance
  // the keyword search can't be used at the same time.
  // You can turn on/off ordering by distance from config.js file.
  config: {},
};

export const filters = [keywordFilterConfig];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'pub_updatedAt', label: 'Newest' },
    { key: '-pub_updatedAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const treetV2SortConfig = {
  ...sortConfig,
  id: 'sort',
  type: SearchFilterType.Sort,
  searchNavBarSection: SearchNavBarSections.Sort,
};
</key></key>