import { Box, Card, CardActionArea, Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC } from 'react';
import { IconCheckCircleFilled } from '..';
import { useShopConfig, useShopConfigV2 } from '../../hooks/shopConfig';
import { Product as ShopifyProduct, ShopifyProductVariant } from '../../types/shopify/product';
import { getProductDisplayTitle, getStockImages } from '../../util/shopifyHelpers';

import css from './Shopify.module.css';
import { defaultTreetStyles } from '../../shopConfig/config';

interface TitleProps {
  product?: ShopifyProduct;
  variant?: ShopifyProductVariant;
  productTitleDisplayType: string;
  size: string;
}

const Title: FC<titleprops> = (props) => {
  const { product, productTitleDisplayType, size, variant } = props;

  return (
    <typography variant="body1" style="{{" textAlign:="" 'center'="" }}="">
      {`${getProductDisplayTitle(product, productTitleDisplayType, variant)}${
        size ? ` | ${size}` : ''
      }`}
    </typography>
  );
};

interface ProductImageAndTitleProps {
  isSelected: boolean;
  size: string;
  product?: ShopifyProduct;
  variant?: any;
}

const ProductImageAndTitle: FC<productimageandtitleprops> = (props) => {
  const { isSelected, product, size, variant } = props;

  const { imageRatio, productTitleDisplayType, chooseStockImagesOption } = useShopConfig();

  const productImages = getStockImages(product, variant, chooseStockImagesOption);
  const featuredProductImage = productImages[0] || product?.featuredImage?.originalSrc;

  return (
    <div className="{classNames(css.product," {="" [css.selectedProductImage]:="" isSelected,="" })}="">
      <div className="{css.threeToTwoWrapper}">
        <div style="{{" paddingBottom:="" `${imageRatio="" *="" 100}%`="" }}="">
          {/* eslint-disable jsx-a11y/img-redundant-alt, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
          <img className="{css.productImage}" src="{featuredProductImage}" alt="product photo">
        </div>
      </div>
      {isSelected && (
        <iconcheckcirclefilled color="{defaultTreetStyles.gray100}" className="{css.selectedCheckMark}"></iconcheckcirclefilled>
      )}

      <box pt="{1}">
        <title product="{product}" variant="{variant}" productTitleDisplayType="{productTitleDisplayType}" size="{size}"></title>
      </box>
    </div>
  );
};

interface ProductTitleProps {
  isSelected: boolean;
  size: string;
  product?: ShopifyProduct;
}

const ProductTitle: FC<producttitleprops> = (props) => {
  const { isSelected, product, size } = props;

  const { productTitleDisplayType } = useShopConfigV2();

  return (
    <div className="{classNames(css.productTitle," {="" [css.selectedProductImage]:="" isSelected,="" })}="">
      <card variant="outlined" style="{{" height:="" '100%',="" width:="" '100%'="" }}="">
        <cardactionarea style="{{" padding:="" '22px',="" height:="" '100%',="" width:="" '100%'="" }}="">
          <title product="{product}" productTitleDisplayType="{productTitleDisplayType}" size="{size}"></title>
        </cardactionarea>
      </card>
    </div>
  );
};

interface ProductProps {
  product?: ShopifyProduct;
  selectedProductId?: string;
  lineItemId?: string;
  selectedLineItemId?: string;
  variant?: any;
  onChange?: (product?: ShopifyProduct, variant?: any, lineItemId?: string) => void;
}

const Product: FC<productprops> = (props: ProductProps) => {
  const { product, selectedProductId, lineItemId, selectedLineItemId, variant, onChange } = props;

  const selectedOptions = variant?.selectedOptions;
  const size = selectedOptions?.find(
    (option: any) => option?.name?.toLowerCase() === 'size'
  )?.value;
  const isSelected =
    product?.id === selectedProductId && (!selectedLineItemId || lineItemId === selectedLineItemId);

  const handleClick = () => {
    if (onChange) {
      onChange(product, variant, lineItemId);
    }
  };

  return (
    <grid 8="" onClick="{handleClick}" style="{{" marginBottom:="" }}="" item="" xs="{6}" md="{3}" key="{product?.id}">
      {product?.featuredImage?.originalSrc ? (
        <productimageandtitle isSelected="{isSelected}" product="{product}" variant="{variant}" size="{size}"></productimageandtitle>
      ) : (
        <producttitle isSelected="{isSelected}" product="{product}" size="{size}"></producttitle>
      )}
    </grid>
  );
};

export default Product;
</productprops></producttitleprops></productimageandtitleprops></titleprops>