import { ApolloProvider } from '@apollo/client';
import { ServerStyleSheets, ThemeProvider } from '@material-ui/core/styles';
import difference from 'lodash/difference';
import mapValues from 'lodash/mapValues';
import moment from 'moment';
import IPData from 'ipdata';
// Flex template application uses English translations as default.
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { apolloClient } from './apollo';
import config from './shopConfig/config';
import AppContext from './context/AppContext';
import { getMuiThemeForShop, getMuiThemeForTreetShop } from './mui';
import routeConfiguration from './routeConfiguration';
import Routes from './Routes';
import configureStore from './store';
import defaultMessages from './translations/en.json';
// If you want to change the language, change the imports to match the wanted locale:
//   1) Change the language in the config.js file!
//   2) Import correct locale rules for Moment library
//   3) Use the `messagesInLocale` import to add the correct translation file.
//   4) To support older browsers we need add the correct locale for intl-relativetimeformat to `util/polyfills.js`
// Note that there is also translations in './translations/countryCodes.js' file
// This file contains ISO 3166-1 alpha-2 country codes, country names and their translations in our default languages
// This used to collect billing address in AddressForm on CheckoutPage
// Step 2:
// If you are using a non-english locale with moment library,
// you should also import time specific formatting rules for that locale
// e.g. for French: import 'moment/locale/fr';
// Step 3:
// If you are using a non-english locale, point `messagesInLocale` to correct .json file
import auMessages from './translations/en-au.json';
import caMessages from './translations/en-ca.json';
import gbMessages from './translations/en-gb.json';
import { IntlProvider } from './util/reactIntl';
import { Feature, isFeatureEnabled } from './util/featureFlags';
import { getCountryObjectFromCode } from './util/countryCodes';
import * as log from './util/log';
import { handle } from './util/helpers';
import { isDev } from './util/envHelpers';
import { CountryCode } from './types/apollo/generated/types.generated';

const IS_TEST_ENV = process.env.NODE_ENV === 'test';
const DEFAULT_LOCALE = 'en-US';
const IP_DATA_CACHE_CONFIG = {
  maxAge: 60 * 1000 * 24, // 1 day in ms
};
const ipdata =
  process.env.REACT_APP_IP_DATA_API_KEY &&
  new IPData(process.env.REACT_APP_IP_DATA_API_KEY, IP_DATA_CACHE_CONFIG);

// If translation key is missing from `messagesInLocale` (e.g. fr.json),
// corresponding key will be added to messages from `defaultMessages` (en.json)
// to prevent missing translation key errors.
const addMissingTranslations = (sourceLangTranslations, targetLangTranslations) => {
  const sourceKeys = Object.keys(sourceLangTranslations);
  const targetKeys = Object.keys(targetLangTranslations);
  const missingKeys = difference(sourceKeys, targetKeys);

  const addMissingTranslation = (translations, missingKey) => ({
    ...translations,
    [missingKey]: sourceLangTranslations[missingKey],
  });

  return missingKeys.reduce(addMissingTranslation, targetLangTranslations);
};

const setupLocale = (locale) => {
  if (IS_TEST_ENV) {
    // Use english as a default locale in tests
    // This affects app.test.js and app.node.test.js tests
    config.locale = 'en';
    return;
  }

  // Set the Moment locale globally
  // See: http://momentjs.com/docs/#/i18n/changing-locale/
  moment.locale(locale);
};

const getLocaleMessages = (locale) => {
  let messages;
  switch (locale) {
    case 'en-AU':
      messages = addMissingTranslations(defaultMessages, auMessages);
      break;
    case 'en-CA':
      messages = addMissingTranslations(defaultMessages, caMessages);
      break;
    case 'en-GB':
      messages = addMissingTranslations(defaultMessages, gbMessages);
      break;
    case DEFAULT_LOCALE:
    default:
      messages = defaultMessages;
      break;
  }
  // Locale should not affect the tests. We ensure this by providing
  // messages with the key as the value of each message.
  const testMessages = mapValues(messages, (val, key) => key);

  return IS_TEST_ENV ? testMessages : messages;
};

const getUserLocale = async (allowedOriginToDestinationCountries = {}) => {
  let overrideLocale;
  if (typeof document !== 'undefined') {
    overrideLocale = new URLSearchParams(document.location.search).get('locale');
  }

  let usersLocaleFromCountryCode;
  if (!isDev && ipdata) {
    // don't need to use up quota on dev
    const [ipdataResponse, ipdataError] = await handle(ipdata.lookup());
    if (ipdataError) log.error(ipdataError, 'ipdata-lookup-failed');
    const usersCountry = ipdataResponse?.country_code;

    if (usersCountry) {
      usersLocaleFromCountryCode = getCountryObjectFromCode(usersCountry).locale;
    }
  }
  const usersLocale = Intl.DateTimeFormat().resolvedOptions().locale;
  const shopDefaultCountry = Object.keys(allowedOriginToDestinationCountries)[0];
  const shopDefaultLocale = getCountryObjectFromCode(shopDefaultCountry || CountryCode.Us).locale;

  return (
    overrideLocale ||
    usersLocaleFromCountryCode ||
    usersLocale ||
    shopDefaultLocale ||
    DEFAULT_LOCALE
  );
};

const getThemeToUse = (treetId, shopConfigV2, treetShopConfig) => {
  const isTreetShop = treetId === 'treet';
  const isTreetShopEnabled = isFeatureEnabled(Feature.TreetShop, treetId);

  const theme =
    isTreetShop && isTreetShopEnabled
      ? getMuiThemeForTreetShop(treetShopConfig?.styles)
      : getMuiThemeForShop(treetId, shopConfigV2);

  return theme;
};

export const ClientApp = (props) => {
  const { store } = props;
  const [locale, setLocale] = useState();

  const {
    treetId,
    shopId,
    canonicalRootUrl,
    userAgent,
    shopConfig: shopConfigV2,
    primaryLocale,
  } = store.getState().initial;

  // Remove the server side css once client side has rendered
  // Ref: https://material-ui.com/guides/server-rendering/
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    getUserLocale(shopConfigV2?.internationalConfig?.allowedOriginToDestinationCountries).then(
      (usersLocale) => {
        setLocale(usersLocale);
        setupLocale(locale);
      }
    );
  }, []);

  const { treetShopConfig } = store.getState().TreetShopLandingPage;
  const theme = getThemeToUse(treetId, shopConfigV2, treetShopConfig);

  const localeMessages = getLocaleMessages(locale);
  const resolvedLocale = locale || 'en';

  return (
    <intlprovider locale="{resolvedLocale}" messages="{localeMessages}" textComponent="span">
      <provider store="{store}">
        <appcontext.provider value="{{" treetId,="" shopId,="" canonicalRootUrl,="" userAgent,="" primaryLocale,="" shopConfig:="" shopConfigV2,="" }}="">
          <themeprovider theme="{theme}">
            <snackbarprovider maxSnack="{3}">
              <apolloprovider client="{apolloClient}">
                <helmetprovider>
                  <browserrouter>
                    <routes routes="{routeConfiguration(treetId)}"></routes>
                  </browserrouter>
                </helmetprovider>
              </apolloprovider>
            </snackbarprovider>
          </themeprovider>
        </appcontext.provider>
      </provider>
    </intlprovider>
  );
};

const { any, string } = PropTypes;

ClientApp.propTypes = { store: any.isRequired };

/**
 * Redirect Client App is used *only* for rendering the page at `/redirect`.
 *
 * `/redirect` is only hit after the IDP Authentication has concluded server-side,
 * and the window quickly closes after it is opened.
 * Rendering an extremely pared-down app ensures no external API calls
 * are made that can potentially cause errors when they are canceled at window close.
 *
 */
export const RedirectClientApp = (props) => {
  const { store } = props;
  // Dummy value, not used for anything except checking
  // if route configuration should use Treet Shop routes.
  const treetId = 'dummy';
  // Remove the server side css once client side has rendered
  // Ref: https://material-ui.com/guides/server-rendering/
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const theme = getMuiThemeForTreetShop();

  return (
    <provider store="{store}">
      <appcontext.provider value="{{" treetId="" }}="">
        <themeprovider theme="{theme}">
          <snackbarprovider maxSnack="{3}">
            <helmetprovider>
              <browserrouter>
                <routes routes="{routeConfiguration(treetId)}"></routes>
              </browserrouter>
            </helmetprovider>
          </snackbarprovider>
        </themeprovider>
      </appcontext.provider>
    </provider>
  );
};

RedirectClientApp.propTypes = { store: any.isRequired };

export const ServerApp = (props) => {
  const { url, context, helmetContext, store } = props;
  const [locale, setLocale] = useState();
  const { treetId, shopId, canonicalRootUrl, userAgent, shopConfig, primaryLocale } =
    store.getState().initial;
  const { treetShopConfig } = store.getState().TreetShopLandingPage;

  const theme = getThemeToUse(treetId, shopConfig, treetShopConfig);

  useEffect(() => {
    getUserLocale(shopConfig?.internationalConfig?.allowedOriginToDestinationCountries).then(
      (usersLocale) => {
        setLocale(usersLocale);
        setupLocale(usersLocale);
      }
    );
  }, []);

  const localeMessages = getLocaleMessages(locale);

  HelmetProvider.canUseDOM = false;
  return (
    <intlprovider locale="{locale}" messages="{localeMessages}" textComponent="span">
      <provider store="{store}">
        <appcontext.provider value="{{" treetId,="" shopId,="" canonicalRootUrl,="" userAgent,="" shopConfig,="" primaryLocale,="" }}="">
          <themeprovider theme="{theme}">
            <snackbarprovider maxSnack="{3}">
              <apolloprovider client="{apolloClient}">
                <helmetprovider context="{helmetContext}">
                  <staticrouter location="{url}" context="{context}">
                    <routes routes="{routeConfiguration(treetId)}"></routes>
                  </staticrouter>
                </helmetprovider>
              </apolloprovider>
            </snackbarprovider>
          </themeprovider>
        </appcontext.provider>
      </provider>
    </intlprovider>
  );
};

ServerApp.propTypes = { url: string.isRequired, context: any.isRequired, store: any.isRequired };

/**
 * Render the given route.
 *
 * @param {String} url Path to render
 * @param {Object} serverContext Server rendering context from react-router
 *
 * @returns {Object} Object with keys:
 *  - {String} body: Rendered application body of the given route
 *  - {Object} head: Application head metadata from react-helmet
 */
// TODO (SY): Figure out why home page goes here but not /s ?
export const renderApp = (req, url, serverContext, preloadedState, collectChunks) => {
  // Don't pass an SDK instance since we're only rendering the
  // component tree with the preloaded store state and components
  // shouldn't do any SDK calls in the (server) rendering lifecycle.

  const store = configureStore({
    ...preloadedState,
  });

  const helmetContext = {};
  const sheets = new ServerStyleSheets();

  // Render the component to a string.

  const body = ReactDOMServer.renderToString(
    collectChunks(
      sheets.collect(
        <serverapp url="{url}" context="{serverContext}" helmetContext="{helmetContext}" store="{store}"></serverapp>
      )
    )
  );

  const css = sheets.toString();
  const { helmet: head } = helmetContext;
  return { head, body, css };
};
