import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconGemFilledProps {
  className?: string;
  color?: string;
}

const IconGemFilled: FC<icongemfilledprops> = (props: IconGemFilledProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg className="{className}" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.2645 3H17.7355L21.2355 11H10.7645L14.2645 3ZM13.173 3H9.90624L3.32886 11H9.67296L13.173 3ZM3.45613 12L15.3252 27.6123L9.6535 12H3.45613ZM16.6748 27.6123L28.5438 12H22.3465L16.6748 27.6123ZM28.6711 11H22.327L18.827 3H22.0937L28.6711 11ZM16 26.076L10.7215 12H21.2785L16 26.076Z" fill="{fillColor}"></path>
    </svg>
  );
};

export default IconGemFilled;
</icongemfilledprops>