/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React from 'react';
import { number, shape, string } from 'prop-types';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { FormattedMessage, LayoutWrapperSideNav, TypographyWrapper } from '..';
import { MAX_MEDIUM_SCREEN_WIDTH } from '../../util/window';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';

const scrollToTab = (currentTab) => {
  const el = document.querySelector(`#${currentTab}Tab`);

  if (el) {
    el.scrollIntoView({
      block: 'end',
      inline: 'end',
      behavior: 'smooth',
    });
  }
};

const LayoutWrapperAccountSettingsSideNavComponent = (props) => {
  const { currentTab, viewport } = props;

  const { allowBuy, allowMarketplace } = useEnabledCustomerExperiences();
  const { isBrand } = useCurrentUserPermissions();

  let hasScrolledToTab = false;

  const { width } = viewport;
  const hasViewport = width > 0;
  const hasHorizontalTabLayout = hasViewport && width <= MAX_MEDIUM_SCREEN_WIDTH;
  const hasVerticalTabLayout = hasViewport && width > MAX_MEDIUM_SCREEN_WIDTH;
  const hasFontsLoaded = hasViewport && document.documentElement.classList.contains('fontsLoaded');

  // Check if scrollToTab call is needed (tab is not visible on mobile)
  if (hasVerticalTabLayout) {
    hasScrolledToTab = true;
  } else if (hasHorizontalTabLayout && !hasScrolledToTab && hasFontsLoaded) {
    scrollToTab(currentTab);
    hasScrolledToTab = true;
  }

  const tabs = [
    {
      text: (
        <typographywrapper variant="h2">
          <formattedmessage id="LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitle"></formattedmessage>
        </typographywrapper>
      ),
      selected: currentTab === 'ContactDetailsPage',
      id: 'ContactDetailsPageTab',
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
    {
      text: (
        <typographywrapper variant="h2">
          <formattedmessage id="LayoutWrapperAccountSettingsSideNav.passwordTabTitle"></formattedmessage>
        </typographywrapper>
      ),
      selected: currentTab === 'PasswordChangePage',
      id: 'PasswordChangePageTab',
      linkProps: {
        name: 'PasswordChangePage',
      },
    },
    ...(allowMarketplace || (allowBuy && isBrand)
      ? [
          {
            text: (
              <typographywrapper variant="h2">
                <formattedmessage id="LayoutWrapperAccountSettingsSideNav.paymentsTabTitle"></formattedmessage>
              </typographywrapper>
            ),
            selected: currentTab === 'StripePayoutPage',
            id: 'StripePayoutPageTab',
            linkProps: {
              name: 'StripePayoutPage',
            },
          },
        ]
      : []),
    ...(allowBuy
      ? [
          {
            text: (
              <typographywrapper variant="h2">
                <formattedmessage id="LayoutWrapperAccountSettingsSideNav.paymentMethodsTabTitle"></formattedmessage>
              </typographywrapper>
            ),
            selected: currentTab === 'PaymentMethodsPage',
            id: 'PaymentMethodsPageTab',
            linkProps: {
              name: 'PaymentMethodsPage',
            },
          },
        ]
      : []),
  ];

  return <layoutwrappersidenav tabs="{tabs}"></layoutwrappersidenav>;
};

LayoutWrapperAccountSettingsSideNavComponent.defaultProps = {
  currentTab: null,
};

LayoutWrapperAccountSettingsSideNavComponent.propTypes = {
  currentTab: string,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const LayoutWrapperAccountSettingsSideNav = compose(withViewport)(
  LayoutWrapperAccountSettingsSideNavComponent
);

export default LayoutWrapperAccountSettingsSideNav;
