import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconShippingLabelFilledProps {
  className?: string;
  color?: string;
}

const IconShippingLabelFilled: React.FC<iconshippinglabelfilledprops> = (props) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg className="{className}" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4 28V4H28V28H4ZM8 8H24V10H8V8ZM8 12H24V14H8V12ZM10 24L10 16H8V24H10ZM16 16V24H15V16H16ZM14 24V16H11V24H14ZM20 16V24H19V16H20ZM18 24V16H17V24H18ZM24 16V24H21V16H24Z" fill="{fillColor}"></path>
    </svg>
  );
};

export default IconShippingLabelFilled;
</iconshippinglabelfilledprops>