import React from 'react';
import classNames from 'classnames';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconArrowRightProps {
  rootClassName?: string;
  className?: string;
  color?: string;
}

const IconArrowRight: React.FC<iconarrowrightprops> = (props) => {
  const { className, rootClassName, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  const classes = classNames(rootClassName, className);

  return (
    <svg className="{classes}" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M11.364 7.94975L6.41421 3L5 4.41421L8.53553 7.94975L5 11.4853L6.41421 12.8995L11.364 7.94975Z" fill="{fillColor}"></path>
    </svg>
  );
};

export default IconArrowRight;
</iconarrowrightprops>