import React, { ElementType, FC, ReactElement } from 'react';
import { MessageDescriptor, FormattedMessage as ReactIntlFormattedMessage } from 'react-intl';

interface FormattedMessageProps extends MessageDescriptor {
  values?: any;
  tagName?: ElementType;
  children?: ReactElement;
}

const FormattedMessage: FC<formattedmessageprops> = (props: FormattedMessageProps) => (
  <reactintlformattedmessage {...props}="">{(...content) => content}</reactintlformattedmessage>
);

export default FormattedMessage;
</formattedmessageprops>