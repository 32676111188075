import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Button, DialogWithCloseButton, Divider, InlineTextButton } from '..';
import SizeNotificationsForm, {
  SizeNotificationsFormValues,
} from '../../forms/SizeNotificationsForm/SizeNotificationsForm';
import { useShopConfig } from '../../hooks/shopConfig';
import { useIsMobile } from '../../hooks/useIsMobile';
import css from './ManageNotificationsModal.module.css';
import { resetActiveModal } from '../../ducks/modal.duck';

const HeaderBar: FC = () => (
  <box display="flex" flexDirection="row" alignItems="center" justifyContent="center" height="60px">
    <typography variant="h2">Manage Notifications</typography>
  </box>
);

const Footer: FC = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { css: brandCss } = useShopConfig();

  return (
    <box bgcolor="{brandCss?.backgroundColor" ||="" 'white'}="" display="flex" alignItems="center" justifyContent="space-between" p="{2}">
      <inlinetextbutton>
        <box 2="" mx="{{" xs:="" 1,="" sm:="" }}="" onClick="{()" ==""> dispatch(resetActiveModal())}>
          Cancel
        </box>
      </inlinetextbutton>
      <button className="{css.footerButton}" type="submit">
        {isMobile ? 'Save' : 'Save Changes'}
      </button>
    </box>
  );
};

const FooterActions: FC = () => (
  <box className="{css.footer}">
    <divider className="{css.divider}"></divider>
    <footer></footer>
  </box>
);

interface ManageNotificationsModalProps {
  isOpen: boolean;
  subscribeSizes: string[];
  onSubmit: (values: SizeNotificationsFormValues) => void;
}

const ManageNotificationsModal: FC<managenotificationsmodalprops> = (props) => {
  const { isOpen, subscribeSizes, onSubmit } = props;

  const dispatch = useDispatch();
  const { css: brandCss } = useShopConfig();

  return (
    <dialogwithclosebutton open="{isOpen}" allowFullScreen="{false}" headerClassName="{css.dialogHeader}" closeButtonClassName="{css.dialogCloseButton}" fullWidth="" maxWidth="sm" onClose="{()" ==""> dispatch(resetActiveModal())}
    >
      <box bgcolor="{brandCss?.backgroundColor" ||="" 'white'}="" className="{css.content}">
        <box bgcolor="inherit">
          <headerbar></headerbar>
          <divider className="{css.divider}"></divider>
        </box>
        <sizenotificationsform initialValues="{{" sizes:="" subscribeSizes="" }}="" onSubmit="{onSubmit}" actions="{<FooterActions"></sizenotificationsform>}
        />
      </box>
    </dialogwithclosebutton>
  );
};

export default ManageNotificationsModal;
</managenotificationsmodalprops>