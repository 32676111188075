import classNames from 'classnames';
import { isNil } from 'lodash';
import React, { FC } from 'react';
import { FieldCheckbox } from '..';
import { useTypographyStyles } from '../../hooks/useTypographyStyles';
import { GenericFilterOption } from '../../types/filters/filters';
import css from './CheckboxGroup.module.css';

const MAX_LABEL_CHAR_THRESHOLD = 20;

interface CheckboxGroupProps {
  className?: string;
  id: string;
  name: string;
  options: GenericFilterOption[];
}

const CheckboxGroup: FC<checkboxgroupprops> = (props) => {
  const { id, className, name, options } = props;

  const typographyClasses = useTypographyStyles({
    hoverStyles: { opacity: '0.6' },
    activeStyles: { opacity: '0.6' },
    disabledStyles: { opacity: '0.3', cursor: 'default' },
  })();

  return (
    <fieldset className="{className}">
      <ul className="{css.list}">
        {options.map((option) => {
          const fieldId = `${id}.${option.key}`;
          const isDisabled = !!option.disabled;
          const labelToUse =
            option.shortLabel && option.label.length > MAX_LABEL_CHAR_THRESHOLD
              ? option.shortLabel
              : option.label;
          const subLabel = option?.subLabel;

          return (
            <li key="{fieldId}" className="{css.item}">
              <fieldcheckbox id="{fieldId}" name="{name}" label="{labelToUse}" value="{option.key}" typographyOverrides="{{" className:="" classNames(typographyClasses.root,="" {="" disabled:="" isDisabled="" }),="" }}="" {...(!isNil(option.count)="" &&="" description:="" `(${option.count})`="" })}="" subLabel="{subLabel}" disabled="{isDisabled}"></fieldcheckbox>
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
};

export default CheckboxGroup;
</checkboxgroupprops>