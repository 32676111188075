import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconStarProps {
  className?: string;
  color?: string;
}

const IconStar: FC<iconstarprops> = (props: IconStarProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg className="{className}" width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.68569 3.21735L5.11806 0.125L3.55044 3.21735L0.125015 3.75266L2.58159 6.19915L2.03219 9.62234L5.11806 8.042L8.20393 9.62234L7.65453 6.19915L10.1111 3.75266L6.68569 3.21735Z" fill="{fillColor}"></path>
    </svg>
  );
};

export default IconStar;
</iconstarprops>