import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import ListingFeedbackHeader from './ListingFeedbackHeader';
import { ListingWithAuthor } from '../../types/sharetribe/listing';
import { IconPromote } from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import {
  getDarkerBrandCssBackgroundColor,
  getPercentAlteredHexColor,
} from '../../util/colors/colors';
import { defaultTreetStyles } from '../../shopConfig/config';
import { useIsMobile } from '../../hooks/useIsMobile';

interface ListingFeedbackSuccessPaneProps {
  listing: ListingWithAuthor;
}

const ListingFeedbackSuccessPane: FC<listingfeedbacksuccesspaneprops> = (props) => {
  const { listing } = props;

  const isMobile = useIsMobile();
  const { css: brandCss } = useShopConfig();
  const darkerBackgroundColor = getDarkerBrandCssBackgroundColor(brandCss);
  const borderCss = `1px solid ${
    getPercentAlteredHexColor(darkerBackgroundColor, -8) || defaultTreetStyles.gray20
  }`;

  const sellerName = listing.author?.attributes?.profile?.displayName;
  return (
    <>
      <listingfeedbackheader headerCopy="Message Sent!"></listingfeedbackheader>
      <box 4="" 5="" pt="{{" xs:="" 10,="" md:="" }}="" pb="{{" 24,="" px="{isMobile" ?="" :="" 6}="" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <box pb="{4}">
          <box bgcolor="{darkerBackgroundColor}" sx="{{" display:="" 'flex',="" alignItems:="" 'center',="" justifyContent:="" borderRadius:="" '50%',="" width:="" 120,="" height:="" }}="">
            <iconpromote></iconpromote>
          </box>
        </box>
        <box pb="{4}">
          <typographywrapper variant="body1" weight="{TypographyWeight.Bold}" typographyOverrides="{{" style:="" {="" textAlign:="" 'center'="" },="" }}="">
            {`Your feedback has been sent${sellerName ? ` to ${sellerName}.` : '.'}`}
          </typographywrapper>
        </box>
        <box pb="{4}">
          <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" textAlign:="" 'center'="" },="" }}="">
            We’ll let you know if the seller updates their listing in response to your message.
          </typographywrapper>
        </box>
        <box display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="{borderCss}" bgcolor="{darkerBackgroundColor" ||="" defaultTreetStyles.gray10}="" p="{3}">
          <box pb="{1}">
            <typographywrapper variant="body2" weight="{TypographyWeight.Bold}" typographyOverrides="{{" style:="" {="" textAlign:="" 'center'="" },="" }}="">
              Other people are looking at this item
            </typographywrapper>
          </box>
          <typographywrapper variant="body2" typographyOverrides="{{" style:="" {="" textAlign:="" 'center'="" },="" }}="">
            We’ve sent your feedback to the seller but remember that once this item sells it’s gone
            forever!
          </typographywrapper>
        </box>
      </box>
    </>
  );
};

export default ListingFeedbackSuccessPane;
</listingfeedbacksuccesspaneprops>