import React, { FC } from 'react';

export const TREET_AUTH_EVENT = 'treetAuthEvent';

/**
 * Authentication from loginWithIdp.js will redirect here.
 * Success or error responses from idp are appended to the URL,
 * parsed, and attached to an emitted event.
 *
 * This page is intented to be the last page in the authentication
 * cycle that begun as a popup window. See AuthenticationForm.js
 * for an example implementation of opening & handling the authentication window.
 */
const AuthenticationRedirectPage: FC = () => {
  if (typeof window !== 'undefined') {
    // Get auth result params from URL.
    const { searchParams } = new URL(window.location.href);
    const success = !!searchParams.get('authSuccess');
    const error = searchParams.get('authError');

    // Create the event & attach auth result data.
    const authEvent = new CustomEvent(TREET_AUTH_EVENT, {
      detail: { success, error },
    });

    // Only dispatch event if there is a valid authentication result.
    if (success || error) {
      window?.opener?.dispatchEvent(authEvent);
    }

    // Close the popup window after the event is dispatched.
    window.close();
  }

  return <p style="{{" padding:="" '10px'="" }}="">Redirecting...</p>;
};

export default AuthenticationRedirectPage;
