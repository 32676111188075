import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconLinkToProps {
  className?: string;
  color?: string;
}

const IconLinkTo: React.FC<iconlinktoprops> = (props) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg className="{className}" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M12 4V2L16 5L12 8V6C10.7194 6 9.49129 6.50871 8.58579 7.41421L8 8C8 5.79086 9.79086 4 12 4Z" fill="{fillColor}"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.5 2H4H2V4V12V14H4H12H14V12V8.375L12 9.875V12H4V4H8.22506C8.85961 3.40094 9.63666 2.95122 10.5 2.70703V2Z" fill="{fillColor}"></path>
    </svg>
  );
};

export default IconLinkTo;
</iconlinktoprops>