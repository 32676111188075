/**
 * CurrencyInput renders an input field that format it's value according to currency formatting rules
 * onFocus: renders given value in unformatted manner: "9999,99"
 * onBlur: formats the given input: "9 999,99 €"
 */
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import Decimal from 'decimal.js';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { ValidationError } from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { FontConfigSection } from '../../types/shopConfig/shopConfigV2';
import {
  convertMoneyToNumber,
  convertUnitToSubUnit,
  ensureDotSeparator,
  ensureSeparator,
  isSafeNumber,
  truncateToSubUnitPrecision,
  unitDivisor,
} from '../../util/currency';
import * as log from '../../util/log';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { propTypes } from '../../util/types';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import css from './FieldCurrencyInput.module.css';

const { Money } = sdkTypes;

const allowedInputProps = (allProps) => {
  // Strip away props that are not passed to input element (or are overwritten)
  // eslint-disable-next-line no-unused-vars
  const {
    defaultValue,
    intl,
    input,
    meta,
    typographyVariant,
    inputTypographyOverrides,
    inputValue,
    ...inputProps
  } = allProps;

  return inputProps;
};

// Convert unformatted value (e.g. 10,00) to Money (or null)
export const getPrice = (unformattedValue, currencyConfig) => {
  const isEmptyString = unformattedValue === '';
  try {
    return isEmptyString
      ? null
      : new Money(
          convertUnitToSubUnit(unformattedValue, unitDivisor(currencyConfig.currency)),
          currencyConfig.currency
        );
  } catch (e) {
    return null;
  }
};

const CurrencyInputComponent = (props) => {
  const {
    defaultValue,
    input,
    intl,
    inputValue,
    id,
    typographyVariant,
    inputTypographyOverrides = {},
  } = props;

  const { currencyConfig } = useBrandCountryConfig();

  const initialValueIsMoney = input.value instanceof Money;
  const initialValue = initialValueIsMoney ? convertMoneyToNumber(input.value) : defaultValue;
  const hasInitialValue = typeof initialValue === 'number' && !Number.isNaN(initialValue);

  // We need to handle number format - some locales use dots and some commas as decimal separator
  // TODO Figure out if this could be digged from React-Intl directly somehow
  const testSubUnitFormat = intl.formatNumber('1.1', currencyConfig);
  const usesComma = testSubUnitFormat.indexOf(',') >= 0;

  const [formattedValue, setFormattedValue] = useState('');
  const [unformattedValue, setUnformattedValue] = useState('');
  const [value, setValue] = useState('');

  useEffect(() => {
    try {
      // We need this check because we only want this code to run the very first time,
      // and when someone changes the value programatically outside of this file
      if (
        inputValue?.amount !==
        getPrice(ensureDotSeparator(unformattedValue), currencyConfig)?.amount
      ) {
        // whatever is passed as a default value, will be converted to currency string
        // Unformatted value is digits + localized sub unit separator ("9,99")
        const unformattedInputVal = hasInitialValue
          ? truncateToSubUnitPrecision(
              ensureSeparator(initialValue.toString(), usesComma),
              unitDivisor(currencyConfig.currency),
              usesComma
            )
          : '';
        // Formatted value fully localized currency string ("$1,000.99")
        const formattedInputVal = hasInitialValue
          ? intl.formatNumber(ensureDotSeparator(unformattedInputVal), currencyConfig)
          : '';

        setFormattedValue(formattedInputVal);
        setUnformattedValue(unformattedInputVal);

        const inputEl = document.getElementById(id);
        const isInputInFocus = document.activeElement === inputEl;
        // Depending on if the input is in focused state or not, we display the formatted or unformatted
        if (isInputInFocus) {
          setValue(unformattedInputVal);
        } else {
          setValue(formattedInputVal);
        }
      }
    } catch (e) {
      log.error(e, 'currency-input-init-failed', { currencyConfig, defaultValue, initialValue });
      throw e;
    }
  }, [inputValue, currencyConfig, unformattedValue]);

  if (initialValueIsMoney && input.value.currency !== currencyConfig.currency) {
    const e = new Error('Value currency different from marketplace currency');
    log.error(e, 'currency-input-invalid-currency', { currencyConfig, inputValue: input.value });
  }

  function updateValues(event) {
    try {
      const targetValue = event.target.value.trim();
      const isEmptyString = targetValue === '';
      const valueOrZero = isEmptyString ? '0' : targetValue;

      const targetDecimalValue = isEmptyString
        ? null
        : new Decimal(ensureDotSeparator(targetValue));

      const isSafeValue =
        isEmptyString || (targetDecimalValue.isPositive() && isSafeNumber(targetDecimalValue));
      if (!isSafeValue) {
        throw new Error(`Unsafe money value: ${targetValue}`);
      }

      // truncate decimals to subunit precision: 10000.999 => 10000.99
      const truncatedValueString = truncateToSubUnitPrecision(
        valueOrZero,
        unitDivisor(currencyConfig.currency),
        usesComma
      );
      const unformattedValueVal = !isEmptyString ? truncatedValueString : '';
      const formattedValueVal = !isEmptyString
        ? intl.formatNumber(ensureDotSeparator(truncatedValueString), currencyConfig)
        : '';

      setFormattedValue(formattedValueVal);
      setValue(unformattedValueVal);
      setUnformattedValue(unformattedValueVal);

      return {
        formattedValue: formattedValueVal,
        value: unformattedValueVal,
        unformattedValue: unformattedValueVal,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      // If an error occurs while filling input field, use previous values
      // This ensures that string like '12.3r' doesn't end up to a state.
      return { formattedValue, unformattedValue, value };
    }
  }

  function onInputChange(event) {
    event.preventDefault();
    event.stopPropagation();
    // Update value strings on state
    const { unformattedValue: val } = updateValues(event);
    // Notify parent component about current price change
    const price = getPrice(ensureDotSeparator(val), currencyConfig);
    props.input.onChange(price);
  }

  function onInputBlur(event) {
    event.preventDefault();
    event.stopPropagation();
    const {
      input: { onBlur },
    } = props;
    if (onBlur && unformattedValue) {
      // If parent component has provided onBlur function, call it with current price.
      const price = getPrice(ensureDotSeparator(unformattedValue), currencyConfig);
      onBlur(price);
    }

    setValue(formattedValue);
  }

  function onInputFocus(event) {
    event.preventDefault();
    event.stopPropagation();
    const {
      input: { onFocus },
    } = props;
    if (onFocus && unformattedValue) {
      // If parent component has provided onFocus function, call it with current price.
      const price = getPrice(ensureDotSeparator(unformattedValue), currencyConfig);
      onFocus(price);
    }

    setValue(unformattedValue);
  }

  const { className, placeholder } = props;
  const placeholderText = placeholder || intl.formatNumber(defaultValue, currencyConfig);

  return (
    <typographywrapper variant="{typographyVariant" ||="" 'body1'}="" {...{="" typographyOverrides:="" inputTypographyOverrides="" }}="">
      <input className="{className}" {...allowedInputProps(props)}="" value="{value}" onChange="{onInputChange}" onBlur="{onInputBlur}" onFocus="{onInputFocus}" type="text" placeholder="{placeholderText}" style="{{" fontFamily:="" 'inherit',="" fontSize:="" padding:="" 'inherit'="" }}="">
    </typographywrapper>
  );
};

CurrencyInputComponent.defaultProps = {
  className: null,
  defaultValue: null,
  placeholder: null,
  inputValue: null,
  typographyVariant: null,
  inputTypographyOverrides: {},
};

const { func, oneOfType, number, shape, string, object } = PropTypes;

CurrencyInputComponent.propTypes = {
  className: string,
  defaultValue: number,
  intl: intlShape.isRequired,
  input: shape({
    value: oneOfType([string, propTypes.money]),
    onBlur: func,
    onChange: func.isRequired,
    onFocus: func,
  }).isRequired,

  placeholder: string,
  // this is only needed so that useEffect is called when the inputValue is changed externally
  inputValue: object,
  typographyVariant: string,
  inputTypographyOverrides: object,
};

export const CurrencyInput = injectIntl(CurrencyInputComponent);

const FieldCurrencyInputComponent = (props) => {
  const {
    rootClassName,
    className,
    id,
    label,
    input,
    meta,
    typographyVariant,
    boxContainerProps,
    inputTypographyOverrides,
    ...rest
  } = props;

  const { fontConfig } = useShopConfig();
  const labelTypographyVariant = fontConfig[FontConfigSection.FieldLabel];

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const inputClasses = classNames(css.input, {
    [css.inputSuccess]: valid,
    [css.inputError]: hasError,
  });

  const inputProps = { className: inputClasses, id, input, ...rest };
  const classes = classNames(rootClassName, className);

  return (
    <box className="{classes}" {...boxContainerProps}="">
      {label ? (
        <label htmlFor="{id}">
          <typographywrapper variant="{typographyVariant" ||="" labelTypographyVariant}="" typographyOverrides="{{" style:="" {="" fontWeight:="" 'bold'="" }="" }}="">
            {label}
          </typographywrapper>
        </label>
      ) : null}
      <currencyinput {...inputProps}="" {...{="" typographyVariant,="" inputTypographyOverrides="" }}=""></currencyinput>
      <validationerror fieldMeta="{meta}"></validationerror>
    </box>
  );
};

FieldCurrencyInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  id: null,
  label: null,
  inputValue: null,
  typographyVariant: null,
  boxContainerProps: {},
  inputTypographyOverrides: {},
};

FieldCurrencyInputComponent.propTypes = {
  rootClassName: string,
  className: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,
  // this is only needed so that useEffect is called when the inputValue is changed externally
  inputValue: object,
  typographyVariant: string,
  boxContainerProps: object,
  inputTypographyOverrides: object,
};

const FieldCurrencyInput = (props) => <field component="{FieldCurrencyInputComponent}" {...props}=""></field>;

export default FieldCurrencyInput;
