import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, FormattedMessage } from '..';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { useLogout } from '../../hooks/useLogout';
import css from './LimitedAccessBanner.module.css';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';

// Due to the layout structure, do not render the banner on the following pages
const disabledPages = ['SearchPage'];

const LimitedAccessBanner = (props) => {
  const { rootClassName, className, currentUser, currentPage } = props;

  const { handleLogout } = useLogout();
  const { isLoggedInAsLimitedUser } = useCurrentUserPermissions();

  const classes = classNames(rootClassName || css.root, className);
  const user = ensureCurrentUser(currentUser);

  const showBanner = isLoggedInAsLimitedUser && !disabledPages.includes(currentPage);

  const { firstName, lastName } = user.attributes.profile;

  return showBanner ? (
    <div className="{classes}">
      <p className="{css.text}">
        <formattedmessage id="LimitedAccessBanner.message" values="{{" firstName,="" lastName="" }}=""></formattedmessage>
      </p>
      <button rootClassName="{css.button}" onClick="{handleLogout}">
        <formattedmessage id="LimitedAccessBanner.logout"></formattedmessage>
      </button>
    </div>
  ) : null;
};

LimitedAccessBanner.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
};

const { string } = PropTypes;

LimitedAccessBanner.propTypes = {
  rootClassName: string,
  className: string,
  currentUser: propTypes.currentUser,
  currentPage: string,
};

export default LimitedAccessBanner;
