import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchBar from 'material-ui-search-bar';
import { Box, Dialog, makeStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { resetActiveModal } from '../../ducks/modal.duck';
import { useIsMobile } from '../../hooks/useIsMobile';
import { IconCloseV2, IconSearch } from '..';
import {
  getHandleSearchChangedValueFn,
  pickSearchParamsOnly,
} from '../../containers/LandingPageV2/LandingPageV2.helpers';
import { parse } from '../../util/urlHelpers';
import { getKeywordQueryParam } from '../KeywordFilter/KeywordFilter';
import { keywordFilterConfig } from '../../shopConfig/filters/marketplace-custom-config';
import { CurrentUser } from '../../types/sharetribe/currentUser';
import AppContext from '../../context/AppContext';
import { useShopConfig } from '../../hooks/shopConfig';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Feature } from '../../util/featureFlags';
import { sortConfigForFrenzy } from '../../util/frenzyHelpers';
import config from '../../shopConfig/config';
import { LandingPageV2State } from '../../containers/LandingPageV2/LandingPageV2.duck';
import { FrenzyApiModes } from '../../types/frenzy/query';

interface SearchModalProps {
  isOpen: boolean;
}

const useStyles = makeStyles({
  searchBar: {
    position: 'absolute',
    top: '30%',
    '& .MuiInputBase-input': {
      // Font size must be >= 16px to prevent zoom-in when clicking the search bar on mobile
      fontSize: '16px',
    },
  },
});

const SearchModal: FC<searchmodalprops> = (props: SearchModalProps) => {
  const { isOpen } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const isMobile = useIsMobile();
  const { filters: shopConfigFilters, shopName } = useShopConfig();
  const { treetId } = useContext(AppContext);
  const history = useHistory();

  // Focus the search bar when the modal opens
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const searchBarTextInput = document.querySelector(
          '.searchBar .MuiInputBase-input'
        ) as HTMLInputElement;
        if (searchBarTextInput) {
          searchBarTextInput.focus();
        }
      }, 0);
    }
  }, [isOpen]);

  const { searchApiFilters } = useSelector<any>(
    (state) => state.LandingPageV2
  ) as LandingPageV2State;
  const currentUser = useSelector<any>((state) => state.user.currentUser) as
    | CurrentUser
    | undefined;
  const filters = searchApiFilters || shopConfigFilters;

  const isFrenzySearchEnabled = useFeatureFlags(Feature.FrenzySearch);
  const sortConfig = isFrenzySearchEnabled ? sortConfigForFrenzy : config.custom.treetV2SortConfig;
  const { ...searchInURL } = parse(location.search);
  const urlQueryParams = pickSearchParamsOnly(searchInURL, filters, sortConfig);

  const [currentQueryParams, setCurrentQueryParams] = useState(urlQueryParams);
  const [searchQuery, setSearchQuery] = useState('');

  const urlParam = getKeywordQueryParam(keywordFilterConfig.queryParamNames);

  const handleSearchChangedValueFn = getHandleSearchChangedValueFn({
    urlQueryParams,
    currentQueryParams,
    currentUser,
    setCurrentQueryParams,
    sortConfig,
    filterConfig: filters,
    history,
    shopName,
    treetId,
  });

  const useHistoryPush = true;
  const onSearchQueryChange = handleSearchChangedValueFn(useHistoryPush, FrenzyApiModes.RawQuery);

  const handleCancelSearch = () => {
    setSearchQuery('');
    onSearchQueryChange({ [urlParam]: undefined });
  };

  const handleSearch = (query: string) => {
    onSearchQueryChange({ [urlParam]: query !== '' ? query : undefined });
  };

  const handleSubmit = () => {
    handleSearch(searchQuery);
    dispatch(resetActiveModal());
  };

  const placeholderText = isMobile ? 'Search' : 'Search by style, color, etc.';

  return (
    <dialog open="{isOpen}" fullScreen="{false}" fullWidth="" maxWidth="md" onClose="{()" ==""> dispatch(resetActiveModal())}
      classes={{ paper: classes.searchBar }}
    >
      <box overflow="hidden">
        <searchbar className="searchBar" placeholder="{placeholderText}" value="{searchQuery}" onChange="{(newValue)" ==""> setSearchQuery(newValue)}
          onRequestSearch={handleSubmit}
          onCancelSearch={handleCancelSearch}
          cancelOnEscape
          style={{
            boxShadow: 'none',
            borderRadius: '0',
            height: '48px',
          }}
          searchIcon={<iconsearch></iconsearch>}
          closeIcon={<iconclosev2></iconclosev2>}
        />
      </searchbar></box>
    </dialog>
  );
};

export default SearchModal;
</any></any></searchmodalprops>