import { Box } from '@material-ui/core';
import React, { FC } from 'react';

interface CustomIconProps {
  url: string;
  altText: string;
  width?: string;
  height?: string;
  className?: string;
  imgStyleOverrides?: any;
}

const CustomIcon: FC<customiconprops> = (props: CustomIconProps) => {
  const { url, altText, width, height, className, imgStyleOverrides } = props;

  if (!url) return null;

  return (
    <box width="{width}" height="{height}">
      <img style="{{" height:="" '100%',="" maxWidth:="" ...imgStyleOverrides="" }}="" src="{url}" alt="{altText}" className="{className}">
    </box>
  );
};

export default CustomIcon;
</customiconprops>