import React from 'react';
import { bool } from 'prop-types';
import SelectSingleFilterPlain from './SelectSingleFilterPlain';
import SelectSingleFilterPopup from './SelectSingleFilterPopup';

const SelectSingleFilter = (props) => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? (
    <selectsinglefilterpopup {...rest}=""></selectsinglefilterpopup>
  ) : (
    <selectsinglefilterplain {...rest}=""></selectsinglefilterplain>
  );
};

SelectSingleFilter.defaultProps = {
  showAsPopup: false,
};

SelectSingleFilter.propTypes = {
  showAsPopup: bool,
};

export default SelectSingleFilter;
