import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { defaultTreetStyles } from '../../shopConfig/config';
import TextWithLineBreaks from '../TextWithLineBreaks/TextWithLineBreaks';

interface HighlightBannerProps {
  text: string;
  color?: string;
}

const HighlightBanner: FC<highlightbannerprops> = (props: HighlightBannerProps) => {
  const { text, color } = props;
  return (
    <box bgcolor="{color" ||="" defaultTreetStyles.gray80}="" p="{2}" textAlign="center">
      <typography variant="body1" style="{{" color:="" 'white'="" }}="">
        <textwithlinebreaks text="{text}"></textwithlinebreaks>
      </typography>
    </box>
  );
};

export default HighlightBanner;
</highlightbannerprops>