import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { object, string } from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AuthenticationModal,
  BuilderSection,
  ConditionalWrapper,
  FavoriteButton,
  NamedLink,
  Overlay,
  ResponsiveImage,
} from '..';
import { logFavoriteItem, logSelectItem, logUnfavoriteItem } from '../../analytics/ga4analytics';
import { defaultTreetStyles } from '../../shopConfig/config';
import { favoriteListing } from '../../ducks/user.duck';
import { useFeaturedListingImage, useSpecificListingImage } from '../../hooks/images';
import { useShopConfig } from '../../hooks/shopConfig';
import { FilterId } from '../../types/filters/filters';
import { ITEM_AVAILABILITY_PURCHASED } from '../../util/constants';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { convertNumberToMoney, formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { getLabel } from '../../util/helpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import IconBrandDirectExplainer from '../Icons/IconBrandDirectExplainer/IconBrandDirectExplainer';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { BrandDirectIconType } from '../../types/shopConfig/shopConfigV2';
import { isProd } from '../../util/envHelpers';
import { BuilderSections } from '../../util/builder';
import { ListingItemType } from '../../types/sharetribe/listing';
import { useTradeInConfig } from '../../hooks/useTradeInConfig';
import { removeInvalidImages } from '../../util/api';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Feature } from '../../util/featureFlags';
import AppContext from '../../context/AppContext';
import { determineImageType } from './ListingCard.utils.ts';
import { useIsMobile } from '../../hooks/useIsMobile';
import { FindItemMethod } from '../../util/listings/listing';
import { isUploadcareImage } from '../../util/uploadcare';
import { getFavoritedListingsFromState } from '../../util/favoritedListingHelpers';
import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  }
  return {};
};

const ResponsiveListingImage = (props) => <responsiveimage {...props}=""></responsiveimage>;

const LazyImage = lazyLoadWithDimensions(ResponsiveListingImage, {
  loadAfterInitialRendering: 3000,
});

// TODO (TREET-1809): Move custom brand direct icons to Contentful

const useTooltipStyles = makeStyles({
  customTooltip: {
    backgroundColor: 'white',
    maxWidth: '264px',
    color: '#454545',
    borderRadius: '0',
    fontSize: '12px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  },
});

const HoverTooltip = () => {
  const shopConfig = useShopConfig();
  const {
    copy: { brandDirectLabel, brandDirectDescription },
  } = shopConfig;
  const classes = useTooltipStyles();

  return (
    <tooltip classes="{{" tooltip:="" classes.customTooltip="" }}="" title="{" <="">
          <typography color="inherit">{brandDirectLabel}</typography>
          {brandDirectDescription ||
            'Items sold directly from a brand like samples, returns, and overstock.'}
        </>
      }
      enterDelay={200}
      placement="bottom-start"
    >
      <div className="{css.brandDirectIconWrapper}">
        <iconbranddirectexplainer></iconbranddirectexplainer>
      </div>
    </tooltip>
  );
};

const ListingImage = (props) => {
  const { currentListing, renderSizes, title, favoriteButtonEl, canShowHoverImage } = props;
  const [isHovered, setHovered] = useState(false);
  const { imageRatio, brandDirectIconType } = useShopConfig();
  const { showBrandDirectIconForRelistedItems } = useTradeInConfig();
  const { allowSell } = useEnabledCustomerExperiences();
  const isRemoveInvalidImagesEnabled = useFeatureFlags(Feature.RemoveInvalidImages);
  const isListingCardHoverEnabled = useFeatureFlags(Feature.ListingCardHover);
  const featuredListingImage = useFeaturedListingImage(currentListing);
  const [imageProps, setImageProps] = useState(featuredListingImage);
  const [hasInvalidImage, setHasInvalidImage] = useState(false);
  const backupImage = useFeaturedListingImage(currentListing, true);
  const secondListingImage = useSpecificListingImage(currentListing, 1);
  const hasHoveredRef = useRef(false);

  const { publicData } = currentListing.attributes;
  const { isBrandDirect, sourceListingId, findItemMethod } = publicData;
  const ensuredAuthor = ensureUser(currentListing?.author);

  useEffect(() => {
    if (hasInvalidImage && isRemoveInvalidImagesEnabled) {
      removeInvalidImages(currentListing.id.uuid);
    }
  }, [hasInvalidImage]);

  // Preload secondary image so that image swap on cursor hover experience is performant
  useEffect(() => {
    if (!canShowHoverImage || hasInvalidImage) return;
    const image = new Image();
    image.src = secondListingImage;
  }, [secondListingImage, canShowHoverImage]);

  const handleMouseEnter = () => {
    // on first hover if secondListingImage is not from uploadcare, disable hover effect & upload images to uploadcare.
    if (
      hasHoveredRef.current === false &&
      secondListingImage &&
      !isUploadcareImage(secondListingImage)
    ) {
      hasHoveredRef.current = true;
      setImageProps(featuredListingImage);
      setHasInvalidImage(true);
      return;
    }

    if (hasHoveredRef.current === true && hasInvalidImage) return;

    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  // if isListingCardHoverEnabled flag is on, we want to overide css animations
  const threeToTwoWrapperStyle = isListingCardHoverEnabled
    ? {
        transform: 'none',
        boxShadow: 'none',
      }
    : {};

  // RelistAsDuplicate is only available to brands
  const showIconIfRelist =
    findItemMethod === FindItemMethod.RelistAsDuplicate ||
    !sourceListingId ||
    showBrandDirectIconForRelistedItems;

  const showHoverTooltip =
    isBrandDirect &&
    brandDirectIconType !== BrandDirectIconType.None &&
    !ensuredAuthor?.attributes?.profile?.publicData?.hideIsBrandUser &&
    allowSell &&
    showIconIfRelist;

  const imagePropsToUse = {
    ...imageProps,
    ...(canShowHoverImage &&
      !hasInvalidImage && {
        src: isHovered ? secondListingImage : imageProps.src,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      }),
  };

  return (
    <div className="{css.threeToTwoWrapper}" style="{threeToTwoWrapperStyle}">
      {/* Overwrite the ratio for individual stores since each store's images have different ratios */}
      <div className="{css.aspectWrapper}" style="{{" paddingBottom:="" `${imageRatio="" *="" 100}%`="" }}="">
        <lazyimage rootClassName="{css.rootForImage}" alt="{title}" variants="{['default']}" sizes="{renderSizes}" onError="{(event)" ==""> {
            let useBackupImage = false;
            const isHoverImageError = event.target.src === secondListingImage;
            if (isHoverImageError) {
              // reseting back to the featured image if the hover image failed to load
              setImageProps(featuredListingImage);
              setHasInvalidImage(true);
            } else if (!isEqual(backupImage, imageProps)) {
              useBackupImage = true;
              setImageProps(backupImage);
              setHasInvalidImage(true);
            }
            if (isProd) {
              console.error('Listing image load error.', {
                listingId: currentListing.id.uuid,
                imageProps,
                useBackupImage,
                isHoverImageError,
              });
            }
          }}
          {...imagePropsToUse}
        />
        {showHoverTooltip && <hovertooltip></hovertooltip>}
        {favoriteButtonEl}
      </lazyimage></div>
    </div>
  );
};

const ListingDetails = (props) => {
  const { title, color, size, price, originalPrice, favoriteButtonEl } = props;

  const { showColorOnListingCard } = useShopConfig();

  return (
    <box display="flex" flexDirection="column" padding="8px 0 2px 0">
      <box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" width="100%">
        <box display="flex" justifyContent="space-between" width="100%">
          <box className="{css.title}" display="flex" justifyContent="flex-start">
            <typographywrapper variant="body1" weight="{TypographyWeight.Bold}" typographyOverrides="{{" style:="" {="" overflow:="" 'hidden',="" textOverflow:="" 'ellipsis',="" whiteSpace:="" 'nowrap',="" },="" }}="">
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </typographywrapper>
          </box>
          {favoriteButtonEl}
        </box>
      </box>
      {size && (
        <div className="{css.size}">
          <typographywrapper variant="body2">{`Size: ${size}`}</typographywrapper>
        </div>
      )}
      {color && showColorOnListingCard && (
        <div className="{css.color}">
          <typographywrapper variant="body2">{`Color: ${color}`}</typographywrapper>
        </div>
      )}

      <box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <div className="{css.price}">
          <div className="{css.priceValue}" title="{price}">
            <typographywrapper variant="body2" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.red60="" },="" }}="" weight="{TypographyWeight.Bold}">
              {price}
            </typographywrapper>
          </div>
          {originalPrice && (
            <div className="{css.originalPriceValue}" title="{price}">
              <strike>
                <typographywrapper variant="body2" typographyOverrides="{{" style:="" {="" textDecoration:="" 'line-through'="" }="" }}="">
                  {originalPrice}
                </typographywrapper>
              </strike>
            </div>
          )}
        </div>
      </box>
    </box>
  );
};

export const ListingCardComponent = (props) => {
  const {
    style,
    className,
    rootClassName,
    index,
    intl,
    listing,
    renderSizes,
    referrerLocation,
    soldListingText,
    trackClickListingCardHeapEvent,
  } = props;

  const dispatch = useDispatch();
  const shopConfig = useShopConfig();
  const { treetId } = useContext(AppContext);
  const currentListing = ensureListing(listing);
  const featuredListingImage = useFeaturedListingImage(currentListing);
  const secondListingImage = useSpecificListingImage(currentListing, 1);
  const isUploadcareSmartResizeEnabled = useFeatureFlags(Feature.UploadcareSmartResize);
  const isListingCardHoverEnabled = useFeatureFlags(Feature.ListingCardHover);
  const isMobile = useIsMobile();
  const { currencyConfig } = useBrandCountryConfig();

  const { pagination } = useSelector((state) => state.LandingPageV2);
  const userState = useSelector((state) => state.user);
  const { currentUser } = userState;

  const [builderData, setBuilderData] = useState(null);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  // We keep track of the favorite button state here so that there's no delay
  // between the user clicking the favorite button and the button's appearance
  // changing in response to the click. Default to false so that we wait until
  // the current user is fetched before setting the actual favorite button status.
  const [isFavoriteButtonSelected, setIsFavoriteButtonSelected] = useState(false);

  const id = currentListing.id.uuid;

  const favoriteListingIds = getFavoritedListingsFromState(userState, treetId);

  useEffect(() => {
    const isFavorited = !!favoriteListingIds?.includes(id);
    setIsFavoriteButtonSelected(isFavorited);
  }, [favoriteListingIds]);

  const { builderConfig } = shopConfig;
  const listingCardDetailsBuilderSectionId =
    builderConfig?.sections?.[BuilderSections.ListingCardDetails];
  const showFavoriteButtonOnImage = builderData?.showFavoriteButtonOnImage;

  const isPurchased =
    currentListing.attributes?.publicData?.availability === ITEM_AVAILABILITY_PURCHASED;
  const { title = '' } = currentListing.attributes;
  const classes = classNames(rootClassName || css.root, className);
  const slug = createSlug(title);
  const isMarketplaceListing =
    currentListing.attributes.publicData?.listingItemType === ListingItemType.Marketplace;
  const featuredListingImageSrc = featuredListingImage?.src;
  const secondListingImageSrc = secondListingImage;
  const stockPhotos = currentListing?.attributes?.publicData?.stockPhotoUrls || [];

  const { price, publicData } = currentListing.attributes;
  const { color, colour } = publicData;
  const listingColor = color || colour;
  const { filters, showColorOnListingCard, sizeVariantOptionName } = shopConfig;
  const size = publicData[sizeVariantOptionName];
  const { formattedPrice } = priceData(price, intl);
  const sizeOptions = findOptionsForSelectFilter(FilterId.Size, filters);
  const sizeLabel = getLabel(sizeOptions, size);

  let formattedOriginalPrice;
  if (publicData.originalPrice && parseFloat(publicData.originalPrice) > price.amount / 100) {
    const originalPrice = convertNumberToMoney(publicData.originalPrice, currencyConfig.currency);
    formattedOriginalPrice = priceData(originalPrice, intl).formattedPrice;
  }

  const canShowHoverImage =
    !isMobile &&
    featuredListingImageSrc &&
    secondListingImage &&
    isUploadcareSmartResizeEnabled &&
    isListingCardHoverEnabled &&
    !isPurchased;

  const setFavorite = () => {
    setIsFavoriteButtonSelected(!isFavoriteButtonSelected);
    dispatch(favoriteListing({ listingId: id }));
  };

  const handleFavoriteButtonClick = (e) => {
    e.preventDefault(); // Prevent re-routing to listing
    e.stopPropagation(); // Prevent analytics logging for selecting listing

    if (isFavoriteButtonSelected) {
      logUnfavoriteItem(currentListing);
    } else {
      logFavoriteItem(currentListing);
    }

    if (!currentUser) {
      setIsAuthModalOpen(true);
    } else {
      setFavorite();
    }
  };

  const handleNamedLinkClick = async () => {
    logSelectItem(currentListing, referrerLocation);

    if (trackClickListingCardHeapEvent) {
      const featuredImageType = featuredListingImageSrc
        ? determineImageType(featuredListingImageSrc, stockPhotos)
        : null;

      const hoveredImageType = canShowHoverImage
        ? determineImageType(secondListingImageSrc, stockPhotos)
        : null;

      const pageNumber = pagination?.page ? pagination?.page : null;

      trackClickListingCardHeapEvent({
        listingId: currentListing.id.uuid,
        treetId,
        indexOnPage: index ?? null,
        pageNumber,
        page: referrerLocation,
        isPurchased,
        isMobile,
        featuredImageType,
        hoveredImageType,
      });
    }
  };

  const handleAuthModalSuccess = () => {
    setFavorite();
    setIsAuthModalOpen(false);
  };

  const favoriteButtonEl = (
    <favoritebutton className="{classNames(css.favoriteButton," {="" [css.favoriteButtonOnImage]:="" showFavoriteButtonOnImage,="" })}="" iconClassName="{classNames(css.favoriteButtonIcon," [css.favoriteButtonIconOnImage]:="" isSelected="{isFavoriteButtonSelected}" onClick="{handleFavoriteButtonClick}"></favoritebutton>
  );

  return (
    <box style="{style}">
      <conditionalwrapper condition="{isMarketplaceListing}" wrapper="{(children)" ==""> (
          <namedlink className="{classes}" name="ListingPage" onClick="{handleNamedLinkClick}" params="{{" id,="" slug="" }}="">
            {children}
          </namedlink>
        )}
      >
        <div style="{{" position:="" 'relative'="" }}="">
          <listingimage currentListing="{currentListing}" renderSizes="{renderSizes}" shopConfig="{shopConfig}" title="{title}" canShowHoverImage="{canShowHoverImage}" isPurchased="{isPurchased}" {...(showFavoriteButtonOnImage="" &&="" {="" favoriteButtonEl="" })}=""></listingimage>
          {isPurchased && <overlay message="{soldListingText}" shouldAllowClick=""></overlay>}
        </div>
        {listingCardDetailsBuilderSectionId && (
          <buildersection sectionType="{BuilderSections.ListingCardDetails}" sectionId="{listingCardDetailsBuilderSectionId}" sectionData="{{" title,="" color:="" showColorOnListingCard="" ?="" listingColor="" :="" undefined,="" size:="" sizeLabel,="" price:="" formattedPrice,="" originalPrice:="" formattedOriginalPrice,="" }}="" fetchDataFromBuilder="{(builderResponse)" ==""> setBuilderData(builderResponse)}
          />
        )}
        {!listingCardDetailsBuilderSectionId && (
          <listingdetails title="{title}" color="{listingColor}" size="{size}" price="{formattedPrice}" originalPrice="{formattedOriginalPrice}" {...(!showFavoriteButtonOnImage="" &&="" {="" favoriteButtonEl="" })}=""></listingdetails>
        )}
      </buildersection></conditionalwrapper>
      <authenticationmodal open="{isAuthModalOpen}" handleClose="{()" ==""> setIsAuthModalOpen(false)}
        onSuccess={handleAuthModalSuccess}
        bannerText="Sign up or log in to favorite items."
      />
    </authenticationmodal></box>
  );
};

ListingCardComponent.defaultProps = {
  style: null,
  className: null,
  rootClassName: null,
  renderSizes: null,
  soldListingText: 'SOLD',
};

ListingCardComponent.propTypes = {
  style: object,
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  referrerLocation: string.isRequired,
  soldListingText: string,

  // Responsive image sizes hint
  renderSizes: string,
};

export default injectIntl(ListingCardComponent);
