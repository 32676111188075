import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { OnChange } from 'react-final-form-listeners';
import { FieldTextInput } from '../../components';
import { required } from '../../util/validators';

export const USER_GENERATED_TITLE = 'userGeneratedTitle';

interface ItemNameProps {
  onChange?: (values: any) => void;
}

const ItemNameForm: FC<itemnameprops> = (props) => {
  const { onChange = () => {} } = props;

  return (
    <box pb="{1}">
      <fieldtextinput id="{USER_GENERATED_TITLE}" name="{USER_GENERATED_TITLE}" label="Item Name" placeholder="Enter a name for your listing" validate="{required('Please" specify="" a="" name.')}=""></fieldtextinput>
      <onchange name="{USER_GENERATED_TITLE}">{(value) => onChange(value)}</onchange>
    </box>
  );
};

export default ItemNameForm;
</itemnameprops>