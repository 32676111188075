import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { bool, number, shape, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Builder } from '@builder.io/react';
import { isEqual } from 'lodash';
import {
  AdminButton,
  AuthenticationModal,
  BuilderSection,
  FormattedMessage,
  IconFavorite,
  IconLinkTo,
  NamedLink,
  ShoppingBagButton,
  TopbarLogo,
} from '..';
import { AdminPageTab } from '../../containers/AdminPage/AdminPage.utils';
import TopbarTransparencyContext from '../../context/TopbarTransparencyContext';
import { useShopConfig } from '../../hooks/shopConfig';
import { useActionCopy } from '../../hooks/useActionCopy';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useIsShopSideLaunched } from '../../hooks/useIsShopSideLaunched';
import { useShoppingBag } from '../../hooks/useShoppingBag';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import { RequestStatus } from '../../types/requestStatus';
import { TopbarLink, TopbarSection } from '../../types/shopConfig/shopConfigV2';
import { withViewport } from '../../util/contextHelpers';
import TypographyWrapper, { TypographyFormat } from '../TypographyWrapper/TypographyWrapper';
import ProfileMenu from './ProfileMenu';
import { ModalType, setActiveModal } from '../../ducks/modal.duck';
import { Feature } from '../../util/featureFlags';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import TopbarSearch from '../Topbar/TopbarSearch';
import { BuilderSections } from '../../util/builder';
import css from './TopbarDesktop.module.css';
import { fetchShoppingBagListings } from '../../ducks/shoppingBag.duck';
import { getCartListingsFromPG, getCartListingsFromState } from '../../util/cartListingHelpers';
import AppContext from '../../context/AppContext';
import { cartListingIdsUpdated } from '../../ducks/user.duck';

// Based off of manually inspected window widths for long logos
const MAX_MEDIUM_SCREEN_WIDTH = 1260;
const MAX_SMALL_SCREEN_WIDTH = 990;

const Logo = () => {
  const isTransparentTopbar = useContext(TopbarTransparencyContext);
  return (
    <div className="{classNames(css.section," css.logoSection,="" {="" [css.transparentTopbarForeground]:="" isTransparentTopbar,="" })}="">
      <topbarlogo></topbarlogo>
    </div>
  );
};

const CategoryLink = ({ categoryConfig }) => {
  // Search Query; check for null because it can be empty string
  if (categoryConfig.searchQuery == null || categoryConfig.hideOnTopbar) return null;

  return (
    <namedlink name="SearchPage" to="{{" search:="" categoryConfig.searchQuery="" }}="" className="{css.link}" key="{categoryConfig.label}">
      <span className="{css.linkLabel}">
        <typographywrapper variant="subtitle1" format="{TypographyFormat.HoverUnderlined}">
          {categoryConfig.label}
        </typographywrapper>
      </span>
    </namedlink>
  );
};

const ShopAll = () => {
  const { css: brandCss } = useShopConfig();
  const topbarLayout = brandCss?.topbarLayout;

  return (
    <categorylink categoryConfig="{{" label:="" topbarLayout.find((section)=""> section.type === TopbarLink.ShopAll)?.config?.label ||
          'Shop All',
        searchQuery: 'mode=raw-query',
      }}
    />
  );
};

const ListItemLink = ({ viewport }) => {
  const { isListTradeInOnly } = useEnabledCustomerExperiences();
  const isTransparentTopbar = useContext(TopbarTransparencyContext);
  const { listActionCopy } = useActionCopy();

  const { width } = viewport;
  const hasViewport = width > 0;
  const isSmallWidth = hasViewport && width <= MAX_SMALL_SCREEN_WIDTH;
  const listItemCopy = isSmallWidth ? 'Sell' : listActionCopy;

  return (
    <div className="{css.sectionWrapper}">
      <namedlink className="{css.sectionLink}" name="{isListTradeInOnly" ?="" 'ManageTradeInsPage'="" :="" 'NewListingPage'}="">
        <span className="{classNames(css.sectionText," {="" [css.transparentTopbarForeground]:="" isTransparentTopbar,="" })}="">
          <typographywrapper variant="subtitle1" format="{TypographyFormat.HoverUnderlined}">
            {listItemCopy}
          </typographywrapper>
        </span>
      </namedlink>
    </div>
  );
};

const ListItemLinkWithViewport = withViewport(ListItemLink);

const IconLinks = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const { currentUser } = userState;
  const { activeModal } = useSelector((state) => state.modal);
  const { isAuthenticated } = useSelector((state) => state.Auth);

  const { treetId } = useContext(AppContext);
  const isSearchModalEnabled = useFeatureFlags(Feature.SearchBarV2);
  const { shoppingBagCount } = useShoppingBag();
  const { isAdmin, isBrand, isAccounting } = useCurrentUserPermissions();
  const isShopSideLaunched = useIsShopSideLaunched();
  const { allowBuy, hasOrderHistory } = useEnabledCustomerExperiences();

  const isTransparentTopbar = useContext(TopbarTransparencyContext);

  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  const toggleShoppingBagOpen = async () => {
    if (!currentUser) {
      setIsAuthModalOpen(true);
    } else {
      dispatch(setActiveModal(ModalType.ShoppingBag));
      const stateCartListingIds = getCartListingsFromState(userState, treetId);
      const pgCartListingIds = await getCartListingsFromPG(currentUser, treetId);
      if (!isEqual(stateCartListingIds, pgCartListingIds)) {
        await dispatch(cartListingIdsUpdated(pgCartListingIds));
        dispatch(fetchShoppingBagListings());
      }
    }
  };

  return (
    <box display="flex" alignItems="center" height="100%">
      {isSearchModalEnabled && allowBuy && isShopSideLaunched && (
        <div className="{classNames(css.sectionWrapper," {="" [css.transparentTopbarForeground]:="" isTransparentTopbar,="" })}="">
          <topbarsearch className="{css.sectionLink}"></topbarsearch>
        </div>
      )}
      {allowBuy && currentUser && (
        <div className="{classNames(css.sectionWrapper," {="" [css.transparentTopbarForeground]:="" isTransparentTopbar,="" })}="">
          <namedlink className="{css.sectionLink}" name="FavoritesPage">
            <iconfavorite></iconfavorite>
          </namedlink>
        </div>
      )}
      <profilemenu isAuthenticatedOnClientSide="{mounted" &&="" isAuthenticated}=""></profilemenu>
      {allowBuy && isShopSideLaunched && (
        <shoppingbagbutton count="{shoppingBagCount}" isOpen="{activeModal" =="=" ModalType.ShoppingBag}="" onClick="{toggleShoppingBagOpen}" className="{css.shoppingBagButton}" rootClassName="{classNames(css.sectionWrapper," {="" [css.transparentTopbarForeground]:="" isTransparentTopbar,="" })}="" isTransparentTopbar="{isTransparentTopbar}"></shoppingbagbutton>
      )}
      {(isAdmin || isBrand || isAccounting) && (
        <div className="{classNames(css.sectionWrapper," {="" [css.transparentTopbarForeground]:="" isTransparentTopbar,="" })}="">
          <namedlink className="{css.sectionLink}" name="AdminPage" params="{{" tab:="" hasOrderHistory="" ?="" AdminPageTab.MarketplaceDashboard="" :="" AdminPageTab.TradeInDashboard,="" }}="">
            <adminbutton></adminbutton>
          </namedlink>
        </div>
      )}
      <authenticationmodal open="{isAuthModalOpen}" handleClose="{()" ==""> setIsAuthModalOpen(false)}
        onSuccess={() => setIsAuthModalOpen(false)}
        bannerText="Sign up or log in to view your shopping bag."
      />
    </authenticationmodal></box>
  );
};

const MainSiteLink = () => {
  const { shopName, mainSite } = useShopConfig();
  const isTransparentTopbar = useContext(TopbarTransparencyContext);

  return (
    <a className="{css.link}" href="{mainSite}" key="main-site">
      <span className="{classNames(css.linkLabel," {="" [css.transparentTopbarForeground]:="" isTransparentTopbar,="" })}="">
        <box display="flex" flexDirection="row" alignItems="center">
          <typographywrapper variant="subtitle1" format="{TypographyFormat.HoverUnderlined}">
            Back to {shopName}
          </typographywrapper>
          <box pl="4px"></box>
          <iconlinkto></iconlinkto>
        </box>
      </span>
    </a>
  );
};

const OtherSiteLink = () => {
  const { css: brandCss } = useShopConfig();
  const topbarLayout = brandCss?.topbarLayout;
  const otherSiteConfig = topbarLayout.find(
    (section) => section.type === TopbarLink.OtherSite
  )?.config;

  return (
    <a className="{css.link}" href="{otherSiteConfig.url}" key="other-site">
      <span className="{css.linkLabel}">
        <box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <typographywrapper variant="subtitle1" format="{TypographyFormat.HoverUnderlined}">
            {otherSiteConfig.label}
          </typographywrapper>
        </box>
      </span>
    </a>
  );
};

const SizeGuideLink = () => {
  const { sizeGuideUrl } = useShopConfig();
  return (
    <a className="{css.link}" href="{sizeGuideUrl}" key="size-guide">
      <span className="{css.linkLabel}">
        <typographywrapper variant="subtitle1">Size Guide</typographywrapper>
      </span>
    </a>
  );
};

const TopbarCategories = () => {
  const { homepageCategories } = useShopConfig();
  return homepageCategories.map((categoryConfig) => (
    <categorylink categoryConfig="{categoryConfig}" key="{categoryConfig.label}"></categorylink>
  ));
};

const SignUpAndLogInLinks = () => {
  const isTransparentTopbar = useContext(TopbarTransparencyContext);
  return (
    <box display="flex" alignItems="center" height="100%">
      <namedlink name="SignupPage" className="{classNames(css.signupLink," {="" [css.transparentTopbarForeground]:="" isTransparentTopbar,="" })}="">
        <span className="{css.signup}">
          <typographywrapper variant="subtitle1" format="{TypographyFormat.HoverUnderlined}">
            <formattedmessage id="TopbarDesktop.signup"></formattedmessage>
          </typographywrapper>
        </span>
      </namedlink>
      <namedlink name="LoginPage" className="{classNames(css.loginLink," {="" [css.transparentTopbarForeground]:="" isTransparentTopbar,="" })}="">
        <span className="{css.login}">
          <typographywrapper variant="subtitle1" format="{TypographyFormat.HoverUnderlined}">
            <formattedmessage id="TopbarDesktop.login"></formattedmessage>
          </typographywrapper>
        </span>
      </namedlink>
    </box>
  );
};

const TopbarDesktopComponent = (props) => {
  const { className, rootClassName, isAuthenticated, viewport } = props;

  const { homepageCategories, css: brandCss, builderConfig } = useShopConfig();
  const topbarLayout = brandCss?.topbarLayout;
  const { allowList } = useEnabledCustomerExperiences();
  const isShopSideLaunched = useIsShopSideLaunched();
  const isTransparentTopbar = useContext(TopbarTransparencyContext);
  const { currentUserShowStatus } = useSelector((state) => state.user);

  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  const { width } = viewport;
  const hasViewport = width > 0;
  const isSmallWidth = hasViewport && width <= MAX_SMALL_SCREEN_WIDTH;

  const getTopbarLinks = () => {
    const shouldShowShopAll = !isSmallWidth && isShopSideLaunched;

    const isMediumWidth =
      hasViewport && width <= MAX_MEDIUM_SCREEN_WIDTH && width > MAX_SMALL_SCREEN_WIDTH;
    const isMediumOrSmallWidth = isMediumWidth || isSmallWidth;
    const shouldShowCategories = !(isMediumOrSmallWidth && homepageCategories.length > 0);
    const otherSiteConfig = topbarLayout.find(
      (section) => section.type === TopbarLink.OtherSite
    )?.config;
    const shouldShowOtherSite =
      !!otherSiteConfig && (!isMediumOrSmallWidth || otherSiteConfig.alwaysShow);

    return {
      [TopbarLink.ShopAll]: shouldShowShopAll ? <shopall></shopall> : null,
      [TopbarLink.Categories]: shouldShowCategories ? <topbarcategories></topbarcategories> : null,
      [TopbarLink.SizeGuide]: <sizeguidelink></sizeguidelink>,
      [TopbarLink.MainSite]: <mainsitelink></mainsitelink>,
      [TopbarLink.OtherSite]: shouldShowOtherSite ? <othersitelink></othersitelink> : null,
    };
  };

  const classes = classNames(rootClassName || css.root, className);
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const topbarLinks = getTopbarLinks();
  const hasTopbarLinksRight = topbarLayout.some(
    (topbarLink) => topbarLink.section === TopbarSection.Right
  );

  const topbarContentSectionId = builderConfig?.sections?.[BuilderSections.TopbarContent];

  if (topbarContentSectionId) {
    return (
      <buildersection sectionType="{BuilderSections.TopbarContent}" sectionId="{topbarContentSectionId}" sectionData="{{" isLoggedIn:="" isAuthenticatedOrJustHydrated="" }}=""></buildersection>
    );
  }

  return (
    <nav>
      <box className="{classes}" width="100%">
        <box display="flex" justifyContent="space-between" alignItems="center" mx="auto" className="{css.contentWrapper}">
          <div className="{classNames(css.section," css.shopSection)}="">
            {hasViewport && (
              <>
                {topbarLayout
                  .filter((topbarLink) => topbarLink.section === TopbarSection.Left)
                  .map((topbarLink) => (
                    <div key="{topbarLink.type}" className="{classNames(css.linkSection," {="" [css.transparentTopbarForeground]:="" isTransparentTopbar,="" })}="">
                      {topbarLinks[topbarLink.type]}
                    </div>
                  ))}
              </>
            )}
          </div>
          <logo></logo>
          <div className="{classNames(css.section," css.linksSection)}="">
            {hasTopbarLinksRight && (
              <div className="{css.sectionWrapper}">
                {topbarLayout
                  .filter((topbarLink) => topbarLink.section === TopbarSection.Right)
                  .map((topbarLink) => (
                    <div key="{topbarLink.type}" className="{classNames(css.linkSection," {="" [css.transparentTopbarForeground]:="" isTransparentTopbar,="" })}="">
                      {topbarLinks[topbarLink.type]}
                    </div>
                  ))}
              </div>
            )}
            {allowList && <listitemlinkwithviewport></listitemlinkwithviewport>}
            {!isAuthenticatedOrJustHydrated && <signupandloginlinks></signupandloginlinks>}
            {currentUserShowStatus === RequestStatus.Success && <iconlinks></iconlinks>}
          </div>
        </box>
      </box>
    </nav>
  );
};

TopbarDesktopComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

TopbarDesktopComponent.propTypes = {
  rootClassName: string,
  className: string,
  isAuthenticated: bool.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

Builder.registerComponent(Logo, { name: 'Logo' });
Builder.registerComponent(ShopAll, { name: 'Shop All Link' });
Builder.registerComponent(MainSiteLink, { name: 'Main Site Link' });
Builder.registerComponent(OtherSiteLink, { name: 'Other Site Link' });
Builder.registerComponent(SizeGuideLink, { name: 'Size Guide Link' });
Builder.registerComponent(ListItemLinkWithViewport, { name: 'List Item Link' });
Builder.registerComponent(SignUpAndLogInLinks, { name: 'Sign Up And Log In Links' });
Builder.registerComponent(TopbarCategories, { name: 'Category Links' });
Builder.registerComponent(IconLinks, { name: 'Icon Links' });

const TopbarDesktop = withViewport(TopbarDesktopComponent);

export default TopbarDesktop;
</categorylink>