import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconSubtractFilledProps {
  className?: string;
  color?: string;
}

const IconSubtractFilled: FC<iconsubtractfilledprops> = (props: IconSubtractFilledProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg className="{className}" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 15V14H21H11H10V15V17V18H11H21H22V17V15ZM5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16Z" fill="{fillColor}" stroke="{fillColor}" strokeWidth="2"></path>
    </svg>
  );
};

export default IconSubtractFilled;
</iconsubtractfilledprops>