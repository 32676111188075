import { Box, Divider } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  FormattedMessage,
  IconUser,
  IconUserFilled,
  InlineTextButton,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
} from '..';
import { AboutPageTab } from '../../containers/AboutPage/aboutPageUtils';
import { useShopConfig } from '../../hooks/shopConfig';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { useLogout } from '../../hooks/useLogout';
import { usePageTitles } from '../../hooks/usePageTitles';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import { PAGE_TO_PATH } from '../../routeConfiguration';
import { Feature } from '../../util/featureFlags';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import TopbarTransparencyContext from '../../context/TopbarTransparencyContext';
import { EmailSubscribeSource } from '../../types/apollo/generated/types.generated';
import { setSavedSearchSource } from '../../ducks/user.duck';
import { useAllowedFindItemMethods } from '../../hooks/useAllowedFindItemMethods';
import { ModalType, setActiveModal } from '../../ducks/modal.duck';
import { getBasePath } from '../../util/routes';
import css from './TopbarDesktop.module.css';

interface ProfileMenuProps {
  isAuthenticatedOnClientSide: boolean;
}

const currentPathClass = (path: string, currentPath?: string) =>
  currentPath === path ? css.currentPage : null;

const YourPurchases: FC = () => {
  const { purchasesTitle } = usePageTitles();
  const location = useLocation();
  const { pathname } = location;

  return (
    <namedlink className="{classNames(" css.menuLink,="" currentPathClass(PAGE_TO_PATH.ManagePurchasesPage,="" pathname)="" )}="" name="ManagePurchasesPage">
      <span className="{css.menuItemBorder}"></span>
      <typographywrapper variant="body1">{purchasesTitle}</typographywrapper>
    </namedlink>
  );
};

const YourSales: FC = () => {
  const { salesTitle } = usePageTitles();
  const location = useLocation();
  const { pathname } = location;

  return (
    <namedlink className="{classNames(css.menuLink," currentPathClass(PAGE_TO_PATH.ManageSalesPage,="" pathname))}="" name="ManageSalesPage">
      <span className="{css.menuItemBorder}"></span>
      <typographywrapper variant="body1">{salesTitle}</typographywrapper>
    </namedlink>
  );
};

const YourListings: FC = () => {
  const { listingsTitle } = usePageTitles();
  const location = useLocation();
  const { pathname } = location;

  return (
    <namedlink className="{classNames(" css.menuLink,="" currentPathClass(PAGE_TO_PATH.ManageListingsPage,="" pathname)="" )}="" name="ManageListingsPage">
      <span className="{css.menuItemBorder}"></span>
      <typographywrapper variant="body1">{listingsTitle}</typographywrapper>
    </namedlink>
  );
};

const Closet: FC = () => {
  const { closetTitle } = usePageTitles();
  const location = useLocation();
  const { pathname } = location;

  return (
    <namedlink className="{classNames(" css.menuLink,="" currentPathClass(PAGE_TO_PATH.ManageClosetPage,="" pathname)="" )}="" name="ManageClosetPage">
      <span className="{css.menuItemBorder}"></span>
      <typographywrapper variant="body1">{closetTitle}</typographywrapper>
    </namedlink>
  );
};

const YourTradeIns: FC = () => {
  const { tradeInsTitle } = usePageTitles();
  const location = useLocation();
  const { pathname } = location;

  return (
    <namedlink className="{classNames(" css.menuLink,="" currentPathClass(PAGE_TO_PATH.ManageTradeInsPage,="" pathname)="" )}="" name="ManageTradeInsPage">
      <span className="{css.menuItemBorder}"></span>
      <typographywrapper variant="body1">{tradeInsTitle}</typographywrapper>
    </namedlink>
  );
};

const ProfileSettings: FC = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <namedlink className="{classNames(" css.menuLink,="" currentPathClass(PAGE_TO_PATH.ProfileSettingsPage,="" pathname)="" )}="" name="ProfileSettingsPage">
      <span className="{css.menuItemBorder}"></span>
      <typographywrapper variant="body1">
        <formattedmessage id="TopbarDesktop.profileSettingsLink"></formattedmessage>
      </typographywrapper>
    </namedlink>
  );
};

const AccountSettings: FC = () => {
  const location = useLocation();
  const { pathname } = location;
  const basePath = getBasePath(pathname);

  return (
    <namedlink className="{classNames(" css.menuLink,="" currentPathClass(PAGE_TO_PATH.AccountSettingsPage,="" basePath)="" )}="" name="AccountSettingsPage">
      <span className="{css.menuItemBorder}"></span>
      <typographywrapper variant="body1">
        <formattedmessage id="TopbarDesktop.accountSettingsLink"></formattedmessage>
      </typographywrapper>
    </namedlink>
  );
};

const NotificationSettings: FC = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <namedlink className="{classNames(" css.menuLink,="" currentPathClass(PAGE_TO_PATH.NotificationSettingsPage,="" pathname)="" )}="" name="NotificationSettingsPage">
      <span className="{css.menuItemBorder}"></span>
      <typographywrapper variant="body1">Notification Settings</typographywrapper>
    </namedlink>
  );
};

const InSearchOf: FC = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setActiveModal(ModalType.ISO));
    dispatch(setSavedSearchSource({ source: EmailSubscribeSource.ProfileDropdownIso }));
  };

  return (
    <box className="{css.menuLink}">
      <inlinetextbutton style="{{" textDecoration:="" 'none'="" }}="" onClick="{handleClick}">
        <span className="{css.menuItemBorder}"></span>
        <typographywrapper component="span" variant="body1">
          Submit An Item Request
        </typographywrapper>
      </inlinetextbutton>
    </box>
  );
};

const AboutShop: FC = () => {
  const { treetShopName } = useShopConfig();
  const location = useLocation();
  const { pathname } = location;
  const pagePath = `${PAGE_TO_PATH.AboutBasePage}/${AboutPageTab.Info}`;

  return (
    <namedlink className="{classNames(css.menuLink," currentPathClass(pagePath,="" pathname))}="" params="{{" tab:="" AboutPageTab.Info="" }}="" name="AboutPage">
      <span className="{css.menuItemBorder}"></span>
      <typographywrapper variant="body1">About {treetShopName}</typographywrapper>
    </namedlink>
  );
};

const AboutTreet: FC = () => {
  const { isTradeInOnly } = useEnabledCustomerExperiences();
  const location = useLocation();
  const { pathname } = location;

  const pageName = !isTradeInOnly ? 'AboutPage' : 'AboutTreetPage';
  const pagePath = !isTradeInOnly
    ? `${PAGE_TO_PATH.AboutBasePage}/${AboutPageTab.AboutTreet}`
    : PAGE_TO_PATH.AboutTreetPage;
  const pageParams = !isTradeInOnly ? { params: { tab: AboutPageTab.AboutTreet } } : {};

  return (
    <namedlink className="{classNames(css.menuLink," currentPathClass(pagePath,="" pathname))}="" name="{pageName}" {...pageParams}="">
      <span className="{css.menuItemBorder}"></span>
      <typographywrapper variant="body1">About Treet</typographywrapper>
    </namedlink>
  );
};

const Logout: FC = () => {
  const { handleLogout } = useLogout();

  return (
    <box mb="{2}" className="{css.logoutButtonWrapper}">
      <inlinetextbutton rootClassName="{css.logoutButton}" onClick="{handleLogout}">
        <span className="{css.menuItemBorder}"></span>
        <typographywrapper component="span" variant="body1">
          <formattedmessage id="TopbarDesktop.logout"></formattedmessage>
        </typographywrapper>
      </inlinetextbutton>
    </box>
  );
};

const ProfileMenu: FC<profilemenuprops> = (props: ProfileMenuProps) => {
  const { isAuthenticatedOnClientSide } = props;
  const isTransparentTopbar = useContext(TopbarTransparencyContext);
  const isISOEnabled = useFeatureFlags(Feature.InSearchOf);
  const isNotificationSettingsPageEnabled = useFeatureFlags(Feature.FollowingPage);
  const { allowBuy, allowMarketplace } = useEnabledCustomerExperiences();
  const { canOwnTradeIns, canOwnListingsForSale, isTradeInAdmin, canViewPage } =
    useCurrentUserPermissions();

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState<boolean>(false);

  const { shouldAllowSearch: hasProductCatalog } = useAllowedFindItemMethods();

  if (!isAuthenticatedOnClientSide) return null;

  const hasManageSectionLinks =
    allowBuy || isTradeInAdmin || canOwnTradeIns || canOwnListingsForSale;

  return (
    <menu isOpen="{isProfileMenuOpen}" onToggleActive="{(isActive:" boolean)=""> setIsProfileMenuOpen(isActive)}
      className={css.sectionLink}
    >
      <menulabel className="{classNames(css.profileMenuLabel," {="" [css.transparentTopbarForeground]:="" isTransparentTopbar,="" })}="" isOpenClassName="{classNames(css.profileMenuIsOpen,">
        {isProfileMenuOpen ? <iconuserfilled></iconuserfilled> : <iconuser></iconuser>}
      </menulabel>
      <menucontent className="{css.profileMenuContent}" style="{{" right:="" 0,="" left:="" 'unset'="" }}="">
        {canViewPage.ManagePurchasesPage && (
          <menuitem key="ManagePurchasesPage">
            <yourpurchases></yourpurchases>
          </menuitem>
        )}
        {canViewPage.ManageSalesPage && (
          <menuitem key="ManageSalesPage">
            <yoursales></yoursales>
          </menuitem>
        )}
        {canViewPage.ManageClosetPage && (
          <menuitem key="ManageClosetPage">
            <closet></closet>
          </menuitem>
        )}
        {canViewPage.ManageListingsPage && (
          <menuitem key="ManageListingsPage">
            <yourlistings></yourlistings>
          </menuitem>
        )}
        {canViewPage.ManageTradeInsPage && (
          <menuitem key="ManageTradeInsPage">
            <yourtradeins></yourtradeins>
          </menuitem>
        )}
        {hasManageSectionLinks && (
          <menuitem key="dividerSettings">
            <box my="{2}">
              <divider></divider>
            </box>
          </menuitem>
        )}
        {canViewPage.ProfileSettingsPage && (
          <menuitem key="ProfileSettingsPage">
            <profilesettings></profilesettings>
          </menuitem>
        )}
        <menuitem key="AccountSettingsPage">
          <accountsettings></accountsettings>
        </menuitem>
        {isNotificationSettingsPageEnabled && canViewPage.NotificationSettingsPage && (
          <menuitem key="NotificationSettingsPage">
            <notificationsettings></notificationsettings>
          </menuitem>
        )}
        <menuitem key="dividerInfo">
          <box my="{2}">
            <divider></divider>
          </box>
        </menuitem>
        {isISOEnabled && allowMarketplace && hasProductCatalog && (
          <menuitem key="InSearchOfModal">
            <insearchof></insearchof>
          </menuitem>
        )}
        {isISOEnabled && allowMarketplace && hasProductCatalog && (
          <menuitem key="dividerInSearchOf">
            <box my="{2}">
              <divider></divider>
            </box>
          </menuitem>
        )}
        <menuitem key="AboutPage">
          <aboutshop></aboutshop>
        </menuitem>
        <menuitem key="AboutTreetPage">
          <abouttreet></abouttreet>
        </menuitem>
        <menuitem key="dividerLogout">
          <box my="{2}">
            <divider></divider>
          </box>
        </menuitem>
        <menuitem key="logout">
          <logout></logout>
        </menuitem>
      </menucontent>
    </menu>
  );
};

export default ProfileMenu;
</boolean></profilemenuprops>