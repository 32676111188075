import { Box } from '@material-ui/core';
import React, { FC, ReactNode } from 'react';
import { Field } from 'react-final-form';
import { FieldSwitch } from '../FieldSwitch/FieldSwitch';
import css from './ToggleBanner.module.css';

interface ToggleBannerProps {
  formId: string;
  name: string;
  children: ReactNode;
}

/**
 * Must be used inside a React Final Form form.
 */
const ToggleBanner: FC<togglebannerprops> = (props: ToggleBannerProps) => {
  const { formId, name, children } = props;
  const id = `${formId}-${name}`;

  return (
    <>
      <field name="{id}" component="input" type="checkbox" id="{id}" className="{css.hidden}"></field>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="{name}" className="{css.label}">
        <box 4="" 5="" display="flex" alignItems="center" justifyContent="space-between" More="" padding="" on="" left="" to="" account="" for="" extra="" from="" the="" MUI="" Switch="" component="" pl="{{" xs:="" 3,="" sm:="" }}="" pr="{{" 2,="" py="{2}" className="{css.banner}">
          <box display="flex" flexDirection="column">
            {children}
          </box>
          <fieldswitch name="{name}" data="{{" label:="" '',="" value:="" true="" }}="" color="default"></fieldswitch>
        </box>
      </label>
    </>
  );
};

export default ToggleBanner;
</togglebannerprops>