/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 *
 * The corresponding component when rendering the selected
 * values is PropertyGroup.
 *
 */

import React from 'react';
import { arrayOf, bool, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import { FieldCheckbox, ValidationError } from '..';

import css from './FieldCheckboxGroup.module.css';

const FieldCheckboxRenderer = (props) => {
  const { className, rootClassName, label, twoColumns, id, fields, options, meta } = props;

  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(css.list, css.twoColumns) : css.list;

  return (
    <fieldset className="{classes}">
      {label ? (
        <legend>
          <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" fontWeight:="" 'bold'="" }="" }}="">
            {label}
          </typographywrapper>
        </legend>
      ) : null}
      <ul className="{listClasses}">
        {options.map((option) => {
          const fieldId = `${id}.${option.key}`;
          return (
            <li key="{fieldId}" className="{css.item}">
              <fieldcheckbox id="{fieldId}" name="{fields.name}" label="{option.label}" value="{option.key}"></fieldcheckbox>
            </li>
          );
        })}
      </ul>
      <validationerror fieldMeta="{{" ...meta="" }}=""></validationerror>
    </fieldset>
  );
};

FieldCheckboxRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  twoColumns: false,
};

FieldCheckboxRenderer.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
  twoColumns: bool,
};

const FieldCheckboxGroup = (props) => <fieldarray component="{FieldCheckboxRenderer}" {...props}=""></fieldarray>;

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldCheckboxGroup.propTypes = {
  name: string.isRequired,
  id: string.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
  twoColumns: bool,
};

export default FieldCheckboxGroup;
