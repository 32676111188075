import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import startCase from 'lodash/startCase';
import {
  Button,
  Divider,
  Empty,
  IconArrowRight,
  InlineTextButton,
  NamedLink,
  ShoppingBagBundles,
  TypographyWrapper,
} from '..';
import { getListingsByUuid } from '../../ducks/marketplaceData.duck';
import { ShoppingBagState } from '../../ducks/shoppingBag.duck';
import { useShopConfig } from '../../hooks/shopConfig';
import { RequestStatus } from '../../types/requestStatus';
import { ListingWithAuthorAndImages } from '../../types/sharetribe/listing';
import { pluralize } from '../../util/strings';
import { getShopsCanonicalRootUrl } from '../../shopConfig/configHelper';
import { Referrer } from '../../util/sessionHelpers/referrerSessionHelpers';
import { redirectToURLWithoutModalState } from '../../util/window';
import { ModalParams } from '../../util/urlHelpers';
import { useShopCss } from '../../hooks/useShopCss';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { useRouteConfiguration } from '../../hooks/useRouteConfiguration';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import { resetActiveModal } from '../../ducks/modal.duck';
import css from './ShoppingBag.module.css';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Feature } from '../../util/featureFlags';

interface HeaderBarProps {
  children: ReactNode;
}

const HeaderBar: FC<headerbarprops> = (props: HeaderBarProps) => {
  const { children } = props;
  const shopCss = useShopCss();
  const bagTitle = shopCss?.addToBagButton?.bagText
    ? startCase(shopCss?.addToBagButton?.bagText)
    : 'Bag';

  return (
    <box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" px="{3}" className="{css.headerBar}">
      <div className="{css.headerBox}"></div>

      <typographywrapper typographyOverrides="{{" style:="" {="" textAlign:="" 'center'="" }="" }}="" variant="h2">
        {bagTitle}
      </typographywrapper>

      {children}
    </box>
  );
};

const EmptyBag: FC = () => {
  const dispatch = useDispatch();
  const { shopName } = useShopConfig();
  const shopCss = useShopCss();

  const emptyText = `Your ${shopCss?.addToBagButton?.bagText || 'bag'} is empty.`;
  const emptyButton = (
    <namedlink name="SearchPage" to="{{" search:="" ''="" }}="" className="{css.shopButton}" onClick="{()" ==""> dispatch(resetActiveModal())}
      style={{ textDecoration: 'none' }}
    >
      <button>Shop {shopName}</button>
    </namedlink>
  );

  return <empty text="{emptyText}" button="{emptyButton}"></empty>;
};

interface EditButtonProps {
  isEditing: boolean;
  onClick: () => void;
}

const EditButton: FC<editbuttonprops> = (props: EditButtonProps) => {
  const { isEditing, onClick } = props;
  const text = isEditing ? 'Done' : 'Edit';

  return (
    <inlinetextbutton className="{classNames(css.headerBox," css.editButton)}="" onClick="{onClick}">
      <h5 className="{css.editButtonText}">{text}</h5>
    </inlinetextbutton>
  );
};

const OtherShopsListings: FC = () => {
  const { otherShopsListingsCountAndShopInfo } = useSelector<any>(
    (state) => state.shoppingBag
  ) as ShoppingBagState;

  return (
    <>
      {Object.entries(otherShopsListingsCountAndShopInfo).map(([treetId, countAndShopInfo]) => {
        const shopCanonicalRootUrl = getShopsCanonicalRootUrl(countAndShopInfo.shopUrl, treetId);
        const rootUrl = shopCanonicalRootUrl.concat(`?referrer=${Referrer.TreetShop}`);

        return (
          <a href="{rootUrl}" key="{treetId}">
            <divider className="{css.divider}"></divider>
            <box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" px="{3}" py="{2}">
              <typographywrapper variant="body1">
                <b>{pluralize('Item', countAndShopInfo.count, true)}</b> from{' '}
                {countAndShopInfo.shopName}
              </typographywrapper>
              <iconarrowright></iconarrowright>
            </box>
          </a>
        );
      })}
    </>
  );
};

interface ShoppingBagProps {
  isOpen: boolean;
  className?: string;
}

const ShoppingBag: FC<shoppingbagprops> = (props: ShoppingBagProps) => {
  const { isOpen, className } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const routes = useRouteConfiguration();
  const { css: brandCss } = useShopConfig();
  const bgcolor = brandCss?.backgroundColor || 'white';

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const isMultiSellerEnabled = useFeatureFlags(Feature.MultiSeller);

  useEffect(() => {
    if (!isOpen) {
      setIsEditing(false);
    }
  }, [isOpen]);

  const { fetchShoppingBagListingsStatus: fetchListingsStatus, shoppingBagResultIds } =
    useSelector<any>((state) => state.shoppingBag) as ShoppingBagState;

  const listings = useSelector<any>((state) =>
    getListingsByUuid(state, shoppingBagResultIds)
  ) as ListingWithAuthorAndImages[];

  const handleMobileShoppingBagClose = () => {
    dispatch(resetActiveModal());
    redirectToURLWithoutModalState(history, location, ModalParams.MobileShoppingBag);
  };

  const isLoading = fetchListingsStatus === RequestStatus.Pending;
  const isEmpty = !isLoading && listings.length === 0;

  let shoppingBagContent;
  if (isLoading) {
    shoppingBagContent = <box p="20px">Loading...</box>;
  } else if (isEmpty) {
    shoppingBagContent = (
      <box className="{css.empty}">
        <emptybag></emptybag>
      </box>
    );
  } else {
    shoppingBagContent = <shoppingbagbundles isEditing="{isEditing}" listings="{listings}"></shoppingbagbundles>;
  }

  const classes = classNames(css.root, className);

  const handleCheckoutAll = () => {
    // Customize checkout page state with shopping bag listings
    const { setInitialValues: checkoutPageSetInitialValues } = findRouteByRouteName(
      'CheckoutPage',
      routes
    );

    dispatch(checkoutPageSetInitialValues(listings));
    handleMobileShoppingBagClose();

    // Clear previous Stripe errors from store if there is any
    dispatch(initializeCardPaymentData());

    // Redirect to CheckoutPage
    history.push(createResourceLocatorString('CheckoutPage', routes));
  };

  return (
    <div className="{classes}">
      <box bgcolor="{bgcolor}" className="{css.header}">
        <headerbar>
          {isLoading || isEmpty ? (
            <div className="{css.headerBox}"></div>
          ) : (
            <editbutton isEditing="{isEditing}" onClick="{()" ==""> setIsEditing((prev) => !prev)} />
          )}
        </editbutton></headerbar>
        <divider className="{css.divider}"></divider>
      </box>
      <box bgcolor="{bgcolor}" className="{css.content}">
        {shoppingBagContent}
      </box>
      <othershopslistings></othershopslistings>
      {!isEmpty && (
        <>
          {isMultiSellerEnabled && (
            <box display="flex" alignItems="center" justifyContent="space-between" px="{3}" py="{2}" className="{css.continueFooter}" bgcolor="{bgcolor}">
              <inlinetextbutton className="{css.link}" type="button" onClick="{handleMobileShoppingBagClose}">
                Keep Shopping
              </inlinetextbutton>
              <box>
                <button onClick="{handleCheckoutAll}">Checkout All</button>
              </box>
            </box>
          )}
          {!isMultiSellerEnabled && (
            <box display="flex" alignItems="center" justifyContent="center" p="{3}" className="{css.continueFooter}" bgcolor="{bgcolor}">
              <inlinetextbutton type="button" onClick="{handleMobileShoppingBagClose}">
                Keep Shopping
                <iconarrowright></iconarrowright>
              </inlinetextbutton>
            </box>
          )}
        </>
      )}
    </div>
  );
};

export default ShoppingBag;
</any></any></boolean></shoppingbagprops></any></editbuttonprops></headerbarprops>