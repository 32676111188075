import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useShopConfigV2 } from '../../hooks/shopConfig';
import SubscribeEmailForm from './SubscribeEmailForm';
import css from './SubscribeModal.module.css';

interface SubscribeEmailProps {
  onSubmit: () => void;
}

const SubscribeEmail: FC<subscribeemailprops> = (props: SubscribeEmailProps) => {
  const { onSubmit } = props;

  const { images, copy } = useShopConfigV2();

  const loginImageUrl = images?.loginImage?.url;
  const missionImageUrl = images?.missionImage?.url;
  const backgroundImageUrl = loginImageUrl || missionImageUrl;
  const backgroundImage = `linear-gradient(-46deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImageUrl})`;
  const descriptionCopy =
    copy?.subscribeSubtitle ||
    `Get updates for new releases, discounts, and personalized “just for you” collections from
  Treet.`;
  const headerCopy = copy?.subscribeTitle || 'Be in the know.';

  return (
    <box 8="" 15="" px="{{" xs:="" 4,="" sm:="" }}="" py="{{" 10,="" className="{css.subscribeEmailBackground}" style="{{" backgroundImage="">
      <typography variant="h1" style="{{" color:="" 'white',="" textAlign:="" 'center',="" fontWeight:="" 'bold'="" }}="">
        {headerCopy}
      </typography>
      <typography variant="body1" style="{{" color:="" 'white',="" paddingTop:="" '16px',="" textAlign:="" 'center'="" }}="">
        {descriptionCopy}
      </typography>
      <box mt="{4}">
        <subscribeemailform onSubmit="{onSubmit}"></subscribeemailform>
      </box>
    </box>
  );
};

export default SubscribeEmail;
</subscribeemailprops>