import React, { FC } from 'react';
import { useShopCss } from '../../../hooks/useShopCss';

interface IconBrandDirectProps {
  className?: string;
}

const IconBrandDirect: FC<iconbranddirectprops> = (props: IconBrandDirectProps) => {
  const { className } = props;

  const shopCss = useShopCss();

  const backgroundColor = shopCss?.productBanner?.backgroundColor || '#F8F8F8';
  const borderColor = shopCss?.productBanner?.borderColor || '#D1D1D1';
  const fontColor = shopCss?.productBanner?.fontColor || '#454545';

  return (
    <svg className="{className}" width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <circle cx="36" cy="34" r="31.5" fill="{backgroundColor}" stroke="{borderColor}"></circle>
        <path d="M18.155 24.3027C18.9547 24.3027 19.591 24.4662 20.0639 24.7933C20.5368 25.1134 20.7733 25.5936 20.7733 26.2338C20.7733 26.6235 20.6898 26.961 20.5229 27.2463C20.3629 27.5316 20.1404 27.7613 19.8553 27.9353C20.2517 28.1232 20.5646 28.3702 20.7941 28.6764C21.0236 28.9756 21.1384 29.3236 21.1384 29.7202C21.1384 30.1378 21.0619 30.4927 20.9089 30.7849C20.7628 31.0703 20.5611 31.3034 20.3038 31.4843C20.0535 31.6583 19.7684 31.787 19.4485 31.8705C19.1286 31.9471 18.7982 31.9853 18.4575 31.9853H15.9018V24.3027H18.155ZM18.3323 27.4447C18.6313 27.4447 18.8504 27.3542 18.9895 27.1733C19.1355 26.9923 19.2085 26.787 19.2085 26.5574C19.2085 26.293 19.1181 26.0946 18.9373 25.9624C18.7635 25.8302 18.527 25.7641 18.228 25.7641H17.4143V27.4447H18.3323ZM18.3844 30.5762C18.7183 30.5762 18.9999 30.5101 19.2294 30.3778C19.4589 30.2387 19.5736 30.0195 19.5736 29.7202C19.5736 29.4975 19.511 29.3305 19.3859 29.2192C19.2607 29.1009 19.0973 29.0209 18.8956 28.9791C18.7009 28.9304 18.4957 28.906 18.2801 28.906H17.4143V30.5762H18.3844Z" fill="{fontColor}"></path>
        <path d="M25.8686 24.3027C26.8353 24.3027 27.5759 24.5393 28.0905 25.0125C28.6121 25.4788 28.8729 26.1051 28.8729 26.8914C28.8729 27.3298 28.779 27.7613 28.5912 28.1858C28.4104 28.6103 28.101 28.9478 27.6628 29.1983L29.4466 31.9853H27.6837L26.0668 29.5428H25.055V31.9853H23.5424V24.3027H25.8686ZM26.1294 28.0814C26.4354 28.0814 26.6718 28.0188 26.8388 27.8935C27.0126 27.7613 27.1343 27.6082 27.2039 27.4342C27.2734 27.2533 27.3082 27.0898 27.3082 26.9436C27.3082 26.8601 27.2943 26.7557 27.2664 26.6305C27.2456 26.4982 27.1969 26.366 27.1204 26.2338C27.0439 26.1016 26.9257 25.9902 26.7657 25.8998C26.6127 25.8093 26.4041 25.7641 26.1398 25.7641H25.055V28.0814H26.1294Z" fill="{fontColor}"></path>
        <path d="M30.7145 31.9853L34.1778 24H34.2612L37.7245 31.9853H35.972L35.3774 30.4822H32.8321L32.2062 31.9853H30.7145ZM33.3641 29.2087H34.8767L34.1361 27.3403L33.3641 29.2087Z" fill="{fontColor}"></path>
        <path d="M46.6047 32.2985L41.2325 27.4447L41.2638 31.9853H39.7304V24H39.7929L45.1547 28.9269L45.1234 24.3027H46.6464V32.2985H46.6047Z" fill="{fontColor}"></path>
        <path d="M49.5103 31.9853V24.3027H51.8052C52.5771 24.3027 53.2343 24.4175 53.7767 24.6472C54.3192 24.8768 54.7538 25.183 55.0807 25.5658C55.4145 25.9485 55.6579 26.3765 55.8109 26.8497C55.9639 27.3229 56.0404 27.7996 56.0404 28.2797C56.0404 28.8504 55.9361 29.3653 55.7274 29.8246C55.5258 30.2769 55.2476 30.6666 54.8929 30.9937C54.5382 31.3138 54.1279 31.5609 53.662 31.7348C53.1961 31.9018 52.7023 31.9853 52.1807 31.9853H49.5103ZM51.0228 30.524H51.9199C52.6849 30.524 53.3004 30.3256 53.7663 29.929C54.2392 29.5323 54.4757 28.9478 54.4757 28.1753C54.4757 27.6743 54.3922 27.2672 54.2253 26.9541C54.0654 26.6339 53.8637 26.3869 53.6203 26.2129C53.3838 26.039 53.1404 25.9207 52.8901 25.858C52.6397 25.7884 52.4276 25.7536 52.2538 25.7536H51.0228V30.524Z" fill="{fontColor}"></path>
        <path d="M15 44.9158V37.2331H17.2949C18.0669 37.2331 18.724 37.3479 19.2665 37.5776C19.8089 37.8072 20.2436 38.1134 20.5704 38.4962C20.9042 38.8789 21.1476 39.3069 21.3006 39.7801C21.4536 40.2533 21.5301 40.73 21.5301 41.2101C21.5301 41.7808 21.4258 42.2957 21.2172 42.755C21.0155 43.2073 20.7373 43.597 20.3826 43.9241C20.028 44.2442 19.6177 44.4913 19.1517 44.6652C18.6858 44.8322 18.192 44.9158 17.6705 44.9158H15ZM16.5126 43.4544H17.4097C18.1746 43.4544 18.7901 43.256 19.256 42.8594C19.7289 42.4627 19.9654 41.8782 19.9654 41.1057C19.9654 40.6047 19.8819 40.1976 19.715 39.8845C19.5551 39.5643 19.3534 39.3173 19.11 39.1433C18.8736 38.9694 18.6302 38.8511 18.3798 38.7884C18.1294 38.7188 17.9173 38.684 17.7435 38.684H16.5126V43.4544Z" fill="{fontColor}"></path>
        <path d="M23.9344 37.2331H25.447V44.9158H23.9344V37.2331Z" fill="{fontColor}"></path>
        <path d="M30.6414 37.2331C31.6081 37.2331 32.3487 37.4697 32.8633 37.9429C33.3849 38.4092 33.6457 39.0355 33.6457 39.8218C33.6457 40.2602 33.5518 40.6917 33.3641 41.1162C33.1832 41.5407 32.8738 41.8782 32.4357 42.1287L34.2194 44.9158H32.4565L30.8396 42.4732H29.8278V44.9158H28.3152V37.2331H30.6414ZM30.9022 41.0118C31.2082 41.0118 31.4447 40.9492 31.6116 40.8239C31.7854 40.6917 31.9071 40.5386 31.9767 40.3646C32.0462 40.1837 32.081 40.0202 32.081 39.874C32.081 39.7905 32.0671 39.6861 32.0393 39.5609C32.0184 39.4287 31.9697 39.2964 31.8932 39.1642C31.8167 39.032 31.6985 38.9206 31.5385 38.8302C31.3855 38.7397 31.1769 38.6945 30.9127 38.6945H29.8278V41.0118H30.9022Z" fill="{fontColor}"></path>
        <path d="M36.3532 37.2331H41.5898V38.6945H37.8657V40.3333H41.1621V41.7947H37.8657V43.4544H41.7358V44.9158H36.3532V37.2331Z" fill="{fontColor}"></path>
        <path d="M49.9455 44.279C49.8621 44.3486 49.7056 44.4425 49.4761 44.5608C49.2536 44.6791 48.9789 44.7835 48.652 44.874C48.3252 44.9645 47.9636 45.0062 47.5672 44.9993C46.9621 44.9853 46.4197 44.8775 45.9399 44.6757C45.467 44.4669 45.0636 44.1851 44.7298 43.8302C44.403 43.4753 44.1526 43.0682 43.9787 42.6089C43.8049 42.1496 43.718 41.659 43.718 41.1371C43.718 40.3577 43.8744 39.6687 44.1874 39.0703C44.5003 38.4718 44.935 38.0021 45.4913 37.6611C46.0546 37.3201 46.7083 37.1496 47.4524 37.1496C47.967 37.1496 48.4225 37.2192 48.8189 37.3584C49.2153 37.4976 49.5387 37.6472 49.7891 37.8072L49.1945 39.2373C49.0206 39.1051 48.7876 38.9694 48.4956 38.8302C48.2104 38.684 47.8801 38.611 47.5046 38.611C47.1151 38.611 46.75 38.7188 46.4093 38.9346C46.0755 39.1503 45.8042 39.4426 45.5956 39.8114C45.3939 40.1733 45.2931 40.5873 45.2931 41.0536C45.2931 41.5476 45.387 41.9826 45.5748 42.3584C45.7625 42.7272 46.0303 43.016 46.378 43.2247C46.7257 43.4335 47.1325 43.5379 47.5985 43.5379C48.0088 43.5379 48.36 43.4718 48.652 43.3396C48.9441 43.2073 49.1667 43.0682 49.3197 42.922L49.9455 44.279Z" fill="{fontColor}"></path>
        <path d="M51.826 37.2331H57V38.6945H55.1328V44.9158H53.6202V38.6945H51.826V37.2331Z" fill="{fontColor}"></path>
      </g>
      <defs>
        <filter id="filter0_d" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IconBrandDirect;
</iconbranddirectprops>