/* eslint-disable import/prefer-default-export */
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import AppContext from '../context/AppContext';
import { CurrentUser } from '../types/sharetribe/currentUser';
import { useEnabledCustomerExperiences } from './useEnabledCustomerExperiences';

type RestrictedPage =
  | 'ManagePurchasesPage'
  | 'ManageSalesPage'
  | 'ManageClosetPage'
  | 'ManageListingsPage'
  | 'ManageTradeInsPage'
  | 'ProfileSettingsPage'
  | 'NotificationSettingsPage';
// TODO (mk|TREET-2661) Refactor out "isAdmin" flag
export const useCurrentUserPermissions = (): {
  isAdmin: boolean;
  isBrand: boolean;
  isAnyBrand: boolean;
  isTreet: boolean;
  isAccounting: boolean;
  isTradeInAdmin: boolean;
  isLoggedInAsLimitedUser: boolean;
  canOwnTradeIns: boolean;
  canOwnListingsForSale: boolean;
  canViewPage: { [key in RestrictedPage]: boolean };
} => {
  const currentUser = useSelector<any>((state) => state.user.currentUser) as
    | CurrentUser
    | undefined;
  const { authScopes, isAuthenticated } = useSelector<any>((state) => state.Auth) as any;
  const { canonicalRootUrl } = useContext(AppContext);
  const {
    isAdmin = false,
    isTreet = false,
    isBrand = false,
    isAccounting = false,
    isTradeInAdmin: isTradeInAdminOverride = false,
  } = currentUser?.attributes?.profile?.publicData || {};
  const { shopUrl = '' } =
    currentUser?.attributes?.profile?.protectedData.accountCreatedFromShopInfo || {};
  const isBrandForCurrentShop = (shopUrl === canonicalRootUrl && isBrand) || isTreet;
  const isAccountingForCurrentShop = (shopUrl === canonicalRootUrl && isAccounting) || isTreet;
  const {
    allowTradeIn,
    allowMarketplace,
    allowTradeInAndMarketplace,
    allowBuy,
    isBrandDirectOnly,
    isListTradeInOnly,
    allowSell,
    hasBuyHistory,
  } = useEnabledCustomerExperiences();
  // Use isTradeInAdminOverride for the admin account of a shop that has trade-in enabled for
  // only a subset of "supersellers," since the allowTradeIn experience is not enabled site-wide.
  // e.g. tenable6.
  const isTradeInAdmin = (allowTradeIn || isTradeInAdminOverride) && isBrandForCurrentShop;
  const canOwnTradeIns = allowTradeIn && !isBrandForCurrentShop;
  const canOwnListingsForSale =
    allowMarketplace ||
    (isBrandDirectOnly && isBrandForCurrentShop) ||
    (isTradeInAdmin && allowBuy);

  const canViewClosetPage = allowTradeInAndMarketplace;

  const isLoggedInAsLimitedUser =
    currentUser?.id &&
    isAuthenticated &&
    authScopes &&
    authScopes.length === 1 &&
    authScopes[0] === 'user:limited';

  return {
    isAdmin,
    isAnyBrand: isBrand, // is brand for any shop
    isBrand: isBrandForCurrentShop,
    isTreet,
    isAccounting: isAccountingForCurrentShop,
    isTradeInAdmin,
    isLoggedInAsLimitedUser,
    canOwnTradeIns,
    canOwnListingsForSale,
    canViewPage: {
      ManagePurchasesPage: hasBuyHistory || isTradeInAdmin,
      ManageSalesPage: canOwnTradeIns || canOwnListingsForSale,
      ManageClosetPage: allowTradeInAndMarketplace,
      ManageListingsPage: canOwnListingsForSale && !canViewClosetPage,
      ManageTradeInsPage: isListTradeInOnly && canOwnTradeIns,
      ProfileSettingsPage: allowSell,
      NotificationSettingsPage: allowBuy,
    },
  };
};
</any></any>