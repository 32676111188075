import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { IconArrowLeft, NamedLink, TypographyWrapper } from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import { Participant } from '../../types/apollo/generated/types.generated';
import { BundleInfo } from '../../types/models/bundle';
import { getFormattedDate } from '../../util/dates';
import css from './ManageBundlesContainer.module.css';
import ThreeDotsIconButton from './ThreeDotsIconButton';

interface ManageBundlesTopbarProps {
  bundle: BundleInfo;
  rootPageName: string;
  participant: Participant;
  onCancelClick?: (bundle: BundleInfo) => void;
}

const ManageBundlesTopbar: FC<managebundlestopbarprops> = (props: ManageBundlesTopbarProps) => {
  const { bundle, rootPageName, participant, onCancelClick } = props;
  const { css: brandCss } = useShopConfig();

  return (
    <box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" className="{css.topBar}" bgcolor="{brandCss?.backgroundColor" ||="" 'white'}="" p="{1}">
      <namedlink name="{rootPageName}">
        <iconarrowleft className="{css.backIcon}"></iconarrowleft>
      </namedlink>
      <h3>
        <typographywrapper variant="h1" typographyOverrides="{{" style:="" {="" fontWeight:="" 'bold'="" }="" }}="">
          {getFormattedDate(bundle.createdAt)}
        </typographywrapper>
      </h3>
      <threedotsiconbutton participant="{participant}" bundle="{bundle}" onCancelClick="{onCancelClick}"></threedotsiconbutton>
    </box>
  );
};
export default ManageBundlesTopbar;
</managebundlestopbarprops>