import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Builder } from '@builder.io/react';
import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useIsShopSideLaunched } from '../../hooks/useIsShopSideLaunched';
import ListingItemsCarousel, {
  MIN_CAROUSEL_LISTINGS,
} from '../ListingItemsCarousel/ListingItemsCarousel';
import {
  fetchRecentListings,
  RecentArrivalsCarouselState,
} from '../../ducks/recentArrivalsCarousel.duck';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import { trackClickListingCard, TrackListingCardParams } from '../../util/heap';
import { parse } from '../../util/urlHelpers';

const RecentArrivalsCarousel: FC = () => {
  const dispatch = useDispatch();
  const { recentListings } = useSelector<any>(
    (state) => state.recentArrivalsCarousel
  ) as RecentArrivalsCarouselState;
  const isShopSideLaunched = useIsShopSideLaunched();
  const location = useLocation();

  const searchParams = parse(location.search);

  const trackListingCardHeapEvent = (params: TrackListingCardParams) => {
    trackClickListingCard({
      ...params,
      originOfClick: 'RecentArrivalsListingsItemCarousel',
    });
  };

  let referrerLocation = '';
  const pathName = typeof window !== 'undefined' ? window?.location?.pathname : '';

  if (pathName === '/' && searchParams?.keywords) {
    referrerLocation = 'Search Page No Results';
  } else if (pathName === '/') {
    referrerLocation = 'Search Page';
  } else if (pathName === '/about/buyer') {
    referrerLocation = 'Buyer Info Page';
  }

  useEffect(() => {
    dispatch(fetchRecentListings());
  }, []);

  if (!isShopSideLaunched || recentListings.length < MIN_CAROUSEL_LISTINGS) return null;

  return (
    <>
      <box mb="{3}">
        <typographywrapper variant="h1" typographyOverrides="{{" style:="" {="" textAlign:="" 'center'="" }="" }}="">
          Recent Arrivals
        </typographywrapper>
      </box>
      <listingitemscarousel trackClickListingCardHeapEvent="{trackListingCardHeapEvent}" listings="{recentListings}" referrerLocation="{referrerLocation}"></listingitemscarousel>
    </>
  );
};

Builder.registerComponent(RecentArrivalsCarousel, {
  name: 'RecentArrivalsCarousel',
});

export default RecentArrivalsCarousel;
</any>