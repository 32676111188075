import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Divider, IconSecure, LineItemBreakdown, TypographyWrapper } from '../../components';
import CheckoutError from '../../containers/CheckoutPage/CheckoutError';
import { CheckoutPageState } from '../../containers/CheckoutPage/CheckoutPage.duck';
import DiscountCodeInput from '../../containers/CheckoutPage/DiscountCodeInput';
import { defaultTreetStyles } from '../../shopConfig/config';
import { BundleType, Participant } from '../../types/apollo/generated/types.generated';
import { CheckoutLineItem } from '../../types/models/lineItem';
import PaymentFields from './PaymentFields';
import ShippingAddressFields from './ShippingAddressFields';
import css from './StripePaymentFormMobile.module.css';
import { CountryCodeAndName } from '../../util/countryCodes';
import StripeExpressCheckoutSection from './StripeExpressCheckoutSection';
import IncompleteAddressErrorMessage from './IncompleteAddressErrorMessage';
import { useShopConfig } from '../../hooks/shopConfig';

interface StripePaymentFormMobileProps {
  defaultPaymentMethod: any;
  error?: string;
  form: any;
  handleChangePaymentMethod: (paymentMethod: string) => void;
  handleShippingCountryChange: (value: string) => void;
  handleStripeElementRef: (el: any) => void;
  allowedShipToCountryCodes: CountryCodeAndName[];
  isCardValueValid: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  lineItems: CheckoutLineItem[];
  selectedPaymentMethod: string;
  shouldEnableSeel: boolean;
  isMultiSellerCheckout: boolean;
  isStripeExpressCheckoutEnabled: boolean;
  handleExpressCheckoutButtonClick: () => void;
  onConfirm: () => void;
  onShippingAddressChange: () => void;
  shouldDisplayIncompleteAddressErrorMessage: boolean;
}

const StripePaymentFormMobile: FC<stripepaymentformmobileprops> = (
  props: StripePaymentFormMobileProps
) => {
  const {
    defaultPaymentMethod,
    error,
    form,
    handleChangePaymentMethod,
    handleShippingCountryChange,
    handleStripeElementRef,
    allowedShipToCountryCodes,
    isCardValueValid,
    isLoading,
    isSubmitting,
    lineItems,
    selectedPaymentMethod,
    shouldEnableSeel,
    isMultiSellerCheckout,
    isStripeExpressCheckoutEnabled,
    handleExpressCheckoutButtonClick,
    onConfirm,
    onShippingAddressChange,
    shouldDisplayIncompleteAddressErrorMessage,
  } = props;

  const { shouldDisableDiscounts } = useShopConfig();

  const { handleCardChargeError } = useSelector<any>((state) => state.stripe) as any;
  const {
    finishCheckoutError,
    initiateCheckoutError,
    submitCheckoutError,
    isExpressCheckoutError,
  } = useSelector<any>((state) => state.CheckoutPage) as CheckoutPageState;

  const hasError =
    handleCardChargeError || finishCheckoutError || initiateCheckoutError || submitCheckoutError;

  return (
    <>
      <div className="{css.shipping}">
        {!isMultiSellerCheckout && (
          <stripeexpresscheckoutsection isStripeExpressCheckoutEnabled="{isStripeExpressCheckoutEnabled}" handleExpressCheckoutButtonClick="{handleExpressCheckoutButtonClick}" onConfirm="{onConfirm}" onShippingAddressChange="{onShippingAddressChange}" isMultiSellerCheckout="{false}"></stripeexpresscheckoutsection>
        )}

        <typographywrapper variant="h2">Ship To</typographywrapper>
        {shouldDisplayIncompleteAddressErrorMessage && <incompleteaddresserrormessage></incompleteaddresserrormessage>}
        <shippingaddressfields form="{form}" allowedShipToCountryCodes="{allowedShipToCountryCodes}" handleShippingCountryChange="{handleShippingCountryChange}"></shippingaddressfields>
        {isMultiSellerCheckout && (
          <stripeexpresscheckoutsection isStripeExpressCheckoutEnabled="{isStripeExpressCheckoutEnabled}" handleExpressCheckoutButtonClick="{handleExpressCheckoutButtonClick}" onConfirm="{onConfirm}" onShippingAddressChange="{onShippingAddressChange}" isMultiSellerCheckout=""></stripeexpresscheckoutsection>
        )}
      </div>
      {shouldEnableSeel && (
        <box mb="{1}" px="{2}">
          <typographywrapper variant="body1">
            <div id="seel-ra-widget-root"></div>
          </typographywrapper>
        </box>
      )}
      <div className="{css.payment}">
        <box display="flex" flexDirection="row" alignItems="center">
          <typographywrapper variant="h2" typographyOverrides="{{" style:="" {="" lineHeight:="" 1,="" marginRight:="" '6px'="" }="" }}="">
            Checkout With Card
          </typographywrapper>
          <box mb="{1}">
            <iconsecure width="22" height="22"></iconsecure>
          </box>
        </box>
        <paymentfields defaultPaymentMethod="{defaultPaymentMethod}" error="{error}" handleChangePaymentMethod="{handleChangePaymentMethod}" handleStripeElementRef="{handleStripeElementRef}" isCardValueValid="{isCardValueValid}" isLoading="{isLoading}" isSubmitting="{isSubmitting}" selectedPaymentMethod="{selectedPaymentMethod}"></paymentfields>
      </div>
      <divider className="{css.divider}" color="{defaultTreetStyles.gray20}"></divider>
      {!shouldDisableDiscounts && (
        <>
          <discountcodeinput></discountcodeinput>
          <divider className="{css.divider}"></divider>
        </>
      )}
      <lineitembreakdown lineItems="{lineItems}" participant="{Participant.Buyer}" bundleType="{BundleType.Marketplace}" shouldCombineItemsAndShipping=""></lineitembreakdown>
      <divider className="{css.divider}"></divider>
      {hasError && !isExpressCheckoutError && (
        <div className="{css.error}">
          <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.red80="" }="" }}="">
            <checkouterror></checkouterror>
          </typographywrapper>
        </div>
      )}
    </>
  );
};

export default StripePaymentFormMobile;
</any></any></stripepaymentformmobileprops>