import React, { FC } from 'react';
import { useShopConfig } from '../../../hooks/shopConfig';

interface IconPoweredByTreetProps {
  className?: string;
  color?: string;
}

const IconPoweredByTreet: FC<iconpoweredbytreetprops> = (props: IconPoweredByTreetProps) => {
  const { className, color } = props;

  const { styles } = useShopConfig();
  const iconColor = color || styles.gray60;

  return (
    <svg className="{className}" width="98" height="11" viewBox="0 0 98 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M65.8109 6.72316C65.8109 8.36296 66.8604 9.42883 68.3526 9.42883C68.5986 9.42883 68.8117 9.39603 68.9921 9.34684C69.0905 9.33044 69.1725 9.29764 69.2545 9.26485V7.62505L69.1233 7.67425C69.0413 7.69064 68.9429 7.70704 68.7625 7.70704C68.1066 7.70704 67.6967 7.29709 67.6967 6.64117V3.52556H69.3365V1.80378H67.6967V0H66.0569V1.06587C66.0569 1.67259 65.7617 1.96776 65.155 1.96776H64.663V3.52556H65.8109V6.72316Z" fill="{iconColor}"></path>
      <path d="M70.8898 9.34684H72.7756V5.32934C72.7756 4.34546 73.4151 3.68954 74.3334 3.68954C74.5301 3.68954 74.6941 3.72234 74.8089 3.73874L74.9893 3.77153V1.80378L74.8253 1.77098C74.7105 1.75458 74.5957 1.72179 74.4153 1.72179C73.8414 1.72179 73.3987 1.93496 73.0871 2.18093C72.9067 2.31211 72.7428 2.45969 72.6116 2.62367L72.2836 1.80378H70.8898V9.34684Z" fill="{iconColor}"></path>
      <path d="M80.9777 7.05112C80.8957 7.16591 80.781 7.2643 80.6498 7.34629C80.4038 7.49387 80.0922 7.62505 79.5839 7.62505C78.6 7.62505 78.0425 7.05112 77.8621 6.31322H83.1095L83.1423 6.06725C83.1587 5.91966 83.1915 5.75568 83.1915 5.57531C83.1915 3.27959 81.5517 1.72179 79.5839 1.72179C77.5506 1.72179 75.8124 3.44357 75.8124 5.57531C75.8124 7.70704 77.5506 9.42883 79.5839 9.42883C81.2893 9.42883 82.5519 8.18258 82.9455 7.05112H80.9777ZM77.7801 5.00138C77.9605 4.16508 78.6 3.52556 79.5839 3.52556C80.5678 3.52556 81.2073 4.16508 81.3877 5.00138H77.7801Z" fill="{iconColor}"></path>
      <path d="M89.3368 7.05112C89.2549 7.16591 89.1401 7.2643 89.0089 7.34629C88.7629 7.49387 88.4514 7.62505 87.943 7.62505C86.9591 7.62505 86.4016 7.05112 86.2212 6.31322H91.4686L91.5014 6.06725C91.5178 5.91966 91.5506 5.75568 91.5506 5.57531C91.5506 3.27959 89.9108 1.72179 87.943 1.72179C85.9097 1.72179 84.1715 3.44357 84.1715 5.57531C84.1715 7.70704 85.9097 9.42883 87.943 9.42883C89.6484 9.42883 90.9111 8.18258 91.3046 7.05112H89.3368ZM86.1392 5.00138C86.3196 4.16508 86.9591 3.52556 87.943 3.52556C88.9269 3.52556 89.5664 4.16508 89.7468 5.00138H86.1392Z" fill="{iconColor}"></path>
      <path d="M93.5145 6.72316C93.5145 8.36296 94.564 9.42883 96.0562 9.42883C96.3021 9.42883 96.5153 9.39603 96.6957 9.34684C96.7941 9.33044 96.8761 9.29764 96.9581 9.26485V7.62505L96.8269 7.67425C96.7449 7.69064 96.6465 7.70704 96.4661 7.70704C95.8102 7.70704 95.4002 7.29709 95.4002 6.64117V3.52556H97.04V1.80378H95.4002V0H93.7605V1.06587C93.7605 1.67259 93.4653 1.96776 92.8586 1.96776H92.3666V3.52556H93.5145V6.72316Z" fill="{iconColor}"></path>
      <path d="M64.5492 4.67798C62.9141 4.67798 61.5885 6.00355 61.5885 7.63872L61.7024 7.63872C63.3375 7.63872 64.6631 6.31315 64.6631 4.67798L64.5492 4.67798Z" fill="{iconColor}"></path>
      <path d="M63.5032 3.16751C62.4961 2.65069 61.2606 3.04821 60.7438 4.0554L60.8451 4.10738C61.8523 4.6242 63.0877 4.22668 63.6046 3.2195L63.5032 3.16751Z" fill="{iconColor}"></path>
      <path d="M63.5878 1.92439C63.6052 1.00978 62.8778 0.254264 61.9632 0.2369L61.9615 0.328902C61.9441 1.24351 62.6715 1.99903 63.5861 2.01639L63.5878 1.92439Z" fill="{iconColor}"></path>
      <path d="M0 10.9292H1.42465V8.45152C1.53614 8.56301 1.67241 8.64973 1.82107 8.73645C2.08122 8.87272 2.42809 9.00899 2.91124 9.00899C4.33588 9.00899 5.57471 7.78255 5.57471 6.09775C5.57471 4.41295 4.33588 3.18652 2.91124 3.18652C2.41571 3.18652 2.01928 3.34756 1.73435 3.49622C1.57331 3.58294 1.42465 3.69443 1.30077 3.80593L1.053 3.24846H0V10.9292ZM2.72541 7.64628C1.92018 7.64628 1.30077 7.03926 1.30077 6.09775C1.30077 5.15625 1.92018 4.54922 2.72541 4.54922C3.53065 4.54922 4.15006 5.15625 4.15006 6.09775C4.15006 7.03926 3.53065 7.64628 2.72541 7.64628Z" fill="{iconColor}"></path>
      <path d="M9.16923 4.54922C9.97447 4.54922 10.5939 5.15625 10.5939 6.09775C10.5939 7.03926 9.97447 7.64628 9.16923 7.64628C8.364 7.64628 7.74458 7.03926 7.74458 6.09775C7.74458 5.15625 8.364 4.54922 9.16923 4.54922ZM9.16923 3.18652C7.63309 3.18652 6.31994 4.48728 6.31994 6.09775C6.31994 7.70822 7.63309 9.00899 9.16923 9.00899C10.7054 9.00899 12.0185 7.70822 12.0185 6.09775C12.0185 4.48728 10.7054 3.18652 9.16923 3.18652Z" fill="{iconColor}"></path>
      <path d="M14.3045 8.94705H15.5434L16.5964 5.54028L17.6494 8.94705H18.8882L20.7464 3.24846H19.3218L18.2068 6.90299L17.03 3.24846H16.1628L14.9859 6.90299L13.871 3.24846H12.4463L14.3045 8.94705Z" fill="{iconColor}"></path>
      <path d="M25.0784 7.21269C25.0165 7.29941 24.9298 7.37374 24.8307 7.43568C24.6448 7.54718 24.4095 7.64628 24.0254 7.64628C23.2821 7.64628 22.8609 7.21269 22.7247 6.65522H26.6889L26.7137 6.4694C26.7261 6.3579 26.7508 6.23402 26.7508 6.09775C26.7508 4.3634 25.512 3.18652 24.0254 3.18652C22.4893 3.18652 21.1761 4.48728 21.1761 6.09775C21.1761 7.70822 22.4893 9.00899 24.0254 9.00899C25.3138 9.00899 26.2677 8.06748 26.565 7.21269H25.0784ZM22.6627 5.66416C22.799 5.03236 23.2821 4.54922 24.0254 4.54922C24.7687 4.54922 25.2519 5.03236 25.3881 5.66416H22.6627Z" fill="{iconColor}"></path>
      <path d="M27.8009 8.94705H29.2256V5.91193C29.2256 5.16863 29.7087 4.6731 30.4025 4.6731C30.5511 4.6731 30.675 4.69788 30.7617 4.71027L30.898 4.73505V3.24846L30.7741 3.22368C30.6874 3.21129 30.6007 3.18652 30.4644 3.18652C30.0308 3.18652 29.6963 3.34756 29.461 3.53339C29.3247 3.63249 29.2008 3.74399 29.1017 3.86787L28.8539 3.24846H27.8009V8.94705Z" fill="{iconColor}"></path>
      <path d="M35.4221 7.21269C35.3602 7.29941 35.2735 7.37374 35.1744 7.43568C34.9885 7.54718 34.7532 7.64628 34.3691 7.64628C33.6258 7.64628 33.2046 7.21269 33.0684 6.65522H37.0326L37.0574 6.4694C37.0698 6.3579 37.0945 6.23402 37.0945 6.09775C37.0945 4.3634 35.8557 3.18652 34.3691 3.18652C32.833 3.18652 31.5198 4.48728 31.5198 6.09775C31.5198 7.70822 32.833 9.00899 34.3691 9.00899C35.6575 9.00899 36.6114 8.06748 36.9087 7.21269H35.4221ZM33.0064 5.66416C33.1427 5.03236 33.6258 4.54922 34.3691 4.54922C35.1124 4.54922 35.5956 5.03236 35.7318 5.66416H33.0064Z" fill="{iconColor}"></path>
      <path d="M42.3566 8.94705H43.4096V1.14246H41.985V3.74399C41.8735 3.64488 41.7372 3.55816 41.5886 3.47145C41.3284 3.33517 40.9568 3.18652 40.4984 3.18652C39.0738 3.18652 37.8349 4.41295 37.8349 6.09775C37.8349 7.78255 39.0738 9.00899 40.4984 9.00899C40.9939 9.00899 41.3904 8.86033 41.6753 8.69928C41.8363 8.61256 41.9726 8.50107 42.1089 8.38958L42.3566 8.94705ZM40.6842 7.64628C39.879 7.64628 39.2596 7.03926 39.2596 6.09775C39.2596 5.15625 39.879 4.54922 40.6842 4.54922C41.4895 4.54922 42.1089 5.15625 42.1089 6.09775C42.1089 7.03926 41.4895 7.64628 40.6842 7.64628Z" fill="{iconColor}"></path>
      <path d="M47.5568 8.94705H48.6098L48.8576 8.38958C48.9814 8.50107 49.1301 8.61256 49.2912 8.69928C49.5761 8.86033 49.9725 9.00899 50.468 9.00899C51.8927 9.00899 53.1315 7.78255 53.1315 6.09775C53.1315 4.41295 51.8927 3.18652 50.468 3.18652C49.9849 3.18652 49.638 3.33517 49.3779 3.47145C49.2292 3.55816 49.0929 3.64488 48.9814 3.74399V1.14246H47.5568V8.94705ZM50.2822 7.64628C49.477 7.64628 48.8576 7.03926 48.8576 6.09775C48.8576 5.15625 49.477 4.54922 50.2822 4.54922C51.0874 4.54922 51.7069 5.15625 51.7069 6.09775C51.7069 7.03926 51.0874 7.64628 50.2822 7.64628Z" fill="{iconColor}"></path>
      <path d="M54.4962 10.9292L54.6448 10.9663C54.7315 10.9787 54.8554 10.9911 54.9917 10.9911C55.9208 10.9911 56.4163 10.4832 56.9738 9.13287C57.0481 8.9966 57.6056 7.51001 58.1631 6.07297L59.2656 3.24846H57.779L56.4783 6.96493L55.0536 3.24846H53.567L55.735 8.69928L55.6111 9.00899C55.4253 9.44258 55.1279 9.75228 54.4962 9.75228V10.9292Z" fill="{iconColor}"></path>
    </svg>
  );
};

export default IconPoweredByTreet;
</iconpoweredbytreetprops>