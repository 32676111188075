import React, { FC, useContext, useEffect, useState } from 'react';
import { withTypes } from 'react-final-form';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import DialogWithCloseButton from '../DialogWithCloseButton/DialogWithCloseButton';
import { Form } from '..';
import ListingFeedbackCategoryPane from './ListingFeedbackCategoryPane';
import ListingFeedbackFreeformPane from './ListingFeedbackFreeformPane';
import ListingFeedbackEmailPane from './ListingFeedbackEmailPane';
import ListingFeedbackSuccessPane from './ListingFeedbackSuccessPane';
import ListingFeedbackFooter from './ListingFeedbackFooter';
import css from './ListingFeedbackModal.module.css';
import { ListingWithAuthor } from '../../types/sharetribe/listing';
import { ListingFeedbackCategory, ListingFeedbackFormValues } from './ListingFeedback.utils';
import { useCreateListingFeedback } from '../../types/apollo/generated/types.generated';
import { handle } from '../../util/helpers';
import * as heap from '../../util/heap';
import AppContext from '../../context/AppContext';
import { deleteSuppression } from '../../ducks/user.duck';
import { LISTING_FEEDBACK_BUYER_UPDATES_GROUP_ID } from '../../util/sendgrid';
import { useAppDispatch } from '../../hooks/appDispatch';

export enum Pane {
  Category = 'CATEGORY',
  Freeform = 'FREEFORM',
  Email = 'EMAIL',
  Success = 'SUCCESS',
}

interface ListingFeedbackModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
  listing: ListingWithAuthor;
}

const { Form: FinalForm } = withTypes<listingfeedbackformvalues>();

const ListingFeedbackModal: FC<listingfeedbackmodalprops> = (props) => {
  const { isOpen, handleClose, handleSuccess, listing } = props;

  const { currentUser } = useSelector<any>((state) => state.user) as any;
  const [activePane, setActivePane] = useState(Pane.Category);
  const [createListingFeedback] = useCreateListingFeedback();
  const { treetId } = useContext(AppContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen) {
      heap.trackShowListingFeedbackModal(treetId);
    }
  }, [isOpen]);

  let body: React.ReactNode;
  switch (activePane) {
    case Pane.Email:
      body = <listingfeedbackemailpane></listingfeedbackemailpane>;
      break;
    case Pane.Freeform:
      body = <listingfeedbackfreeformpane></listingfeedbackfreeformpane>;
      break;
    case Pane.Success:
      body = <listingfeedbacksuccesspane listing="{listing}"></listingfeedbacksuccesspane>;
      break;
    case Pane.Category:
    default:
      body = <listingfeedbackcategorypane></listingfeedbackcategorypane>;
  }

  const currentUserEmail = currentUser?.attributes?.email;
  const isLoggedIn = !!currentUserEmail;

  // Filter out irrelevant panes
  const panesToShow = Object.values(Pane).filter((pane) => {
    if (isLoggedIn && pane === Pane.Email) return false;
    return true;
  });

  const currentPaneIndex = panesToShow.findIndex((pane) => pane === activePane);

  const onSubmit = async (values: ListingFeedbackFormValues) => {
    const { email, category, note } = values;

    if (activePane === Pane.Success) {
      handleSuccess();
      setActivePane(Pane.Category);
      return;
    }

    const feedbackAuthorEmail = currentUserEmail || email;
    const isLoggedInResponseComplete =
      isLoggedIn && currentUserEmail && activePane === Pane.Freeform;
    const isLoggedOutResponseComplete = !isLoggedIn && email && activePane === Pane.Email;
    const isCompleteSubmission = isLoggedInResponseComplete || isLoggedOutResponseComplete;

    if (isCompleteSubmission) {
      await handle(
        createListingFeedback({
          variables: {
            input: {
              email: feedbackAuthorEmail,
              sharetribeListingId: listing.id.uuid,
              category,
              note,
              sharetribeUserId: currentUser?.id.uuid,
            },
          },
        })
      );

      await dispatch(
        deleteSuppression({
          email: feedbackAuthorEmail,
          groupId: LISTING_FEEDBACK_BUYER_UPDATES_GROUP_ID,
        })
      );
    }

    heap.trackListingFeedbackProgress({
      email: feedbackAuthorEmail,
      category: category as ListingFeedbackCategory,
      modalPane: activePane,
      treetId,
      hasFeedbackNote: !!note,
      isCompleteSubmission: !!isCompleteSubmission,
    });

    const nextPane = panesToShow[currentPaneIndex + 1];
    setActivePane(nextPane);
  };

  const onBackClick = () => {
    const previousPane = panesToShow[currentPaneIndex - 1];
    setActivePane(previousPane);
  };

  const showBackButton = ![Pane.Category, Pane.Success].includes(activePane);

  return (
    <dialogwithclosebutton open="{isOpen}" onClose="{()" ==""> {
        handleClose();
        setActivePane(Pane.Category);
      }}
      aria-labelledby="listing-feedback"
      aria-describedby="listing-feedback-modal"
      headerClassName={css.dialogHeader}
      fullWidth
      maxWidth="sm"
    >
      <box id="scrollableDiv" style="{{" overflowY:="" 'auto',="" height:="" '100%'="" }}="">
        <finalform onSubmit="{onSubmit}" render="{(formRenderProps)" ==""> {
            const { handleSubmit } = formRenderProps;

            return (
              <form id="ListingFeedbackModal" onSubmit="{handleSubmit}" className="{css.formWrapper}">
                {body}
                <listingfeedbackfooter pane="{activePane}" onBackClick="{showBackButton" ?="" :="" undefined}=""></listingfeedbackfooter>
              </form>
            );
          }}
        />
      </finalform></box>
    </dialogwithclosebutton>
  );
};

export default ListingFeedbackModal;
</any></listingfeedbackmodalprops></listingfeedbackformvalues>