import React, { FC } from 'react';
import { Box, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { Button } from '..';
import DialogWithCloseButton from '../DialogWithCloseButton/DialogWithCloseButton';
import { ButtonVariant } from '../Button/Button';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import { PHOTO_TIPS_TO_LABEL, PhotoTips } from '../../util/listings/listing';

interface PhotoTipsModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  photoTipOptions: any;
}

const PhotoTipsModal: FC<phototipsmodalprops> = (props) => {
  const { isOpen, onClose, title, photoTipOptions } = props;

  return (
    <dialogwithclosebutton scroll="paper" open="{isOpen}" onClose="{onClose}" titleText="{title}" fullWidth="">
      <dialogcontent dividers="">
        <dialogcontenttext>
          {Object.entries(photoTipOptions).map(([tip, description]) => (
            <box key="{tip}" mb="{2}">
              <typographywrapper variant="body1">
                <b>{PHOTO_TIPS_TO_LABEL[tip as PhotoTips]}</b>
              </typographywrapper>
              <typographywrapper variant="body1">{description as string}</typographywrapper>
            </box>
          ))}
        </dialogcontenttext>
      </dialogcontent>
      <dialogactions>
        <button onClick="{onClose}" variant="{ButtonVariant.Primary}">
          Got It
        </button>
      </dialogactions>
    </dialogwithclosebutton>
  );
};

export default PhotoTipsModal;
</phototipsmodalprops>