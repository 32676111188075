import React, { useContext } from 'react';
import MobileDetect from 'mobile-detect';
import { Box } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Carousel from 'react-multi-carousel';
import { arrayOf, string } from 'prop-types';
import { ListingCard, TypographyWrapper } from '..';
import AppContext from '../../context/AppContext';
import { propTypes } from '../../util/types';
import { useShopConfig } from '../../hooks/shopConfig';
import css from './ListingItemsCarousel.module.css';

export const MIN_CAROUSEL_LISTINGS = 4;
const MAX_CAROUSEL_LISTINGS = 15;

// based on https://github.com/YIZHUANG/react-multi-carousel/blob/master/examples/ssr/pages/index.js
const ListingItemsCarousel = ({
  listings,
  referrerLocation,
  title,
  trackClickListingCardHeapEvent,
}) => {
  const { css: shopCss } = useShopConfig();
  const { userAgent } = useContext(AppContext);
  // logic based on https://github.com/YIZHUANG/react-multi-carousel/blob/master/examples/ssr/pages/index.js
  const md = new MobileDetect(userAgent);
  let deviceType;
  if (md.tablet()) {
    deviceType = 'tablet';
  } else if (md.mobile()) {
    deviceType = 'mobile';
  } else {
    deviceType = 'desktop';
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 550 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  if (!listings || listings.length < MIN_CAROUSEL_LISTINGS) {
    return null;
  }

  const listingCardStyle = shopCss?.listingCardStyle || {};

  return (
    <div className="{css.root}">
      {title && (
        <box pb="{6}">
          <typographywrapper variant="h1">{title}</typographywrapper>
        </box>
      )}
      <div className="{css.listingsContainer}">
        <carousel responsive="{responsive}" ssr="" containerClass="container-with-dots" itemClass="image-item" deviceType="{deviceType}" removeArrowOnDeviceType="{['mobile']}" customLeftArrow="{<ChevronLeftIcon" className="{css.leftIcon}"></carousel>}
          customRightArrow={<chevronrighticon className="{css.rightIcon}"></chevronrighticon>}
        >
          {listings.slice(0, MAX_CAROUSEL_LISTINGS).map((l) => (
            <listingcard key="{l.id.uuid}" listing="{l}" referrerLocation="{referrerLocation}" trackClickListingCardHeapEvent="{trackClickListingCardHeapEvent}" style="{{" padding:="" '0="" 4px',="" ...listingCardStyle="" }}=""></listingcard>
          ))}
        
      </div>
    </div>
  );
};

ListingItemsCarousel.defaultProps = {
  title: '',
};

ListingItemsCarousel.propTypes = {
  listings: arrayOf(propTypes.listing).isRequired,
  referrerLocation: string.isRequired,
  title: string,
};

export default ListingItemsCarousel;
