import { Box, Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TopbarContainer } from '..';
import {
  Button,
  Error,
  Footer,
  IconArrowRight,
  IconSpinner,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  ListingCardPreview,
  ManageListingCard,
  NamedLink,
  Page,
  UserNavbar,
} from '../../components';
import TypographyWrapper, {
  TypographyFormat,
} from '../../components/TypographyWrapper/TypographyWrapper';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { useIsMobile } from '../../hooks/useIsMobile';
import { defaultTreetStyles } from '../../shopConfig/config';
import { RequestStatus } from '../../types/requestStatus';
import { CurrentUser } from '../../types/sharetribe/currentUser';
import { ListingWithImages } from '../../types/sharetribe/listing';
import { LISTING_STATE_DRAFT } from '../../util/types';
import EmptyCloset from './Empty';
import SellOrTradeInInfoCards from './SellOrTradeInInfoCards';
import { getListingsFromMarketplaceData, ManageClosetPageState } from './ManageClosetPage.duck';
import css from './ManageClosetPage.module.css';
import { getDarkerBrandCssBackgroundColor } from '../../util/colors/colors';
import { useShopConfig } from '../../hooks/shopConfig';
import { CoreBundle, useAllBundlesLazyQuery } from '../../types/apollo/generated/types.generated';

const SectionBoxWrapper: FC<any> = (props: any) => {
  const { children, boxOverrides } = props;
  const isMobile = useIsMobile();

  return (
    <box 0="" mx="{isMobile" ?="" :="" 4}="" {...boxOverrides}="">
      {children}
    </box>
  );
};

const ListingsGridItem: FC = (props) => {
  const { children } = props;
  return (
    <grid item="" xs="{6}" sm="{6}" md="{6}" lg="{3}">
      {children}
    </grid>
  );
};

interface ListingsRowProps {
  listings: ListingWithImages[];
  bundles?: CoreBundle[];
}

const ListingCardRow: FC<listingsrowprops> = (props) => {
  const { listings, bundles } = props;
  const currentUser = useSelector<any>((state) => state.user.currentUser) as
    | CurrentUser
    | undefined;
  if (!listings) return null;

  const getMostRecentBundle = (listingId: string) =>
    bundles?.find((bundle) =>
      bundle.bundleItems.some((bundleItem) => bundleItem.listing.sharetribeListingId === listingId)
    );

  return (
    <grid container="" spacing="{3}">
      {listings.map((l: ListingWithImages) => (
        <listingsgriditem key="{l.id.uuid}">
          <managelistingcard key="{l.id.uuid}" listing="{l}" isMenuOpen="{false}" onToggleMenu="{()" ==""> {}}
            currentUser={currentUser}
            bundle={getMostRecentBundle(l.id.uuid)}
            hideMenu
          />
        </managelistingcard></listingsgriditem>
      ))}
    </grid>
  );
};

interface ContinueTradeInProps {
  listings: ListingWithImages[];
}

const ContinueTradeIn: FC<continuetradeinprops> = (props) => {
  const { listings } = props;
  const isMobile = useIsMobile();
  const { css: brandCss } = useShopConfig();
  const darkerBrandCssBackgroundColor = getDarkerBrandCssBackgroundColor(brandCss);

  const listingsToShow = listings?.slice(0, 20);

  return (
    <namedlink name="ManageTradeInsPage">
      <sectionboxwrapper boxOverrides="{{" className:="" css.banner,="" px:="" 3,="" py:="" 1,="" display:="" 'flex',="" flexDirection:="" 'column',="" style:="" {="" backgroundColor:="" darkerBrandCssBackgroundColor,="" border:="" `1px="" solid="" ${defaultTreetStyles.gray20}`,="" },="" }}="">
        <box display="flex" flexDirection="row" justifyContent="center">
          <box display="flex" flexDirection="row" className="{css.listingImages}" style="{{" position:="" 'relative'="" }}="">
            {listingsToShow.length > 1 && <div className="{css.listingPreviewGradient}"></div>}
            {listingsToShow.map((listing, index) => {
              const isLast = index === listingsToShow?.length - 1;
              return (
                <box 1="" {...(!isLast="" &&="" {="" mr:="" })}="">
                  <listingcardpreview listingImageClassName="{css.listingPreview}" listing="{listing}" allowListingRedirect="{false}"></listingcardpreview>
                </box>
              );
            })}
          </box>
          <box className="{css.submitButton}" display="flex" flexDirection="row" alignItems="center" ml="{2}">
            <typography variant="{isMobile" ?="" 'body1'="" :="" 'subtitle1'}="" style="{{" fontWeight:="" 'bold'="" }}="">
              Submit Trade-In
            </typography>
            <iconarrowright></iconarrowright>
          </box>
        </box>
      </sectionboxwrapper>
    </namedlink>
  );
};

interface SeeListingsProps {
  listings: ListingWithImages[];
  isDraft?: boolean;
  bundles?: CoreBundle[];
}

const SeeListings: FC<seelistingsprops> = (props) => {
  const { listings, isDraft, bundles } = props;
  const isMobile = useIsMobile();

  const maxNumToShow = isMobile ? 2 : 4;
  const listingsToShow = listings?.slice(0, maxNumToShow);
  const title = isDraft ? 'Drafts' : 'Listings For Sale';
  const hasMore = listings?.length > maxNumToShow;

  return (
    <sectionboxwrapper 3="" boxOverrides="{{" mt:="" }}="">
      <namedlink name="ManageListingsPage" {...(isDraft="" &&="" {="" to:="" search:="" `state="${LISTING_STATE_DRAFT}`" }="" })}="">
        <box mb="{1}" display="flex" flexDirection="row" alignItems="center">
          <typographywrapper variant="h1" format="{TypographyFormat.Underlined}">
            {title}
          </typographywrapper>
        </box>
      </namedlink>
      <listingcardrow listings="{listingsToShow}" bundles="{bundles}"></listingcardrow>
      {hasMore && (
        <box display="flex" justifyContent="center" alignItems="center" my="{1}">
          <namedlink name="ManageListingsPage" style="{{" textDecoration:="" 'none'="" }}="" {...(isDraft="" &&="" {="" to:="" search:="" `state="${LISTING_STATE_DRAFT}`" }="" })}="">
            <button>All {title}</button>
          </namedlink>
        </box>
      )}
    </sectionboxwrapper>
  );
};

export const ManageClosetPage: FC = () => {
  const scrollingDisabled = useSelector<any>((state) => isScrollingDisabled(state));
  const {
    listingResultIds,
    draftListingResultIds,
    tradeInListingResultIds,
    fetchListingsError,
    fetchDraftListingsError,
    fetchTradeInListingsError,
    fetchListingsStatus,
    fetchDraftListingsStatus,
    fetchTradeInListingsStatus,
  } = useSelector<any>((state) => state.ManageClosetPage) as ManageClosetPageState;
  const currentUser = useSelector<any>((state) => state.user.currentUser) as
    | CurrentUser
    | undefined;

  const rootState = useSelector<any>((state) => state) as any;
  const listings = getListingsFromMarketplaceData(rootState, listingResultIds);
  const draftListings = getListingsFromMarketplaceData(rootState, draftListingResultIds);
  const tradeInListings = getListingsFromMarketplaceData(rootState, tradeInListingResultIds);

  const [queryAllBundles, { loading: areBundlesLoading, data: bundlesResponse }] =
    useAllBundlesLazyQuery({
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    if (currentUser && !isEmpty(listingResultIds)) {
      queryAllBundles({
        variables: {
          userId: currentUser.id.uuid,
          sharetribeListingIds: listings.map((listing) => listing.id.uuid),
        },
      });
    }
  }, [currentUser, listingResultIds]);

  const hasError = fetchListingsError || fetchDraftListingsError || fetchTradeInListingsError;
  const isLoading =
    fetchListingsStatus === RequestStatus.Pending ||
    fetchDraftListingsStatus === RequestStatus.Pending ||
    fetchTradeInListingsStatus === RequestStatus.Pending ||
    areBundlesLoading;
  const isEmptyState = isEmpty(tradeInListings) && isEmpty(draftListings) && isEmpty(listings);

  return (
    <page title="Manage Closet" scrollingDisabled="{scrollingDisabled}">
      <layoutsinglecolumn>
        <layoutwrappertopbar>
          <topbarcontainer currentPage="ManageClosetPage"></topbarcontainer>
          <usernavbar selectedPageName="ManageClosetPage"></usernavbar>
        </layoutwrappertopbar>
        <layoutwrappermain>
          <div className="{css.listingPanel}">
            <box display="flex" flexDirection="column" className="{css.title}" pb="{4}">
              {hasError && (
                <box my="{3}">
                  <error>Error querying listings. Please try again.</error>
                </box>
              )}
              {isLoading && (
                <box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex="{1}">
                  <iconspinner></iconspinner>
                </box>
              )}
              {!isLoading && !hasError && (
                <>
                  {!isEmpty(tradeInListings) && <continuetradein listings="{tradeInListings}"></continuetradein>}
                  <sellortradeininfocards hasActiveTradeIn="{!isEmpty(tradeInListings)}"></sellortradeininfocards>
                  {!isEmpty(draftListings) && (
                    <seelistings listings="{draftListings}" bundles="{bundlesResponse?.bundles}" isDraft=""></seelistings>
                  )}
                  {!isEmpty(listings) && (
                    <seelistings listings="{listings}" bundles="{bundlesResponse?.bundles}"></seelistings>
                  )}
                  {isEmptyState && <emptycloset></emptycloset>}
                </>
              )}
            </box>
          </div>
        </layoutwrappermain>
        <layoutwrapperfooter>
          <footer></footer>
        </layoutwrapperfooter>
      </layoutsinglecolumn>
    </page>
  );
};

export default ManageClosetPage;
</any></any></any></any></seelistingsprops></continuetradeinprops></any></listingsrowprops></any>