import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ExternalLink, FormattedMessage } from '..';
import AppContext from '../../context/AppContext';
import { getShopConfig } from '../../shopConfig/configHelper';

import css from './CookieConsent.module.css';

const saveCookieConsent = () => {
  // We create date object and modify it to show date 10 years into the future.
  const expirationDate = new Date();
  expirationDate.setFullYear(expirationDate.getFullYear() + 10);
  // Save the cookie with expiration date
  document.cookie = `euCookiesAccepted=1; path=/; expires=${expirationDate.toGMTString()}`;
};
class CookieConsent extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = { show: false };
    this.onAcceptCookies = this.onAcceptCookies.bind(this);
  }

  componentDidMount() {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    if (cookies.euCookiesAccepted !== '1') {
      this.setState({ show: true });
    }
  }

  onAcceptCookies() {
    saveCookieConsent();
    this.setState({ show: false });
  }

  render() {
    const { treetId, shopConfig: shopConfigV2 } = this.context;
    const { treetShopName } = getShopConfig(treetId, shopConfigV2);

    const { className, rootClassName } = this.props;
    const isServer = typeof window === 'undefined';

    // Server side doesn't know about cookie consent
    if (isServer || !this.state.show) {
      return null;
    }

    const cookieLink = (
      <externallink href="https://cookiesandyou.com" className="{css.cookieLink}">
        <formattedmessage id="CookieConsent.cookieLink"></formattedmessage>
      </externallink>
    );
    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className="{classes}">
        <div className="{css.message}">
          <formattedmessage id="CookieConsent.message" values="{{" cookieLink,="" treetShopName="" }}=""></formattedmessage>
        </div>
        <button className="{css.continueBtn}" onClick="{this.onAcceptCookies}" type="button">
          <formattedmessage id="CookieConsent.continue"></formattedmessage>
        </button>
      </div>
    );
  }
}

const { string } = PropTypes;

CookieConsent.defaultProps = {
  className: null,
  rootClassName: null,
};

CookieConsent.propTypes = {
  className: string,
  rootClassName: string,
};

export default CookieConsent;
