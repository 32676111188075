import React from 'react';
import { isEmpty } from 'lodash';
import { string } from 'prop-types';
import classNames from 'classnames';
import { Box } from '@material-ui/core';
import { injectIntl, intlShape } from '../../util/reactIntl';
import {
  BuilderSection,
  ExternalLink,
  FormattedMessage,
  IconSocialMedia,
  Logo,
  NamedLink,
  TextWithLineBreaks,
  TypographyWrapper,
} from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import { defaultTreetStyles } from '../../shopConfig/config';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useStyleType } from '../../hooks/useStyleType';
import { AboutPageTab } from '../../containers/AboutPage/aboutPageUtils';
import { IconSocialMediaType } from '../Icons/IconSocialMedia/IconSocialMedia';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useActionCopy } from '../../hooks/useActionCopy';
import css from './Footer.module.css';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Feature } from '../../util/featureFlags';
import { BuilderSections } from '../../util/builder';

const renderSocialMediaLinks = (intl) => {
  const { socialMediaConfig } = useShopConfig();
  const { instagramLink, facebookLink, pinterestLink, tiktokLink } = socialMediaConfig || {};

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTiktok = intl.formatMessage({ id: 'Footer.goToTiktok' });
  const goToPinterest = intl.formatMessage({ id: 'Footer.goToPinterest' });

  const facebookLinkEl = facebookLink ? (
    <externallink key="linkToFacebook" href="{facebookLink}" className="{css.icon}" title="{goToFb}">
      <iconsocialmedia type="{IconSocialMediaType.Facebook}"></iconsocialmedia>
    </externallink>
  ) : null;

  const tiktokEl = tiktokLink ? (
    <externallink key="linkToTiktok" href="{tiktokLink}" className="{css.icon}" title="{goToTiktok}">
      <iconsocialmedia type="{IconSocialMediaType.Tiktok}"></iconsocialmedia>
    </externallink>
  ) : null;

  const instagramLinkEl = instagramLink ? (
    <externallink key="linkToInstagram" href="{instagramLink}" className="{css.icon}" title="{goToInsta}">
      <iconsocialmedia type="{IconSocialMediaType.Instagram}"></iconsocialmedia>
    </externallink>
  ) : null;

  const pinterestEl = pinterestLink ? (
    <externallink key="linkToPinterest" href="{pinterestLink}" className="{css.icon}" title="{goToPinterest}">
      <iconsocialmedia type="{IconSocialMediaType.Pinterest}"></iconsocialmedia>
    </externallink>
  ) : null;

  return [tiktokEl, facebookLinkEl, instagramLinkEl, pinterestEl].filter((v) => v != null);
};

const Footer = (props) => {
  const { rootClassName, className, intl } = props;

  const shopConfig = useShopConfig();
  const { allowBuy, allowSell, allowTradeIn, allowList, isListTradeInOnly } =
    useEnabledCustomerExperiences();
  const { isCondensedStyle } = useStyleType();
  const { listActionCopy } = useActionCopy();
  const isMobile = useIsMobile();
  const isCookieConsentEnabled = useFeatureFlags(Feature.CookieConsent);

  const { builderConfig } = shopConfig;
  const footerBuilderSectionId = builderConfig?.sections?.[BuilderSections.FooterContent];

  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);
  const {
    shopName,
    copy,
    css: brandCss,
    enabledAboutTreetPages = [],
    additionalFooterSections,
  } = shopConfig;
  const shouldUseAboutTreetPages = !isEmpty(enabledAboutTreetPages);

  const organizationDescriptionDefault = allowSell ? (
    <formattedmessage id="Footer.organizationDescription" values="{{" shopName="" }}=""></formattedmessage>
  ) : (
    <formattedmessage id="Footer.organizationDescriptionBrandDirectOnly" values="{{" shopName="" }}=""></formattedmessage>
  );

  if (footerBuilderSectionId) {
    return (
      <buildersection sectionType="{BuilderSections.FooterContent}" sectionId="{footerBuilderSectionId}"></buildersection>
    );
  }

  return (
    <box className="{classes}" bgcolor="{brandCss?.backgroundColor}">
      <div className="{css.topBorderWrapper}">
        <div className="{css.content}">
          <div className="{css.someLinksMobile}">{socialMediaLinks}</div>
          <div className="{css.links}">
            <div className="{css.organization}" id="organization">
              <namedlink name="LandingPage" className="{classNames(css.logoLink," {="" [css.condensedLogoLink]:="" isCondensedStyle="" })}="">
                <logo format="desktop" className="{css.logo}"></logo>
              </namedlink>
              <div className="{css.organizationInfo}">
                <div className="{classNames(css.organizationDescription," {="" [css.condensedOrganizationDescription]:="" isCondensedStyle,="" })}="">
                  <typographywrapper variant="body2">
                    <textwithlinebreaks text="{" copy?.brandFooterSubtitle="" ||="" copy?.heroSubtitle="" organizationDescriptionDefault="" }=""></textwithlinebreaks>
                  </typographywrapper>
                </div>
              </div>
            </div>
            <div style="{{" order:="" 2,="" display:="" 'flex',="" flexDirection:="" 'row',="" whiteSpace:="" 'nowrap'="" }}="">
              <div className="{css.infoLinks}">
                <ul className="{css.list}">
                  {allowList && (
                    <li className="{css.listItem}">
                      <namedlink name="{isListTradeInOnly" ?="" 'ManageTradeInsPage'="" :="" 'NewListingPage'}="" className="{css.link}">
                        <typographywrapper variant="body1">{listActionCopy}</typographywrapper>
                      </namedlink>
                    </li>
                  )}
                  <li className="{css.listItem}">
                    <namedlink name="{shouldUseAboutTreetPages" ?="" 'AboutTreetPage'="" :="" 'AboutPage'}="" {...(!shouldUseAboutTreetPages="" &&="" {="" params:="" tab:="" AboutPageTab.AboutTreet="" },="" })}="" className="{css.link}">
                      <typographywrapper variant="body1">About Treet</typographywrapper>
                    </namedlink>
                  </li>
                  {allowBuy && (
                    <li className="{css.listItem}">
                      <namedlink className="{css.link}" name="AboutPage" params="{{" tab:="" AboutPageTab.Buyer="" }}="">
                        <typographywrapper variant="body1">Buyer FAQs</typographywrapper>
                      </namedlink>
                    </li>
                  )}
                  {allowSell && (
                    <li className="{css.listItem}">
                      <namedlink className="{css.link}" name="AboutPage" params="{{" tab:="" AboutPageTab.Seller="" }}="">
                        <typographywrapper variant="body1">Seller FAQs</typographywrapper>
                      </namedlink>
                    </li>
                  )}
                  {allowTradeIn && (
                    <li className="{css.listItem}">
                      <namedlink className="{css.link}" name="AboutPage" params="{{" tab:="" AboutPageTab.TradeIn="" }}="">
                        <typographywrapper variant="body1">Trade-In FAQs</typographywrapper>
                      </namedlink>
                    </li>
                  )}
                  <li className="{css.listItem}">
                    <a className="{css.link}" target="_blank" href="https://treet.zendesk.com/hc/en-us" rel="noreferrer">
                      <typographywrapper variant="body1">Contact</typographywrapper>
                    </a>
                  </li>
                  {allowBuy && (
                    <li className="{css.listItem}">
                      <namedlink name="AboutPage" params="{{" tab:="" AboutPageTab.Protection="" }}="" className="{css.link}">
                        <typographywrapper variant="body1">Treet Protection</typographywrapper>
                      </namedlink>
                    </li>
                  )}
                  {additionalFooterSections?.map((footerSection) => (
                    <li key="{footerSection.title}" className="{css.listItem}">
                      <a className="{css.link}" target="_blank" href="{footerSection.link}" rel="noreferrer">
                        <typographywrapper variant="body1">{footerSection.title}</typographywrapper>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              {/* In their own div so they can both be on the right. When we enable more columns, we can take out this div */}
              <div className="{css.extraLinks}">
                <ul className="{css.list}">
                  <li className="{css.listItem}">
                    <div>{socialMediaLinks}</div>
                  </li>
                  {/* Dummy list item to preserve spacing */}
                  <li className="{css.listItem}">
                    <div></div>
                  </li>
                  <li className="{css.listItem}">
                    <namedlink name="PrivacyPolicyPage" className="{css.legalLink}">
                      <typographywrapper variant="{isMobile" ?="" 'body2'="" :="" 'body1'}="">
                        <formattedmessage id="Footer.privacyPolicy"></formattedmessage>
                      </typographywrapper>
                    </namedlink>
                  </li>
                  {isCookieConsentEnabled && (
                    <li className="{css.listItem}">
                      <namedlink name="CookiePolicyPage" className="{css.legalLink}">
                        <typographywrapper variant="{isMobile" ?="" 'body2'="" :="" 'body1'}="">
                          <formattedmessage id="Footer.cookiePolicy"></formattedmessage>
                        </typographywrapper>
                      </namedlink>
                    </li>
                  )}
                  <li className="{css.listItem}">
                    <namedlink name="TermsOfServicePage" className="{css.legalLink}">
                      <typographywrapper variant="{isMobile" ?="" 'body2'="" :="" 'body1'}="">
                        <formattedmessage id="Footer.termsOfUse"></formattedmessage>
                      </typographywrapper>
                    </namedlink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <box className="{css.copyrightAndTermsMobile}" bgcolor="{brandCss?.backgroundColor}">
            <div className="{css.tosAndPrivacyMobile}">
              <namedlink name="PrivacyPolicyPage" className="{css.privacy}">
                <typographywrapper variant="body2" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.gray40="" },="" display:="" 'inline',="" }}="">
                  <formattedmessage id="Footer.privacy"></formattedmessage>
                </typographywrapper>
              </namedlink>
              {isCookieConsentEnabled && (
                <namedlink name="CookiePolicyPage" className="{css.cookiePolicy}">
                  <typographywrapper variant="body2" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.gray40="" },="" display:="" 'inline',="" }}="">
                    <formattedmessage id="Footer.cookiePolicy"></formattedmessage>
                  </typographywrapper>
                </namedlink>
              )}
              <namedlink name="TermsOfServicePage" className="{css.terms}">
                <typographywrapper variant="body2" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.gray40="" },="" display:="" 'inline',="" }}="">
                  <formattedmessage id="Footer.terms"></formattedmessage>
                </typographywrapper>
              </namedlink>
            </div>
          </box>
        </div>
      </div>
    </box>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
