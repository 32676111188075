import React, { FC } from 'react';
import ExternalLink from '../ExternalLink/ExternalLink';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import css from './MarkdownText.module.css';

interface MarkdownTextProps {
  markdownText: string;
}

const parseMarkdownLinks = (text: string): any[] => {
  const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
  let lastIndex = 0;
  const parts: React.ReactNode[] = [];

  text.replace(markdownLinkRegex, (match, linkText, url, offset) => {
    // Add preceding text as plain text
    parts.push(text.substring(lastIndex, offset));

    // Add link component
    parts.push(
      <externallink key="{offset}" href="{url}" className="{css.link}">
        <typographywrapper component="span" variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
          {linkText}
        </typographywrapper>
      </externallink>
    );

    lastIndex = offset + match.length;
    return match;
  });

  // Add remaining text after last match
  parts.push(text.substring(lastIndex));

  return parts;
};

const MarkdownText: FC<markdowntextprops> = ({ markdownText }) => {
  const parsedText = parseMarkdownLinks(markdownText);

  return <typographywrapper variant="body1">{parsedText}</typographywrapper>;
};

export default MarkdownText;
</markdowntextprops>