import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { FieldCheckbox, FormattedMessage, TypographyWrapper } from '../../components';
import { defaultTreetStyles } from '../../shopConfig/config';
import { PAYMENT_ADDRESS_FIELD_ID } from '../../util/constants';

import css from './StripePaymentForm.module.css';
import { useIsMobile } from '../../hooks/useIsMobile';
import { TypographyWeight } from '../../components/TypographyWrapper/TypographyWrapper';

interface OneTimePaymentWithCardProps {
  cardClasses: string;
  handleStripeElementRef: (el: any) => void;
  hasCardError: boolean;
  error?: string;
  label?: string;
}

const OneTimePaymentWithCard: FC<onetimepaymentwithcardprops> = (
  props: OneTimePaymentWithCardProps
) => {
  const { cardClasses, handleStripeElementRef, hasCardError, error, label } = props;

  const intl = useIntl();
  const isMobile = useIsMobile();

  const labelText =
    label || intl.formatMessage({ id: 'StripePaymentForm.saveAfterOnetimePayment' });

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="{css.paymentLabel}" htmlFor="{`${PAYMENT_ADDRESS_FIELD_ID}-card`}">
        <typographywrapper 0="" variant="body1" weight="{TypographyWeight.Bold}" typographyOverrides="{{" style:="" {="" marginTop:="" isMobile="" ?="" '10px'="" :="" }="" }}="">
          <formattedmessage id="StripePaymentForm.paymentCardDetails"></formattedmessage>
        </typographywrapper>
      </label>
      <div className="{cardClasses}" id="{`${PAYMENT_ADDRESS_FIELD_ID}-card`}" ref="{handleStripeElementRef}"></div>
      {hasCardError && (
        <span className="{css.error}">
          <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.red80="" }="" }}="">
            {error}
          </typographywrapper>
        </span>
      )}
      <div className="{css.saveForLaterUse}">
        <fieldcheckbox className="{css.saveForLaterUseCheckbox}" textClassName="{css.saveForLaterUseLabel}" id="saveAfterOnetimePayment" name="saveAfterOnetimePayment" value="saveAfterOnetimePayment" label="{labelText}" useSuccessColor=""></fieldcheckbox>
        <span className="{css.saveForLaterUseLegalInfo}">
          <typographywrapper variant="body2" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.gray20="" }="" }}="">
            <formattedmessage id="StripePaymentForm.saveforLaterUseLegalInfo"></formattedmessage>
          </typographywrapper>
        </span>
      </div>
    </>
  );
};

export default OneTimePaymentWithCard;
</onetimepaymentwithcardprops>