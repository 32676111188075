import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { propTypes } from '../../util/types';
import {
  MAX_LARGE_SCREEN_WIDTH,
  MAX_MEDIUM_SCREEN_WIDTH,
  MAX_SMALL_SCREEN_WIDTH,
} from '../../util/window';
import { useShopConfig } from '../../hooks/shopConfig';
import AppContext from '../../context/AppContext';
import { formatPayoutPercentage } from '../../util/currency';
import { shouldShowListItemCard } from '../../util/search';
import { ListAnItemCard, ListingCard, PaginationLinks } from '..';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { trackClickListingCard } from '../../util/heap';
import css from './SearchResultsPanel.module.css';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useYotpo } from '../../hooks/useYotpo';
import { useActivePromos } from '../../hooks/useActivePromos';
import { parse } from '../../util/urlHelpers';

const MainContentIfUsingLoyaltyPoints = (props) => {
  const { formattedCreditPayout } = props;
  return (
    <>
      <typographywrapper variant="body1" weight="{TypographyWeight.Bold}" typographyOverrides="{{" display:="" 'inline',="" fontSize:="" 'inherit'="" }}="">
        {`Get ${formattedCreditPayout}% brand credit `}
      </typographywrapper>
      in loyalty points when your item sells.
    </>
  );
};

const MainContentIfUsingBrandCredit = (props) => {
  const { formattedCreditPayout, formattedPromoCreditPayoutPercentage } = props;
  const activeCreditPercentage = formattedPromoCreditPayoutPercentage ?? formattedCreditPayout;
  const inactiveCreditStrikethrough = formattedPromoCreditPayoutPercentage ? (
    <>
      {' '}
      <typographywrapper variant="body1" component="span" typographyOverrides="{{" style:="" {="" display:="" 'inline',="" fontSize:="" 'inherit',="" textDecoration:="" 'line-through',="" },="" }}="">
        {formattedCreditPayout}%
      </typographywrapper>{' '}
    </>
  ) : null;

  return (
    <>
      Get {inactiveCreditStrikethrough}
      <typographywrapper variant="body1" component="span" weight="{TypographyWeight.Bold}" typographyOverrides="{{" style:="" {="" display:="" 'inline',="" fontSize:="" 'inherit'="" }="" }}="">
        {`${activeCreditPercentage}% `}
      </typographywrapper>
      brand credit for every item you sell.
    </>
  );
};

const ListingCardGridItem = ({ children }) => (
  <grid 0="" Padding="" is="" set="" on="" the="" listing="" card="" so="" we="" can="" customize="" how="" large="" gutter="" width="" style="{{" marginBottom:="" 10,="" padding:="" }}="" item="" xs="{6}" sm="{4}" md="{3}" lg="{3}">
    {children}
  </grid>
);

const SearchResultsPanel = (props) => {
  const { className, rootClassName, listings, pagination, search } = props;

  const { treetId } = useContext(AppContext);
  const { creditPayoutPercentage, css: shopCss } = useShopConfig();
  const { allowSell } = useEnabledCustomerExperiences();
  const formattedCreditPayout = formatPayoutPercentage(creditPayoutPercentage);
  const { doesBrandUseLoyaltyPoints } = useYotpo();
  const { promoCreditPayoutPercentage } = useActivePromos();
  const formattedPromoCreditPayoutPercentage = promoCreditPayoutPercentage
    ? formatPayoutPercentage(promoCreditPayoutPercentage)
    : undefined;

  const classes = classNames(rootClassName || css.root, className);
  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <paginationlinks className="{css.pagination}" pageName="LandingPage" pageSearchParams="{search}" pagination="{pagination}"></paginationlinks>
    ) : null;

  const creditMainContent = doesBrandUseLoyaltyPoints ? (
    <maincontentifusingloyaltypoints formattedCreditPayout="{formattedCreditPayout}"></maincontentifusingloyaltypoints>
  ) : (
    <maincontentifusingbrandcredit formattedCreditPayout="{formattedCreditPayout}" formattedPromoCreditPayoutPercentage="{formattedPromoCreditPayoutPercentage}"></maincontentifusingbrandcredit>
  );

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    `(max-width: ${MAX_SMALL_SCREEN_WIDTH}px) 100vw`,
    `(max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) ${panelMediumWidth}vw`,
    `(max-width: ${MAX_LARGE_SCREEN_WIDTH}px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const location = useLocation();
  const searchParams = parse(location.search);

  const trackListingCardHeapEvent = (params) => {
    trackClickListingCard({
      ...params,
      originOfClick: 'SearchResults',
      searchKeywords: searchParams.keywords,
    });
  };

  const isFirstPage = pagination?.page === 1;
  const isSinglePage = pagination?.totalPages === 1;
  const showListItemCard = shouldShowListItemCard(
    isFirstPage,
    isSinglePage,
    allowSell,
    search,
    listings.length
  );
  const listingCardStyle = shopCss?.listingCardStyle || {};

  return (
    <div className="{classes}">
      <div className="{css.listingCards}">
        <grid container="" direction="row" spacing="{1}">
          {showListItemCard && (
            <listingcardgriditem key="list-an-item">
              <listanitemcard style="{{" padding:="" '0="" 4px',="" ...listingCardStyle="" }}="" buttonText="{{" default:="" 'List="" An="" Item',="" condensed:="" 'Sell'="" captionHeaderText="List An Item" captionSubtitleText="{" creditPayoutPercentage="" ?="" `Get="" ${="" formattedPromoCreditPayoutPercentage="" ||="" formattedCreditPayout="" }%="" brand="" credit`="" :="" 'Earn="" Cash'="" }="" mainContent="{" creditMainContent="" cash="" for="" every="" item="" you="" sell.'=""></listanitemcard>
            </listingcardgriditem>
          )}
          {listings.map((l, index) => (
            <listingcardgriditem key="{l.id.uuid}">
              <listingcard key="{l.id.uuid}" style="{{" padding:="" '0="" 4px',="" ...listingCardStyle="" }}="" index="{index}" listing="{l}" referrerLocation="Search Page" renderSizes="{cardRenderSizes}" subdomain="{treetId}" soldListingText="JUST SOLD" trackClickListingCardHeapEvent="{trackListingCardHeapEvent}"></listingcard>
            </listingcardgriditem>
          ))}
        </grid>
        {props.children}
      </div>
      {paginationLinks}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
