import { Box, DialogActions, DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Button, DialogWithCloseButton, FieldSelect, FieldTextInput, TypographyWrapper } from '..';
import { CANCEL_REASON_TYPES } from '../../util/constants';
import { required } from '../../util/validators';
import { ButtonVariant } from '../Button/Button';

const CancelModal = (props) => {
  const { open, onClose, onSubmit, inProgress } = props;

  return (
    <dialogwithclosebutton fullWidth="" maxWidth="md" open="{open}" onClose="{onClose}" titleText="Cancel Order">
      {/* FinalForm has to be inside Dialog or else submit button doesn't work as intended */}
      <finalform onSubmit="{onSubmit}" render="{(fieldRenderProps)" ==""> {
          const { handleSubmit, invalid, submitting, values } = fieldRenderProps;
          return (
            <div>
              <form onSubmit="{handleSubmit}">
                <dialogcontent dividers="">
                  <h4>
                    <typographywrapper variant="body1">
                      By canceling, you’re confirming you will not be shipping this order.
                    </typographywrapper>
                  </h4>
                  <box mb="{3}">
                    <fieldselect id="cancelReason" name="cancelReason" disabled="{false}" label="Reason for cancellation:" validate="{required(`Please" choose="" an="" option`)}="">
                      <option disabled="" value="">
                        Please choose a reason
                      </option>
                      {CANCEL_REASON_TYPES.map(({ key, label }) => (
                        <option key="{key}" value="{key}">
                          {label}
                        </option>
                      ))}
                    </fieldselect>
                  </box>
                  {values.cancelReason && (
                    <box mb="{3}">
                      <fieldtextinput id="description" name="description" type="textarea" label="Description" placeholder="{" CANCEL_REASON_TYPES.find((claim)=""> claim.key === values.cancelReason)
                            ?.descriptionPlaceholder
                        }
                      />
                    </fieldtextinput></box>
                  )}
                </dialogcontent>
                <dialogactions>
                  <button type="submit" disabled="{invalid" ||="" submitting}="" inProgress="{submitting" inProgress}="" variant="{ButtonVariant.Danger}">
                    Cancel Order
                  </button>
                </dialogactions>
              </form>
            </div>
          );
        }}
      />
    </finalform></dialogwithclosebutton>
  );
};
CancelModal.defaultProps = {
  inProgress: false,
};

CancelModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
};

export default CancelModal;
