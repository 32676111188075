import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconRelistProps {
  className?: string;
  color?: string;
}

const IconRelistFilled: React.FC<iconrelistprops> = (props) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg className="{className}" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28C22.6274 28 28 22.6274 28 16ZM10 16C10 12.6863 12.6863 10 16 10C17.372 10 18.6345 10.4594 19.6451 11.2337L18.8004 12.0783L22.835 12.8852L22.0281 8.85062L21.0685 9.81022C19.689 8.67943 17.9226 8 16 8C11.5817 8 8 11.5817 8 16H10ZM9.97189 23.1494L9.16496 19.1148L13.1996 19.9217L12.3549 20.7663C13.3655 21.5406 14.628 22 16 22C19.3137 22 22 19.3137 22 16H24C24 20.4183 20.4183 24 16 24C14.0774 24 12.311 23.3206 10.9315 22.1898L9.97189 23.1494Z" fill="{fillColor}"></path>
    </svg>
  );
};

export default IconRelistFilled;
</iconrelistprops>