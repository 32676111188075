import { isEmpty } from 'lodash';
import { PRODUCT_DISPLAY_OPTIONS } from '../../shopConfig/config';
import { Conditions } from '../../shopConfig/filters/condition';
import { IShopCategory } from '../../types/contentful/types.generated';
import { SharetribeAddress } from '../../types/sharetribe/address';
import {
  ListingItemType,
  ListingWithImages,
  SharetribeListingBasicFieldsPayload,
  SharetribeListingPrivateDataPayload,
  SharetribeListingPublicDataPayload,
} from '../../types/sharetribe/listing';
import { Money } from '../../types/sharetribe/money';
import { Product, ShopifyProductVariant } from '../../types/shopify/product';
import { PayoutOptions } from '../constants';
import { convertMoneyToNumber } from '../currency';
import { getCategories, getCategory, getProductDisplayTitle } from '../shopifyHelpers';
import { ImageSource } from '../uploadcare';
import { FindItemMethod } from './listing';
import { ListingFlowSectionToTimestamp } from '../../containers/EditListingPage/EditListingPage.utils';
import { CountryCode } from '../../types/apollo/generated/types.generated';

// Params to build listing basic fields
interface GetListingBasicFieldsBase {
  state?: string;
  shopName?: string;
  price?: Money | null;
  productTitleDisplayType?: string;
  shopifyProduct?: Product;
  shopifyProductVariant?: ShopifyProductVariant;
  titleOverride?: string;
  listingId?: string;
}

interface GetListingBasicFieldsForSearchForm extends GetListingBasicFieldsBase {
  shopName: string;
  productTitleDisplayType: string;
}

interface GetListingBasicFieldsForItemDetails extends GetListingBasicFieldsBase {
  price: Money;
  listingId: string;
}

interface GetListingBasicFieldsForExistingListing extends GetListingBasicFieldsBase {
  shopName: string;
  price: Money;
  titleOverride: string;
}

interface GetListingBasicFieldsForFullListing extends GetListingBasicFieldsBase {
  state: string;
  shopName: string;
  price: Money;
  productTitleDisplayType: string;
  shopifyProduct: Product;
  titleOverride: string;
  listingId: string;
}

// Params to build listing private data
interface GetSharetribeListingPrivateDataBase {
  shipFromAddress?: SharetribeAddress;
  isBrand?: boolean;
  listingItemType?: ListingItemType;
  cashPayoutPercentage?: number;
}

interface GetSharetribeListingPrivateDataForSearchForm extends GetSharetribeListingPrivateDataBase {
  isBrand: boolean;
  cashPayoutPercentage: number;
}

interface GetSharetribeListingPrivateDataForItemDetails
  extends GetSharetribeListingPrivateDataForSearchForm {
  listingItemType: ListingItemType;
}

interface GetSharetribeListingPrivateDataForFullListing
  extends GetSharetribeListingPrivateDataBase {
  isBrand: boolean;
  cashPayoutPercentage: number;
  listingItemType: ListingItemType;
  shipFromAddress: SharetribeAddress;
}

type SharedPublicDataFields =
  | 'category'
  | 'categories'
  | 'selectedVariantOptions'
  | 'isBrandDirect'
  | 'listingItemType'
  | 'payoutInfo'
  | 'updatedAt'
  | 'shopifyProductVariant'
  | 'shopifyProductVariantId'
  | 'shopifyProductVariantSku'
  | 'listingFlowSectionToTimestamp'
  | 'disableAutoApproval';

type PickedSearchFormFields =
  | SharedPublicDataFields
  | 'availability'
  | 'findItemMethod'
  | 'domain'
  | 'shopName'
  | 'shopifyProduct'
  | 'shopifyProductId'
  | 'tags'
  | 'userGeneratedTitle'
  | 'userGeneratedBrandName'
  | 'listingStartTime'
  | 'listingFlowId'
  | 'listingItemType'
  | 'originatedAsGuestListing'
  | 'emailOrPhoneForOrder'
  | 'lineItemId'
  | 'orderConfirmationNumber'
  | 'configCategories'
  | 'productTitleDisplayType'
  | 'shipFromCountry';

type PickedListingDetailsFields =
  | SharedPublicDataFields
  | 'condition'
  | 'conditionInfo'
  | 'originalPrice'
  | 'images'
  | 'imageSource'
  | 'internalBrandNotes'
  | 'featuredImageId'
  | 'numRequiredImages'
  | 'listingFinishTime'
  | 'stockPhotoUrls'
  | 'didUseQRCode'
  | 'recommendedPrice'
  | 'automaticPriceDropInfo'
  | 'payoutCreditBoostPromoId'
  | 'titleOverride'
  | 'syncShopifyInventory'
  | 'brandApprovalFields';

type PickedDraftListingFields =
  | PickedSearchFormFields
  | PickedListingDetailsFields
  | 'createdListingId'
  | 'approvalEmailSent'
  | 'approvedAt'
  | 'sourceListingId';

type SearchFormPublicDataPayload = Pick<sharetribelistingpublicdatapayload, PickedSearchFormFields="">;
type ListingDetailsPublicDataPayload = Pick<
  SharetribeListingPublicDataPayload,
  PickedListingDetailsFields
>;
type DraftListingPublicDataPayload = Pick<
  SharetribeListingPublicDataPayload,
  PickedDraftListingFields
>;

const getTitle = (
  productTitleDisplayType: string,
  shopifyProduct?: Product,
  shopifyProductVariant?: ShopifyProductVariant,
  titleOverride?: string
) => {
  const formattedTitle =
    shopifyProduct &&
    getProductDisplayTitle(shopifyProduct, productTitleDisplayType, shopifyProductVariant);
  return titleOverride?.trim() || formattedTitle?.trim() || 'N/A';
};

// Build required top level sharetribe listing fields
export const getSharetribeListingBasicFields = (
  params: GetListingBasicFieldsBase
): SharetribeListingBasicFieldsPayload => {
  const {
    state,
    shopName,
    price,
    productTitleDisplayType = PRODUCT_DISPLAY_OPTIONS.TITLE,
    shopifyProduct,
    shopifyProductVariant,
    titleOverride,
    listingId,
  } = params;

  let title;
  if (titleOverride || (productTitleDisplayType && shopifyProduct)) {
    title = getTitle(productTitleDisplayType, shopifyProduct, shopifyProductVariant, titleOverride);
  }
  return {
    ...(title && { title }),
    ...(shopName && { description: shopName }),
    ...(price && { price }),
    // Listing id is required in the payload if it's used to edit/publish an existing draft.
    ...(listingId && { id: listingId }),
    // State can be specified in the payload for integration API listing/create calls
    ...(state && { state }),
  };
};

// Build sharetribe private data
export const getSharetribeListingPrivateData = (
  params: GetSharetribeListingPrivateDataBase
): SharetribeListingPrivateDataPayload => {
  const { shipFromAddress, isBrand, listingItemType, cashPayoutPercentage } = params;
  let payoutOption;
  if (listingItemType === ListingItemType.TradeIn || (!isBrand && cashPayoutPercentage === 0)) {
    payoutOption = PayoutOptions.Credit;
  } else if (isBrand) {
    payoutOption = PayoutOptions.Cash;
  } else {
    payoutOption = null;
  }
  return {
    ...(shipFromAddress && { shipFromAddress }),
    payoutOption,
  };
};

export interface SearchFormValues {
  category?: string | null;
  categories?: string[] | null;
  emailOrPhoneForOrder?: string;
  findItemMethod: FindItemMethod;
  lineItemId?: string | null;
  orderConfirmationNumber?: string | null;
  shopifyProduct?: Product;
  shopifyProductVariant?: ShopifyProductVariant | null;
  tags?: string[] | null;
  userGeneratedTitle?: string;
  selectedVariantOptions?: Record<string, string=""> | null;
  userGeneratedBrandName?: string;
  isSearchActive?: boolean;
}

interface BuildSearchFormListingPayload extends SearchFormValues {
  treetId: string;
  shopName: string;
  cashPayoutPercentage: number;
  creditPayoutPercentage: number;
  availability: string;
  isBrand: boolean;
  disableAutoApproval?: boolean;
  countryCode: CountryCode;
  configCategories?: IShopCategory[];
  productTitleDisplayType?: string;
  defaultListingItemType?: ListingItemType;
  originatedAsGuestListing?: boolean;
  listingStartTime?: number;
  listingFlowId?: string;
  listingFlowSectionToTimestamp?: ListingFlowSectionToTimestamp;
}

export interface SearchFormListingPayload extends SharetribeListingBasicFieldsPayload {
  title?: string;
  publicData: SearchFormPublicDataPayload;
  privateData: SharetribeListingPrivateDataPayload;
}

// Build listing draft fields after the search form is complete in the listing flow.
export const buildSearchFormListingPayload = (
  params: BuildSearchFormListingPayload
): SearchFormListingPayload => {
  // Explicitly pass `null` to ensure Search Form values are being properly
  // reset on the listing when the form field value is undefined.
  const {
    shopifyProduct,
    categories = null,
    category = null,
    emailOrPhoneForOrder,
    lineItemId = null,
    orderConfirmationNumber = null,
    shopifyProductVariant = null,
    tags = null,
    userGeneratedTitle,
    findItemMethod,
    userGeneratedBrandName,
    shopName,
    configCategories,
    productTitleDisplayType,
    treetId,
    cashPayoutPercentage,
    creditPayoutPercentage,
    selectedVariantOptions = null,
    availability,
    isBrand,
    disableAutoApproval,
    countryCode,
    defaultListingItemType,
    originatedAsGuestListing,
    listingStartTime,
    listingFlowId,
    listingFlowSectionToTimestamp,
  } = params;

  const updatedCategory =
    category || (shopifyProduct && getCategory(configCategories, shopifyProduct)) || 'N/A';
  const updatedCategories =
    categories || (shopifyProduct && getCategories(configCategories, shopifyProduct));

  const privateData = getSharetribeListingPrivateData({
    isBrand,
    cashPayoutPercentage,
  } as GetSharetribeListingPrivateDataForSearchForm);

  return {
    ...getSharetribeListingBasicFields({
      shopName,
      productTitleDisplayType,
      shopifyProduct,
      titleOverride: userGeneratedTitle,
    } as GetListingBasicFieldsForSearchForm),
    publicData: {
      category: updatedCategory,
      categories: updatedCategories,
      emailOrPhoneForOrder,
      lineItemId,
      orderConfirmationNumber,
      shopifyProductId: shopifyProduct?.id,
      shopifyProduct,
      shopifyProductVariant,
      ...(shopifyProductVariant && {
        shopifyProductVariantId: shopifyProductVariant.id,
        shopifyProductVariantSku: shopifyProductVariant.sku,
      }),
      tags: tags || shopifyProduct?.tags || null,
      userGeneratedTitle,
      userGeneratedBrandName,
      selectedVariantOptions,
      shopName,
      domain: treetId,
      findItemMethod,
      availability,
      isBrandDirect: !!isBrand,
      disableAutoApproval: !!disableAutoApproval,
      payoutInfo: {
        cashPayoutPercentage,
        creditPayoutPercentage,
      },
      // Add updatedAt so we can sort by updatedAt
      updatedAt: Date.now(),
      // TODO: (mk|TREET-3891) - allow user to adjust their country if there is a mismatch
      // between shop supported countries and current country.
      shipFromCountry: countryCode,
      // In the case of 'allowTradeInOrMarketplace', this property should not have a value.
      listingItemType: defaultListingItemType,
      originatedAsGuestListing: !!originatedAsGuestListing,
      listingStartTime,
      listingFlowId,
      listingFlowSectionToTimestamp,
    },
    ...(privateData && { privateData }),
  };
};

export interface ListingDetailsListingPayload extends SharetribeListingBasicFieldsPayload {
  title?: string;
  publicData: ListingDetailsPublicDataPayload;
  privateData: SharetribeListingPrivateDataPayload;
}

interface BuildItemDetailsListingPayload {
  listingId?: string;
  isBrand: boolean;
  disableAutoApproval?: boolean;
  cashPayoutPercentage: number;
  shouldUseImagesAPIFields: boolean;
  shopifyProductVariant?: ShopifyProductVariant | null;
  shopifyProduct?: Product;
  category?: string | null;
  condition?: Conditions;
  description?: string;
  quirks?: string[];
  price?: Money;
  originalPrice?: Money | null;
  variantValues?: Record<string, string="">;
  imagesToSave?: any[];
  numRequiredImages?: number;
  listingItemType?: ListingItemType;
  internalBrandNotes?: string;
  payoutInfo: {
    cashPayoutPercentage: number;
    creditPayoutPercentage: number;
  };
  orderedImageIds?: string[];
  featuredImageId?: string;
  listingFinishTime?: number;
  listingFlowSectionToTimestamp?: ListingFlowSectionToTimestamp;
  didUseQRCode?: string;
  productTitleDisplayType?: keyof typeof PRODUCT_DISPLAY_OPTIONS;
  stockPhotoUrls?: string[] | null;
  title?: string;
  // TODO (awu|TREET-6545) Refactor originalPrice and recommendedPrice to be numbers instead of Money types.
  recommendedPrice?: Money | null;
  brandApprovalFields?: string[];
  automaticPriceDropInfo?: {
    minPrice?: number | null;
    percentageDrop?: number | null;
    enabled?: boolean | null;
    anchorPrice?: number | null;
  };
  payoutCreditBoostPromoId?: string;
}

// Saves listing fields from the item details form. May be a draft or a complete listing.
export const buildItemDetailsListingPayload = (
  params: BuildItemDetailsListingPayload
): ListingDetailsListingPayload => {
  const {
    listingId,
    shopifyProductVariant,
    cashPayoutPercentage,
    isBrand,
    disableAutoApproval,
    category,
    condition,
    description,
    quirks,
    price,
    originalPrice,
    variantValues,
    shouldUseImagesAPIFields,
    imagesToSave,
    numRequiredImages,
    listingItemType,
    internalBrandNotes,
    payoutInfo,
    orderedImageIds,
    featuredImageId,
    listingFinishTime,
    listingFlowSectionToTimestamp,
    shopifyProduct,
    productTitleDisplayType = PRODUCT_DISPLAY_OPTIONS.TITLE,
    stockPhotoUrls,
    didUseQRCode,
    title,
    recommendedPrice,
    brandApprovalFields,
    automaticPriceDropInfo,
    payoutCreditBoostPromoId,
  } = params;

  const privateData = getSharetribeListingPrivateData({
    isBrand,
    listingItemType,
    cashPayoutPercentage,
  } as GetSharetribeListingPrivateDataForItemDetails);

  return {
    ...getSharetribeListingBasicFields({
      price,
      listingId,
      shopifyProduct,
      shopifyProductVariant,
      productTitleDisplayType,
      ...(title && { titleOverride: title }),
    } as GetListingBasicFieldsForItemDetails),
    publicData: {
      updatedAt: Date.now(),
      category: category || null,
      categories: category ? [category] : null,
      condition,
      conditionInfo: { condition, notes: description, quirks },
      originalPrice: originalPrice && convertMoneyToNumber(originalPrice),
      isBrandDirect: !!isBrand,
      shopifyProductVariant,
      ...(shopifyProductVariant && {
        shopifyProductVariantId: shopifyProductVariant.id,
        shopifyProductVariantSku: shopifyProductVariant.sku,
      }),
      selectedVariantOptions: variantValues,
      ...(shouldUseImagesAPIFields &&
        !isEmpty(imagesToSave) && {
          images: imagesToSave,
          imageSource: ImageSource.Uploadcare,
        }),
      ...variantValues,
      disableAutoApproval: !!disableAutoApproval,
      listingItemType,
      internalBrandNotes,
      payoutInfo,
      featuredImageId,
      numRequiredImages: numRequiredImages || 0,
      listingFinishTime,
      listingFlowSectionToTimestamp,
      stockPhotoUrls,
      didUseQRCode,
      recommendedPrice: recommendedPrice && convertMoneyToNumber(recommendedPrice),
      brandApprovalFields,
      automaticPriceDropInfo,
      payoutCreditBoostPromoId,
    },
    ...(privateData && { privateData }),
    ...(!shouldUseImagesAPIFields &&
      !isEmpty(orderedImageIds) && {
        images: orderedImageIds,
      }),
  };
};

export interface DraftListingPayload extends SharetribeListingBasicFieldsPayload {
  title?: string;
  publicData: DraftListingPublicDataPayload;
  privateData: SharetribeListingPrivateDataPayload;
}

interface BuildDraftListingPayload {
  listing: ListingWithImages;
  shopName: string;
  isBrand: boolean;
  cashPayoutPercentage: number;
  creditPayoutPercentage: number;
  availability: string;
  listingItemType: ListingItemType;
  findItemMethod: FindItemMethod;
  sourceListingId: string;
  price?: Money | null;
  shipFromAddress?: SharetribeAddress;
  featuredImageId?: string;
}

export const buildDraftListingPayloadFromExistingListing = (
  params: BuildDraftListingPayload,
  overrideSellerNotes = true
): DraftListingPayload => {
  const {
    listing,
    shopName,
    price,
    availability,
    listingItemType,
    cashPayoutPercentage,
    creditPayoutPercentage,
    findItemMethod,
    isBrand,
    sourceListingId,
    shipFromAddress,
    featuredImageId,
  } = params;
  const { conditionInfo } = listing.attributes.publicData;

  const privateData = getSharetribeListingPrivateData({
    isBrand,
    listingItemType,
    cashPayoutPercentage,
    shipFromAddress,
  } as GetSharetribeListingPrivateDataForFullListing);

  return {
    ...getSharetribeListingBasicFields({
      shopName,
      price,
      titleOverride: listing.attributes.title,
    } as GetListingBasicFieldsForExistingListing),
    // TODO (anniew|TREET-4325) Refactor publicData
    publicData: {
      ...listing.attributes.publicData,
      approvalEmailSent: false,
      approvedAt: undefined,
      createdListingId: undefined,
      // Do not copy over the old seller notes, as it may often be irrelevant to the new
      // listing/seller.
      conditionInfo: { ...conditionInfo, ...(overrideSellerNotes ? { notes: undefined } : {}) },
      isBrandDirect: isBrand,
      availability,
      listingItemType,
      payoutInfo: {
        cashPayoutPercentage,
        creditPayoutPercentage,
      },
      updatedAt: Date.now(),
      findItemMethod,
      sourceListingId,
      featuredImageId,
      automaticPriceDropInfo: undefined,
      syncShopifyInventory: undefined,
    },
    ...(privateData && { privateData }),
  };
};

// Build full listing payload fields. This is used to create the listing in 1 step, separate
// from the usual listing flow UI.
// TODO (anniew|TREET-4327) This function will not yet be used in the Create Listing API until
// public data is handled.
// TODO (anniew|TREET-4327) Type these params and payload when ready to be used.
export const buildFullListingPayload = (params: any) => {
  const {
    shopifyProduct,
    shopName,
    productTitleDisplayType,
    cashPayoutPercentage,
    isBrand,
    listingId,
    price,
    listingItemType,
    shipFromAddress,
    state,
    titleOverride,
  } = params;

  const privateData = getSharetribeListingPrivateData({
    shipFromAddress,
    isBrand,
    listingItemType,
    cashPayoutPercentage,
  } as GetSharetribeListingPrivateDataForFullListing);

  return {
    ...getSharetribeListingBasicFields({
      state,
      shopName,
      price,
      productTitleDisplayType,
      shopifyProduct,
      titleOverride,
      listingId,
    } as GetListingBasicFieldsForFullListing),
    // TODO (anniew|TREET-4325) Refactor publicData
    ...(privateData && { privateData }),
  };
};
</string,></string,></sharetribelistingpublicdatapayload,>