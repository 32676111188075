import { Box, Typography } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Error, IconSpinner } from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import { ensureCurrentUser } from '../../util/data';
import { FindItemMethod } from '../../util/listings/listing';
import { parse, PRESERVED_SEARCH_PARAMS } from '../../util/urlHelpers';
import ShopifyOrder from './ShopifyOrder';
import CannotFindItemLink from '../../containers/EditListingPage/CannotFindItemLink';
import css from './Shopify.module.css';
import { useAllowedFindItemMethods } from '../../hooks/useAllowedFindItemMethods';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import { ButtonVariant } from '../Button/Button';
import { getShopifyOrders } from '../../util/api';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { useLazyApi } from '../../hooks/useLazyApi';

interface EmptyProps {
  email: string;
}

const Empty: FC<emptyprops> = (props) => {
  const { email } = props;
  return <typography variant="body1">No orders found for {email}.</typography>;
};

const Loading: FC = () => (
  <box display="flex" justifyContent="center" py="{2}">
    <iconspinner></iconspinner>
  </box>
);

interface ShowMoreButtonProps {
  ordersMetadata?: any;
  onClick?: (params: any) => void;
}

const ShowMoreButton: FC<showmorebuttonprops> = (props) => {
  const { ordersMetadata, onClick } = props;

  const hasMoreOrders = ordersMetadata?.pageInfo?.hasNextPage;
  if (!hasMoreOrders) return null;

  return (
    <box display="flex" justifyContent="center" pt="{1}">
      <button variant="{ButtonVariant.Primary}" className="{css.showMoreButton}" onClick="{onClick}">
        Show More Orders
      </button>
    </box>
  );
};

const ShopifyOrders: FC = () => {
  const currentUser = useSelector<any>((state) => state.user.currentUser);
  const { shouldAllowSearch, shouldAllowCannotFind } = useAllowedFindItemMethods();
  const shopConfig = useShopConfig();
  const { additionalOrdersQuery, shopId } = shopConfig;
  const { countryCode } = useBrandCountryConfig();

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const { email: currentUserEmail, id: currentUserId } = ensuredCurrentUser.attributes;

  const location = useLocation();
  const { isTreet } = useCurrentUserPermissions();
  const emailOverride = parse(location.search)[PRESERVED_SEARCH_PARAMS.ShopifyEmailOverride];

  const email = isTreet ? emailOverride || currentUserEmail : currentUserEmail;

  const getShopifyOrdersUpdateQuery = (previousResult?: any, fetchMoreResult?: any) => ({
    ...previousResult,
    shopifyOrders: {
      ...previousResult?.shopifyOrders,
      pageInfo: {
        ...fetchMoreResult?.shopifyOrders.pageInfo,
      },
      edges: [
        ...(previousResult?.shopifyOrders.edges || []),
        ...(fetchMoreResult?.shopifyOrders.edges || []),
      ],
    },
  });

  const {
    lazyQuery: lazyGetShopifyOrders,
    loading: areOrdersByEmailLoading,
    data: ordersData,
    error: getOrdersError,
  } = useLazyApi(getShopifyOrders, getShopifyOrdersUpdateQuery);

  const ordersMetadata = ordersData?.shopifyOrders;
  const orders = ordersMetadata?.edges;

  const fetchOrders = () => {
    const cursor = orders?.slice(-1)[0]?.cursor;
    lazyGetShopifyOrders({
      currentUserId,
      email: currentUserEmail,
      cursor,
      countryCode,
      additionalOrdersQuery,
      subdomain: shopId,
    });
  };

  useEffect(() => {
    if (email) fetchOrders();
  }, [email]);
  const areOrdersEmpty = isEmpty(orders);

  if (getOrdersError) {
    return (
      <error>
        There was an error fetching the orders from your account. Please refresh the page or try
        searching for your item instead.
      </error>
    );
  }

  if (!currentUser || (areOrdersByEmailLoading && areOrdersEmpty)) return <loading></loading>;

  if (areOrdersEmpty) return <empty email="{email}"></empty>;

  return (
    <>
      {orders.map((order: any) => (
        <shopifyorder order="{order}" findItemMethod="{FindItemMethod.EmailAccount}" key="{order.node?.name}"></shopifyorder>
      ))}
      {areOrdersByEmailLoading && <loading></loading>}
      <showmorebutton ordersMetadata="{ordersMetadata}" onClick="{fetchOrders}"></showmorebutton>
      {!shouldAllowSearch && shouldAllowCannotFind && (
        <box py="{2}" display="flex" justifyContent="center">
          <cannotfinditemlink></cannotfinditemlink>
        </box>
      )}
    </>
  );
};

export default ShopifyOrders;
</any></showmorebuttonprops></emptyprops>