import { Box } from '@material-ui/core';
import React, { FC, ReactElement } from 'react';
import { useStyleType } from '../../hooks/useStyleType';

interface MultiButtonWrapperProps {
  children: ReactElement[];
}

const MultiButtonWrapper: FC<multibuttonwrapperprops> = (props) => {
  const { children } = props;
  const { isCondensedStyle } = useStyleType();

  return (
    <box display="flex" flexDirection="{{" xs:="" 'column',="" sm:="" 'row'="" }}="" justifyContent="center">
      {children.map((button: ReactElement, index: number) => (
        <box 0="" 2="" minWidth="{isCondensedStyle" ?="" '100px'="" :="" '150px'}="" ml="{{" sm:="" index="==" }}="" Need="" !important="" override="" for="" Safari="" mt="{{" xs:="" 1,="" '0="" !important'="" key="{button.key}">
          {button}
        </box>
      ))}
    </box>
  );
};

export default MultiButtonWrapper;
</multibuttonwrapperprops>