import React, { FC } from 'react';
import { defaultTreetStyles } from '../../shopConfig/config';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';

interface ErrorProps {
  children?: React.ReactElement | string | (string | React.ReactElement)[];
}

const Error: FC<errorprops> = (props: ErrorProps) => {
  const { children } = props;
  return (
    <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.red80="" }="" }}="">
      {children}
    </typographywrapper>
  );
};

export default Error;
</errorprops>