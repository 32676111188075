import React, { FC } from 'react';

const IconPostIt: FC = () => (
  <svg width="100" height="100" viewBox="0 0 544 544" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="272" cy="272" r="272" fill="#F8F8F8"></circle>
    <path fillRule="evenodd" clipRule="evenodd" d="M163.05 251.153L137 191.219L233.384 153C238.015 162.555 254.745 181.664 284.615 181.664C314.485 181.664 331.215 162.555 335.846 153L432.23 191.219L406.18 251.153H365.369V391H284.615H203.861V251.153H163.05Z" fill="#D1D1D1"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M235.363 130.912L236.983 134.255C241.097 142.743 256.597 160.664 284.615 160.664C312.633 160.664 328.132 142.743 332.246 134.255L333.867 130.912L437.545 172.024L408.803 238.153H369.369V378H199.861V238.153H160.427L131.684 172.024L235.363 130.912ZM142.316 176.414L165.673 230.153H207.861V370H361.369V230.153H403.557L426.914 176.414L337.629 141.01C330.759 152.042 313.322 168.664 284.615 168.664C255.908 168.664 238.471 152.042 231.601 141.01L142.316 176.414Z" fill="#454545"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M115 227C115 218.163 122.163 211 131 211H219C227.837 211 235 218.163 235 227V395C235 403.837 227.837 411 219 411H131C122.163 411 115 403.837 115 395V227Z" fill="#F8F8F8"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M115 227C115 218.163 122.163 211 131 211H219C227.837 211 235 218.163 235 227V395C235 403.837 227.837 411 219 411H131C122.163 411 115 403.837 115 395V227ZM196 223C196 220.791 197.791 219 200 219H219C223.418 219 227 222.582 227 227V395C227 399.418 223.418 403 219 403H131C126.582 403 123 399.418 123 395V227C123 222.582 126.582 219 131 219H150C152.209 219 154 220.791 154 223C154 225.209 155.791 227 158 227H192C194.209 227 196 225.209 196 223Z" fill="#454545"></path>
    <rect x="154" y="387" width="42" height="8" rx="4" fill="#454545"></rect>
    <path fillRule="evenodd" clipRule="evenodd" d="M143.786 303.569L137 287.956L162.108 278C163.315 280.489 167.673 285.467 175.454 285.467C183.236 285.467 187.594 280.489 188.8 278L213.909 287.956L207.123 303.569H196.491V340H175.454H154.418V303.569H143.786Z" fill="#454545"></path>
  </svg>
);

export default IconPostIt;
