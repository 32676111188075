import arrayMutators from 'final-form-arrays';
import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { withTypes } from 'react-final-form';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import DialogWithCloseButton from '../DialogWithCloseButton/DialogWithCloseButton';
import { Form } from '..';
import ISOSearchPane from './ISOSearchPane';
import ISOSizePane from './ISOSizePane';
import ISOEmailPane from './ISOEmailPane';
import ISOSuccessPane from './ISOSuccessPane';
import {
  setSavedSearchSource,
  updateGeneralSavedSearchEmailSubscription,
  updateISOSavedSearchEmailSubscription,
} from '../../ducks/user.duck';
import { Cadence } from '../../types/apollo/generated/types.generated';
import { useShopConfig } from '../../hooks/shopConfig';
import { FilterId } from '../../types/filters/filters';
import { trackShowISOModal } from '../../util/heap';
import { FindItemMethod } from '../../util/listings/listing';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  ITEM_REQUEST_NOTIFICATIONS_GROUP_ID,
  RELEASES_AND_DISCOUNTS_GROUP_ID,
} from '../../util/sendgrid';
import AppContext from '../../context/AppContext';
import { insertISOSavedSearch } from '../../containers/NotificationSettingsPage/NotificationSettingsPage.duck';
import { useAppDispatch } from '../../hooks/appDispatch';
import * as heap from '../../util/heap';
import ISOFooter from './ISOFooter';
import css from './ISOModal.module.css';

const { Form: FinalForm } = withTypes<unknown>();

export enum Pane {
  Search = 'SEARCH',
  Email = 'EMAIL',
  Size = 'SIZE',
  Success = 'SUCCESS',
}

export const ISOContext = createContext(false);

interface ISOModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
}

const ISOModal: FC<isomodalprops> = (props) => {
  const { isOpen, handleClose, handleSuccess } = props;

  const { filters } = useShopConfig();
  const { treetId } = useContext(AppContext);
  const dispatch = useAppDispatch();

  const { currentUser, savedSearchSource } = useSelector<any>((state) => state.user) as any;

  const [activePane, setActivePane] = useState(Pane.Search);

  useEffect(() => {
    if (isOpen) {
      trackShowISOModal(treetId);
    }
  }, [isOpen]);

  const sizeOptions: {
    key: string;
    label: string;
  }[] = findOptionsForSelectFilter(FilterId.Size, filters);

  let body: React.ReactNode;
  switch (activePane) {
    case Pane.Email:
      body = <isoemailpane></isoemailpane>;
      break;
    case Pane.Size:
      body = <isosizepane></isosizepane>;
      break;
    case Pane.Success:
      body = <isosuccesspane></isosuccesspane>;
      break;
    case Pane.Search:
    default:
      body = <isosearchpane></isosearchpane>;
  }

  const currentUserEmail = currentUser?.attributes?.email;
  const isLoggedIn = !!currentUserEmail;
  const hasSizes = sizeOptions.length;

  // Filter out irrelevant panes
  const panesToShow = Object.values(Pane).filter((pane) => {
    if (isLoggedIn && pane === Pane.Email) return false;
    if (!hasSizes && pane === Pane.Size) return false;
    return true;
  });

  const currentPaneIndex = panesToShow.findIndex((pane) => pane === activePane);

  // TODO: Type the values here
  const onSubmit = async (values: any) => {
    const { email, shopifyProduct, search, sizes, shouldNotify } = values;

    if (activePane === Pane.Success) {
      handleSuccess();
      setActivePane(Pane.Search);
      return;
    }

    // Email may not be present yet if the user is not logged in or hasn't reached the email pane
    const emailToSubscribe = email || currentUserEmail;
    if (emailToSubscribe) {
      const cadence = Cadence.Immediately;
      const response = await dispatch(
        updateISOSavedSearchEmailSubscription({
          email: emailToSubscribe,
          cadence,
          shopifyProductId: shopifyProduct?.id,
          sizes,
          groupId: ITEM_REQUEST_NOTIFICATIONS_GROUP_ID,
        })
      );

      dispatch(insertISOSavedSearch(response.data?.upsertISOSavedSearch.savedSearch));

      const trackingProperties = {
        shopName: treetId,
        email,
        subscribeSource: savedSearchSource,
        cadence,
        modalPane: activePane,
        findItemMethod: shopifyProduct?.id ? FindItemMethod.Search : FindItemMethod.CannotFind,
        searchedQuery: search,
        shopifyProductId: shopifyProduct?.id,
      };

      if (response?.data?.upsertISOSavedSearch?.metadata?.created) {
        heap.trackSubscribeISO(trackingProperties);
      } else {
        heap.trackSubscribeISOSizes({ ...trackingProperties, sizes });
      }
    }

    if (emailToSubscribe && activePane === Pane.Size && shouldNotify) {
      dispatch(
        updateGeneralSavedSearchEmailSubscription({
          email: emailToSubscribe,
          cadence: Cadence.Weekly,
          groupId: RELEASES_AND_DISCOUNTS_GROUP_ID,
          sizes,
        })
      );
    }

    const nextPane = panesToShow[currentPaneIndex + 1];
    setActivePane(nextPane);
  };

  const onBackClick = () => {
    const previousPane = panesToShow[currentPaneIndex - 1];
    setActivePane(previousPane);
  };

  const showBackButton = ![Pane.Search, Pane.Success].includes(activePane);

  return (
    <dialogwithclosebutton open="{isOpen}" onClose="{()" ==""> {
        dispatch(setSavedSearchSource({ source: null }));
        handleClose();
        setActivePane(Pane.Search);
      }}
      aria-labelledby="in-search-of"
      aria-describedby="in-search-of-item-modal"
      headerClassName={css.dialogHeader}
      fullWidth
      maxWidth="lg"
    >
      <box id="scrollableDiv" style="{{" overflowY:="" 'auto',="" height:="" '100%'="" }}="">
        <finalform onSubmit="{onSubmit}" mutators="{{" ...arrayMutators="" }}="" render="{(formRenderProps)" ==""> {
            const { handleSubmit } = formRenderProps;

            return (
              <form id="ISOModal" onSubmit="{handleSubmit}" className="{css.formWrapper}">
                <isocontext.provider value="">{body}</isocontext.provider>
                <isofooter pane="{activePane}" onBackClick="{showBackButton" ?="" :="" undefined}=""></isofooter>
              </form>
            );
          }}
        />
      </finalform></box>
    </dialogwithclosebutton>
  );
};

export default ISOModal;
</any></isomodalprops></unknown>