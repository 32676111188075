import React, { FC } from 'react';
import { DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { Button } from '..';
import DialogWithCloseButton from '../DialogWithCloseButton/DialogWithCloseButton';
import { ButtonVariant } from '../Button/Button';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';

interface MarkAsActionModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  inProgress?: boolean;
  actionLabel: string;
  actionDescription: string;
}

const MarkAsActionModal: FC<markasactionmodalprops> = (props) => {
  const { open, onClose, onSubmit, inProgress, actionLabel, actionDescription } = props;

  return (
    <dialogwithclosebutton scroll="paper" open="{open}" onClose="{onClose}" titleText="{`Mark" Order="" As="" ${actionLabel}`}="">
      <dialogcontent dividers="">
        <dialogcontenttext>
          <typographywrapper variant="body1">
            <h4>{actionDescription}</h4>
            <h4>Please note, this action cannot be undone.</h4>
          </typographywrapper>
        </dialogcontenttext>
      </dialogcontent>
      <dialogactions>
        <button disabled="{inProgress}" inProgress="{inProgress}" onClick="{onSubmit}" variant="{ButtonVariant.Primary}">
          {`Mark as ${actionLabel}`}
        </button>
      </dialogactions>
    </dialogwithclosebutton>
  );
};

export default MarkAsActionModal;
</markasactionmodalprops>