import React from 'react';

interface ExternalLinkProps {
  children: React.ReactNode;
  href: string;
  className?: string;
}

// External link that opens in a new tab/window, ensuring that the
// opened page doesn't have access to the current page.
const ExternalLink: React.FC<externallinkprops> = ({ children, href, className }) => (
  <a href="{href}" target="_blank" rel="noopener noreferrer" className="{className}">
    {children}
  </a>
);

export default ExternalLink;
</externallinkprops>