import { useDispatch, useStore } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

/**
 * This hook gives us the correct dispatch type that supports thunks
 */
export function useAppDispatch() {
  const store = useStore();

  type RootState = ReturnType<typeof store.getState="">;

  return useDispatch<thunkdispatch<rootstate, any,="" AnyAction="">>();
}
</thunkdispatch<rootstate,></typeof>