import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  IconVerticalThreeDots,
  InlineTextButton,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  TypographyWrapper,
} from '..';
import { Participant } from '../../types/apollo/generated/types.generated';
import { BundleInfo } from '../../types/models/bundle';
import css from './ManageBundlesContainer.module.css';
import { isBundleCancellable } from './manageBundlesContainerUtils';
import * as zendesk from '../../util/zendesk';
import { useShopConfigV2 } from '../../hooks/shopConfig';

interface ThreeDotsIconButtonProps {
  bundle: BundleInfo;
  participant: Participant;
  onCancelClick?: (bundle: BundleInfo) => void;
}
const ThreeDotsIconButton: FC<threedotsiconbuttonprops> = (props: ThreeDotsIconButtonProps) => {
  const { participant, bundle, onCancelClick } = props;
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const { shopifyDomain } = useShopConfigV2();

  const buildViewInShopifyLink = () => {
    const { shopifyOrderId } = bundle;
    if (!isEmpty(shopifyOrderId)) {
      const orderId = shopifyOrderId!.split('/').pop();
      return orderId
        ? `https://admin.shopify.com/store/${shopifyDomain}/orders/${orderId}`
        : undefined;
    }
    return undefined;
  };

  const viewInShopifyLink = buildViewInShopifyLink();

  return (
    <>
      <menu className="{css.menu}" onToggleActive="{(isOpen:" boolean)=""> setIsMenuOpen(isOpen)}
        isOpen={isMenuOpen}
        useArrow={false}
        contentPosition="left"
      >
        <menulabel className="{css.threeDotIconWrapper}">
          <iconverticalthreedots className="{css.threeDotIcon}"></iconverticalthreedots>
        </menulabel>

        <menucontent className="{css.menuContent}">
          <menuitem key="help" className="{css.menuItem}">
            <inlinetextbutton className="{css.menuItemText}" onClick="{()" ==""> zendesk.open()}>
              <h4>
                <typographywrapper variant="body1">Help with this order</typographywrapper>
              </h4>
            </inlinetextbutton>
          </menuitem>
          {isBundleCancellable(participant, bundle) && onCancelClick && (
            <menuitem key="cancel" className="{css.menuItem}">
              <inlinetextbutton className="{classNames(css.menuItemText," css.cancelOrder)}="" onClick="{()" ==""> onCancelClick(bundle)}
              >
                <h4>
                  <typographywrapper variant="body1">Cancel order</typographywrapper>
                </h4>
              </inlinetextbutton>
            </menuitem>
          )}
          {viewInShopifyLink && participant === Participant.Seller && (
            <menuitem key="cancel" className="{css.menuItem}">
              <inlinetextbutton className="{classNames(css.menuItemText)}" onClick="{()" ==""> window.open(viewInShopifyLink, '_blank')}
              >
                <h4>
                  <typographywrapper variant="body1">View order in Shopify</typographywrapper>
                </h4>
              </inlinetextbutton>
            </menuitem>
          )}
        </menucontent>
      </menu>
    </>
  );
};
export default ThreeDotsIconButton;
</boolean></threedotsiconbuttonprops>