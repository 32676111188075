import { FindItemMethod } from '../../util/listings/listing';
import { ShopConfigResponse } from '../contentful/shopConfig';
import { Filter } from '../filters/filters';
import { ListingItemType } from '../sharetribe/listing';

export enum StyleType {
  Default = 'DEFAULT',
  Condensed = 'CONDENSED',
}

export enum FontConfigSection {
  AboutPageGeneralInfoTitle = 'ABOUT_PAGE_GENERAL_INFO_TITLE',
  AboutPageFAQTitle = 'ABOUT_PAGE_FAQ_TITLE',
  FieldLabel = 'FIELD_LABEL',
}

export enum HowItWorksType {
  Default = 'DEFAULT',
  Swim = 'SWIM',
  Custom = 'CUSTOM',
}

// Note that if BuyerInfo/SellerInfo/TradeInInfo values change, custom "How It Works" icons
// will also need to be updated, as they are keyed on the media title.
export enum BuyerInfo {
  Browse = 'BROWSE',
  Buy = 'BUY',
  Verify = 'VERIFY',
}

export enum SellerInfo {
  PostIt = 'POST_IT',
  ShipIt = 'SHIP_IT',
  GetPaid = 'GET_PAID',
}

export enum TradeInInfoType {
  Submit = 'SUBMIT',
  Ship = 'SHIP',
  Earn = 'EARN',
}

export enum TradeInRestrictionType {
  MinimumNumberOfItems = 'MINIMUM_NUMBER_OF_ITEMS',
  MaximumNumberOfItems = 'MAXIMUM_NUMBER_OF_ITEMS',
}

export enum TradeInPriceRuleTarget {
  Item = 'ITEM',
  ItemWithCondition = 'ITEM_WITH_CONDITION',
  ItemWithCategory = 'ITEM_WITH_CATEGORY',
  Bundle = 'BUNDLE',
  ItemWithFindItemMethod = 'ITEM_WITH_FIND_ITEM_METHOD',
}

export enum FAQ {
  // Buyer
  Refund = 'REFUND',
  WhyShop = 'WHY_SHOP',
  Payment = 'PAYMENT',
  // Seller
  SellRequirements = 'SELL_REQUIREMENTS',
  WhySell = 'WHY_SELL',
  HowToSell = 'HOW_TO_SELL',
  HowToShip = 'HOW_TO_SHIP',
  MisrepresentationClaim = 'MISREPRESENTATION_CLAIM',
  ListingReviewProcess = 'LISTING_REVIEW_PROCESS',
  SellerAdditional = 'SELLER_ADDITIONAL',
  SellerAdditional2 = 'SELLER_ADDITIONAL_2',
  // Trade-in
  WhyTradeIn = 'WHY_TRADE_IN',
  HowToTradeIn = 'HOW_TO_TRADE_IN',
  HowToShipTradeIn = 'HOW_TO_SHIP_TRADE_IN',
  TradeInGetPaid = 'TRADE_IN_GET_PAID',
  TradeInReviewProcess = 'TRADE_IN_REVIEW_PROCESS',
  TradeInRejected = 'TRADE_IN_REJECTED',
  TradeInCreditAmount = 'TRADE_IN_CREDIT_AMOUNT',
  TradeInPayoutDetails = 'TRADE_IN_PAYOUT_DETAILS',
}

export enum TopbarLink {
  ShopAll = 'SHOP_ALL',
  Categories = 'CATEGORIES',
  SizeGuide = 'SIZE_GUIDE',
  MainSite = 'MAIN_SITE',
  OtherSite = 'OTHER_SITE',
}

export enum TopbarSection {
  Left = 'LEFT',
  Right = 'RIGHT',
}

export enum BrandDirectIconType {
  Default = 'DEFAULT',
  Dopple = 'DOPPLE',
  GoldieAndAce = 'GOLDIE_AND_ACE',
  ElizabethSuzann = 'ELIZABETH_SUZANN',
  DL1961 = 'DL1961',
  Sale25Off = 'SALE_25_OFF',
  Sale30Off = 'SALE_30_OFF',
  Sale50Off = 'SALE_50_OFF',
  Sale70Off = 'SALE_70_OFF',
  None = 'NONE',
}

export enum AboutPageInfoTabSection {
  About = 'ABOUT',
  HowItWorks = 'HOW_IT_WORKS',
  // TODO (TREET-3995) Update enum string in Contentful config.
  TradeInHowItWorks = 'BUYBACK_HOW_IT_WORKS',
}

export enum ListingFlowSection {
  VariantOptions = 'VARIANT_OPTIONS',
  Condition = 'CONDITION',
  Quirks = 'QUIRKS',
  Description = 'DESCRIPTION',
  Photos = 'PHOTOS',
  Price = 'PRICE',
  TradeInPrice = 'TRADE_IN_PRICE',
  AcknowledgementCheckbox = 'ACKNOWLEDGEMENT_CHECKBOX',
  InternalBrandNotes = 'INTERNAL_BRAND_NOTES',
  StockPhotos = 'STOCK_PHOTOS',
}

export enum ProgressBarSection {
  FindItem = 'FIND_ITEM',
  ItemDetails = 'ITEM_DETAILS',
  Condition = 'CONDITION',
  Photos = 'PHOTOS',
  Price = 'PRICE',
  Acknowledgement = 'ACKNOWLEDGEMENT',
}

export type ConditionOption = {
  key: string;
  label: string;
  description?: string;
  includedTypes?: ListingItemType[];
};

export enum FulfillmentMethod {
  Treet = 'TREET',
  Shopify = 'SHOPIFY',
  RetailReworks = 'RETAIL_REWORKS',
}

export enum HeroAction {
  Shop = 'SHOP',
  Sell = 'SELL',
  ExternalLink = 'EXTERNAL_LINK',
}

export enum FooterLogo {
  Brand = 'LEFT',
  Treet = 'RIGHT',
  SuperCircle = 'SUPER_CIRCLE',
}

export enum CustomerExperience {
  Buy = 'BUY',
  Sell = 'SELL',
  TradeIn = 'TRADE_IN',
}

export enum Tier {
  Basic = 'BASIC',
  Premium = 'PREMIUM',
}

export type BuyerSellerInfo = {
  [key in BuyerInfo | SellerInfo | TradeInInfoType]: { title: string; description: string };
};

export type TradeInInfo = {
  [key in TradeInInfoType]: { title: string; description: string };
};

export type FAQObject = {
  title: string;
  description: string;
};

export type TopbarLayoutItem = {
  type: TopbarLink;
  config?: { label?: string; url?: string };
  section: TopbarSection;
};

export type HeroActionItem = {
  type: HeroAction;
  config?: { label?: string; url?: string };
};

export type PricingRestriction = {
  type: string;
  tag?: string;
  minimumPrice?: number;
  minimumPercentage?: number;
};

type AdditionalPayoutSettingsType = {
  additionalShopifyDiscountCodeRequirements?: {
    mutation?: Record<string, any="">;
  };
  additionalShopifyGiftCardRequirements?: {
    giftCardExpiresAfterDays?: number;
  };
  creditCodeHelperText?: string;
  tradeInCreditPayoutBundleAmount?: number;
};

export enum AboutTreetPages {
  AboutTreet = 'ABOUT_TREET',
  AboutFAQ = 'ABOUT_FAQ',
  TreetProtection = 'TREET_PROTECTION',
}

export type PrivateShopConfig = {
  filters: Filter[];
  footerLogos: FooterLogo[];
  additionalPayoutSettings?: AdditionalPayoutSettingsType;
  tradeInAllowedFindItemMethods?: FindItemMethod[];
  hideHeaderLogoAtPathnames?: string[];
  hideConfettiAtCheckout?: boolean;
};

export type ShopConfigV2 = ShopConfigResponse & PrivateShopConfig;
</string,>