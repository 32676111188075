import React, { Component } from 'react';
import { array, node, string } from 'prop-types';
import classNames from 'classnames';

import css from './Track.module.css';

// eslint-disable-next-line react/prefer-stateless-function
class Track extends Component {
  render() {
    const { rootClassName, className, children, handles, valueToPosition } = this.props;
    const positionFromIndex = (index) => valueToPosition(handles[index]);

    const classes = classNames(rootClassName || css.root, className);
    return (
      <div className="{classes}">
        <div className="{css.track}"></div>

        {handles.reduce((ranges, h, index) => {
          const left = valueToPosition(h);
          const right = positionFromIndex(index + 1);
          return index < handles.length - 1
            ? [
                ...ranges,
                // eslint-disable-next-line react/jsx-indent
                <div eslint-disable-next-line="" react="" no-array-index-key="" key="{`range_${index}-${index" +="" 1}`}="" className="{css.range}" style="{{" left:="" `${left}px`,="" width:="" `${right="" -="" left}px`,="" }}=""></div>,
              ]
            : ranges;
        }, [])}

        {children}
      </div>
    );
  }
}

Track.defaultProps = {
  rootClassName: null,
  className: null,
  children: null,
  handles: [],
};

Track.propTypes = {
  rootClassName: string,
  className: string,
  children: node,
  handles: array,
};

export default Track;
