import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { TreetShopLandingPageState } from '../containers/TreetShopLandingPage/TreetShopLandingPage.duck';
import AppContext from '../context/AppContext';
import { useShopConfig } from './shopConfig';

/**
 * This hook gives us access to the styling config to use.
 */
export function useShopCss() {
  const shopConfig = useShopConfig();
  const { treetId } = useContext(AppContext);

  const { treetShopConfig } = useSelector<any>(
    (state) => state.TreetShopLandingPage
  ) as TreetShopLandingPageState;

  const isTreetShop = treetId === 'treet';

  return isTreetShop ? treetShopConfig?.styles : shopConfig?.css;
}
</any>