import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IconSecure, TypographyWrapper } from '../../components';
import CheckoutError from '../../containers/CheckoutPage/CheckoutError';
import PaymentFields from './PaymentFields';
import ShippingAddressFields from './ShippingAddressFields';
import { CountryCodeAndName } from '../../util/countryCodes';
import StripeExpressCheckoutSection from './StripeExpressCheckoutSection';
import { CheckoutPageState } from '../../containers/CheckoutPage/CheckoutPage.duck';
import IncompleteAddressErrorMessage from './IncompleteAddressErrorMessage';

interface StripePaymentFormDesktopProps {
  defaultPaymentMethod: any;
  error?: string;
  form: any;
  handleChangePaymentMethod: (paymentMethod: string) => void;
  handleShippingCountryChange: (value: string) => void;
  handleStripeElementRef: (el: any) => void;
  allowedShipToCountryCodes: CountryCodeAndName[];
  isCardValueValid: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  selectedPaymentMethod: string;
  shouldEnableSeel: boolean;
  isMultiSellerCheckout: boolean;
  isStripeExpressCheckoutEnabled: boolean;
  handleExpressCheckoutButtonClick: () => void;
  onConfirm: (values: any) => void;
  onShippingAddressChange: (values: any) => void;
  shouldDisplayIncompleteAddressErrorMessage: boolean;
}

const StripePaymentFormDesktop: FC<stripepaymentformdesktopprops> = (
  props: StripePaymentFormDesktopProps
) => {
  const {
    defaultPaymentMethod,
    error,
    form,
    handleChangePaymentMethod,
    handleShippingCountryChange,
    handleStripeElementRef,
    allowedShipToCountryCodes,
    isCardValueValid,
    isLoading,
    isSubmitting,
    selectedPaymentMethod,
    shouldEnableSeel,
    isMultiSellerCheckout,
    isStripeExpressCheckoutEnabled,
    handleExpressCheckoutButtonClick,
    onConfirm,
    onShippingAddressChange,
    shouldDisplayIncompleteAddressErrorMessage,
  } = props;

  const { isExpressCheckoutError } = useSelector<any>(
    (state) => state.CheckoutPage
  ) as CheckoutPageState;

  return (
    <>
      {!isMultiSellerCheckout && (
        <stripeexpresscheckoutsection isStripeExpressCheckoutEnabled="{isStripeExpressCheckoutEnabled}" handleExpressCheckoutButtonClick="{handleExpressCheckoutButtonClick}" onConfirm="{onConfirm}" onShippingAddressChange="{onShippingAddressChange}" isMultiSellerCheckout="{false}"></stripeexpresscheckoutsection>
      )}
      <typographywrapper variant="h2">Ship To</typographywrapper>
      {shouldDisplayIncompleteAddressErrorMessage && <incompleteaddresserrormessage></incompleteaddresserrormessage>}
      {(isMultiSellerCheckout || (!isMultiSellerCheckout && !isExpressCheckoutError)) && (
        <checkouterror></checkouterror>
      )}
      <shippingaddressfields form="{form}" allowedShipToCountryCodes="{allowedShipToCountryCodes}" handleShippingCountryChange="{handleShippingCountryChange}"></shippingaddressfields>
      {isMultiSellerCheckout && (
        <stripeexpresscheckoutsection isStripeExpressCheckoutEnabled="{isStripeExpressCheckoutEnabled}" handleExpressCheckoutButtonClick="{handleExpressCheckoutButtonClick}" onConfirm="{onConfirm}" onShippingAddressChange="{onShippingAddressChange}" isMultiSellerCheckout=""></stripeexpresscheckoutsection>
      )}
      {shouldEnableSeel && (
        <box py="{1}">
          <typographywrapper variant="body1">
            <div id="seel-ra-widget-root"></div>
          </typographywrapper>
        </box>
      )}
      <box display="flex" flexDirection="row" alignItems="center">
        <typographywrapper variant="h2" typographyOverrides="{{" style:="" {="" lineHeight:="" 1,="" marginRight:="" '6px'="" }="" }}="">
          Checkout With Card
        </typographywrapper>
        <box mb="{1}">
          <iconsecure width="22" height="22"></iconsecure>
        </box>
      </box>
      <paymentfields defaultPaymentMethod="{defaultPaymentMethod}" error="{error}" handleChangePaymentMethod="{handleChangePaymentMethod}" handleStripeElementRef="{handleStripeElementRef}" isCardValueValid="{isCardValueValid}" isLoading="{isLoading}" isSubmitting="{isSubmitting}" selectedPaymentMethod="{selectedPaymentMethod}"></paymentfields>
    </>
  );
};

export default StripePaymentFormDesktop;
</any></stripepaymentformdesktopprops>