import { useLocation } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useContext } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FORM_ERROR } from 'final-form';
import { Button, EmailTextField, Form } from '..';
import { subscribeEmail, SubscribeModalState } from '../../ducks/subscribeModal.duck';
import { useAppDispatch } from '../../hooks/appDispatch';
import { useShopConfig } from '../../hooks/shopConfig';
import { getFilterOptions } from '../../shopConfig/configHelper';
import { Cadence, EmailSubscribeSource } from '../../types/apollo/generated/types.generated';
import { FilterId } from '../../types/filters/filters';
import { RequestStatus } from '../../types/requestStatus';
import { CurrentUser } from '../../types/sharetribe/currentUser';
import { handle } from '../../util/helpers';
import { RELEASES_AND_DISCOUNTS_GROUP_ID } from '../../util/sendgrid';
import * as validators from '../../util/validators';
import { ButtonVariant } from '../Button/Button';
import css from './SubscribeModal.module.css';
import { getRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import AppContext from '../../context/AppContext';
import { PageName } from '../../types/routes';
import { ModalState, ModalType, resetActiveModal } from '../../ducks/modal.duck';

const ONE_SECOND = 1000; // in ms
const EMAIL = 'email';

interface SubscribeEmailFormValues {
  email: string;
}

interface SubscribeEmailFormProps {
  onSubmit: () => void;
}

const SubscribeEmailForm: FC<subscribeemailformprops> = (props) => {
  const { onSubmit } = props;

  const shopConfig = useShopConfig();
  const intl = useIntl();
  const location = useLocation();
  const { treetId } = useContext(AppContext);

  const dispatch = useAppDispatch();
  const currentUser = useSelector<any>((state) => state.user.currentUser) as
    | CurrentUser
    | undefined;
  const { subscribeEmailStatus } = useSelector<any>(
    (state) => state.subscribeModal
  ) as SubscribeModalState;
  const { activeModal } = useSelector<any>((state) => state.modal) as ModalState;

  const onEmailSubmit = async (values: SubscribeEmailFormValues) => {
    const { email } = values;

    const errors: any = {};

    const emailRequiredMessage = intl.formatMessage({ id: 'SignupForm.emailRequired' });
    const emailRequired = validators.required(emailRequiredMessage);
    const emailInvalidMessage = intl.formatMessage({ id: 'SignupForm.emailInvalid' });
    const emailValid = validators.emailFormatValid(emailInvalidMessage);

    const emailErrors = validators.composeValidators(emailRequired, emailValid)(email);
    if (emailErrors) {
      errors.email = emailErrors;
    }

    if (!isEmpty(errors)) return errors;

    const subscribeSource = EmailSubscribeSource.LandingPageModal;
    const sizeOptions: { key: string; label: string }[] | undefined = getFilterOptions(
      shopConfig,
      FilterId.Size
    );
    // Subscribe to all sizes initially
    const sizes = sizeOptions?.map((option) => option.key);

    const genericError = 'Oops, something went wrong. Please try again.';
    const [, subscribeError] = await handle(
      dispatch(
        subscribeEmail({
          email,
          sizes,
          groupId: RELEASES_AND_DISCOUNTS_GROUP_ID,
          subscribeSource,
          cadence: Cadence.Weekly,
        })
      )
    );

    if (subscribeError) return { [FORM_ERROR]: genericError };

    // Wait 1 second so that button success state renders before closing or transitioning the modal
    if (!sizes) {
      setTimeout(() => {
        if (activeModal === ModalType.Subscribe) {
          dispatch(resetActiveModal());
        }
      }, ONE_SECOND);
    } else {
      setTimeout(onSubmit, ONE_SECOND);
    }
    return errors;
  };

  const initialValues = { [EMAIL]: currentUser?.attributes.email };

  const routeName = getRouteName(location.pathname, routeConfiguration(treetId)) as PageName;
  const isAuthPage = [PageName.LoginPage, PageName.SignupPage].includes(routeName);

  return (
    <finalform initialValues="{initialValues}" onSubmit="{onEmailSubmit}" render="{(fieldRenderProps)" ==""> {
        const { handleSubmit, submitting, submitError } = fieldRenderProps;
        const isDisabled = submitting;

        return (
          <form onSubmit="{handleSubmit}">
            <grid container="" spacing="{1}" justifyContent="center">
              <grid item="" xs="{12}" md="{8}">
                <emailtextfield id="email-subscribe" name="{EMAIL}" label="Email" variant="outlined" required="" autoFocus="{!isAuthPage}"></emailtextfield>
              </grid>
              <grid item="" xs="{12}" md="{4}">
                <button type="submit" inProgress="{subscribeEmailStatus" =="=" RequestStatus.Pending}="" disabled="{isDisabled}" className="{css.subscribeEmailButton}" spinnerClassName="{css.subscribeEmailSpinner}" variant="{ButtonVariant.Secondary}" ready="{subscribeEmailStatus" RequestStatus.Success}="">
                  Subscribe
                </button>
              </grid>
              {!!submitError && (
                <box pt="{1}" display="flex" justifyContent="center">
                  <typography variant="body1" style="{{" color:="" 'white'="" }}="">
                    {submitError}
                  </typography>
                </box>
              )}
            </grid>
          </form>
        );
      }}
    />
  );
};

export default SubscribeEmailForm;
</finalform></any></any></any></subscribeemailformprops>