import React, { Component } from 'react';
import { oneOfType, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { AvatarSmall, FormattedMessage, IconArrowRight, IconVerified, InlineTextButton } from '..';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { propTypes } from '../../util/types';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { pluralize, truncated } from '../../util/strings';
import css from './UserCard.module.css';
import { useRouteConfiguration } from '../../hooks/useRouteConfiguration';
import { findRouteByRouteName, pathByRouteName } from '../../util/routes';
import { buildNewSearchString } from '../../util/urlHelpers';

// Approximated collapsed size so that there are ~three lines of text
// in the desktop layout in the host section of the ListingPage.
const BIO_COLLAPSED_LENGTH = 170;

class ExpandableBio extends Component {
  constructor(props) {
    super(props);
    this.state = { expand: false };
  }

  render() {
    const { expand } = this.state;
    const { bio } = this.props;
    const truncatedBio = truncated(bio, BIO_COLLAPSED_LENGTH);
    const handleShowMoreClick = () => {
      this.setState({ expand: true });
    };
    const showMore = (
      <box style="{{" display:="" 'inline'="" }}="">
        {' '}
        <inlinetextbutton rootClassName="{css.showMore}" onClick="{handleShowMoreClick}">
          <formattedmessage id="UserCard.showFullBioLink"></formattedmessage>
        </inlinetextbutton>
      </box>
    );
    return (
      <typographywrapper variant="body1">
        {expand ? bio : truncatedBio}
        {bio !== truncatedBio && !expand ? showMore : null}
      </typographywrapper>
    );
  }
}

ExpandableBio.propTypes = {
  bio: string.isRequired,
};

const UserProfileNavigation = ({ name, params, className, children, ...props }) => {
  const history = useHistory();
  const routes = useRouteConfiguration();
  const location = useLocation();

  const navigate = () => {
    const pathname = pathByRouteName(name, routes, params);
    const newSearchString = buildNewSearchString(location, {});
    history.push({ pathname, search: newSearchString });
  };

  const onOver = () => {
    const { component: Page } = findRouteByRouteName(name, routes);
    // Preload component if possible
    if (Page && Page.preload) {
      Page.preload();
    }
  };

  return (
    <box role="link" tabIndex="{0}" aria-label="Go to user's profile" sx="{{" cursor:="" 'pointer'="" }}="" className="{className}" onMouseOver="{onOver}" onTouchStart="{onOver}" onClick="{navigate}" {...props}="">
      {children}
    </box>
  );
};

const UserCardMobile = (props) => {
  const { ensuredUser, mainText, secondaryText } = props;

  return (
    <userprofilenavigation name="ProfilePage" params="{{" id:="" ensuredUser.id?.uuid="" }}="" className="{css.contentMobile}">
      <avatarsmall className="{css.avatar}" user="{ensuredUser}"></avatarsmall>
      <box display="flex" flexDirection="column" justifyContent="center">
        <box display="flex" alignItems="center" maxWidth="100%">
          <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline',="" style:="" {="" whiteSpace:="" 'nowrap',="" overflow:="" 'hidden',="" textOverflow:="" 'ellipsis',="" },="" }}="">
            {mainText}
          </typographywrapper>
          <iconverified className="{css.verifiedIcon}"></iconverified>
        </box>
        {secondaryText && (
          <box display="flex" alignItems="center">
            <typographywrapper variant="body2" weight="{TypographyWeight.Bold}" typographyOverrides="{{" style:="" {="" fontSize:="" '12px'="" }="" }}="">
              {secondaryText}
            </typographywrapper>
            <iconarrowright className="{css.otherListingsArrowIcon}"></iconarrowright>
          </box>
        )}
      </box>
    </userprofilenavigation>
  );
};

const UserCardDesktop = (props) => {
  const { ensuredUser, mainText, secondaryText } = props;

  return (
    <box>
      <userprofilenavigation name="ProfilePage" params="{{" id:="" ensuredUser.id?.uuid="" }}="" className="{css.contentDesktop}">
        <box display="flex" pr="{2}" maxWidth="50%">
          <avatarsmall className="{css.avatar}" user="{ensuredUser}"></avatarsmall>
          <box display="flex" alignItems="center" maxWidth="100%">
            <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline',="" style:="" {="" whiteSpace:="" 'nowrap',="" overflow:="" 'hidden',="" textOverflow:="" 'ellipsis',="" },="" }}="">
              {mainText}
            </typographywrapper>
            <iconverified className="{css.verifiedIcon}"></iconverified>
          </box>
        </box>
        {secondaryText && (
          <box display="flex" alignItems="center">
            <typographywrapper variant="body2" weight="{TypographyWeight.Bold}" typographyOverrides="{{" style:="" {="" fontSize:="" '12px'="" }="" }}="">
              {secondaryText}
            </typographywrapper>
            <iconarrowright className="{css.otherListingsArrowIcon}"></iconarrowright>
          </box>
        )}
      </userprofilenavigation>
    </box>
  );
};

const UserCard = (props) => {
  const { user } = props;

  const theme = useTheme();
  const isXSmallMediaQueryView = useMediaQuery(theme.breakpoints.down('xs'));

  const { authorListingIds } = useSelector((state) => state.ListingPage);

  const userIsCurrentUser = user && user.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);
  const { displayName } = ensuredUser.attributes.profile;

  let numOtherListingsDisplay;
  if (authorListingIds?.length > 1) {
    const numOtherListings = authorListingIds.length - 1;
    numOtherListingsDisplay = numOtherListings === 99 ? `${numOtherListings}+` : numOtherListings;
  }

  const mainText = `Sold by ${displayName}`;
  const secondaryText = numOtherListingsDisplay
    ? `${numOtherListingsDisplay} Other ${pluralize('Listing', numOtherListingsDisplay)}`
    : undefined;

  if (isXSmallMediaQueryView) {
    return (
      <usercardmobile ensuredUser="{ensuredUser}" mainText="{mainText}" secondaryText="{secondaryText}"></usercardmobile>
    );
  }

  return (
    <usercarddesktop ensuredUser="{ensuredUser}" mainText="{mainText}" secondaryText="{secondaryText}"></usercarddesktop>
  );
};

UserCard.defaultProps = {
  user: null,
};

UserCard.propTypes = {
  user: oneOfType([propTypes.user, propTypes.currentUser]),
};

export default UserCard;
