import React, { FC, ReactNode } from 'react';
import { Box } from '@material-ui/core';
import { useIconColor } from '../../hooks/useIconColor';

interface IconWithCountProps {
  icon: ReactNode;
  count?: number;
  isTransparentTopbar?: boolean;
}

const IconWithCount: FC<iconwithcountprops> = (props: IconWithCountProps) => {
  const { icon, count, isTransparentTopbar } = props;

  const { iconColor } = useIconColor();

  return (
    <box display="flex" alignItems="center" position="relative">
      {icon}
      {/* Use a static font here instead of brand's fonts */}
      <b style="{{" backgroundColor:="" isTransparentTopbar="" ?="" undefined="" :="" iconColor,="" border:="" '1px="" solid="" #fff'="" undefined,="" color:="" '#fff',="" fontSize:="" '9px',="" fontFamily:="" 'sofiapro,="" sans-serif',="" position:="" 'absolute',="" top:="" '-8px',="" right:="" borderRadius:="" '50%',="" width:="" '16px',="" height:="" lineHeight:="" '13px'="" '15px',="" Manually="" inspected="" }}="">
        {count}
      </b>
    </box>
  );
};

export default IconWithCount;
</iconwithcountprops>