import React, { FC } from 'react';

interface Icon70OffProps {
  className?: string;
}

const Icon70Off: FC<icon70offprops> = (props: Icon70OffProps) => {
  const { className } = props;

  return (
    <svg className="{className}" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="31.5" fill="#962A2A" stroke="#D1D1D1"></circle>
      <path d="M18.4806 28.5229L22.3086 20.4029H18.0829V18.0829H25.0429V19.9886L21.0657 28.5229H18.4806Z" fill="white"></path>
      <path d="M32.267 23.3029C32.267 21.3309 31.2727 20.32 30.1955 20.32C29.1184 20.32 28.1241 21.3309 28.1241 23.3029C28.1241 25.308 29.1184 26.2857 30.1955 26.2857C31.2727 26.2857 32.267 25.308 32.267 23.3029ZM25.7213 23.3029C25.7213 19.9886 27.7098 18 30.1955 18C32.6813 18 34.6698 19.9886 34.6698 23.3029C34.6698 26.6171 32.6813 28.6057 30.1955 28.6057C27.7098 28.6057 25.7213 26.6171 25.7213 23.3029Z" fill="white"></path>
      <path d="M42.4642 26.2857C42.4642 26.7994 42.8619 27.1971 43.3756 27.1971C43.8894 27.1971 44.2871 26.7994 44.2871 26.2857C44.2871 25.772 43.8894 25.3743 43.3756 25.3743C42.8619 25.3743 42.4642 25.772 42.4642 26.2857ZM41.0556 26.2857C41.0556 24.96 42.0499 23.9657 43.3756 23.9657C44.7014 23.9657 45.6956 24.96 45.6956 26.2857C45.6956 27.6114 44.7014 28.6057 43.3756 28.6057C42.0499 28.6057 41.0556 27.6114 41.0556 26.2857ZM38.9842 20.32C38.9842 19.8063 38.5865 19.4086 38.0728 19.4086C37.5591 19.4086 37.1614 19.8063 37.1614 20.32C37.1614 20.8337 37.5591 21.2314 38.0728 21.2314C38.5865 21.2314 38.9842 20.8337 38.9842 20.32ZM35.7528 20.32C35.7528 18.9943 36.7471 18 38.0728 18C39.3985 18 40.3928 18.9943 40.3928 20.32C40.3928 21.6457 39.3985 22.64 38.0728 22.64C36.7471 22.64 35.7528 21.6457 35.7528 20.32ZM36.5814 28.5229L42.9614 18.0829H44.8671L38.4871 28.5229H36.5814Z" fill="white"></path>
      <path d="M23.1371 43.2857C24.6451 43.2857 25.8714 42.0263 25.8714 40.3029C25.8714 38.5794 24.6451 37.32 23.1371 37.32C21.6291 37.32 20.4029 38.5794 20.4029 40.3029C20.4029 42.0263 21.6291 43.2857 23.1371 43.2857ZM23.1371 45.6057C20.3034 45.6057 18 43.2857 18 40.3029C18 37.32 20.3034 35 23.1371 35C25.9709 35 28.2743 37.32 28.2743 40.3029C28.2743 43.2857 25.9709 45.6057 23.1371 45.6057Z" fill="white"></path>
      <path d="M29.7605 45.5229V35.0829H36.9691V37.4029H32.1634V39.5571H36.0577V41.8771H32.1634V45.5229H29.7605Z" fill="white"></path>
      <path d="M38.3699 45.5229V35.0829H45.5785V37.4029H40.7728V39.5571H44.6671V41.8771H40.7728V45.5229H38.3699Z" fill="white"></path>
    </svg>
  );
};

export default Icon70Off;
</icon70offprops>