import {
  IconCamera,
  IconCash,
  IconFabric,
  IconPaw,
  IconQuestion,
  IconSizingTape,
  IconSmoking,
} from '..';

export enum ListingFeedbackFormField {
  Email = 'email',
  Category = 'category',
  Note = 'note',
}

export type ListingFeedbackFormValues = Record<listingfeedbackformfield, string="">;

export enum ListingFeedbackCategory {
  Photos = 'PHOTOS',
  Sizing = 'SIZING',
  Quirks = 'QUIRKS',
  Material = 'MATERIAL',
  Pets = 'PETS',
  Smoking = 'SMOKING',
  Price = 'PRICE',
  Other = 'OTHER',
}

export const buttonDetails = {
  [ListingFeedbackCategory.Photos]: {
    structuredText: [{ text: 'Not enough photos', bold: true }, { text: 'for this listing' }],
    Icon: IconCamera,
    key: ListingFeedbackCategory.Photos,
  },
  [ListingFeedbackCategory.Sizing]: {
    structuredText: [{ text: 'Can you provide more' }, { text: 'sizing details?', bold: true }],
    Icon: IconSizingTape,
    key: ListingFeedbackCategory.Sizing,
  },
  [ListingFeedbackCategory.Quirks]: {
    structuredText: [{ text: 'Question about' }, { text: 'item quirks', bold: true }],
    Icon: IconQuestion,
    key: ListingFeedbackCategory.Quirks,
  },
  [ListingFeedbackCategory.Material]: {
    structuredText: [{ text: 'What is the' }, { text: 'material', bold: true }, { text: 'like?' }],
    Icon: IconFabric,
    key: ListingFeedbackCategory.Material,
  },
  [ListingFeedbackCategory.Pets]: {
    structuredText: [{ text: 'Does this come from a house with' }, { text: 'pets?', bold: true }],
    Icon: IconPaw,
    key: ListingFeedbackCategory.Pets,
  },
  [ListingFeedbackCategory.Smoking]: {
    structuredText: [
      { text: 'Does this come from a' },
      { text: 'smoking', bold: true },
      { text: 'household?' },
    ],
    Icon: IconSmoking,
    key: ListingFeedbackCategory.Smoking,
  },
  [ListingFeedbackCategory.Price]: {
    structuredText: [{ text: 'Pricing', bold: true }, { text: 'questions' }],
    Icon: IconCash,
    key: ListingFeedbackCategory.Price,
  },
  [ListingFeedbackCategory.Other]: {
    structuredText: [{ text: 'Other Question', bold: true }],
    Icon: IconQuestion,
    key: ListingFeedbackCategory.Other,
  },
};

type StructuredTextType = { text: string; bold?: boolean };

export const CATEGORY_TO_DEFAULT_NOTE: Record<listingfeedbackcategory, string=""> = Object.entries(
  buttonDetails
).reduce(
  (
    acc: Record<listingfeedbackcategory, string="">,
    [category, buttonDetail]
  ): Record<listingfeedbackcategory, string=""> => {
    const structuredTexts: StructuredTextType[] = buttonDetail.structuredText;
    const note: string = structuredTexts
      .reduce(
        (noteAcc: string, structuredText: StructuredTextType): string =>
          `${noteAcc}${structuredText.text} `,
        ''
      )
      .trim();
    acc[category as ListingFeedbackCategory] = note;
    return acc;
  },
  {} as Record<listingfeedbackcategory, string="">
);
</listingfeedbackcategory,></listingfeedbackcategory,></listingfeedbackcategory,></listingfeedbackcategory,></listingfeedbackformfield,>