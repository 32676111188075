/* eslint-disable import/prefer-default-export */
import { isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { useContext } from 'react';
import { useShopConfig } from './shopConfig';
import { CurrentUser } from '../types/sharetribe/currentUser';
import AppContext from '../context/AppContext';
import { useCurrentUserPermissions } from './useUserPermissions';

// Keep in sync with server/api-util/createListings/sharetribe.ts
const calculateCashPayoutPercentage = (
  isBrand: boolean,
  cashPayoutPercentage: number,
  overrideCashPayoutPercentage?: number
) => {
  if (isBrand && !isNil(overrideCashPayoutPercentage)) return overrideCashPayoutPercentage;

  // Override cash payout to be the standard rate (80%) if cash payout is set to 0,
  // e.g. brand does credit only for non-BD items.
  return isBrand && cashPayoutPercentage === 0 ? 0.8 : cashPayoutPercentage;
};

export const useCashPayoutPercentage = (cashPayoutPercentage?: number) => {
  const { treetId } = useContext(AppContext);
  const { cashPayoutPercentage: configCashPayoutPercentage } = useShopConfig();
  const { isBrand } = useCurrentUserPermissions();
  const currentUser = useSelector<any>((state) => state.user.currentUser) as
    | CurrentUser
    | undefined;

  const overrideCashPayoutPercentage =
    currentUser?.attributes.profile?.protectedData?.overridePayoutInfo?.[treetId]
      ?.cashPayoutPercentage;

  return calculateCashPayoutPercentage(
    isBrand,
    cashPayoutPercentage || configCashPayoutPercentage,
    overrideCashPayoutPercentage
  );
};
</any>