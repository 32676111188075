import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';
import { CustomIconType, useCustomIcon } from '../../../hooks/useCustomIcon';
import CustomIcon from '../../CustomIcon/CustomIcon';

interface IconShoppingBagProps {
  className?: string;
  color?: string;
}

const IconShoppingBag: FC<iconshoppingbagprops> = (props: IconShoppingBagProps) => {
  const { className, color } = props;

  const customShoppingBagIcon = useCustomIcon(CustomIconType.ShoppingBag);
  const { iconColor } = useIconColor();

  if (customShoppingBagIcon) {
    return (
      <customicon url="{customShoppingBagIcon.icon.url}" altText="shopping-bag" className="{className}"></customicon>
    );
  }
  const fillColor = color || iconColor;

  return (
    <>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M18 8V9H14V8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8ZM12 9V8C12 5.79086 13.7909 4 16 4C18.2091 4 20 5.79086 20 8V9H25L27 26H5L7 9H12ZM20 11H12H8.7785L7.24909 24H24.7509L23.2215 11H20Z" fill="{fillColor}"></path>
      </svg>
    </>
  );
};

export default IconShoppingBag;
</iconshoppingbagprops>