import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import { GemPing, NamedLink } from '..';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import ISOHeader from './ISOHeader';
import { useActiveModal } from '../../hooks/useActiveModal';
import { ModalType } from '../../ducks/modal.duck';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Feature } from '../../util/featureFlags';
import css from './ISOModal.module.css';

const ISOSuccessPane: FC = () => {
  const { values } = useFormState();
  const { closeModal: closeISOModal } = useActiveModal(ModalType.ISO);
  const isEmailManagementEnabled = useFeatureFlags(Feature.EmailManagement);
  const location = useLocation();

  const title = values?.shopifyProduct?.title;
  const imgSrc = values?.shopifyProduct?.featuredImage?.originalSrc;

  return (
    <box height="100%">
      <isoheader headerCopy="We’re On It!"></isoheader>
      <box 5="" py="{{" xs:="" 10,="" md:="" }}="" px="{2}" display="flex" flexDirection="{{" 'column',="" 'row'="" alignItems="center" justifyContent="center" flexGrow="{1}">
        <box position="relative">
          {/* eslint-disable jsx-a11y/img-redundant-alt, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
          <img className="{css.productImage}" src="{imgSrc}" alt="product photo">
          <box width="100px" height="100px" position="absolute" top="{0}" right="{0}">
            <gemping></gemping>
          </box>
        </box>
        <box 0="" px="{{" xs:="" 5,="" md:="" }}="" textAlign="{{" 'center',="" 'left'="">
          <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
            We’ll email you when
          </typographywrapper>
          <typographywrapper variant="body1" weight="{TypographyWeight.Bold}" typographyOverrides="{{" display:="" 'inline'="" }}="">
            &nbsp;{title}&nbsp;
          </typographywrapper>
          <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
            lists!
          </typographywrapper>
          {isEmailManagementEnabled && location.pathname !== '/notification-settings' && (
            <box pt="{1}">
              <namedlink name="NotificationSettingsPage" to="{{" hash:="" 'item-requests'="" }}="" className="{css.link}" onClick="{closeISOModal}">
                <typographywrapper variant="body1">Manage your item requests</typographywrapper>
              </namedlink>
            </box>
          )}
        </box>
      </box>
    </box>
  );
};

export default ISOSuccessPane;
