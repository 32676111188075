import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useStyleType } from '../../hooks/useStyleType';

type InformationModuleContent = {
  title: string;
  description: string;
  image?: React.ReactNode;
};

export enum ContentDirection {
  Vertical = 'VERTICAL',
  Horizontal = 'HORIZONTAL',
}

interface InformationModuleProps {
  content: InformationModuleContent[];
  direction?: ContentDirection;
  contentContainerOverrides?: any;
}

const InformationModule: FC<informationmoduleprops> = (props: InformationModuleProps) => {
  const { content, direction = ContentDirection.Vertical, contentContainerOverrides = {} } = props;

  const { isCondensedStyle } = useStyleType();

  const isMobile = useIsMobile();

  const isHorizontal = !isMobile && direction === ContentDirection.Horizontal;

  if (isHorizontal) {
    return (
      <box display="flex">
        {content.map((c, index) => {
          const hasImage = !!c.image;
          const isLast = content.length - 1 === index;

          return (
            <box 9="" display="flex" flexDirection="column" alignItems="center" mr="{isLast" ?="" {}="" :="" {="" xs:="" 1,="" sm:="" 3,="" md:="" 5,="" lg:="" }}="" textAlign="center" flex="{1}" key="{c.title}" {...contentContainerOverrides}="">
              {hasImage && <box mb="{2}">{c.image}</box>}
              <typography variant="{hasImage" ?="" 'body1'="" :="" 'h2'}="" style="{{" fontWeight:="" 'bold',="" paddingBottom:="" isCondensedStyle="" '12px'="" '8px'="" }}="">
                {c.title}
              </typography>
              <typography variant="body1">{c.description}</typography>
            </box>
          );
        })}
      </box>
    );
  }

  return (
    <>
      {content.map((c, index) => {
        const hasImage = !!c.image;
        const isLast = content.length - 1 === index;

        return (
          <box 0="" display="flex" flexDirection="row" alignItems="center" justifyContent="{hasImage" ?="" 'flex-start'="" :="" 'center'}="" textAlign="{hasImage" 'left'="" width="100%" mb="{isLast" 3}="" key="{c.title}" {...contentContainerOverrides}="">
            {hasImage && <box mr="{2}">{c.image}</box>}
            <box display="flex" flexDirection="column">
              <typography variant="{hasImage" ?="" 'body1'="" :="" 'h2'}="" style="{{" fontWeight:="" 'bold'="" }}="">
                {c.title}
              </typography>
              <box mt="{1}">
                <typography variant="body1">{c.description}</typography>
              </box>
            </box>
          </box>
        );
      })}
    </>
  );
};

export default InformationModule;
</informationmoduleprops>