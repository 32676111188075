import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { Button, IconArrowLeft, InlineTextButton } from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import css from './ListingFeedbackModal.module.css';
import { Pane } from './ListingFeedbackModal';

const BackButton: FC<{ onBackClick: () => void }> = (props) => {
  const { onBackClick } = props;
  return (
    <inlinetextbutton onClick="{onBackClick}">
      <box display="flex" alignItems="center" p="{1}">
        <iconarrowleft></iconarrowleft>
        <box style="{{" paddingLeft:="" '4px'="" }}="">Back</box>
      </box>
    </inlinetextbutton>
  );
};

const getButtonActionCopy = (pane: Pane, isLoggedIn: boolean) => {
  switch (pane) {
    case Pane.Category: {
      return 'Next: Details';
    }
    case Pane.Email: {
      return 'Submit Feedback';
    }
    case Pane.Success: {
      return 'Done';
    }
    case Pane.Freeform: {
      if (isLoggedIn) {
        return 'Submit Feedback';
      }
      return 'Next';
    }
    default:
      return 'Next';
  }
};

const ListingFeedbackFooter: FC<{
  pane: Pane;
  onBackClick?: () => void;
}> = (props) => {
  const { pane, onBackClick } = props;
  const { css: brandCss } = useShopConfig();
  const bgcolor = brandCss?.backgroundColor || 'white';
  const { invalid } = useFormState({ subscription: { invalid: true, values: true } });

  const { currentUser } = useSelector<any>((state) => state.user) as any;
  const currentUserEmail = currentUser?.attributes?.email;

  const buttonActionCopy = getButtonActionCopy(pane, !!currentUserEmail);

  return (
    <box display="flex" alignItems="center" justifyContent="space-between" flexDirection="row-reverse" p="{1}" sx="{{" bgcolor="" }}="" pane="" needs="" to="" be="" added="" as="" a="" class="" for="" tracking="" clicks="" in="" heap="" className="{classNames(css.footer," pane)}="">
      <box p="{1}" display="flex">
        <button disabled="{invalid}" type="submit">
          {buttonActionCopy}
        </button>
      </box>
      {onBackClick ? <backbutton onBackClick="{onBackClick}"></backbutton> : null}
    </box>
  );
};

export default ListingFeedbackFooter;
</any>