import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconAdminFilledProps {
  className?: string;
  color?: string;
}

const IconAdminFilled: FC<iconadminfilledprops> = (props: IconAdminFilledProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg className="{className}" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.94709 3.47009C5.20706 3.94486 4 5.52539 4 7.32903V12.5975C4 17.3952 6.15293 21.9397 9.86548 24.9786L13.4664 27.9261C14.9401 29.1324 17.0599 29.1324 18.5336 27.9261L22.1345 24.9786C25.8471 21.9397 28 17.3952 28 12.5975V7.32903C28 5.52539 26.7929 3.94486 25.0529 3.47009L23.3704 3.01101C18.545 1.69441 13.455 1.69441 8.62964 3.01101L6.94709 3.47009ZM16 8.00001L17.8838 11.9072L22 12.5836L19.048 15.6748L19.7082 20L16 18.0032L12.2918 20L12.952 15.6748L10 12.5836L14.1162 11.9072L16 8.00001Z" fill="{fillColor}"></path>
    </svg>
  );
};

export default IconAdminFilled;
</iconadminfilledprops>