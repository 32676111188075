import { Grid } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Button, FieldTextInput, InlineTextButton, TypographyWrapper } from '../../components';
import { validateVoucher, VoucherifyState } from '../../ducks/voucherify.duck';
import { useAppDispatch } from '../../hooks/appDispatch';
import { RequestStatus } from '../../types/requestStatus';
import { handle } from '../../util/helpers';
import { CheckoutPageState, fetchOrderLineItems, setVoucher } from './CheckoutPage.duck';

import css from './DiscountCodeInput.module.css';

const DiscountCodeInput: FC = () => {
  const dispatch = useAppDispatch();

  const { fetchLineItemsStatus } = useSelector<any>(
    (state) => state.CheckoutPage
  ) as CheckoutPageState;
  const { validateVoucherStatus } = useSelector<any>(
    (state) => state.voucherify
  ) as VoucherifyState;

  const [shouldShowInput, setShouldShowInput] = useState<boolean>(false);
  const [discountCodeError, setDiscountCodeError] = useState<string |="" undefined="">(undefined);

  const onApplyDiscountCode = async (values: { discountCode?: string }) => {
    const { discountCode } = values;
    if (!discountCode) return;

    const [voucherResponse, voucherError] = await handle(dispatch(validateVoucher(discountCode)));
    await dispatch(setVoucher(voucherResponse));

    if (voucherError) {
      setDiscountCodeError('This discount code is not valid.');
    } else {
      setDiscountCodeError(undefined);
    }
    dispatch(fetchOrderLineItems());
  };

  if (!shouldShowInput) {
    return (
      <inlinetextbutton onClick="{()" ==""> setShouldShowInput(true)}>
        <h5 className="{css.addLink}">
          <typographywrapper variant="body2">Add a discount code</typographywrapper>
        </h5>
      </inlinetextbutton>
    );
  }

  const isDiscountApplicationLoading = validateVoucherStatus === RequestStatus.Pending;
  const shouldDisableApplication =
    isDiscountApplicationLoading || fetchLineItemsStatus !== RequestStatus.Success;

  return (
    <finalform onSubmit="{onApplyDiscountCode}" render="{(formRenderProps)" ==""> {
        const { handleSubmit } = formRenderProps;
        return (
          <form id="discountCodeForm" onSubmit="{handleSubmit}">
            <grid className="{css.discountCodeWrapper}" container="" direction="row" alignItems="center" spacing="{3}">
              <grid item="" xs="{9}">
                <fieldtextinput id="discountCode" name="discountCode" placeholder="TREET20OFF" label="Discount Code" customErrorText="{discountCodeError}"></fieldtextinput>
              </grid>
              <grid item="" xs="{3}" className="{css.applyButtonWrapper}">
                <button type="submit" form="discountCodeForm" inProgress="{isDiscountApplicationLoading}" disabled="{shouldDisableApplication}" className="{css.applyButton}">
                  Apply
                </button>
              </grid>
            </grid>
          </form>
        );
      }}
    />
  );
};

export default DiscountCodeInput;
</finalform></string></boolean></any></any>