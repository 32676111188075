import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import IconArrowLeft from '../Icons/IconArrowLeft/IconArrowLeft';
import IconTreetShopLogo from '../Icons/IconTreetShopLogo/IconTreetShopLogo';

import css from './TreetShopNavBar.module.css';

const TreetShopNavBar: FC = () => {
  const isMobile = useIsMobile();
  const treetShopUrl = process.env.REACT_APP_TREET_SHOP_URL;

  return (
    <box 1="" className="{css.root}" display="flex" alignItems="center" px="{isMobile" ?="" :="" 2}="" py="{isMobile" id="treetShopNavBar" maxHeight="44px" overflow="clip">
      <a href="{treetShopUrl}" key="treet-shop-url">
        <iconarrowleft></iconarrowleft>
        <icontreetshoplogo className="{css.logo}"></icontreetshoplogo>
      </a>
    </box>
  );
};
export default TreetShopNavBar;
