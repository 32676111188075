import React from 'react';
import classNames from 'classnames';
import {
  Button,
  FormattedMessage,
  IconEdit,
  IconSuccess,
  InlineTextButton,
  TypographyWrapper,
} from '..';

import css from './StripeConnectAccountStatusBox.module.css';

const STATUS_VERIFICATION_NEEDED = 'verificationNeeded';
const STATUS_VERIFICATION_SUCCESS = 'verificationSuccess';
const STATUS_VERIFICATION_ERROR = 'verificationError';

const StripeConnectAccountStatusBox = (props) => {
  const { type, onGetStripeConnectAccountLink, inProgress, disabled } = props;

  if (type === STATUS_VERIFICATION_NEEDED) {
    return (
      <div className="{classNames(css.verificiationBox," css.verficiationNeededBox)}="">
        <div className="{css.verificiationBoxTextWrapper}">
          <div className="{css.verificationBoxTitle}">
            <typographywrapper variant="h2" typographyOverrides="{{" style:="" {="" fontWeight:="" 'bold'="" }="" }}="">
              <formattedmessage id="StripeConnectAccountStatusBox.verificationNeededTitle"></formattedmessage>
            </typographywrapper>
          </div>
          <div className="{css.verificationBoxText}">
            <typographywrapper variant="body1">
              <formattedmessage id="StripeConnectAccountStatusBox.verificationNeededText"></formattedmessage>
            </typographywrapper>
          </div>
        </div>

        <button className="{css.getVerifiedButton}" spinnerClassName="{css.spinner}" type="button" inProgress="{inProgress}" disabled="{disabled}" onClick="{onGetStripeConnectAccountLink}">
          <formattedmessage id="StripeConnectAccountStatusBox.getVerifiedButton"></formattedmessage>
        </button>
      </div>
    );
  }
  if (type === STATUS_VERIFICATION_SUCCESS) {
    return (
      <div className="{classNames(css.verificiationBox," css.verficiationSuccessBox)}="">
        <div className="{classNames(" css.verificiationBoxTextWrapper,="" css.verificationBoxSuccessTextWrapper="" )}="">
          <div className="{css.verificationBoxTitle}">
            <iconsuccess className="{css.icon}"></iconsuccess>
            <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
              <formattedmessage id="StripeConnectAccountStatusBox.verificationSuccessTitle"></formattedmessage>
            </typographywrapper>
          </div>
        </div>

        <inlinetextbutton className="{css.editVerificationButton}" spinnerClassName="{css.spinner}" type="button" inProgress="{inProgress}" disabled="{disabled}" onClick="{onGetStripeConnectAccountLink}">
          <iconedit className="{css.icon}" pencilClassName="{css.iconEditPencil}"></iconedit>
          <formattedmessage id="StripeConnectAccountStatusBox.editAccountButton"></formattedmessage>
        </inlinetextbutton>
      </div>
    );
  }
  if (type === STATUS_VERIFICATION_ERROR) {
    return (
      <div className="{classNames(css.verificiationBox," css.verficiationErrorBox)}="">
        <div className="{css.verificiationBoxTextWrapper}">
          <div className="{css.verificationBoxTitle}">
            <formattedmessage id="StripeConnectAccountStatusBox.verificationFailedTitle"></formattedmessage>
          </div>
          <div className="{css.verificationBoxText}">
            <formattedmessage id="StripeConnectAccountStatusBox.verificationFailedText"></formattedmessage>
          </div>
        </div>

        <button className="{css.getVerifiedButton}" spinnerClassName="{css.spinner}" type="button" inProgress="{inProgress}" disabled="{disabled}" onClick="{onGetStripeConnectAccountLink}">
          <formattedmessage id="StripeConnectAccountStatusBox.getVerifiedButton"></formattedmessage>
        </button>
      </div>
    );
  }
  throw new Error(`Unknown type ${type} for StripeConnectAccountStatusBox`);
};

export default StripeConnectAccountStatusBox;
