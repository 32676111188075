import { Typography } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { NamedLink } from '../../components';
import { ListingPageParamTab } from './EditListingPage.utils';
import { EditListingPageState } from './EditListingPage.duck';

import css from './EditListingPage.module.css';
import { ListingItemType } from '../../types/sharetribe/listing';
import { getUrlHash } from '../../util/urlHelpers';
import { ISOContext } from '../../components/ISOModal/ISOModal';

interface CannotFindItemLinkProps {
  listingItemType?: ListingItemType;
  children?: React.ReactNode;
}

const CannotFindItemLink: FC<cannotfinditemlinkprops> = (props) => {
  const { listingItemType, children } = props;
  const isISO = useContext(ISOContext);
  const { params } = useSelector<any>((state) => state.EditListingPage) as EditListingPageState;
  const search = listingItemType ? new URLSearchParams({ listingItemType }).toString() : null;
  const hash = getUrlHash()?.substring(1);

  // TODO: TREET-5498 determine whether this ISO context is still necessary
  if (isISO) {
    return null;
  }

  return (
    <namedlink name="EditListingPage" params="{{" ...params,="" tab:="" ListingPageParamTab.CannotFind="" }}="" className="{css.link}" {...{="" to:="" {="" search,="" hash="" }="">
      {children || <typography variant="body2">Can’t find your item?</typography>}
    </namedlink>
  );
};

export default CannotFindItemLink;
</any></cannotfinditemlinkprops>