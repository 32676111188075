import React from 'react';
import { bool, func, node, object } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Form, TypographyWrapper } from '../../components';

import css from './FilterForm.module.css';
import { defaultTreetStyles } from '../../shopConfig/config';

const FilterFormComponent = (props) => {
  const { liveEdit, onChange, onSubmit, onCancel, onClear, ...rest } = props;

  if (liveEdit && !onChange) {
    throw new Error('FilterForm: if liveEdit is true you need to provide onChange function');
  }

  if (!liveEdit && !(onCancel && onClear && onSubmit)) {
    throw new Error(
      'FilterForm: if liveEdit is false you need to provide onCancel, onClear, and onSubmit functions'
    );
  }

  const handleChange = (formState) => {
    if (formState.dirty) {
      onChange(formState.values);
    }
  };

  const formCallbacks = liveEdit ? { onSubmit: () => null } : { onSubmit, onCancel, onClear };
  return (
    <finalform {...rest}="" {...formCallbacks}="" mutators="{{" ...arrayMutators="" }}="" render="{(formRenderProps)" ==""> {
        const {
          id,
          form,
          handleSubmit,
          /* eslint-disable-next-line @typescript-eslint/no-shadow */
          onClear,
          /* eslint-disable-next-line @typescript-eslint/no-shadow */
          onCancel,
          style,
          paddingClasses,
          intl,
          children,
        } = formRenderProps;

        const handleCancel = () => {
          // reset the final form to initialValues
          form.reset();
          onCancel();
        };

        const clear = intl.formatMessage({ id: 'FilterForm.clear' });
        const cancel = intl.formatMessage({ id: 'FilterForm.cancel' });
        const submit = intl.formatMessage({ id: 'FilterForm.submit' });

        const classes = classNames(css.root);

        return (
          <form id="{id}" className="{classes}" onSubmit="{handleSubmit}" tabIndex="0" style="{{" ...style="" }}="">
            <div className="{classNames(paddingClasses" ||="" css.contentWrapper)}="">{children}</div>

            {liveEdit ? (
              <formspy onChange="{handleChange}" subscription="{{" values:="" true,="" dirty:="" true="" }}=""></formspy>
            ) : (
              <div className="{css.buttonsWrapper}">
                <button className="{css.clearButton}" type="button" onClick="{onClear}">
                  <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.gray40="" }="" }}="">
                    {clear}
                  </typographywrapper>
                </button>
                <button className="{css.cancelButton}" type="button" onClick="{handleCancel}">
                  <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.gray40="" }="" }}="">
                    {cancel}
                  </typographywrapper>
                </button>
                <button className="{css.submitButton}" type="submit">
                  <typographywrapper variant="body1">{submit}</typographywrapper>
                </button>
              </div>
            )}
          </form>
        );
      }}
    />
  );
};

FilterFormComponent.defaultProps = {
  liveEdit: false,
  style: null,
  onCancel: null,
  onChange: null,
  onClear: null,
  onSubmit: null,
};

FilterFormComponent.propTypes = {
  liveEdit: bool,
  onCancel: func,
  onChange: func,
  onClear: func,
  onSubmit: func,
  style: object,
  children: node.isRequired,

  // form injectIntl
  intl: intlShape.isRequired,
};

const FilterForm = injectIntl(FilterFormComponent);

export default FilterForm;
</finalform>