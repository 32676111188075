import { compact, isEmpty } from 'lodash';
import React, { FC } from 'react';
import { useActivePromos } from '../../hooks/useActivePromos';
import HighlightBanner from './HighlightBanner';

const PromoHighlightBanner: FC = () => {
  const { activePromoHighlightBanners } = useActivePromos();

  if (isEmpty(activePromoHighlightBanners)) {
    return null;
  }

  return (
    <>
      {compact(activePromoHighlightBanners).map((banner) => (
        <highlightbanner text="{banner.bannerText}" color="{banner.color}" key="{banner.bannerText}"></highlightbanner>
      ))}
    </>
  );
};

export default PromoHighlightBanner;
