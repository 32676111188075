import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box } from '@material-ui/core';
import { FormattedMessage } from '..';
import Button from '../Button/Button';
import AppContext from '../../context/AppContext';
import { useShopConfigV2 } from '../../hooks/shopConfig';
import { useStyleType } from '../../hooks/useStyleType';
import { getShopConfig } from '../../shopConfig/configHelper';
import { LANDING_PAGE_SECTION } from '../../shopConfig/config';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import { useIsMobile } from '../../hooks/useIsMobile';

import css from './SectionAbout.module.css';

const scrollToHowItWorksSection = () => {
  const element = document.getElementById('howItWorksSection');
  const offset = 65;

  const bodyRect = document.body.getBoundingClientRect().top;

  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
};

const SectionAboutOverlapLayout = (props) => {
  const { classes, imageUrl, missionTitle, missionDescription, landingPageLayoutOrder, actionEl } =
    props;

  const { images, css: brandCss } = useShopConfigV2();
  const { isCondensedStyle } = useStyleType();
  const isMobile = useIsMobile();
  const { missionImageStyle } = images;
  const style = isMobile
    ? brandCss?.missionContentStyle?.mobile
    : brandCss?.missionContentStyle?.desktop;

  const actions =
    actionEl ||
    (landingPageLayoutOrder.includes(LANDING_PAGE_SECTION.HOW_IT_WORKS) && (
      <button className="{css.button}" onClick="{()" ==""> {
          scrollToHowItWorksSection();
        }}
      >
        HOW IT WORKS
      </button>
    ));

  return (
    <div className="{classes}">
      <div className="{css.imageWrapper}">
        <img src="{imageUrl}" alt="About Us" className="{css.aboutImage}" style="{missionImageStyle}">
      </div>
      <box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className="{css.text}" p="{{" xs:="" '32px',="" sm:="" '56px="" 72px'="" }}="" bgcolor="{brandCss?.backgroundColor" ||="" 'white'}="" style="{style}">
        <box>
          <h1 className="{css.title}">
            <typographywrapper variant="h1">{missionTitle}</typographywrapper>
          </h1>
        </box>
        <box mt="{1}">
          <div className="{classNames(css.description," {="" [css.condensedDescription]:="" isCondensedStyle,="" })}="">
            <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" whiteSpace:="" 'pre-wrap'="" }="" }}="">
              {missionDescription}
            </typographywrapper>
          </div>
        </box>
        <box 3="" pt="{isCondensedStyle" ?="" :="" 2}="" width="100%">
          {actions}
        </box>
      </box>
    </div>
  );
};

const SectionAbout = (props) => {
  const { rootClassName, className, actionEl } = props;

  const { treetId, shopConfig: shopConfigV2 } = useContext(AppContext);
  const { copy = {}, landingPageLayoutOrder, shopName } = getShopConfig(treetId, shopConfigV2);

  // Config V2
  const missionTitleV2 = shopConfigV2?.copy?.missionTitle;

  const missionDescriptionV2 = shopConfigV2?.copy?.missionDescription;

  const imageUrlV2 = shopConfigV2?.images?.missionImage?.url;

  // Config V1
  const { missionStatement, missionStatementTitle } = copy;

  const missionTitleV1 = (
    <>
      {missionStatementTitle || <formattedmessage id="SectionAbout.title" values="{{" shopName="" }}=""></formattedmessage>}
    </>
  );
  const missionDescriptionV1 = (
    <>{missionStatement || <formattedmessage id="SectionAbout.description"></formattedmessage>}</>
  );
  const imageUrlV1 = `/static/aboutImages/${treetId}.jpg`;

  const missionTitle = missionTitleV2 || missionTitleV1;
  const missionDescription = missionDescriptionV2 || missionDescriptionV1;
  const imageUrl = imageUrlV2 || imageUrlV1;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <sectionaboutoverlaplayout classes="{classes}" imageUrl="{imageUrl}" missionTitle="{missionTitle}" missionDescription="{missionDescription}" landingPageLayoutOrder="{landingPageLayoutOrder}" actionEl="{actionEl}"></sectionaboutoverlaplayout>
  );
};

SectionAbout.defaultProps = {
  rootClassName: null,
  className: null,
  actionEl: null,
};

const { string, node } = PropTypes;

SectionAbout.propTypes = {
  rootClassName: string,
  className: string,
  actionEl: node,
};

export default SectionAbout;
