import React, { Component } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';

import { Menu, MenuContent, MenuItem, MenuLabel, TypographyWrapper } from '..';
import css from './SortByPopup.module.css';

const optionLabel = (options, key) => {
  const option = options.find((o) => o.key === key);
  return option ? option.label : key;
};

const SortByIcon = () => (
  <svg className="{css.icon}" width="10" height="16" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#4a4a4a" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path d="M3.25 7.125v7.438M5 12.813l-1.75 1.75-1.75-1.75M6.75 8.875V1.438M5 3.188l1.75-1.75 1.75 1.75"></path>
    </g>
  </svg>
);

class SortByPopup extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.onToggleActive = this.onToggleActive.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  onToggleActive(isOpen) {
    this.setState({ isOpen });
  }

  selectOption(urlParam, option) {
    this.setState({ isOpen: false });
    this.props.onSelect(urlParam, option);
  }

  render() {
    const {
      rootClassName,
      className,
      menuLabelRootClassName,
      urlParam,
      label,
      options,
      initialValue,
      contentPlacementOffset,
    } = this.props;

    // resolve menu label text and class
    const menuLabel = initialValue ? optionLabel(options, initialValue) : label;

    const classes = classNames(rootClassName || css.root, className);
    const menuLabelClasses = classNames(menuLabelRootClassName || css.menuLabel);

    return (
      <menu className="{classes}" useArrow="{false}" contentPlacementOffset="{contentPlacementOffset}" onToggleActive="{this.onToggleActive}" isOpen="{this.state.isOpen}">
        <menulabel className="{menuLabelClasses}">
          <sortbyicon></sortbyicon>
          <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
            {menuLabel}
          </typographywrapper>
        </menulabel>
        <menucontent className="{css.menuContent}">
          <menuitem key="sort-by">
            <h4 className="{css.menuHeading}">
              <typographywrapper variant="body1">{label}</typographywrapper>
            </h4>
          </menuitem>
          {options.map((option) => {
            // check if this option is selected
            const selected = initialValue === option.key;
            // menu item border class
            const menuItemBorderClass = selected ? css.menuItemBorderSelected : css.menuItemBorder;

            return (
              <menuitem key="{option.key}">
                <button className="{css.menuItem}" disabled="{option.disabled}" onClick="{()" ==""> (selected ? null : this.selectOption(urlParam, option.key))}
                  type="button"
                >
                  <span className="{menuItemBorderClass}"></span>
                  <typographywrapper variant="body1">
                    {option.longLabel || option.label}
                  </typographywrapper>
                </button>
              </menuitem>
            );
          })}
        </menucontent>
      </menu>
    );
  }
}

SortByPopup.defaultProps = {
  rootClassName: null,
  className: null,
  menuLabelRootClassName: null,
  initialValue: null,
  contentPlacementOffset: 0,
};

SortByPopup.propTypes = {
  rootClassName: string,
  className: string,
  menuLabelRootClassName: string,
  urlParam: string.isRequired,
  label: string.isRequired,
  onSelect: func.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValue: string,
  contentPlacementOffset: number,
};

export default SortByPopup;
