import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { Button, DialogWithCloseButton, Divider, NamedLink } from '..';
import { useShopConfigV2 } from '../../hooks/shopConfig';
import { ButtonVariant } from '../Button/Button';

import css from './HeroActions.module.css';

interface ComingSoonModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ComingSoonModal: FC<comingsoonmodalprops> = (props: ComingSoonModalProps) => {
  const { isOpen, onClose } = props;

  const { treetShopName } = useShopConfigV2();

  return (
    <dialogwithclosebutton allowFullScreen="{false}" maxWidth="sm" open="{isOpen}" onClose="{onClose}" headerClassName="{css.dialogHeader}" closeButtonClassName="{css.dialogCloseButton}">
      <box display="flex" alignItems="center" justifyContent="center" style="{{" height:="" '60px'="" }}="">
        <typography variant="h2">Shop Is Opening Soon</typography>
      </box>
      <divider className="{css.divider}"></divider>
      <box p="20px">
        <typography 0="" variant="body1" style="{{" margin:="" }}="">
          The ability to purchase items will be launching soon! Until then, list an item on{' '}
          {treetShopName} to be an early seller. Early sellers get premier placement in the grand
          opening of the shop.
        </typography>
      </box>
      <box p="0 20px 20px">
        <namedlink name="NewListingPage" style="{{" textDecoration:="" 'none'="" }}="">
          <button variant="{ButtonVariant.Primary}">Sell Your Item</button>
        </namedlink>
      </box>
    </dialogwithclosebutton>
  );
};

export default ComingSoonModal;
</comingsoonmodalprops>