import { Box, Tooltip, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { flatten, isEmpty, isNil } from 'lodash';
import { Divider, IconInfo, TypographyWrapper } from '..';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { defaultTreetStyles } from '../../shopConfig/config';
import {
  BundleType,
  CoreRefund,
  LineItemCode,
  Participant,
} from '../../types/apollo/generated/types.generated';
import { CheckoutLineItem } from '../../types/models/lineItem';
import {
  calculateLineItemTotal,
  getDiscountTotal,
  getListingsTotal,
  getReturnInsuranceTotal,
  getShippingTotal,
  getTaxTotal,
  SIGNATURE_CONFIRMATION_SHIPPING_LINE_ITEM_NAME,
} from '../../util/lineItems';
import { formatDollarAmount } from '../../util/currency';
import { useTax } from '../../hooks/useTax';
import { useShopConfig } from '../../hooks/shopConfig';

const LineItemWrapper: FC = (props) => (
  <box display="flex" flexDirection="row" justifyContent="space-between" p="{2}">
    {props.children}
  </box>
);

interface ItemsAndShippingLineItemProps {
  hasShippingLineItems: boolean;
  shippingTotal: number;
  itemsAndShippingTotal: number;
}

const ItemsAndShippingLineItem: FC<itemsandshippinglineitemprops> = (props) => {
  const { hasShippingLineItems, shippingTotal, itemsAndShippingTotal } = props;

  const intl = useIntl();
  const { currencyConfig } = useBrandCountryConfig();

  return (
    <>
      <lineitemwrapper>
        <typography variant="body2" style="{{" fontWeight:="" 'bold'="" }}="" display="inline">
          {shippingTotal > 0 ? 'Items + Shipping' : 'Items'}
        </typography>
        <typography variant="body2" style="{{" textAlign:="" 'end'="" }}="">
          {hasShippingLineItems
            ? intl.formatNumber(itemsAndShippingTotal, currencyConfig)
            : 'Calculated based on shipping address'}
        </typography>
      </lineitemwrapper>
      <divider></divider>
    </>
  );
};

interface ItemsLineItemProps {
  listingsTotal: number;
}

const ItemsLineItem: FC<itemslineitemprops> = ({ listingsTotal }) => {
  const intl = useIntl();
  const { currencyConfig } = useBrandCountryConfig();

  return (
    <lineitemwrapper>
      <typography variant="body2" style="{{" fontWeight:="" 'bold'="" }}="">
        Items
      </typography>
      <typography variant="body2">{intl.formatNumber(listingsTotal, currencyConfig)}</typography>
    </lineitemwrapper>
  );
};

interface ShippingLineItemProps {
  lineItems: CheckoutLineItem[];
}

const ShippingLineItem: FC<shippinglineitemprops> = (props) => {
  const { lineItems } = props;

  const intl = useIntl();
  const { currencyConfig } = useBrandCountryConfig();

  const shippingTotal = getShippingTotal(lineItems) / 100;
  const shippingLineItems = lineItems.filter((lineItem) => lineItem.code === LineItemCode.Shipping);
  const firstShippingCost = shippingLineItems[0]?.shippingLineItem?.price;
  const areAllShippingCostsSame = shippingLineItems.reduce(
    (acc, lineItem) => acc && firstShippingCost === lineItem.shippingLineItem?.price,
    true
  );
  const shippingBreakdown =
    !isNil(firstShippingCost) && shippingLineItems.length > 1 && areAllShippingCostsSame
      ? ` (${formatDollarAmount(intl, firstShippingCost, currencyConfig)} × ${
          shippingLineItems.length
        })`
      : '';

  const numListings = lineItems.filter((lineItem) => lineItem.code === LineItemCode.Listing).length;
  const noBundledShippingTotal = firstShippingCost ? (numListings * firstShippingCost) / 100 : 0;
  const savedShippingCost = areAllShippingCostsSame ? noBundledShippingTotal - shippingTotal : 0;
  const isSignedDelivery =
    shippingLineItems[0]?.name === SIGNATURE_CONFIRMATION_SHIPPING_LINE_ITEM_NAME;

  const shippingTotalText =
    shippingTotal > 0 ? intl.formatNumber(shippingTotal, currencyConfig) : 'Free';

  return (
    <lineitemwrapper>
      <div>
        <box display="flex" flexDirection="row" alignItems="center">
          <typography variant="body2" style="{{" fontWeight:="" 'bold'="" }}="" display="inline">
            {shippingLineItems[0]?.name || 'Shipping'}
          </typography>
          {isSignedDelivery && (
            <box ml="{1}">
              <tooltip title="{" <TypographyWrapper="" variant="body2" typographyOverrides="{{" color:="" 'inherit'="" }}="">
                    This shipment requires a signature upon delivery.
                  
                }
                placement="right"
                enterTouchDelay={0}
              >
                <box display="flex" alignItems="center">
                  <iconinfo height="12" width="12"></iconinfo>
                </box>
              </tooltip>
            </box>
          )}
        </box>
        <typography variant="body2" display="inline">
          {shippingBreakdown}
        </typography>
        {savedShippingCost > 0 && (
          <typography variant="body2" style="{{" color:="" defaultTreetStyles.gray40,="" fontStyle:="" 'italic'="" }}="">
            You saved {intl.formatNumber(savedShippingCost, currencyConfig)} on bundle shipping
          </typography>
        )}
      </div>
      <typography variant="body2" style="{{" textAlign:="" 'end'="" }}="">
        {!isEmpty(shippingLineItems) ? shippingTotalText : 'Calculated based on shipping address'}
      </typography>
    </lineitemwrapper>
  );
};

interface TaxLineItemProps {
  taxTotal: number;
  name?: string;
}

const TaxLineItem: FC<taxlineitemprops> = (props) => {
  const { taxTotal, name } = props;

  const intl = useIntl();
  const { currencyConfig } = useBrandCountryConfig();

  return (
    <lineitemwrapper>
      <typography variant="body2" style="{{" fontWeight:="" 'bold'="" }}="" display="inline">
        {name || 'Tax'}
      </typography>
      <typography variant="body2" style="{{" textAlign:="" 'end'="" }}="">
        {intl.formatNumber(taxTotal, currencyConfig)}
      </typography>
    </lineitemwrapper>
  );
};

interface RefundTotalLineItemProps {
  refundTotal: number;
}

const RefundTotalLineItem: FC<refundtotallineitemprops> = ({ refundTotal }) => {
  const intl = useIntl();
  const { currencyConfig } = useBrandCountryConfig();

  return (
    <lineitemwrapper>
      <typography variant="body2" style="{{" fontWeight:="" 'bold'="" }}="" display="inline">
        Refund Total
      </typography>
      <typography variant="body2" style="{{" textAlign:="" 'end'="" }}="">
        -{intl.formatNumber(refundTotal, currencyConfig)}
      </typography>
    </lineitemwrapper>
  );
};

interface LineItemBreakdownProps {
  lineItems: CheckoutLineItem[];
  participant: Participant;
  bundleType: BundleType;
  refunds?: CoreRefund[];
  shouldCombineItemsAndShipping?: boolean;
}

const LineItemBreakdown: FC<lineitembreakdownprops> = (props: LineItemBreakdownProps) => {
  const {
    lineItems: allLineItems,
    participant,
    bundleType,
    refunds,
    shouldCombineItemsAndShipping = false,
  } = props;

  const intl = useIntl();
  const { css: brandCss } = useShopConfig();
  const { currencyConfig } = useBrandCountryConfig();
  const { isTaxEnabled } = useTax();

  const bgcolor = brandCss?.backgroundColor || 'white';

  // Only show the line items that is relevant for the participant
  const lineItems = allLineItems.filter((lineItem) => lineItem.includeFor.includes(participant));
  const refundedLineItemIds = flatten(
    refunds?.map((refund) => refund.refundedLineItems.map((item) => item.id)) || []
  );
  const refundedLineItems = lineItems.filter(
    (lineItem) => lineItem.id && refundedLineItemIds.includes(lineItem.id)
  );
  const totalRefundPrice = calculateLineItemTotal(refundedLineItems);

  const isBuyer = participant === Participant.Buyer;
  const listingsTotal = getListingsTotal(lineItems) / 100;
  const shippingTotal = getShippingTotal(lineItems) / 100;
  const discountTotal = getDiscountTotal(lineItems) / 100;
  const returnInsuranceTotal = getReturnInsuranceTotal(lineItems) / 100;
  const taxTotal = getTaxTotal(lineItems) / 100;
  const refundTotal = totalRefundPrice / 100;
  const finalTotal = calculateLineItemTotal(lineItems) / 100 - refundTotal;

  // There should only be one discount applied
  const discountItem = lineItems.find(
    (lineItem) => lineItem.code === LineItemCode.Discount
  )?.discountLineItem;
  const returnInsuranceLineItem = lineItems.find(
    (lineItem) => lineItem.code === LineItemCode.ReturnInsurance
  );

  // Shipping may not be known if a user is on the checkout page and has not entered
  // their shipping address
  const hasShippingLineItems = !isEmpty(
    lineItems.filter((lineItem) => lineItem.code === LineItemCode.Shipping)
  );
  const itemsAndShippingTotal = listingsTotal + shippingTotal;
  const taxLineItem = lineItems.find((lineItem) => lineItem.code === LineItemCode.Tax);

  return (
    <box display="flex" flexDirection="column" bgcolor="{bgcolor}">
      {shouldCombineItemsAndShipping && (
        <itemsandshippinglineitem hasShippingLineItems="{hasShippingLineItems}" shippingTotal="{shippingTotal}" itemsAndShippingTotal="{itemsAndShippingTotal}"></itemsandshippinglineitem>
      )}
      {!shouldCombineItemsAndShipping && (
        <>
          <itemslineitem listingsTotal="{listingsTotal}"></itemslineitem>
          <divider></divider>
          {isBuyer && bundleType === BundleType.Marketplace && (
            <>
              <shippinglineitem lineItems="{lineItems}"></shippinglineitem>
              <divider></divider>
            </>
          )}
        </>
      )}
      {!!discountTotal && (
        <div key="{discountItem?.title}">
          <lineitemwrapper>
            <typography variant="body2" style="{{" fontWeight:="" 'bold'="" }}="">
              {discountItem?.title}
            </typography>
            <typography variant="body2">
              -{intl.formatNumber(discountTotal, currencyConfig)}
            </typography>
          </lineitemwrapper>
          <divider></divider>
        </div>
      )}
      {!!returnInsuranceTotal && (
        <>
          <lineitemwrapper>
            <typography variant="body2" style="{{" fontWeight:="" 'bold'="" }}="">
              {returnInsuranceLineItem?.name}
            </typography>
            <typography variant="body2" style="{{" textAlign:="" 'end'="" }}="">
              {intl.formatNumber(returnInsuranceTotal, currencyConfig)}
            </typography>
          </lineitemwrapper>
          <divider></divider>
        </>
      )}
      {isTaxEnabled && (
        <>
          <taxlineitem taxTotal="{taxTotal}" name="{taxLineItem?.name}"></taxlineitem>
          <divider></divider>
        </>
      )}
      {!!refundTotal && (
        <>
          <refundtotallineitem refundTotal="{refundTotal}"></refundtotallineitem>
          <divider></divider>
        </>
      )}
      <lineitemwrapper>
        <typography variant="body2" style="{{" fontWeight:="" 'bold'="" }}="">
          Total
        </typography>
        <typography variant="body2" style="{{" color:="" defaultTreetStyles.red60,="" fontWeight:="" 'bold'="" }}="">
          {intl.formatNumber(finalTotal, currencyConfig)}
        </typography>
      </lineitemwrapper>
    </box>
  );
};

export default LineItemBreakdown;
</lineitembreakdownprops></refundtotallineitemprops></taxlineitemprops></shippinglineitemprops></itemslineitemprops></itemsandshippinglineitemprops>