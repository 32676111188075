import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconSparklesLeftProps {
  className?: string;
  color?: string;
}

const IconSparklesLeft: React.FC<iconsparklesleftprops> = (props) => {
  const { className, color } = props;
  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg className="{className}" width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.13043 6.26172L4.01143 4.01228L6.26087 3.13128L4.01143 2.25029L3.13043 0.000849927L2.24944 2.25029L-2.73671e-07 3.13128L2.24944 4.01228L3.13043 6.26172Z" fill="{fillColor}"></path>
      <path d="M7.82549 10.957L8.55965 9.0825L10.4342 8.34834L8.55965 7.61417L7.82549 5.73964L7.09133 7.61417L5.2168 8.34834L7.09133 9.0825L7.82549 10.957Z" fill="{fillColor}"></path>
      <path d="M9.91313 4.17578L10.5005 2.67615L12.0001 2.08882L10.5005 1.5015L9.91313 0.00186902L9.3258 1.5015L7.82617 2.08883L9.3258 2.67615L9.91313 4.17578Z" fill="{fillColor}"></path>
    </svg>
  );
};

export default IconSparklesLeft;
</iconsparklesleftprops>