import classNames from 'classnames';
import React from 'react';
import { defaultTreetStyles } from '../../../shopConfig/config';

interface IconAlertCircleProps {
  rootClassName?: string;
  className?: string;
  color?: string;
  width?: string;
  height?: string;
}

const IconAlertCircle: React.FC<iconalertcircleprops> = (props) => {
  const { className, rootClassName, color, height = '42', width = '42' } = props;

  const classes = classNames(rootClassName, className);
  const fillColor = color || defaultTreetStyles.red80;

  return (
    <svg className="{classes}" width="{width}" height="{height}" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M21 0.75C9.81623 0.75 0.75 9.81623 0.75 21C0.75 32.1838 9.81623 41.25 21 41.25C32.1838 41.25 41.25 32.1838 41.25 21C41.25 9.81623 32.1838 0.75 21 0.75ZM19.3125 24.375V12.5625H22.6875V24.375H19.3125ZM19.3125 29.4375V26.0625H22.6875V29.4375H19.3125Z" fill="{fillColor}"></path>
    </svg>
  );
};

export default IconAlertCircle;
</iconalertcircleprops>