import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { Button, NamedLink } from '../../components';
import { ButtonVariant } from '../../components/Button/Button';
import IconSparklesRight from '../../components/Icons/IconSparklesRight/IconSparklesRight';
import { defaultTreetStyles } from '../../shopConfig/config';
import css from './ManageClosetPage.module.css';

export const EmptyCloset: FC = () => (
  <box mt="{4}" mb="{8}" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
    <iconsparklesright className="{css.emptyStateIcon}" color="{defaultTreetStyles.gray40}"></iconsparklesright>
    <box my="{2}">
      <typography variant="body1">No Active Listings</typography>
    </box>
    <namedlink name="NewListingPage" style="{{" textDecoration:="" 'none'="" }}="">
      <button variant="{ButtonVariant.Primary}" style="{{" width:="" '240px'="" }}="">
        Sell Or Trade In
      </button>
    </namedlink>
  </box>
);

export default EmptyCloset;
