import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import AuthenticationForm from '../AuthenticationForm/AuthenticationForm';
import DialogWithCloseButton from '../DialogWithCloseButton/DialogWithCloseButton';
import css from './AuthenticationModal.module.css';

const TAB_SIGNUP = 'signup';
const TAB_LOGIN = 'login';

const AuthenticationModal = ({ open, handleClose, bannerText, onSuccess, from: specifiedFrom }) => {
  const [tab, setTab] = useState(TAB_SIGNUP);

  const location = useLocation();
  const authModalFrom = location ? `${location.pathname}${location.search}${location.hash}` : null;
  // Check if there's a 'from' passed in, which is the case if we're redirecting users to a
  // page that's different from the current location.
  // Otherwise, default to the current location.
  const from = specifiedFrom || authModalFrom;

  return (
    <div>
      <dialogwithclosebutton open="{open}" onClose="{handleClose}" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" headerClassName="{css.dialogHeader}" fullScreen="{false}" fullWidth="">
        <div style="{{" display:="" 'inline-block',="" position:="" 'relative'="" }}="">
          <authenticationform bannerText="{bannerText}" tab="{tab}" loginTabProperties="{{" onClick:="" ()=""> setTab(TAB_LOGIN) }}
            signupTabProperties={{ onClick: () => setTab(TAB_SIGNUP) }}
            className={css.formRoot}
            onSuccess={onSuccess}
            handleOpenTermsOfService={() =>
              window.open(
                `${window.location.protocol}//${window.location.host}/terms-of-service`,
                '_blank'
              )
            }
            handleOpenPrivacyPolicy={() =>
              window.open(
                `${window.location.protocol}//${window.location.host}/privacy-policy`,
                '_blank'
              )
            }
            from={from}
          />
        </authenticationform></div>
      </dialogwithclosebutton>
    </div>
  );
};

AuthenticationModal.defaultProps = {
  onSuccess: () => {},
  bannerText: '',
  from: null,
};

const { bool, func, string } = PropTypes;

AuthenticationModal.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  onSuccess: func,
  bannerText: string,
  from: string,
};

export default AuthenticationModal;
