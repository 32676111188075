/* eslint-disable import/prefer-default-export */
import { MutableRefObject, useEffect, useState } from 'react';

export const useElementSize = (ref: MutableRefObject<any>) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const getDimensions = () => ({
    width: ref.current?.offsetWidth || 0,
    height: ref.current?.offsetHeight || 0,
  });

  const handleResize = () => {
    const newDimensions = getDimensions();
    setDimensions(newDimensions);
  };

  useEffect(() => {
    if (ref.current) handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);

  return dimensions;
};
</any>