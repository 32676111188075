/* eslint-disable no-nested-ternary */
import { useSelector } from 'react-redux';
import { Box, Divider } from '@material-ui/core';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import {
  Bundle,
  Button,
  Empty,
  IconArrowRight,
  IconSpinner,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  TypographyWrapper,
  UserNavbar,
} from '..';
import { defaultTreetStyles } from '../../shopConfig/config';
import { TopbarContainer } from '../../containers';
import { useShopConfig } from '../../hooks/shopConfig';
import { BundleType, Participant } from '../../types/apollo/generated/types.generated';
import { BundleInfo } from '../../types/models/bundle';
import { getBundleListings, getBundleStatusLabel } from '../../util/bundles';
import { getFormattedDate } from '../../util/dates';
import BundleActions from '../BundleActions/BundleActions';
import IndividualBundleContainer from './IndividualBundleContainer';
import ManageBundlesTopbar from './ManageBundlesTopbar';
import css from './ManageBundlesContainer.module.css';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { usePageTitles } from '../../hooks/usePageTitles';
import { useActionCopy } from '../../hooks/useActionCopy';
import BundleTypeMarker from '../BundleTypeMarker/BundleTypeMarker';

interface EmptyBundlesProps {
  isSales: boolean;
}

const EmptyBundles: FC<emptybundlesprops> = ({ isSales }) => {
  const { isTradeInOnly, allowTradeInAndMarketplace, isListTradeInOnly, hasBuyHistory } =
    useEnabledCustomerExperiences();
  const { shopName } = useShopConfig();
  const { listActionCopy } = useActionCopy();

  const transactionType = () => {
    if (isTradeInOnly) {
      if (hasBuyHistory && !isSales) return 'purchases';
      return 'trade-ins';
    }
    if (isSales) {
      if (isListTradeInOnly) return 'trade-ins';
      if (allowTradeInAndMarketplace) return 'sales or trade-ins';
      return 'sales';
    }
    return 'purchases';
  };

  const emptyText = `No ${transactionType()} yet.`;
  const emptyButton = isSales ? (
    <namedlink name="{isListTradeInOnly" ?="" 'ManageTradeInsPage'="" :="" 'NewListingPage'}="" className="{css.emptyButton}" style="{{" textDecoration:="" 'none'="" }}="">
      <button>{listActionCopy}</button>
    </namedlink>
  ) : (
    <namedlink name="SearchPage" to="{{" search:="" ''="" }}="" className="{css.emptyButton}" style="{{" textDecoration:="" 'none'="">
      <button>Shop {shopName}</button>
    </namedlink>
  );

  return <empty className="{css.empty}" text="{emptyText}" button="{emptyButton}"></empty>;
};

interface OrderBundlesProps {
  bundles: BundleInfo[];
  participant: Participant;
  bundleActionsFn: (bundle: BundleInfo) => React.ReactElement;
}

const OrderBundles: FC<orderbundlesprops> = (props: OrderBundlesProps) => {
  const { bundles, participant, bundleActionsFn } = props;
  const rootState = useSelector<any>((state) => state) as any;
  const isSales = participant === Participant.Seller;
  const referrerLogging = isSales ? 'Manage Sales Page' : 'Manage Purchases Page';
  const individualBundlePageName = isSales ? 'ManageSalePage' : 'ManagePurchasePage';
  const { allowTradeInAndMarketplace } = useEnabledCustomerExperiences();

  return (
    <>
      {bundles.map((bundle, index) => {
        const isTradeInBundle = bundle.type === BundleType.TradeIn;
        const listings = getBundleListings(bundle, rootState);

        if (listings.length < 1) {
          return null;
        }

        const heading = (
          <box display="flex" flexDirection="row" justifyContent="space-between">
            <div className="{css.bundleTitle}">
              <h4>
                <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" fontWeight:="" 'bold'="" }="" }}="">
                  {getFormattedDate(bundle.createdAt)}
                </typographywrapper>
              </h4>
              <h4>
                <typographywrapper variant="body1">
                  {getBundleStatusLabel(participant, bundle)}
                </typographywrapper>
              </h4>
              {allowTradeInAndMarketplace && (
                <box paddingTop="6px">
                  <bundletypemarker title="{isTradeInBundle" ?="" 'Trade-In'="" :="" 'Sale'}=""></bundletypemarker>
                </box>
              )}
            </div>
            <iconarrowright className="{css.rightArrowIcon}"></iconarrowright>
          </box>
        );

        return (
          <namedlink name="{individualBundlePageName}" params="{{" id:="" bundle.id="" }}="" key="{bundle.id}">
            <bundle heading="{heading}" listings="{listings}" referrerForLogging="{referrerLogging}" key="{bundle.id}" actionButton="{bundleActionsFn(bundle)}" allowListingRedirect="{false}"></bundle>
            {index !== bundles.length - 1 && <divider></divider>}
          </namedlink>
        );
      })}
    </>
  );
};

interface RootBundlesContainerProps {
  isLoading: boolean;
  bundles: BundleInfo[];
  participant: Participant;
  rootPageName: string;
  bundleActionsFn: (bundle: BundleInfo) => React.ReactElement;
  error?: string;
  fetchMore?: (() => void) | false;
}
const RootBundlesContainer: FC<rootbundlescontainerprops> = (props: RootBundlesContainerProps) => {
  const { isLoading, bundles, participant, rootPageName, bundleActionsFn, error, fetchMore } =
    props;
  const { salesTitle, purchasesTitle } = usePageTitles();

  const isSales = participant === Participant.Seller;
  const pageTitle = isSales ? salesTitle : purchasesTitle;

  const spinnerSection = (
    <box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex="{1}">
      <iconspinner></iconspinner>
    </box>
  );

  let body;
  if (error) {
    body = (
      <h4 className="{css.error}">
        <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.red80="" }="" }}="">
          {error}
        </typographywrapper>
      </h4>
    );
  } else if (isLoading && !bundles?.length) {
    body = spinnerSection;
  } else if (bundles.length === 0) {
    body = <emptybundles isSales="{isSales}"></emptybundles>;
  } else {
    body = (
      <>
        <orderbundles bundles="{bundles}" participant="{participant}" bundleActionsFn="{bundleActionsFn}"></orderbundles>
        {isLoading && spinnerSection}
      </>
    );
  }

  return (
    <>
      <layoutwrappertopbar>
        <topbarcontainer></topbarcontainer>
        <usernavbar selectedPageName="{rootPageName}"></usernavbar>
      </layoutwrappertopbar>
      <layoutwrappermain>
        <h2 className="{css.pageTitle}">
          <typographywrapper variant="h2">{pageTitle}</typographywrapper>
        </h2>
        {body}
        {fetchMore && (
          <box mt="{1}">
            <button onClick="{fetchMore}" disabled="{isLoading}">
              Get More Orders
            </button>
          </box>
        )}
      </layoutwrappermain>
    </>
  );
};
interface ManageBundlesContainerMobileProps {
  isLoading: boolean;
  bundles: BundleInfo[];
  participant: Participant;
  onCancelClick?: (bundle: BundleInfo) => void;
  onVerifyClick?: (bundle: BundleInfo) => void;
  onDisputeClick?: (bundle: BundleInfo) => void;
  onGetPaidClick?: (bundle: BundleInfo) => void;
  onMarkAsFulfilledClick?: (bundle: BundleInfo) => void;
  onMarkAsDeliveredClick?: (bundle: BundleInfo) => void;
  onEditShippingAddressSubmit?: (values: any, addressId: string, onSuccess: () => void) => void;
  error?: string;
  fetchMore?: (() => void) | false;
}

const ManageBundlesContainerMobile: FC<managebundlescontainermobileprops> = (
  props: ManageBundlesContainerMobileProps
) => {
  const {
    bundles,
    isLoading,
    participant,
    onCancelClick,
    onVerifyClick,
    onDisputeClick,
    onGetPaidClick,
    onMarkAsFulfilledClick,
    onMarkAsDeliveredClick,
    onEditShippingAddressSubmit,
    error,
    fetchMore,
  } = props;
  const { id: selectedBundleId } = useParams() as { id?: string };
  const isRootPage = !selectedBundleId;
  const bundle = selectedBundleId ? bundles.find((b) => b.id === selectedBundleId) : undefined;
  const otherBundlesInSameOrder = selectedBundleId
    ? bundles.filter((b) => b?.order?.id && b.order.id === bundle?.order?.id && b.id !== bundle.id)
    : undefined;
  const isSales = participant === Participant.Seller;
  const rootPageName = isSales ? 'ManageSalesPage' : 'ManagePurchasesPage';

  const bundleActionsFn = (b: BundleInfo) => (
    <bundleactions bundle="{b}" participant="{participant}" onVerifyClick="{onVerifyClick}" onDisputeClick="{onDisputeClick}" onGetPaidClick="{onGetPaidClick}" onMarkAsFulfilledClick="{onMarkAsFulfilledClick}" onMarkAsDeliveredClick="{onMarkAsDeliveredClick}"></bundleactions>
  );
  return (
    <>
      {isRootPage && (
        <rootbundlescontainer isLoading="{isLoading}" bundles="{bundles}" participant="{participant}" rootPageName="{rootPageName}" bundleActionsFn="{bundleActionsFn}" error="{error}" fetchMore="{fetchMore}"></rootbundlescontainer>
      )}
      {!isRootPage && bundle && (
        <>
          <managebundlestopbar bundle="{bundle}" rootPageName="{rootPageName}" participant="{participant}" onCancelClick="{onCancelClick}"></managebundlestopbar>
          <div className="{css.individualBundleContainerContent}">
            {error && (
              <h4 className="{css.error}">
                <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.red80="" }="" }}="">
                  {error}
                </typographywrapper>
              </h4>
            )}
            <individualbundlecontainer isLoading="{isLoading}" bundle="{bundle}" otherBundlesInSameOrder="{otherBundlesInSameOrder}" participant="{participant}" onVerifyClick="{onVerifyClick}" onDisputeClick="{onDisputeClick}" onGetPaidClick="{onGetPaidClick}" onMarkAsFulfilledClick="{onMarkAsFulfilledClick}" onMarkAsDeliveredClick="{onMarkAsDeliveredClick}" onEditShippingAddressSubmit="{onEditShippingAddressSubmit}"></individualbundlecontainer>
          </div>
        </>
      )}
    </>
  );
};

export default ManageBundlesContainerMobile;
</managebundlescontainermobileprops></rootbundlescontainerprops></any></orderbundlesprops></emptybundlesprops>