import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Typography,
  withStyles,
} from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import isEmpty from 'lodash/isEmpty';
import { Select } from 'mui-rff';
import React, { FC, useContext } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, EmailTextField } from '..';
import AppContext from '../../context/AppContext';
import { useShopConfig } from '../../hooks/shopConfig';
import { getFilterOptions } from '../../shopConfig/configHelper';
import { Cadence, EmailSubscribeSource } from '../../types/apollo/generated/types.generated';
import { FilterId } from '../../types/filters/filters';
import { UpdateGeneralSavedSearchEmailSubscriptionParams } from '../../types/models/savedSearch';
import * as log from '../../util/log';
import { RELEASES_AND_DISCOUNTS_GROUP_ID } from '../../util/sendgrid';
import * as validators from '../../util/validators';
import { ButtonVariant } from '../Button/Button';

import css from './SubscribeForm.module.css';

const inputStyles = {
  root: {
    '& label': {
      color: 'white',
      fontWeight: 'bold',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiSelect-icon': {
      color: 'white',
      top: 'unset',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 15px) scale(1)',
    },

    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    // This is needed so that the autocomplete backfill fills the whole input
    '& input': {
      padding: '0px 14px',
      height: '100%',
    },
    '& .MuiOutlinedInput-root': {
      color: 'white',
      fontWeight: 'bold',
      width: '100%',
      height: '48px',

      '& fieldset': {
        borderWidth: '1px',
        borderColor: 'white',
        color: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
};

const SizeFormControl = withStyles(inputStyles)(FormControl);

interface SubscribeFormProps {
  onSubscribe: (params: UpdateGeneralSavedSearchEmailSubscriptionParams) => void;
  areSizesEnabled?: boolean;
}

interface SubscribeFormValues {
  email: string;
  sizes?: string[];
}

const SubscribeForm: FC<subscribeformprops> = (props) => {
  const { onSubscribe, areSizesEnabled = true } = props;

  const intl = useIntl();
  const shopConfig = useShopConfig();
  const { treetId } = useContext(AppContext);

  const { updateSavedSearchProgress, savedSearchSource } = useSelector<any>(
    (state) => state.user
  ) as any;

  let sizeOptions: { key: string; label: string }[] | null = null;
  if (areSizesEnabled) {
    sizeOptions = getFilterOptions(shopConfig, FilterId.Size) || null;
  }

  const emailRequiredMessage = intl.formatMessage({ id: 'SignupForm.emailRequired' });
  const emailRequired = validators.required(emailRequiredMessage);
  const emailInvalidMessage = intl.formatMessage({ id: 'SignupForm.emailInvalid' });
  const emailValid = validators.emailFormatValid(emailInvalidMessage);

  const onSubscribeSubmit = (values: SubscribeFormValues) => {
    const { email, sizes } = values;

    const errors: any = {};
    // Only want to run this on submit
    const emailErrors = validators.composeValidators(emailRequired, emailValid)(email);
    if (emailErrors) {
      errors.email = emailErrors;
    }

    if (!isEmpty(errors)) return errors;

    const subscribeSource = savedSearchSource || EmailSubscribeSource.Footer;

    const genericError = 'Oops, something went wrong. Please try again.';
    try {
      return onSubscribe({
        email,
        sizes,
        groupId: RELEASES_AND_DISCOUNTS_GROUP_ID,
        subscribeSource,
        cadence: Cadence.Weekly,
      });
    } catch {
      log.error(
        new Error('[Subscribe Form] Could not subscribe email to sizes sizes'),
        'subscribe-form-failed',
        {
          treetId,
          email,
          sizes,
          subscribeSource,
        }
      );
      return { [FORM_ERROR]: genericError };
    }
  };

  return (
    <finalform onSubmit="{onSubscribeSubmit}" render="{(fieldRenderProps)" ==""> {
        const { handleSubmit, submitting, submitError, values } = fieldRenderProps;
        const isDisabled = submitting;

        return (
          <form onSubmit="{handleSubmit}">
            <grid container="" spacing="{2}" justifyContent="center">
              <grid item="" xs="{12}" md="{5}">
                <emailtextfield id="email-subscribe" name="email" label="Email" variant="outlined" required=""></emailtextfield>
              </grid>
              {sizeOptions && (
                <grid item="" xs="{12}" md="{4}">
                  {/* Multiple select code from https://material-ui.com/components/selects/ */}
                  <sizeformcontrol fullWidth="" variant="outlined">
                    <select variant="outlined" name="sizes" label="Sizes" id="sizes-subscribe" multiple="" renderValue="{(selected:" any)=""> selected.join(', ')}
                      required
                    >
                      {sizeOptions.map(({ key, label }) => (
                        <menuitem key="{key}" value="{key}">
                          <checkbox checked="{(values?.sizes" ||="" []).indexOf(key)=""> -1}
                            color="default"
                          />
                          <listitemtext primary="{label}"></listitemtext>
                        </checkbox></menuitem>
                      ))}
                    </select>
                  </sizeformcontrol>
                </grid>
              )}
              <grid item="" xs="{12}" md="{3}">
                <button type="submit" inProgress="{updateSavedSearchProgress}" disabled="{isDisabled}" className="{css.subscribeButton}" spinnerClassName="{css.subscribeSpinner}" variant="{ButtonVariant.Secondary}">
                  Subscribe
                </button>
              </grid>
              {!!submitError && (
                <box pt="{1}" display="flex" justifyContent="center">
                  <typography variant="body1" style="{{" color:="" 'white'="" }}="">
                    {submitError}
                  </typography>
                </box>
              )}
            </grid>
          </form>
        );
      }}
    />
  );
};

export default SubscribeForm;
</finalform></any></subscribeformprops>