import React, { Component } from 'react';
import { useTheme } from '@material-ui/core';
import { bool, func, object, oneOf, oneOfType, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ExpandingTextarea, TypographyWrapper, ValidationError } from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import { FontConfigSection } from '../../types/shopConfig/shopConfigV2';

import css from './FieldTextInput.module.css';

const CONTENT_MAX_LENGTH = 5000;

const FieldTextInputComponent = (props) => {
  /* eslint-disable no-unused-vars */
  const {
    rootClassName,
    className,
    inputRootClass,
    customErrorText,
    id,
    label,
    input,
    meta,
    onUnmount,
    isUncontrolled,
    inputRef,
    showAsOptional,
    showError,
    style,
    ...rest
  } = props;
  /* eslint-enable no-unused-vars */

  const theme = useTheme();
  const { fontConfig } = useShopConfig();
  const labelTypographyVariant = fontConfig[FontConfigSection.FieldLabel];

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;
  const isTextarea = input.type === 'textarea';

  const errorText = customErrorText || error;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!customErrorText || !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error: errorText };

  // Textarea doesn't need type.
  const { type, ...inputWithoutType } = input;
  // Uncontrolled input uses defaultValue instead of value.
  const { value: defaultValue, ...inputWithoutValue } = input;
  // Use inputRef if it is passed as prop.
  const refMaybe = inputRef ? { ref: inputRef } : {};

  const inputClasses =
    inputRootClass ||
    classNames(css.input, {
      [css.inputSuccess]: valid,
      [css.inputError]: hasError,
      [css.textarea]: isTextarea,
    });
  const maxLength = CONTENT_MAX_LENGTH;
  const inputProps = isTextarea
    ? {
        className: inputClasses,
        id,
        rows: 1,
        maxLength,
        ...refMaybe,
        ...inputWithoutType,
        ...rest,
      }
    : isUncontrolled
    ? {
        className: inputClasses,
        id,
        type,
        defaultValue,
        ...refMaybe,
        ...inputWithoutValue,
        ...rest,
      }
    : { className: inputClasses, id, type, ...refMaybe, ...input, ...rest };

  // Needed because we can't apply !important in inline styles
  let overrideRef;

  const body1Font = theme?.typography?.body1;

  // This is the only way to set the !important flag in React
  // TODO (sonia-y | TREET-1281): Remove this piece of code once we no longer use the fonts configured from config.js
  if (body1Font?.fontFamily) {
    overrideRef = (element) => {
      const sanitizedFontFamily = body1Font.fontFamily.replace('!important', '');
      if (element?.style) {
        element.style.setProperty('font-family', sanitizedFontFamily, 'important');
      }
    };
  }

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className="{classes}">
      {label ? (
        <label htmlFor="{id}">
          <typographywrapper variant="{labelTypographyVariant}" typographyOverrides="{{" style:="" {="" fontWeight:="" 'bold'="" },="" display:="" 'inline'="" }}="">
            {label}
          </typographywrapper>
          {showAsOptional && (
            <span className="{css.optional}">
              <typographywrapper variant="{labelTypographyVariant}" typographyOverrides="{{" display:="" 'inline'="" }}="">
                {' (Optional)'}
              </typographywrapper>
            </span>
          )}
        </label>
      ) : null}
      {isTextarea ? (
        <expandingtextarea {...inputProps}="" style="{{" ...style,="" ...body1Font="" }}="" refFn="{overrideRef}"></expandingtextarea>
      ) : (
        <input {...inputProps}="" style="{{" ...style,="" ...body1Font="" }}="" ref="{overrideRef}">
      )}
      {showError && <validationerror fieldMeta="{fieldMeta}"></validationerror>}
    </div>
  );
};

FieldTextInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
  inputRef: null,
  showAsOptional: false,
  showError: true,
  style: null,
};

FieldTextInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,
  // a ref object passed for input element.
  inputRef: object,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
    // Either 'textarea' or something that is passed to the input element
    type: string,
  }).isRequired,
  meta: object.isRequired,
  showAsOptional: bool,

  // Set to false if the error display text should be handled externally
  showError: bool,

  style: oneOfType([object, oneOf([null])]),
};

class FieldTextInput extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <field component="{FieldTextInputComponent}" {...this.props}=""></field>;
  }
}

export default FieldTextInput;
