import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, NamedLink } from '../../components';
import {
  addressInvalidError,
  isPurchaseReturnInsuranceError,
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateListingInsufficientStockError,
  isTransactionInitiateListingNotFoundError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import { CheckoutPageState } from './CheckoutPage.duck';

import css from './CheckoutError.module.css';

interface FormattedErrorProps {
  id: string;
  values?: any;
}

const FormattedError: FC<formattederrorprops> = (props: FormattedErrorProps) => {
  const { id, values } = props;

  return (
    <p className="{css.failure}">
      <formattedmessage id="{id}" values="{values}"></formattedmessage>
    </p>
  );
};

const CheckoutError: FC = () => {
  const { finishCheckoutError, initiateCheckoutError, submitCheckoutError } = useSelector<any>(
    (state) => state.CheckoutPage
  ) as CheckoutPageState;
  const { handleCardChargeError } = useSelector<any>((state) => state.stripe) as any;

  const isSomeListingNotFound = isTransactionInitiateListingNotFoundError(initiateCheckoutError);
  const isSomeListingInsufficientStock =
    isTransactionInitiateListingInsufficientStockError(initiateCheckoutError);
  const addressValidationError = addressInvalidError(initiateCheckoutError);
  const isAmountTooLow = isTransactionInitiateAmountTooLowError(submitCheckoutError);
  const isReturnInsuranceError = isPurchaseReturnInsuranceError(submitCheckoutError);
  const stripeErrors = transactionInitiateOrderStripeErrors(submitCheckoutError);

  if (isSomeListingNotFound || isSomeListingInsufficientStock) {
    const searchLink = (
      <namedlink name="SearchPage" to="{{" search:="" ''="" }}="" className="{css.searchLink}">
        here
      </namedlink>
    );
    return <formattederror id="CheckoutPage.listingsNotFoundError" values="{{" searchLink="" }}=""></formattederror>;
  }
  if (addressValidationError) {
    return (
      <formattederror id="CheckoutPage.genericError" values="{{" error:="" addressValidationError="" }}=""></formattederror>
    );
  }
  if (isAmountTooLow) {
    return <formattederror id="CheckoutPage.initiateOrderAmountTooLow"></formattederror>;
  }
  if (stripeErrors && stripeErrors.length > 0) {
    // NOTE: Error messages from Stripe are not part of translations. By default they are in
    // English.
    const stripeErrorsAsString = stripeErrors.join(', ');
    return (
      <formattederror id="CheckoutPage.initiateOrderStripeError" values="{{" stripeErrors:="" stripeErrorsAsString="" }}=""></formattederror>
    );
  }
  if (handleCardChargeError) {
    return (
      <formattederror id="CheckoutPage.initiateOrderStripeError" values="{{" stripeErrors:="" handleCardChargeError.message="" }}=""></formattederror>
    );
  }
  if (isReturnInsuranceError) {
    return <formattederror id="CheckoutPage.purchaseReturnAssuranceError"></formattederror>;
  }
  if (submitCheckoutError || finishCheckoutError || initiateCheckoutError) {
    return <formattederror id="CheckoutPage.initiateOrderError"></formattederror>;
  }

  return null;
};

export default CheckoutError;
</any></any></formattederrorprops>