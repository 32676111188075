import React, { FC } from 'react';

interface IconSyncProps {
  className?: string;
}

const IconSync: FC<iconsyncprops> = (props: IconSyncProps) => {
  const { className } = props;

  return (
    <svg className="{className}" width="26" height="26" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16 8C11.5817 8 8 11.5817 8 16C8 20.4183 11.5817 24 16 24C20.4183 24 24 20.4183 24 16H22C22 19.3137 19.3137 22 16 22C12.6863 22 10 19.3137 10 16C10 12.6863 12.6863 10 16 10V8Z" fill="currentColor"></path>
      <path d="M20 9L15.5 11.5981L15.5 6.40192L20 9Z" fill="currentColor"></path>
    </svg>
  );
};

export default IconSync;
</iconsyncprops>