import classNames from 'classnames';
import React, { FC } from 'react';
import { IconAdmin, IconAdminFilled, IconButton } from '..';
import { useIconColor } from '../../hooks/useIconColor';
import css from './AdminButton.module.css';

interface AdminButtonProps {
  isSelected: boolean;
  className?: string;
  iconClassName?: string;
  onClick?: () => void;
}

const AdminButton: FC<adminbuttonprops> = (props: AdminButtonProps) => {
  const { className, iconClassName, isSelected, onClick } = props;

  const { iconHoverColor } = useIconColor();
  const classes = classNames(css.root, className);

  let icon, hoverIcon;
  if (isSelected) {
    icon = <iconadminfilled className="{iconClassName}"></iconadminfilled>;
    hoverIcon = <iconadminfilled className="{iconClassName}" color="{iconHoverColor}"></iconadminfilled>;
  } else {
    icon = <iconadmin className="{iconClassName}"></iconadmin>;
    hoverIcon = <iconadmin className="{iconClassName}" color="{iconHoverColor}"></iconadmin>;
  }

  return <iconbutton className="{classes}" icon="{icon}" hoverIcon="{hoverIcon}" onClick="{onClick}"></iconbutton>;
};

export default AdminButton;
</adminbuttonprops>