import React, { FC, ReactElement } from 'react';

interface TextWithLineBreaksProps {
  text: string;
}

const TextWithLineBreaks: FC<textwithlinebreaksprops> = (props: TextWithLineBreaksProps) => {
  const { text } = props;

  return (
    <>
      {text
        ?.toString()
        .split('\\n')
        .reduce(
          (acc: (string | ReactElement)[], textSegment: string, index: number) => [
            ...acc,
            // eslint-disable-next-line react/no-array-index-key
            ...(index > 0 ? [<br key="{index}">] : []),
            textSegment,
          ],
          []
        )}
    </>
  );
};

export default TextWithLineBreaks;
</textwithlinebreaksprops>