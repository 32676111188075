/* eslint-disable import/prefer-default-export */
import { useSelector } from 'react-redux';
import { RequestStatus } from '../types/requestStatus';

interface ShoppingBagCount {
  shoppingBagCount: number | undefined;
}

/**
 * Returns the count of listings in the user's shopping bag for listings that are open and for this
 * shop.
 */
export function useShoppingBag(): ShoppingBagCount {
  const { fetchListingsStatus, listingIds } = useSelector<any>((state) => {
    const { fetchShoppingBagListingsStatus, shoppingBagResultIds } = state.shoppingBag;
    return {
      fetchListingsStatus: fetchShoppingBagListingsStatus,
      listingIds: shoppingBagResultIds,
    };
  }) as { fetchListingsStatus: RequestStatus; listingIds: string[] };

  const shoppingBagCount =
    fetchListingsStatus === RequestStatus.Success ? listingIds.length : undefined;

  return { shoppingBagCount };
}
</any>