import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconSparklesRightProps {
  className?: string;
  color?: string;
}

const IconSparklesRight: React.FC<iconsparklesrightprops> = (props) => {
  const { className, color } = props;
  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg className="{className}" width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.86957 6.26172L7.98857 4.01228L5.73913 3.13128L7.98857 2.25029L8.86957 0.000849927L9.75056 2.25029L12 3.13128L9.75056 4.01228L8.86957 6.26172Z" fill="{fillColor}"></path>
      <path d="M4.17451 10.957L3.44035 9.0825L1.56581 8.34834L3.44035 7.61417L4.17451 5.73964L4.90867 7.61417L6.7832 8.34834L4.90867 9.0825L4.17451 10.957Z" fill="{fillColor}"></path>
      <path d="M2.08687 4.17578L1.49954 2.67615L-8.37409e-05 2.08882L1.49954 1.5015L2.08687 0.00186902L2.6742 1.5015L4.17383 2.08883L2.6742 2.67615L2.08687 4.17578Z" fill="{fillColor}"></path>
    </svg>
  );
};

export default IconSparklesRight;
</iconsparklesrightprops>