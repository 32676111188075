import { Box, Divider } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import isEmpty from 'lodash/isEmpty';
import React, { FC } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { CoreAddress, CountryCode } from '../../types/apollo/generated/types.generated';
import { constructAddressMutatorFn } from '../../util/address';
import { ADDRESS_FORM_TYPE_SHIPPING } from '../../util/constants';
import { getAllowedShipToCountryCodes } from '../../util/shipping';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import DialogWithCloseButton from '../DialogWithCloseButton/DialogWithCloseButton';
import { AddressForm, Button, Error, Form } from '..';
import css from './BundlePanel.module.css';

export const EDIT_SHIPPING_ADDRESS = 'editShippingAddress';

interface EditShippingAddressFormProps {
  isBrandDirectBundle: boolean;
  shippingAddress: CoreAddress;
  shipFromCountry: CountryCode;
  onSave: (values: any, addressId: string) => void;
}

const EditShippingAddressForm: FC<editshippingaddressformprops> = (props) => {
  const { shippingAddress, shipFromCountry, onSave, isBrandDirectBundle } = props;

  const { allowedShippingDestinationCountries } = useBrandCountryConfig();

  const fullNameParts = shippingAddress.fullName.split(' ');
  const lastName = fullNameParts.pop();
  const firstName = fullNameParts.join(' ');

  const initialValues = {
    [EDIT_SHIPPING_ADDRESS]: {
      ...shippingAddress,
      firstName,
      lastName,
      postal: shippingAddress.zip,
    },
  };

  return (
    <>
      <finalform onSubmit="{(values:" any)=""> onSave(values, shippingAddress.id)}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        mutators={{
          ...arrayMutators,
          updateShippingAddress: constructAddressMutatorFn(EDIT_SHIPPING_ADDRESS),
        }}
        render={(formRenderProps) => {
          const { errors, form, handleSubmit, submitting, submitError } = formRenderProps;

          return (
            <>
              <form id="EditShippingAddressForm" onSubmit="{handleSubmit}">
                <divider className="{css.divider}"></divider>
                <box p="{2}">
                  <addressform form="{form}" addressType="{ADDRESS_FORM_TYPE_SHIPPING}" fieldId="BundlePanelEditShippingAddress" prefix="{EDIT_SHIPPING_ADDRESS}" allowedCountryCodes="{getAllowedShipToCountryCodes(" isBrandDirectBundle,="" shipFromCountry="==" CountryCode.Us,="" allowedShippingDestinationCountries="" )}=""></addressform>
                  {!!submitError && (
                    <box pt="{2}" display="flex" justifyContent="center">
                      <error>{submitError}</error>
                    </box>
                  )}
                  <box display="flex" justifyContent="center" pt="{6}">
                    <button type="submit" className="{css.saveButton}" inProgress="{submitting}" disabled="{!isEmpty(errors)}">
                      Save
                    </button>
                  </box>
                </box>
              </form>
            </>
          );
        }}
      />
    </>
  );
};

interface EditShippingAddressModalProps {
  isBrandDirectBundle: boolean;
  isOpen: boolean;
  shippingAddress: CoreAddress;
  shipFromCountry: CountryCode;
  onClose: () => void;
  onSave: (values: any, addressId: string) => void;
}

const EditShippingAddressModal: FC<editshippingaddressmodalprops> = (props) => {
  const { isOpen, shippingAddress, shipFromCountry, onClose, onSave, isBrandDirectBundle } = props;

  return (
    <dialogwithclosebutton allowFullScreen="{false}" scroll="paper" open="{isOpen}" onClose="{onClose}" fullWidth="" titleText="Edit Shipping Address">
      <editshippingaddressform shippingAddress="{shippingAddress}" shipFromCountry="{shipFromCountry}" onSave="{onSave}" isBrandDirectBundle="{isBrandDirectBundle}"></editshippingaddressform>
    </dialogwithclosebutton>
  );
};

export default EditShippingAddressModal;
</editshippingaddressmodalprops></finalform></editshippingaddressformprops>