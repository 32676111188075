import React, { useState } from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import {
  Button,
  IconArrowHead,
  IconCard,
  IconCheckmark,
  IconClose,
  InlineTextButton,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  Modal,
  ModalParagraph,
  ModalTitle,
  TypographyWrapper,
} from '..';
import css from './SavedCardDetails.module.css';

const DEFAULT_CARD = 'defaultCard';
export const REPLACE_CARD = 'replaceCard';

const SavedCardDetails = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [active, setActive] = useState(DEFAULT_CARD);

  const {
    rootClassName,
    className,
    intl,
    card,
    onChange,
    onDeleteCard,
    onManageDisableScrolling,
    deletePaymentMethodInProgress,
  } = props;

  const { last4Digits, expirationMonth, expirationYear, brand } = card || {};
  const classes = classNames(rootClassName || css.root, className);

  const paymentMethodPlaceholderDesktop = intl.formatMessage(
    { id: 'SavedCardDetails.savedPaymentMethodPlaceholderDesktop' },
    { last4Digits }
  );

  const paymentMethodPlaceholderMobile = intl.formatMessage(
    { id: 'SavedCardDetails.savedPaymentMethodPlaceholderMobile' },
    { last4Digits }
  );

  const paymentMethodPlaceholder = (
    <>
      <span className="{css.paymentMethodPlaceholderDesktop}">{paymentMethodPlaceholderDesktop}</span>
      <span className="{css.paymentMethodPlaceholderMobile}">{paymentMethodPlaceholderMobile}</span>
    </>
  );

  const replaceCardText = intl.formatMessage({
    id: 'SavedCardDetails.replaceCardText',
  });
  const replaceCard = (
    <span>
      <iconcard brand="none" className="{css.cardIcon}"></iconcard>
      <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
        {replaceCardText}
      </typographywrapper>
    </span>
  );

  const expiredCardText = intl.formatMessage(
    { id: 'SavedCardDetails.expiredCardText' },
    { last4Digits }
  );
  const expiredText = <div className="{css.cardExpiredText}">{expiredCardText}</div>;

  const isExpired = (expMonth, expYear) => {
    const currentTime = new Date();
    const currentYear = currentTime.getFullYear();
    const currentMonth = currentTime.getMonth() + 1; // getMonth() method returns the month (from 0 to 11)

    if (expYear < currentYear) {
      return true;
    }
    if (expYear === currentYear && expMonth < currentMonth) {
      return true;
    }

    return false;
  };

  const isCardExpired =
    expirationMonth && expirationYear && isExpired(expirationMonth, expirationYear);

  const defaultCard = (
    <div className="{css.savedPaymentMethod}">
      <iconcard brand="{brand}" className="{css.cardIcon}"></iconcard>
      <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
        {paymentMethodPlaceholder}
      </typographywrapper>
      <span className="{isCardExpired" ?="" css.expirationDateExpired="" :="" css.expirationDate}="">
        <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
          {expirationMonth}/{expirationYear.toString().substring(2)}
        </typographywrapper>
      </span>
    </div>
  );

  const handleClick = (item) => (e) => {
    // Clicking buttons inside a form will call submit
    e.preventDefault();
    e.stopPropagation();

    setActive(item);
    setMenuOpen(false);
    if (onChange) {
      onChange(item);
    }
  };

  const onToggleActive = (isOpen) => {
    setMenuOpen(isOpen);
  };

  const handleDeleteCard = () => {
    setIsModalOpen(true);
  };

  const iconArrowClassName = menuOpen ? css.IconArrowAnimation : null;

  const replaceCardTitle = intl.formatMessage({
    id: 'SavedCardDetails.replaceCardTitle',
  });
  const removeCardModalTitle = intl.formatMessage({ id: 'SavedCardDetails.removeCardModalTitle' });
  const removeCardModalContent = intl.formatMessage(
    { id: 'SavedCardDetails.removeCardModalContent' },
    { last4Digits }
  );
  const cancel = intl.formatMessage({ id: 'SavedCardDetails.cancel' });
  const removeCard = intl.formatMessage({ id: 'SavedCardDetails.removeCard' });
  const deletePaymentMethod = intl.formatMessage({ id: 'SavedCardDetails.deletePaymentMethod' });

  const showExpired = isCardExpired && active === DEFAULT_CARD;

  return (
    <div className="{classes}">
      <menu className="{css.menu}" isOpen="{menuOpen}" onToggleActive="{onToggleActive}" useArrow="{false}">
        <menulabel className="{css.menuLabel}">
          <div className="{showExpired" ?="" css.menuLabelWrapperExpired="" :="" css.menuLabelWrapper}="">
            {active === DEFAULT_CARD ? defaultCard : replaceCard}
            <span>
              <iconarrowhead direction="down" size="small" rootClassName="{css.iconArrow}" className="{iconArrowClassName}"></iconarrowhead>
            </span>
          </div>
        </menulabel>

        <menucontent rootClassName="{css.menuContent}">
          <menuitem key="first item" className="{css.menuItem}">
            <iconcheckmark className="{active" =="=" DEFAULT_CARD="" ?="" css.iconCheckmark="" :="" css.iconCheckmarkHidden}="" size="small"></iconcheckmark>
            <inlinetextbutton className="{css.menuText}" onClick="{handleClick(DEFAULT_CARD)}">
              {defaultCard}
            </inlinetextbutton>
          </menuitem>
          <menuitem key="divider" className="{css.menuDivider}">
            <typographywrapper variant="body1">{replaceCardTitle}</typographywrapper>
          </menuitem>
          <menuitem key="second item" className="{css.menuItem}">
            <iconcheckmark className="{active" =="=" REPLACE_CARD="" ?="" css.iconCheckmark="" :="" css.iconCheckmarkHidden}="" size="small"></iconcheckmark>
            <inlinetextbutton className="{css.menuTextReplaceCard}" onClick="{handleClick(REPLACE_CARD)}">
              {replaceCard}
            </inlinetextbutton>
          </menuitem>
        </menucontent>
      </menu>
      {showExpired && !menuOpen ? expiredText : null}

      {onDeleteCard && active !== REPLACE_CARD ? (
        <inlinetextbutton onClick="{handleDeleteCard}" className="{css.savedPaymentMethodDelete}">
          <iconclose size="small" className="{css.deleteCardCloseIcon}"></iconclose>
          {deletePaymentMethod}
        </inlinetextbutton>
      ) : null}

      {onManageDisableScrolling ? (
        <modal id="VerifyDeletingPaymentMethod" isOpen="{isModalOpen}" onClose="{()" ==""> setIsModalOpen(false)}
          usePortal
          contentClassName={css.modalContent}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div>
            <modaltitle className="{css.modalTitle}">{removeCardModalTitle}</modaltitle>
            <modalparagraph className="{css.modalMessage}">{removeCardModalContent}</modalparagraph>
          </div>
          <div className="{css.modalButtonsWrapper}">
            <div onClick="{()" ==""> setIsModalOpen(false)}
              className={css.cancelCardDelete}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex="0"
            >
              <typographywrapper variant="body1">{cancel}</typographywrapper>
            </div>
            <button onClick="{onDeleteCard}" inProgress="{deletePaymentMethodInProgress}">
              {removeCard}
            </button>
          </div>
        </modal>
      ) : null}
    </div>
  );
};

SavedCardDetails.defaultProps = {
  rootClassName: null,
  className: null,
  card: null,
  onChange: null,
  onDeleteCard: null,
  deletePaymentMethodInProgress: false,
  onManageDisableScrolling: null,
};

SavedCardDetails.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  card: shape({
    brand: string.isRequired,
    expirationMonth: number.isRequired,
    expirationYear: number.isRequired,
    last4Digits: string.isRequired,
  }),
  onChange: func,
  onDeleteCard: func,
  onManageDisableScrolling: func,
  deletePaymentMethodInProgress: bool,
};

export default injectIntl(SavedCardDetails);
