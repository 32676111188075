import { Box, Typography } from '@material-ui/core';
import React, { FC, useContext, useEffect } from 'react';
import AppContext from '../../context/AppContext';
import { useShopConfig } from '../../hooks/shopConfig';
import { useBrandCountryConfig, useUserCountryConfig } from '../../hooks/useCountryConfig';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { ICountryWarningModal } from '../../types/contentful/types.generated';
import * as heap from '../../util/heap';
import { getStoredData, storeData } from '../../util/sessionHelpers/sessionHelpersUtils';
import { listItems } from '../../util/strings';
import Button, { ButtonVariant, InlineTextButton } from '../Button/Button';
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import DialogWithCloseButton from '../DialogWithCloseButton/DialogWithCloseButton';
import css from './CountryWarningModal.module.css';

export const COUNTRY_WARNING_MODAL_TRIGGERED_STORAGE_KEY = 'country_warning_modal_triggered';

const Title: FC = () => {
  const { treetShopName, internationalConfig } = useShopConfig();
  const {
    countryCode,
    canUserBuyInCountry,
    canUserBuyBrandDirectOnlyInCountry,
    canShipFromUserCountry,
  } = useUserCountryConfig();

  const customTitleForCountry =
    internationalConfig?.countryWarningModalConfigCollection?.items?.find(
      (modalConfig: ICountryWarningModal) => modalConfig.displayForCountry === countryCode
    )?.title;
  const customTitleDefault = internationalConfig?.countryWarningModalConfigCollection?.items?.find(
    (modalConfig: ICountryWarningModal) => modalConfig.displayForCountry == null
  )?.title;

  let title;
  if (customTitleForCountry) {
    title = customTitleForCountry;
  } else if (customTitleDefault) {
    title = customTitleDefault;
  } else if (!canUserBuyInCountry && !canShipFromUserCountry) {
    title = `${treetShopName} Is Not Yet Available In Your Area`;
  } else if (!canShipFromUserCountry || canUserBuyBrandDirectOnlyInCountry) {
    title = `${treetShopName} Has Limited Availability In Your Area`;
  }

  if (!title) return null;

  return (
    <typography variant="h1" style="{{" color:="" 'white',="" textAlign:="" 'center',="" fontWeight:="" 'bold'="" }}="">
      {title}
    </typography>
  );
};

const Description: FC = () => {
  const { internationalConfig, treetShopName, shopName } = useShopConfig();
  const {
    countryCode,
    canUserBuyInCountry,
    canUserBuyBrandDirectOnlyInCountry,
    canShipFromUserCountry: canUserListInCountry,
  } = useUserCountryConfig();
  const { allowedShippingDestinationCountries } = useBrandCountryConfig();
  const { allowBuy, allowSell, allowTradeIn } = useEnabledCustomerExperiences();

  const customDescriptionForCountry =
    internationalConfig?.countryWarningModalConfigCollection?.items?.find(
      (modalConfig: ICountryWarningModal) => modalConfig.displayForCountry === countryCode
    )?.subtitle;
  const customDescriptionDefault =
    internationalConfig?.countryWarningModalConfigCollection?.items?.find(
      (modalConfig: ICountryWarningModal) => modalConfig.displayForCountry == null
    )?.subtitle;

  let description;
  if (customDescriptionForCountry) {
    description = customDescriptionForCountry;
  } else if (customDescriptionDefault) {
    description = customDescriptionDefault;
  } else if (!canUserBuyInCountry && !canUserListInCountry) {
    const allowedCountryNames = allowedShippingDestinationCountries.map(
      (countryCodeAndName) => countryCodeAndName.longformName
    );
    description = `${treetShopName} is currently only available in ${listItems(
      allowedCountryNames
    )}.`;
  } else if (!canUserListInCountry || canUserBuyBrandDirectOnlyInCountry) {
    const shopExperiences = [
      ...(allowSell ? ['selling'] : []),
      ...(allowTradeIn ? ['trading in items'] : []),
      ...(allowBuy && allowSell ? ['buying from peers'] : []),
    ];
    const shopExperiencesText = listItems(shopExperiences);
    description = `You can purchase Brand Direct items sold by ${shopName} but ${shopExperiencesText} is
    not currently available in your country.`;
  }

  if (!description) return null;

  return (
    <typography variant="body1" style="{{" color:="" 'white',="" paddingTop:="" '16px',="" textAlign:="" 'center'="" }}="">
      {description}
    </typography>
  );
};

interface ContinueButtonProps {
  onClick: () => void;
}

const ContinueButton: FC<continuebuttonprops> = (props) => {
  const { onClick } = props;

  const { internationalConfig } = useShopConfig();
  const {
    countryCode,
    canShipFromUserCountry: canUserListInCountry,
    canShipToUserCountry,
    canShipWithinUserCountry,
  } = useUserCountryConfig();

  const canUserBuyInCountry = canShipToUserCountry || canShipWithinUserCountry;

  const customConfigForCountry =
    internationalConfig?.countryWarningModalConfigCollection?.items?.find(
      (modalConfig: ICountryWarningModal) => modalConfig.displayForCountry === countryCode
    );
  const customButtonTextForCountry = customConfigForCountry?.buttonText;
  const customButtonLinkForCountry = customConfigForCountry?.buttonLink;

  const customConfigDefault = internationalConfig?.countryWarningModalConfigCollection?.items?.find(
    (modalConfig: ICountryWarningModal) => modalConfig.displayForCountry == null
  );
  const customButtonTextDefault = customConfigDefault?.buttonText;
  const customButtonLinkDefault = customConfigDefault?.buttonLink;

  let buttonText;
  if (customButtonTextForCountry) {
    buttonText = customButtonTextForCountry;
  } else if (customButtonTextDefault) {
    buttonText = customButtonTextDefault;
  } else if (!canUserBuyInCountry && !canUserListInCountry) {
    buttonText = 'Continue Anyway';
  } else {
    buttonText = 'Got It';
  }

  return (
    <conditionalwrapper condition="{!!customButtonLinkForCountry" ||="" !!customButtonLinkDefault}="" wrapper="{(children)" ==""> (
        <box display="flex" flexDirection="column" alignItems="center" width="100%">
          <a className="{css.customButtonLink}" href="{customButtonLinkForCountry" ||="" customButtonLinkDefault}="">
            {children}
          </a>
          <inlinetextbutton onClick="{onClick}" className="{css.continueAnywayLink}">
            <typography variant="body2" style="{{" color:="" 'white'="" }}="">
              Continue Anyway
            </typography>
          </inlinetextbutton>
        </box>
      )}
    >
      <button variant="{ButtonVariant.Secondary}" onClick="{onClick}">
        {buttonText}
      </button>
    </conditionalwrapper>
  );
};

interface CountryWarningContentProps {
  onClick: () => void;
}

const CountryWarningContent: FC<countrywarningcontentprops> = (props) => {
  const { onClick } = props;

  const { images } = useShopConfig();

  const loginImageUrl = images?.loginImage?.url;
  const missionImageUrl = images?.missionImage?.url;
  const backgroundImageUrl = loginImageUrl || missionImageUrl;
  const backgroundImage = `linear-gradient(-46deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImageUrl})`;

  return (
    <box 15="" 16="" px="{{" xs:="" 4,="" sm:="" }}="" py="{{" 10,="" className="{css.countryWarningContentBackground}" style="{{" backgroundImage="">
      <title></title>
      <description></description>
      <box mt="{4}">
        <continuebutton onClick="{onClick}"></continuebutton>
      </box>
    </box>
  );
};

interface CountryWarningModalProps {
  isOpen: boolean;
  onClick: () => void;
}

const CountryWarningModal: FC<countrywarningmodalprops> = (props: CountryWarningModalProps) => {
  const { isOpen, onClick } = props;

  const { treetId } = useContext(AppContext);

  useEffect(() => {
    if (isOpen) {
      const { displayCountryWarningTreetIds = [] } =
        getStoredData(COUNTRY_WARNING_MODAL_TRIGGERED_STORAGE_KEY) || {};
      const data = {
        displayCountryWarningTreetIds: displayCountryWarningTreetIds.concat(treetId),
        storedAt: new Date(),
      };

      storeData(COUNTRY_WARNING_MODAL_TRIGGERED_STORAGE_KEY, data);
      heap.trackShowCountryWarningModal(treetId);
    }
  }, [isOpen]);

  return (
    <dialogwithclosebutton open="{isOpen}" allowFullScreen="{false}" fullWidth="" maxWidth="md" headerClassName="{css.countryWarningModalDialogHeader}">
      <countrywarningcontent onClick="{onClick}"></countrywarningcontent>
    </dialogwithclosebutton>
  );
};

export default CountryWarningModal;
</countrywarningmodalprops></countrywarningcontentprops></continuebuttonprops>