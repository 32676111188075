import { LineItemCode } from '@prisma/client';
import React, { FC, ReactElement } from 'react';
import { Divider, IconSubtractFilled, ItemizedListing } from '..';
import { CoreBundleItem, Participant } from '../../types/apollo/generated/types.generated';
import { CheckoutLineItem } from '../../types/models/lineItem';
import { ListingWithImages } from '../../types/sharetribe/listing';

import css from './ItemizedListingBreakdown.module.css';
import {
  itemHasBeenCanceled,
  itemHasBeenDisputedAndNotVerified,
  itemHasBeenVerified,
} from '../../util/bundleItem';

interface DeleteButtonProps {
  onClick: () => void;
}

const DeleteButton: FC<deletebuttonprops> = (props: DeleteButtonProps) => {
  const { onClick } = props;

  return (
    <button className="{css.removeListingButton}" onClick="{onClick}" type="button">
      <iconsubtractfilled className="{css.removeIcon}"></iconsubtractfilled>
    </button>
  );
};

interface ItemizedListingBreakdownProps {
  // Either pass in a list of listings or tranasctions. Listings are passed in
  // directly when there are no transactions yet (Checkout Phase)
  bundleItems?: CoreBundleItem[];
  listings: ListingWithImages[];
  lineItems: CheckoutLineItem[];
  referrerForLogging: string;
  title?: ReactElement | string;
  handleRemoveListings?: (listingIds: string[]) => void;
  participant?: Participant;
}

const ItemizedListingBreakdown: FC<itemizedlistingbreakdownprops> = (props) => {
  const {
    listings,
    lineItems,
    title,
    referrerForLogging,
    handleRemoveListings,
    participant,
    bundleItems,
  } = props;

  let listingsAndMaybeBundleItems: {
    bundleItem?: CoreBundleItem;
    listing: ListingWithImages;
  }[] = [];

  if (bundleItems) {
    const listingsAndBundleItems: {
      bundleItem: CoreBundleItem;
      listing: ListingWithImages;
    }[] = [];

    bundleItems.forEach((bundleItem) => {
      // Find the corresponding listing in the listings array
      const bundleItemListing = listings.find(
        (listing) => listing.id.uuid === bundleItem.listing.sharetribeListingId
      );

      // Only push into the array if the listing exists
      if (bundleItemListing) {
        listingsAndBundleItems.push({
          listing: bundleItemListing,
          bundleItem,
        });
      }
    });

    listingsAndMaybeBundleItems = listingsAndBundleItems;
  } else {
    listingsAndMaybeBundleItems = listings.map((listing) => ({
      listing,
    }));
  }

  return (
    <>
      {title && (
        <>
          <div className="{css.bundleTitle}">{title}</div>
          <divider></divider>
        </>
      )}

      {listingsAndMaybeBundleItems.map(({ listing, bundleItem }, index) => {
        const listingLineItem = lineItems.find(
          (lineItem) =>
            lineItem.code === LineItemCode.LISTING &&
            lineItem.listingLineItem?.sharetribeListingId === listing.id.uuid
        );

        const isDisputed = bundleItem ? itemHasBeenDisputedAndNotVerified(bundleItem) : false;
        const isCanceled = bundleItem ? itemHasBeenCanceled(bundleItem) : false;
        const isVerified = bundleItem ? itemHasBeenVerified(bundleItem) : false;

        const actionEl = handleRemoveListings ? (
          <deletebutton onClick="{()" ==""> handleRemoveListings([listing.id.uuid])} />
        ) : undefined;

        return (
          <div key="{listing.id.uuid}">
            {index !== 0 && <divider></divider>}
            <itemizedlisting isDisputed="{isDisputed}" isCanceled="{isCanceled}" isVerified="{isVerified}" listing="{listing}" bundleItem="{bundleItem}" lineItem="{listingLineItem}" referrerForLogging="{referrerForLogging}" actionEl="{actionEl}" participant="{participant}"></itemizedlisting>
          </div>
        );
      })}
    </>
  );
};

export default ItemizedListingBreakdown;
</deletebutton></itemizedlistingbreakdownprops></deletebuttonprops>