import React, { FC } from 'react';

const IconGetPaid: FC = () => (
  <svg width="100" height="100" viewBox="0 0 544 544" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M544 272C544 422.221 422.221 544 272 544C121.779 544 0 422.221 0 272C0 121.779 121.779 0 272 0C422.221 0 544 121.779 544 272Z" fill="#F8F8F8"></path>
    <path d="M296.048 195.13C346.641 209.238 385 275.536 385 328.727C385 388.586 336.423 394 276.5 394C216.577 394 168 388.586 168 328.727C168 275.536 206.359 209.238 256.952 195.13L246.232 149H306.768L296.048 195.13Z" fill="#F8F8F8"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M243.099 146.512C243.858 145.557 245.011 145 246.232 145H306.768C307.989 145 309.142 145.557 309.901 146.512C310.66 147.468 310.941 148.717 310.665 149.905L300.79 192.393C325.797 200.635 347.386 220.812 362.87 245.124C379.125 270.646 389 301.254 389 328.727C389 344.229 385.853 356.566 379.881 366.275C373.893 376.009 365.263 382.778 354.863 387.458C334.329 396.699 306.35 398 276.5 398C246.65 398 218.671 396.699 198.137 387.458C187.737 382.778 179.107 376.009 173.119 366.275C167.147 356.566 164 344.229 164 328.727C164 301.254 173.875 270.646 190.13 245.124C205.614 220.812 227.203 200.635 252.21 192.393L242.335 149.905C242.059 148.717 242.34 147.468 243.099 146.512ZM251.268 153L260.849 194.225C261.333 196.311 260.089 198.408 258.027 198.983C233.997 205.684 212.469 224.94 196.878 249.421C181.305 273.872 172 303.009 172 328.727C172 343.155 174.925 353.942 179.933 362.083C184.926 370.2 192.186 376.007 201.42 380.163C220.156 388.594 246.427 390 276.5 390C306.573 390 332.844 388.594 351.58 380.163C360.814 376.007 368.074 370.2 373.067 362.083C378.075 353.942 381 343.155 381 328.727C381 303.009 371.695 273.872 356.122 249.421C340.531 224.94 319.003 205.684 294.973 198.983C292.911 198.408 291.667 196.311 292.152 194.225L301.732 153H251.268Z" fill="#454545"></path>
    <path d="M310 149.5C310 154.747 295.002 159 276.5 159C257.998 159 243 154.747 243 149.5C243 144.253 257.998 140 276.5 140C295.002 140 310 144.253 310 149.5Z" fill="#D1D1D1"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M246.914 149.945C246.913 149.945 246.917 149.938 246.926 149.923C246.919 149.938 246.914 149.945 246.914 149.945ZM247.349 149.5C247.48 149.393 247.647 149.268 247.86 149.125C249.063 148.318 251.067 147.435 253.903 146.631C259.52 145.038 267.51 144 276.5 144C285.49 144 293.48 145.038 299.097 146.631C301.933 147.435 303.937 148.318 305.14 149.125C305.353 149.268 305.52 149.393 305.651 149.5C305.52 149.607 305.353 149.732 305.14 149.875C303.937 150.682 301.933 151.565 299.097 152.369C293.48 153.962 285.49 155 276.5 155C267.51 155 259.52 153.962 253.903 152.369C251.067 151.565 249.063 150.682 247.86 149.875C247.647 149.732 247.48 149.607 247.349 149.5ZM306.086 149.945C306.086 149.945 306.081 149.938 306.074 149.923C306.083 149.938 306.087 149.945 306.086 149.945ZM306.074 149.077C306.081 149.062 306.086 149.055 306.086 149.055C306.087 149.055 306.083 149.062 306.074 149.077ZM246.926 149.077C246.917 149.062 246.913 149.055 246.914 149.055C246.914 149.055 246.919 149.062 246.926 149.077ZM251.721 138.934C258.229 137.089 266.988 136 276.5 136C286.012 136 294.771 137.089 301.279 138.934C304.506 139.849 307.408 141.013 309.595 142.48C311.637 143.849 314 146.144 314 149.5C314 152.856 311.637 155.151 309.595 156.52C307.408 157.987 304.506 159.151 301.279 160.066C294.771 161.911 286.012 163 276.5 163C266.988 163 258.229 161.911 251.721 160.066C248.494 159.151 245.592 157.987 243.405 156.52C241.363 155.151 239 152.856 239 149.5C239 146.144 241.363 143.849 243.405 142.48C245.592 141.013 248.494 139.849 251.721 138.934Z" fill="#454545"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M253.995 189.798C253.89 187.683 252.142 186 250 186C247.791 186 246 187.791 246 190C246 192.961 247.773 195.173 249.578 196.622C251.42 198.101 253.84 199.256 256.475 200.151C261.786 201.955 268.866 203 276.5 203C284.134 203 291.214 201.955 296.525 200.151C299.16 199.256 301.58 198.101 303.422 196.622C305.227 195.173 307 192.961 307 190C307 187.791 305.209 186 303 186C300.858 186 299.11 187.683 299.005 189.798C298.938 189.896 298.775 190.094 298.413 190.384C297.573 191.059 296.112 191.843 293.952 192.576C289.671 194.03 283.501 195 276.5 195C269.499 195 263.329 194.03 259.048 192.576C256.888 191.843 255.427 191.059 254.587 190.384C254.225 190.094 254.062 189.896 253.995 189.798ZM299.046 189.726C299.047 189.726 299.044 189.734 299.035 189.75C299.041 189.734 299.045 189.726 299.046 189.726ZM253.965 189.75C253.956 189.734 253.953 189.726 253.954 189.726C253.955 189.726 253.959 189.734 253.965 189.75Z" fill="#454545"></path>
    <path d="M268.918 349V342.064C265.192 341.624 261.429 340.743 257.63 339.422C253.904 338.028 250.361 336.119 247 333.697L254.014 321.257C256.132 322.872 258.507 324.266 261.137 325.44C263.84 326.541 266.434 327.349 268.918 327.862V306.174C265.995 304.927 263.183 303.422 260.479 301.661C257.776 299.826 255.548 297.477 253.795 294.615C252.041 291.752 251.164 288.156 251.164 283.826C251.164 277.22 253.174 271.862 257.192 267.752C261.283 263.642 266.653 261.147 273.301 260.266V253H282.507V260.376C287.11 260.817 290.982 261.734 294.123 263.128C297.338 264.523 300.297 266.101 303 267.862L296.973 280.083C294.562 278.761 292.114 277.624 289.63 276.67C287.219 275.642 284.845 274.945 282.507 274.578V294.725L283.055 294.945C286.196 296.119 289.228 297.624 292.151 299.459C295.073 301.22 297.484 303.679 299.384 306.835C301.283 309.917 302.233 314.028 302.233 319.165C302.233 322.982 301.32 326.578 299.493 329.954C297.667 333.257 294.963 336.046 291.384 338.321C287.804 340.523 283.384 341.844 278.123 342.284V349H268.918ZM267.603 282.615C267.603 284.156 268.114 285.587 269.137 286.908C270.233 288.229 271.621 289.404 273.301 290.431V275.018C269.502 276.193 267.603 278.725 267.603 282.615ZM285.685 319.165C285.685 317.33 284.954 315.679 283.493 314.211C282.105 312.67 280.315 311.312 278.123 310.138V327.752C280.169 327.239 281.922 326.321 283.384 325C284.918 323.605 285.685 321.661 285.685 319.165Z" fill="#454545"></path>
    <path d="M217 382.5C217 388.851 201.778 394 183 394C164.222 394 149 388.851 149 382.5C149 376.149 164.222 371 183 371C201.778 371 217 376.149 217 382.5Z" fill="#D1D1D1"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M152.999 382.523C152.999 382.523 152.999 382.521 152.999 382.516C152.999 382.521 152.999 382.523 152.999 382.523ZM153.003 382.5C153.028 382.394 153.194 381.928 154.17 381.148C155.39 380.172 157.4 379.118 160.24 378.157C165.879 376.25 173.926 375 183 375C192.074 375 200.121 376.25 205.76 378.157C208.6 379.118 210.61 380.172 211.83 381.148C212.806 381.928 212.972 382.394 212.997 382.5C212.972 382.606 212.806 383.072 211.83 383.852C210.61 384.828 208.6 385.882 205.76 386.843C200.121 388.75 192.074 390 183 390C173.926 390 165.879 388.75 160.24 386.843C157.4 385.882 155.39 384.828 154.17 383.852C153.194 383.072 153.028 382.606 153.003 382.5ZM213.001 382.523C213.001 382.523 213.001 382.521 213.001 382.516C213.001 382.521 213.001 382.523 213.001 382.523ZM213.001 382.484C213.001 382.479 213.001 382.477 213.001 382.477C213.001 382.477 213.001 382.479 213.001 382.484ZM152.999 382.484C152.999 382.479 152.999 382.477 152.999 382.477C152.999 382.477 152.999 382.479 152.999 382.484ZM157.677 370.579C164.343 368.324 173.296 367 183 367C192.704 367 201.657 368.324 208.323 370.579C211.636 371.7 214.605 373.123 216.826 374.9C219.009 376.645 221 379.186 221 382.5C221 385.814 219.009 388.355 216.826 390.1C214.605 391.877 211.636 393.3 208.323 394.421C201.657 396.676 192.704 398 183 398C173.296 398 164.343 396.676 157.677 394.421C154.364 393.3 151.395 391.877 149.174 390.1C146.991 388.355 145 385.814 145 382.5C145 379.186 146.991 376.645 149.174 374.9C151.395 373.123 154.364 371.7 157.677 370.579Z" fill="#454545"></path>
    <path d="M217 370.5C217 376.851 201.778 382 183 382C164.222 382 149 376.851 149 370.5C149 364.149 164.222 359 183 359C201.778 359 217 364.149 217 370.5Z" fill="#D1D1D1"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M152.999 370.523C152.999 370.523 152.999 370.521 152.999 370.516C152.999 370.521 152.999 370.523 152.999 370.523ZM153.003 370.5C153.028 370.394 153.194 369.928 154.17 369.148C155.39 368.172 157.4 367.118 160.24 366.157C165.879 364.25 173.926 363 183 363C192.074 363 200.121 364.25 205.76 366.157C208.6 367.118 210.61 368.172 211.83 369.148C212.806 369.928 212.972 370.394 212.997 370.5C212.972 370.606 212.806 371.072 211.83 371.852C210.61 372.828 208.6 373.882 205.76 374.843C200.121 376.75 192.074 378 183 378C173.926 378 165.879 376.75 160.24 374.843C157.4 373.882 155.39 372.828 154.17 371.852C153.194 371.072 153.028 370.606 153.003 370.5ZM213.001 370.523C213.001 370.523 213.001 370.521 213.001 370.516C213.001 370.521 213.001 370.523 213.001 370.523ZM213.001 370.484C213.001 370.479 213.001 370.477 213.001 370.477C213.001 370.477 213.001 370.479 213.001 370.484ZM152.999 370.484C152.999 370.479 152.999 370.477 152.999 370.477C152.999 370.477 152.999 370.479 152.999 370.484ZM157.677 358.579C164.343 356.324 173.296 355 183 355C192.704 355 201.657 356.324 208.323 358.579C211.636 359.7 214.605 361.123 216.826 362.9C219.009 364.645 221 367.186 221 370.5C221 373.814 219.009 376.355 216.826 378.1C214.605 379.877 211.636 381.3 208.323 382.421C201.657 384.676 192.704 386 183 386C173.296 386 164.343 384.676 157.677 382.421C154.364 381.3 151.395 379.877 149.174 378.1C146.991 376.355 145 373.814 145 370.5C145 367.186 146.991 364.645 149.174 362.9C151.395 361.123 154.364 359.7 157.677 358.579Z" fill="#454545"></path>
    <path d="M217 358C217 364.627 201.778 370 183 370C164.222 370 149 364.627 149 358C149 351.373 164.222 346 183 346C201.778 346 217 351.373 217 358Z" fill="#D1D1D1"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M153 358L153 358L153 358C153 358.008 152.997 358.567 154.235 359.6C155.455 360.618 157.46 361.715 160.29 362.713C165.912 364.698 173.941 366 183 366C192.059 366 200.088 364.698 205.71 362.713C208.54 361.715 210.545 360.618 211.765 359.6C213.003 358.567 213 358.008 213 358V358C213 357.997 213.008 357.437 211.765 356.4C210.545 355.382 208.54 354.285 205.71 353.287C200.088 351.302 192.059 350 183 350C173.941 350 165.912 351.302 160.29 353.287C157.46 354.285 155.455 355.382 154.235 356.4C152.997 357.433 153 357.992 153 358ZM157.627 345.743C164.31 343.384 173.281 342 183 342C192.719 342 201.69 343.384 208.373 345.743C211.696 346.916 214.67 348.405 216.891 350.258C219.089 352.092 221 354.689 221 358C221 361.311 219.089 363.908 216.891 365.742C214.67 367.595 211.696 369.084 208.373 370.257C201.69 372.616 192.719 374 183 374C173.281 374 164.31 372.616 157.627 370.257C154.304 369.084 151.33 367.595 149.109 365.742C146.911 363.908 145 361.311 145 358C145 354.689 146.911 352.092 149.109 350.258C151.33 348.405 154.304 346.916 157.627 345.743Z" fill="#454545"></path>
    <path d="M217 345.5C217 351.851 201.778 357 183 357C164.222 357 149 351.851 149 345.5C149 339.149 164.222 334 183 334C201.778 334 217 339.149 217 345.5Z" fill="#D1D1D1"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M152.999 345.523C152.999 345.523 152.999 345.521 152.999 345.516C152.999 345.521 152.999 345.523 152.999 345.523ZM153.003 345.5C153.028 345.394 153.194 344.928 154.17 344.148C155.39 343.172 157.4 342.118 160.24 341.157C165.879 339.25 173.926 338 183 338C192.074 338 200.121 339.25 205.76 341.157C208.6 342.118 210.61 343.172 211.83 344.148C212.806 344.928 212.972 345.394 212.997 345.5C212.972 345.606 212.806 346.072 211.83 346.852C210.61 347.828 208.6 348.882 205.76 349.843C200.121 351.75 192.074 353 183 353C173.926 353 165.879 351.75 160.24 349.843C157.4 348.882 155.39 347.828 154.17 346.852C153.194 346.072 153.028 345.606 153.003 345.5ZM213.001 345.523C213.001 345.523 213.001 345.521 213.001 345.516C213.001 345.521 213.001 345.523 213.001 345.523ZM213.001 345.484C213.001 345.479 213.001 345.477 213.001 345.477C213.001 345.477 213.001 345.479 213.001 345.484ZM152.999 345.484C152.999 345.479 152.999 345.477 152.999 345.477C152.999 345.477 152.999 345.479 152.999 345.484ZM157.677 333.579C164.343 331.324 173.296 330 183 330C192.704 330 201.657 331.324 208.323 333.579C211.636 334.7 214.605 336.123 216.826 337.9C219.009 339.645 221 342.186 221 345.5C221 348.814 219.009 351.355 216.826 353.1C214.605 354.877 211.636 356.3 208.323 357.421C201.657 359.676 192.704 361 183 361C173.296 361 164.343 359.676 157.677 357.421C154.364 356.3 151.395 354.877 149.174 353.1C146.991 351.355 145 348.814 145 345.5C145 342.186 146.991 339.645 149.174 337.9C151.395 336.123 154.364 334.7 157.677 333.579Z" fill="#454545"></path>
    <path d="M217 333.5C217 339.851 201.778 345 183 345C164.222 345 149 339.851 149 333.5C149 327.149 164.222 322 183 322C201.778 322 217 327.149 217 333.5Z" fill="#F8F8F8"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M152.999 333.523C152.999 333.523 152.999 333.521 152.999 333.516C152.999 333.521 152.999 333.523 152.999 333.523ZM153.003 333.5C153.028 333.394 153.194 332.928 154.17 332.148C155.39 331.172 157.4 330.118 160.24 329.157C165.879 327.25 173.926 326 183 326C192.074 326 200.121 327.25 205.76 329.157C208.6 330.118 210.61 331.172 211.83 332.148C212.806 332.928 212.972 333.394 212.997 333.5C212.972 333.606 212.806 334.072 211.83 334.852C210.61 335.828 208.6 336.882 205.76 337.843C200.121 339.75 192.074 341 183 341C173.926 341 165.879 339.75 160.24 337.843C157.4 336.882 155.39 335.828 154.17 334.852C153.194 334.072 153.028 333.606 153.003 333.5ZM213.001 333.523C213.001 333.523 213.001 333.521 213.001 333.516C213.001 333.521 213.001 333.523 213.001 333.523ZM213.001 333.484C213.001 333.479 213.001 333.477 213.001 333.477C213.001 333.477 213.001 333.479 213.001 333.484ZM152.999 333.484C152.999 333.479 152.999 333.477 152.999 333.477C152.999 333.477 152.999 333.479 152.999 333.484ZM157.677 321.579C164.343 319.324 173.296 318 183 318C192.704 318 201.657 319.324 208.323 321.579C211.636 322.7 214.605 324.123 216.826 325.9C219.009 327.645 221 330.186 221 333.5C221 336.814 219.009 339.355 216.826 341.1C214.605 342.877 211.636 344.3 208.323 345.421C201.657 347.676 192.704 349 183 349C173.296 349 164.343 347.676 157.677 345.421C154.364 344.3 151.395 342.877 149.174 341.1C146.991 339.355 145 336.814 145 333.5C145 330.186 146.991 327.645 149.174 325.9C151.395 324.123 154.364 322.7 157.677 321.579Z" fill="#454545"></path>
  </svg>
);

export default IconGetPaid;
