import React from 'react';
import { bool, func, node, object, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import css from './FieldRadioButton.module.css';

const IconRadioButton = (props) => (
  <div>
    <svg className="{props.className}" width="14" height="14" xmlns="http://www.w3.org/2000/svg">
      <circle className="{props.showAsRequired" ?="" css.required="" :="" css.notChecked}="" cx="5" cy="19" r="6" transform="translate(2 -12)" strokeWidth="2" fill="none" fillRule="evenodd"></circle>

      <g className="{css.checked}" transform="translate(2 -12)" fill="none" fillRule="evenodd">
        <circle strokeWidth="2" cx="5" cy="19" r="6"></circle>
        <circle fill="#FFF" fillRule="nonzero" cx="5" cy="19" r="3"></circle>
      </g>
    </svg>
  </div>
);

IconRadioButton.defaultProps = { className: null };

IconRadioButton.propTypes = { className: string };

const FieldRadioButtonComponent = (props) => {
  const {
    rootClassName,
    className,
    svgClassName,
    id,
    label,
    showAsRequired,
    typographyOverrides,
    labelClassName,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const radioButtonProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'radio',
    ...rest,
  };

  return (
    <span className="{classes}">
      <field {...radioButtonProps}=""></field>
      <label htmlFor="{id}" className="{labelClassName" ||="" css.label}="">
        <span className="{css.radioButtonWrapper}">
          <iconradiobutton className="{svgClassName}" showAsRequired="{showAsRequired}"></iconradiobutton>
        </span>
        <span className="{css.text}">
          <typographywrapper variant="body1" {...(!!typographyOverrides="" &&="" {="" typographyOverrides="" })}="">
            {label}
          </typographywrapper>
        </span>
      </label>
    </span>
  );
};

FieldRadioButtonComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  labelClassName: null,
  label: null,
  disabled: false,
  typographyOverrides: undefined,
};

FieldRadioButtonComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  labelClassName: string,
  disabled: bool,
  typographyOverrides: object,
  showAsRequired: bool,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several RadioButtones to an array of selected values
  name: string.isRequired,

  // RadioButton needs a value that is passed forward when user checks the RadioButton
  value: string.isRequired,
  onChange: func || undefined,
  checked: bool || undefined,
};

export default FieldRadioButtonComponent;
