import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { FormattedMessage, NamedLink, Navbar, TypographyWrapper } from '..';
import { useIsMobile } from '../../hooks/useIsMobile';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { usePageTitles } from '../../hooks/usePageTitles';
import { Feature } from '../../util/featureFlags';
import { TypographyFormat } from '../TypographyWrapper/TypographyWrapper';

export const USER_NAVBAR_ELEM_ID = 'user-navbar';

interface UserNavbarProps {
  selectedPageName: string;
}

const MANAGE_PAGES = [
  'ManagePurchasesPage',
  'ManageSalesPage',
  'ManageListingsPage',
  'ManageTradeInsPage',
  'ManageClosetPage',
];

const UserNavbar: FC<usernavbarprops> = (props: UserNavbarProps) => {
  const { selectedPageName } = props;

  const isNotificationSettingsPageEnabled = useFeatureFlags(Feature.FollowingPage);
  const isMobile = useIsMobile();
  const { canViewPage } = useCurrentUserPermissions();
  const { salesTitle, purchasesTitle, tradeInsTitle, listingsTitle, closetTitle } = usePageTitles();

  const manageTabs = [
    ...(canViewPage.ManagePurchasesPage
      ? [
          {
            text: purchasesTitle,
            selected: selectedPageName === 'ManagePurchasesPage',
            linkProps: {
              name: 'ManagePurchasesPage',
            },
          },
        ]
      : []),
    ...(canViewPage.ManageSalesPage
      ? [
          {
            text: salesTitle,
            selected: selectedPageName === 'ManageSalesPage',
            linkProps: {
              name: 'ManageSalesPage',
            },
          },
        ]
      : []),
    ...(canViewPage.ManageListingsPage
      ? [
          {
            text: listingsTitle,
            selected: selectedPageName === 'ManageListingsPage',
            linkProps: {
              name: 'ManageListingsPage',
            },
          },
        ]
      : []),
    ...(canViewPage.ManageTradeInsPage
      ? [
          {
            text: tradeInsTitle,
            selected: selectedPageName === 'ManageTradeInsPage',
            linkProps: {
              name: 'ManageTradeInsPage',
            },
          },
        ]
      : []),
    ...(canViewPage.ManageClosetPage
      ? [
          {
            text: closetTitle,
            selected: ['ManageClosetPage', 'ManageListingsPage', 'ManageTradeInsPage'].includes(
              selectedPageName
            ),
            linkProps: {
              name: 'ManageClosetPage',
            },
          },
        ]
      : []),
  ];

  const settingsTabs = [
    ...(canViewPage.ProfileSettingsPage
      ? [
          {
            text: <formattedmessage id="ManageListingsPage.profileSettings"></formattedmessage>,
            selected: selectedPageName === 'ProfileSettingsPage',
            linkProps: {
              name: 'ProfileSettingsPage',
            },
          },
        ]
      : []),
    {
      text: <formattedmessage id="ManageListingsPage.accountSettings"></formattedmessage>,
      selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
    ...(isNotificationSettingsPageEnabled && canViewPage.NotificationSettingsPage
      ? [
          {
            text: 'Notification Settings',
            selected: selectedPageName === 'NotificationSettingsPage',
            linkProps: {
              name: 'NotificationSettingsPage',
            },
          },
        ]
      : []),
  ];

  const isManageView = MANAGE_PAGES.includes(selectedPageName);
  const tabs = isManageView ? manageTabs : settingsTabs;

  return (
    <navbar wrapperBoxOverrides="{{" justifyContent:="" isMobile="" ?="" 'start'="" :="" 'center'="" }}="" id="{USER_NAVBAR_ELEM_ID}">
      {tabs.map((tab) => (
        <box m="{1}" key="{tab.text.toString()}">
          <namedlink {...tab.linkProps}="">
            <typographywrapper 0="" variant="subtitle1" typographyOverrides="{{" style:="" {="" whiteSpace:="" 'nowrap',="" margin:="" }="" }}="" format="{tab.selected" ?="" TypographyFormat.Underlined="" :="" TypographyFormat.HoverUnderlined}="">
              {tab.text}
            </typographywrapper>
          </namedlink>
        </box>
      ))}
    </navbar>
  );
};
export default UserNavbar;
</usernavbarprops>