import React, { FC } from 'react';
import { defaultTreetStyles } from '../../../shopConfig/config';

interface IconNotifyProps {
  className?: string;
}

const IconNotify: FC<iconnotifyprops> = (props) => {
  const { className } = props;
  return (
    <svg className="{className}" width="100" height="100" viewBox="0 0 544 544" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M544 272C544 422.221 422.221 544 272 544C121.779 544 0 422.221 0 272C0 121.779 121.779 0 272 0C422.221 0 544 121.779 544 272Z" fill="{defaultTreetStyles.gray10}"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M193.164 311.996C193.573 316.841 193.126 321.723 191.771 326.387L189.373 334.641C185.259 348.797 188.593 364.732 198.195 376.813C205.246 385.685 217.04 388.382 226.045 383.183L371.592 299.151C380.597 293.952 384.158 282.39 380.001 271.848C374.339 257.492 362.206 246.637 347.889 243.121L339.542 241.071C334.78 239.901 330.287 237.818 326.265 235C306.894 250.095 285.289 264.685 261.886 278.197C238.783 291.535 215.647 302.83 193.164 311.996Z" fill="{defaultTreetStyles.gray40}"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M293.418 365.396C290.611 367.017 290.069 370.857 292.662 372.803C304.84 381.944 321.784 383.498 335.805 375.403C349.825 367.308 356.952 351.858 355.124 336.74C354.735 333.522 351.139 332.071 348.332 333.692L293.418 365.396Z" fill="{defaultTreetStyles.gray40}"></path>
      <path d="M226.914 372.618C217.94 377.799 206.522 375.692 199.951 367.642C191.004 356.679 188.312 341.89 192.827 328.502L195.459 320.696C199.446 308.873 197.119 295.816 189.29 286.077C176.252 269.859 175.049 247.122 186.305 229.674C204.458 201.535 235.033 183.882 268.479 182.231C289.217 181.207 308.306 193.618 315.833 213.017C320.352 224.667 330.497 233.211 342.729 235.67L350.805 237.293C364.657 240.077 376.119 249.803 381.139 263.033C384.826 272.748 380.942 283.69 371.967 288.871L226.914 372.618Z" fill="none" stroke="{defaultTreetStyles.gray100}" strokeWidth="8" strokeLinejoin="round"></path>
      <path d="M228.458 169.175C233.642 178.155 230.565 189.638 221.585 194.822C212.605 200.007 201.122 196.93 195.938 187.95C190.753 178.97 193.83 167.487 202.81 162.303C211.79 157.118 223.273 160.195 228.458 169.175Z" fill="none" stroke="{defaultTreetStyles.gray100}" strokeWidth="8" strokeLinejoin="round"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M289.343 354.749C301.538 367.499 321.284 370.786 337.244 361.572C353.204 352.357 360.23 333.613 355.286 316.677L289.343 354.749Z" fill="{defaultTreetStyles.gray100}"></path>
      <path d="M289.343 354.749L287.343 351.285C286.279 351.899 285.557 352.967 285.383 354.183C285.209 355.398 285.603 356.626 286.452 357.514L289.343 354.749ZM355.286 316.677L359.126 315.556C358.782 314.377 357.915 313.421 356.776 312.964C355.636 312.507 354.35 312.598 353.286 313.212L355.286 316.677ZM335.244 358.107C320.918 366.379 303.184 363.433 292.233 351.984L286.452 357.514C299.891 371.565 321.651 375.193 339.244 365.036L335.244 358.107ZM351.446 317.797C355.886 333.005 349.57 349.836 335.244 358.107L339.244 365.036C356.837 354.878 364.575 334.22 359.126 315.556L351.446 317.797ZM353.286 313.212L287.343 351.285L291.343 358.213L357.286 320.141L353.286 313.212Z" fill="{defaultTreetStyles.gray100}"></path>
    </svg>
  );
};

export default IconNotify;
</iconnotifyprops>