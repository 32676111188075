import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconSecureProps {
  className?: string;
  color?: string;
  width?: string;
  height?: string;
}

const IconSecure: FC<iconsecureprops> = (props: IconSecureProps) => {
  const { className, color, width = '32', height = '32' } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg className="{className}" width="{width}" height="{height}" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16 2C12.6863 2 10 4.68629 10 8V10H9C6.23858 10 4 12.2386 4 15V25C4 27.7614 6.23858 30 9 30H23C25.7614 30 28 27.7614 28 25V15C28 12.2386 25.7614 10 23 10H22V8C22 4.68629 19.3137 2 16 2ZM20 10V8C20 5.79086 18.2091 4 16 4C13.7909 4 12 5.79086 12 8V10H20ZM10 12H9C7.34315 12 6 13.3431 6 15V25C6 26.6569 7.34315 28 9 28H23C24.6569 28 26 26.6569 26 25V15C26 13.3431 24.6569 12 23 12H22H10Z" fill="{fillColor}"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.7242 17.3793L14.3334 25.1397L10.2759 20.8793L11.7242 19.5L14.3334 22.2397L20.2759 16L21.7242 17.3793Z" fill="{fillColor}"></path>
    </svg>
  );
};

export default IconSecure;
</iconsecureprops>