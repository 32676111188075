import React, { FC } from 'react';

const IconShipIt: FC = () => (
  <svg width="100" height="100" viewBox="0 0 544 544" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="272" cy="272" r="272" fill="#F8F8F8"></circle>
    <path d="M147 153H396V380H147V153Z" fill="#F8F8F8"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M143 153C143 150.791 144.791 149 147 149H396C398.209 149 400 150.791 400 153V380C400 382.209 398.209 384 396 384H147C144.791 384 143 382.209 143 380V153ZM151 157V376H392V157H151Z" fill="#454545"></path>
    <rect x="320" y="153" width="76" height="227" fill="#D1D1D1" stroke="#454545" strokeWidth="8" strokeLinejoin="round"></rect>
    <path d="M320 153H395.413L421 220H345.587L320 153Z" fill="#D1D1D1"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M316.705 150.732C317.452 149.647 318.684 149 320 149H395.413C397.072 149 398.558 150.024 399.15 151.573L424.737 218.573C425.206 219.803 425.041 221.184 424.295 222.268C423.548 223.353 422.316 224 421 224H345.587C343.928 224 342.442 222.976 341.85 221.427L316.263 154.427C315.794 153.197 315.959 151.816 316.705 150.732ZM325.809 157L348.341 216H415.191L392.659 157H325.809Z" fill="#454545"></path>
    <rect x="173" y="282" width="82" height="74" fill="#454545"></rect>
    <rect x="183" y="295" width="31" height="7" fill="#F8F8F8"></rect>
    <rect x="183" y="309" width="44" height="7" fill="#F8F8F8"></rect>
    <rect x="183" y="323" width="37" height="7" fill="#F8F8F8"></rect>
    <rect x="183" y="338" width="48" height="7" fill="#F8F8F8"></rect>
    <path d="M147.068 153H320L295.932 220H123L147.068 153Z" fill="#F8F8F8"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M143.303 151.648C143.874 150.059 145.38 149 147.068 149H320C321.302 149 322.523 149.634 323.272 150.699C324.021 151.764 324.205 153.127 323.765 154.352L299.697 221.352C299.126 222.941 297.62 224 295.932 224H123C121.698 224 120.477 223.366 119.728 222.301C118.979 221.236 118.795 219.873 119.236 218.648L143.303 151.648ZM149.881 157L128.687 216H293.119L314.313 157H149.881Z" fill="#454545"></path>
    <rect x="346" y="253" width="24" height="50" rx="12" fill="#F8F8F8"></rect>
  </svg>
);

export default IconShipIt;
