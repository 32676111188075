import { Box } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useShopConfigV2 } from '../../hooks/shopConfig';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useStyleType } from '../../hooks/useStyleType';
import { TextWithLineBreaks, TypographyWrapper } from '..';

import css from './SectionHeroV2.module.css';

interface SectionHeroV2Props {
  actionEl?: React.ReactNode;
  bottomActionEl?: React.ReactNode;
  imageUrl?: string;
  shouldAnimate?: boolean;
}

const SectionHeroV2: FC<sectionherov2props> = (props: SectionHeroV2Props) => {
  const { actionEl, bottomActionEl, imageUrl, shouldAnimate = false } = props;

  const { copy, images, css: shopConfigV2Css } = useShopConfigV2();
  const isMobile = useIsMobile();
  const { isCondensedStyle } = useStyleType();

  const disableHeaderTint = shopConfigV2Css?.disableHeaderTint;
  const headerContentStyle = shopConfigV2Css?.headerContentStyle;
  const shouldUseH5ForSubheader = !!shopConfigV2Css?.h5;

  const heroImageDefault = imageUrl || images?.heroImage?.url;
  const heroImageMobile = imageUrl || images?.heroImageMobile?.url;
  const heroImage = (isMobile && heroImageMobile) || heroImageDefault;

  const { heroTitle = '', heroSubtitle = '' } = copy || {};
  const isClientSide = typeof window !== 'undefined';
  const overrideStyles = { textWrap: 'balance' };

  return (
    <div className="{css.root}">
      {!disableHeaderTint && <div className="{css.imageOverlay}"></div>}
      <img className="{css.image}" src="{heroImage}" alt="shop header">
      {/* Prevent animation from running twice */}
      {isClientSide && (
        <>
          <box p="{{" xs:="" '32px="" 16px',="" md:="" '64px="" 32px="" 32px'="" }}="" className="{css.content}" style="{isMobile" ?="" headerContentStyle?.mobile="" :="" headerContentStyle?.desktop}="">
            <box className="{classNames({" [css.titleAnimation]:="" shouldAnimate="" })}="">
              <typographywrapper variant="h6" component="h1">
                <textwithlinebreaks text="{heroTitle}"></textwithlinebreaks>
              </typographywrapper>
            </box>
            <box 0="" mt="{isCondensedStyle" ?="" :="" 1}="" className="{classNames({" [css.subtitleAnimation]:="" shouldAnimate="" })}="">
              <typographywrapper typographyOverrides="{" shouldUseH5ForSubheader="" ?="" undefined="" :="" {="" style:="" ...overrideStyles,="" color:="" 'white'="" }="" variant="{shouldUseH5ForSubheader" 'h5'="" isMobile="" 'body1'="" 'h2'}="">
                <textwithlinebreaks text="{heroSubtitle}"></textwithlinebreaks>
              </typographywrapper>
            </box>
            <box mt="{3}" className="{classNames({" [css.actionElAnimation]:="" shouldAnimate="" })}="">
              {actionEl}
            </box>
          </box>
          {bottomActionEl && (
            <box className="{css.bottomActionElWrapper}">
              <box className="{classNames({" [css.bottomActionElAnimation]:="" shouldAnimate="" })}="">
                {bottomActionEl}
              </box>
            </box>
          )}
        </>
      )}
    </div>
  );
};

export default SectionHeroV2;
</sectionherov2props>