import { Box } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { ExpressCheckoutElement } from '@stripe/react-stripe-js';
import { StripeExpressCheckoutElementReadyEvent } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { TypographyWrapper } from '../../components';
import css from './StripeExpressCheckoutSection.module.css';
import {
  CheckoutPageState,
  initializeStripeExpressElementsError,
  initializeStripeExpressElementsRequest,
  initializeStripeExpressElementsSuccess,
} from '../../containers/CheckoutPage/CheckoutPage.duck';
import CheckoutError from '../../containers/CheckoutPage/CheckoutError';
import { useIsMobile } from '../../hooks/useIsMobile';

interface StripeExpressCheckoutSectionProps {
  isStripeExpressCheckoutEnabled: boolean;
  handleExpressCheckoutButtonClick: () => void;
  onConfirm: (values: any) => void;
  onShippingAddressChange: (values: any) => void;
  isMultiSellerCheckout: boolean;
}

const StripeExpressCheckoutSection: FC<stripeexpresscheckoutsectionprops> = ({
  isStripeExpressCheckoutEnabled,
  handleExpressCheckoutButtonClick,
  onConfirm,
  onShippingAddressChange,
  isMultiSellerCheckout,
}) => {
  const dispatch = useDispatch();

  if (!isStripeExpressCheckoutEnabled) return null;

  const { isExpressCheckoutError } = useSelector<any>(
    (state) => state.CheckoutPage
  ) as CheckoutPageState;

  const isMobile = useIsMobile();

  const [visibility, setVisibility] = useState('none');

  const onReady = (event: StripeExpressCheckoutElementReadyEvent) => {
    if (event.availablePaymentMethods) {
      setVisibility('block');
    }
    dispatch(initializeStripeExpressElementsSuccess());
  };

  const onLoadError = () => {
    dispatch(initializeStripeExpressElementsError());

    setVisibility('none');
  };

  useEffect(() => {
    dispatch(initializeStripeExpressElementsRequest());
  }, []);

  const shouldDisplayCheckoutErrorMessage =
    isExpressCheckoutError && (isMobile || (!isMobile && !isMultiSellerCheckout));

  return (
    <box display="{visibility}">
      <box mb="{2}">
        <typographywrapper variant="h2">Express Checkout</typographywrapper>
      </box>
      <box textAlign="{isMobile" ?="" 'center'="" :="" ''}="" mb="{0.5}">
        {shouldDisplayCheckoutErrorMessage && <checkouterror></checkouterror>}
      </box>
      <expresscheckoutelement options="{{" paymentMethods:="" {="" applePay:="" 'always',="" googlePay:="" 'always'="" },="" layout:="" overflow:="" 'never'="" }}="" onClick="{handleExpressCheckoutButtonClick}" onConfirm="{onConfirm}" onShippingAddressChange="{onShippingAddressChange}" onReady="{onReady}" onLoadError="{onLoadError}"></expresscheckoutelement>
      <box display="flex" flexDirection="row" alignItems="center" justifyContent="center" my="{2}">
        <box className="{css.dividerBox}" flex="{1}"></box>
        <box mx="{2}">
          <typographywrapper variant="h2">or</typographywrapper>
        </box>
        <box className="{css.dividerBox}" flex="{1}"></box>
      </box>
    </box>
  );
};

export default StripeExpressCheckoutSection;
</any></stripeexpresscheckoutsectionprops>