import Box from '@material-ui/core/Box';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetActiveModal } from '../../ducks/modal.duck';
import { fetchBundleAuthorListings } from '../../ducks/shoppingBag.duck';
import {
  Bundle,
  Button,
  DialogWithCloseButton,
  Divider,
  IconButton,
  IconListFilled,
  IconTrash,
  IconTrashWithBorder,
  NamedLink,
  TypographyWrapper,
} from '..';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import { removeListingsFromShoppingBag } from '../../ducks/user.duck';
import { useRouteConfiguration } from '../../hooks/useRouteConfiguration';
import { ListingWithAuthorAndImages } from '../../types/sharetribe/listing';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { pluralize } from '../../util/strings';
import { ButtonProps, ButtonVariant } from '../Button/Button';
import css from './ShoppingBagBundles.module.css';
import { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { defaultTreetStyles } from '../../shopConfig/config';

const ActionButton: FC<buttonprops> = (props: ButtonProps) => {
  const { children, ...rest } = props;

  return (
    <button {...rest}="">
      <box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
        {children}
      </box>
    </button>
  );
};

interface DeleteBundleModalProps {
  title: string;
  children: ReactNode;
}

export const DeleteBundleModal: FC<deletebundlemodalprops> = (props: DeleteBundleModalProps) => {
  const { title, children } = props;

  return (
    <>
      <div className="{css.header}">
        <box display="flex" flexDirection="row" alignItems="center" justifyContent="center" px="{3}" className="{css.headerBar}">
          <typographywrapper variant="h2" weight="{TypographyWeight.Bold}">
            {title}
          </typographywrapper>
        </box>
        <divider className="{css.divider}"></divider>
      </div>
      <div className="{css.content}">{children}</div>
    </>
  );
};

interface DeleteBundleButtonProps {
  numItems: number;
  displayName: string;
}

const DeleteBundleButton: FC<deletebundlebuttonprops &="" ButtonProps=""> = (
  props: DeleteBundleButtonProps & ButtonProps
) => {
  const { onClick, numItems, displayName } = props;

  const [isDeleteBundleModalOpen, setIsDeleteBundleModalOpen] = useState(false);

  const title = `Remove ${pluralize('Item', numItems, true)}`;

  return (
    <>
      <iconbutton onClick="{()" ==""> setIsDeleteBundleModalOpen(true)}
        icon={<icontrash className="{css.trashIcon}"></icontrash>}
      />
      <dialogwithclosebutton open="{isDeleteBundleModalOpen}" headerClassName="{css.dialogHeader}" fullWidth="" maxWidth="sm" onClose="{()" ==""> {
          setIsDeleteBundleModalOpen(false);
        }}
      >
        <deletebundlemodal title="{title}">
          <>
            <box 15="" display="flex" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" px="{4}" pt="{{" xs:="" 20,="" md:="" }}="" pb="{20}">
              <icontrashwithborder className="{css.trashIconRemoveItems}"></icontrashwithborder>
              <typographywrapper variant="body1">
                All items from {displayName} will be removed from your shopping bag.
              </typographywrapper>
            </box>
            <box className="{css.deleteBundleFooter}">
              <actionbutton onClick="{onClick}" variant="{ButtonVariant.Danger}">
                {title}
              </actionbutton>
            </box>
          </>
        </deletebundlemodal>
      </dialogwithclosebutton>
    </>
  );
};

const CheckoutButton: FC<{ numItems: number } & ButtonProps> = (
  props: { numItems: number } & ButtonProps
) => {
  const { onClick, numItems } = props;
  return (
    <actionbutton onClick="{onClick}" enforcePagePreloadFor="CheckoutPage" style="{{" width:="" '120px',="" minHeight:="" '36px',="" padding:="" '0="" 8px',="" fontSize:="" '10px',="" backgroundColor:="" defaultTreetStyles.gray10,="" color:="" defaultTreetStyles.gray80,="" }}="">
      Checkout ({numItems})
    </actionbutton>
  );
};

interface ShoppingBagBundleProps {
  isEditing: boolean;
  listings: ListingWithAuthorAndImages[];
}

const ShoppingBagBundle: FC<shoppingbagbundleprops> = (props: ShoppingBagBundleProps) => {
  const { isEditing, listings } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const routes = useRouteConfiguration();

  const authorId = listings[0].author.id.uuid;
  const authorListingsCount = useSelector<any>(
    (state) => state.shoppingBag.bundleAuthorToListingIds[authorId]?.length || 0
  ) as number;

  const [isDeletingBundle, setIsDeletingBundle] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchBundleAuthorListings(authorId));
  }, []);

  const handleRemoveListings = (listingIds: string[]) => {
    dispatch(removeListingsFromShoppingBag({ listingIds, shouldQueueAbandonedBagEmail: true }));
  };

  const handleDeleteBundle = async () => {
    setIsDeletingBundle(true);
    await new Promise(() => handleRemoveListings(listings.map((l) => l.id.uuid)));
    setIsDeletingBundle(false);
  };

  const handleCloseShoppingBagModal = () => dispatch(resetActiveModal());

  const handleCheckout = () => {
    // Customize checkout page state with shopping bag listings
    const { setInitialValues: checkoutPageSetInitialValues } = findRouteByRouteName(
      'CheckoutPage',
      routes
    );

    dispatch(checkoutPageSetInitialValues(listings));
    handleCloseShoppingBagModal();

    // Clear previous Stripe errors from store if there is any
    dispatch(initializeCardPaymentData());

    // Redirect to CheckoutPage
    history.push(createResourceLocatorString('CheckoutPage', routes));
  };

  const { displayName } = listings[0].author.attributes.profile;

  const actionButton = isEditing ? (
    <box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
      <deletebundlebutton onClick="{handleDeleteBundle}" numItems="{listings.length}" displayName="{displayName}"></deletebundlebutton>
      <checkoutbutton onClick="{handleCheckout}" numItems="{listings.length}"></checkoutbutton>
    </box>
  ) : (
    <checkoutbutton onClick="{handleCheckout}" numItems="{listings.length}"></checkoutbutton>
  );

  const heading = (
    <box display="flex" flexDirection="row" justifyContent="space-between">
      <typographywrapper variant="body1" typographyOverrides="{{" display:="" 'inline'="" }}="">
        <>
          <b>{pluralize('Item', listings.length, true)}</b> from{' '}
          <namedlink name="ProfilePage" params="{{" id:="" authorId="" }}="" className="{css.author}" onClick="{handleCloseShoppingBagModal}">
            {displayName}
          </namedlink>
        </>
      </typographywrapper>
      {actionButton}
    </box>
  );

  const addMoreButton = (
    <namedlink name="ProfilePage" params="{{" id:="" authorId="" }}="" className="{css.addMoreButton}" onClick="{handleCloseShoppingBagModal}">
      <iconlistfilled></iconlistfilled>
      <div className="{css.addMoreText}">
        <typographywrapper variant="body2" typographyOverrides="{{" style:="" {="" fontSize:="" '13px',="" lineHeight:="" '13px'="" }="" }}="">
          Add items <br> and save <br> on shipping
        </typographywrapper>
      </div>
    </namedlink>
  );

  return (
    <bundle isEditing="{isEditing}" isLoading="{isDeletingBundle}" listings="{listings}" heading="{heading}" listingsInlineButton="{" authorListingsCount=""> listings.length && !isEditing ? addMoreButton : undefined
      }
      allowListingRedirect={!isEditing}
      handleRemoveListings={handleRemoveListings}
      referrerForLogging="Shopping Bag"
    />
  );
};

export default ShoppingBagBundle;
</bundle></boolean></any></shoppingbagbundleprops></iconbutton></deletebundlebuttonprops></deletebundlemodalprops></buttonprops>