/* global google */
/* eslint-disable default-case */
import { MutableState, Tools } from 'final-form';
import { ShippoAddress } from '../types/shippo/address';
import { SharetribeAddress } from '../types/sharetribe/address';
import { CountryCode } from '../types/apollo/generated/types.generated';

enum GoogleAddressComponentType {
  StreetNumber = 'street_number',
  Route = 'route',
  PostalCode = 'postal_code',
  PostalCodeSuffix = 'postal_code_suffix',
  Locality = 'locality',
  AdministrativeAreaLevel1 = 'administrative_area_level_1',
  Country = 'country',
}

export const getSharetribeAddressFromShippoAddress = (
  address: ShippoAddress
): SharetribeAddress => ({
  addressLine1: address.street1,
  addressLine2: address.street2,
  city: address.city,
  state: address.state,
  country: address.country,
  postal: address.zip,
  phone: address.phone,
});

export const getPrismaAddressFromSharetribeAddress = (address: SharetribeAddress) => ({
  addressLine1: address.addressLine1,
  addressLine2: address.addressLine2 || null,
  city: address.city,
  state: address.state,
  country: address.country,
  zip: address.postal,
  fullName: address.name || '',
  phone: address.phone || null,
});

export const getSeelAddressFromSharetribeAddress = (address: Partial<sharetribeaddress>) => {
  const { addressLine1, addressLine2, city, state, postal, country } = address;
  return {
    address_1: addressLine1,
    address_2: addressLine2,
    city,
    state,
    zipcode: postal,
    country,
  };
};

export const getSharetribeAddressFromGoogle = (
  googleAutocomplete: google.maps.places.Autocomplete
): SharetribeAddress => {
  const addressObject = googleAutocomplete.getPlace();
  const addressComponents = addressObject.address_components;

  let addressLine1 = '';
  let postal = '';

  const sharetribeAddress: any = {};
  addressComponents?.forEach((component) => {
    const type = component.types[0];
    switch (type) {
      case GoogleAddressComponentType.StreetNumber: {
        addressLine1 = `${component.long_name} ${addressLine1}`;
        break;
      }

      case GoogleAddressComponentType.Route: {
        addressLine1 += component.short_name;
        break;
      }

      case GoogleAddressComponentType.PostalCode: {
        postal = `${component.long_name}${postal}`;
        break;
      }

      case GoogleAddressComponentType.PostalCodeSuffix: {
        postal = `${postal}-${component.long_name}`;
        break;
      }

      case GoogleAddressComponentType.Locality: {
        sharetribeAddress.city = component.long_name;
        break;
      }

      case GoogleAddressComponentType.AdministrativeAreaLevel1: {
        sharetribeAddress.state = component.short_name;
        break;
      }

      case GoogleAddressComponentType.Country: {
        sharetribeAddress.country = component.short_name;
        break;
      }
    }
  });

  sharetribeAddress.addressLine1 = addressLine1;
  sharetribeAddress.postal = postal;
  return sharetribeAddress;
};

export const getAddressAutocomplete = (google: any, elementId: string) =>
  new google.maps.places.Autocomplete(document.getElementById(elementId), {
    fields: ['address_components'],
  });

// Formats address into proper format
// e.g. 535 Mission Street, San Francisco, CA 94105
export const formatAddress = (address: SharetribeAddress) => {
  const { addressLine1, addressLine2, city, state, postal } = address;
  return `${addressLine1}${
    addressLine2 ? ` ${addressLine2}` : ''
  }, ${city}, ${state} ${postal}`.trim();
};

export const constructAddressMutatorFn =
  (prefix: string) => (args: any[], state: MutableState<any>, tools: Tools<any>) => {
    const newValues = args?.[0];
    if (newValues) {
      tools.changeValue(state, prefix, (oldValues) => ({
        ...oldValues,
        ...newValues,
      }));
    }
  };

export const markFieldsAsTouched = (prefix: string) => (args: any[], state: MutableState<any>) => {
  const fields = Object.keys(state.fields);

  fields.forEach((fieldName) => {
    if (fieldName.startsWith(prefix)) {
      if (state.fields[fieldName]) {
        state.fields[fieldName].touched = true; // eslint-disable-line no-param-reassign
      }
    }
  });
};

export const getStateValueMaxLength = (countryValue: CountryCode): number | null => {
  if (countryValue === CountryCode.Us || countryValue === CountryCode.Ca) {
    return 2;
  }
  if (countryValue === CountryCode.Au) {
    return 3;
  }
  return null;
};
</any></any></any></sharetribeaddress>