import { Box } from '@material-ui/core';
import React, { CSSProperties, FC, ReactElement, useRef } from 'react';
import { Button, NamedLink, TypographyWrapper } from '..';
import {
  DRAFT_ID,
  DRAFT_SLUG,
  ListingPageParamTab,
} from '../../containers/EditListingPage/EditListingPage.utils';
import { useShopConfigV2 } from '../../hooks/shopConfig';
import { useElementSize } from '../../hooks/useElementSize';
import { useIsMobile } from '../../hooks/useIsMobile';
import { ListingPageParamType } from '../../util/urlHelpers';
import { LIST_ITEM_CARD_DEFAULT_OVERFLOW_WIDTH } from '../../util/constants';
import { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import css from './ListAnItemCard.module.css';

interface ListAnItemCardProps {
  buttonText: { default: string; condensed: string };
  captionHeaderText: string;
  captionSubtitleText: string;
  mainContent: ReactElement | string;
  urlQueryParams?: { [key: string]: string };
  maxWidthForOverflow?: number | undefined;
  style?: CSSProperties;
}

const ListAnItemCard: FC<listanitemcardprops> = (props) => {
  const {
    mainContent,
    buttonText,
    captionHeaderText,
    captionSubtitleText,
    urlQueryParams,
    maxWidthForOverflow = LIST_ITEM_CARD_DEFAULT_OVERFLOW_WIDTH,
    style,
  } = props;
  const { imageRatio, css: brandCss } = useShopConfigV2();

  const componentRef = useRef(null);
  const { width } = useElementSize(componentRef);
  const isMobile = useIsMobile();
  const isOverflowWidth = isMobile || (!!width && width <= maxWidthForOverflow);
  const fontSize = isOverflowWidth ? '13px' : undefined;

  const formattedButtonText = isOverflowWidth ? buttonText?.condensed : buttonText?.default;
  const search = urlQueryParams ? new URLSearchParams(urlQueryParams).toString() : null;
  const overrideStyles = { textWrap: 'balance' };

  return (
    <div ref="{componentRef}">
      <box style="{style}">
        <namedlink Directs="" to="" NewListingPage.="" Define="" 'params'="" manually="" so="" that="" url="" query="" params="" in="" 'to'="" are="" not="" overwritten.="" name="EditListingPage" slug:="" DRAFT_SLUG,="" id:="" DRAFT_ID,="" type:="" ListingPageParamType.New,="" tab:="" ListingPageParamTab.Search,="" }}="" style="{{" textDecoration:="" 'none'="" {...(search="" &&="" {="" to:="" search="" }="" })}="">
          <box className="{css.aspectWrapper}" style="{{" paddingBottom:="" `calc(${imageRatio="" *="" 100}%="" -="" 2px)`="" }}="" subtract="" 2px="" to="" account="" for="" added="" height="" from="" border="" bgcolor="{brandCss?.backgroundColor}">
            <box 2="" display="flex" flexDirection="column" p="{{" xs:="" 1,="" sm:="" }}="" className="{css.content}">
              <typographywrapper variant="body1" component="span" typographyOverrides="{{" align:="" 'center',="" style:="" {="" fontSize,="" ...overrideStyles="" }="" }}="">
                {mainContent}
              </typographywrapper>
              <box mt="{1}">
                <button className="{css.button}">{formattedButtonText}</button>
              </box>
            </box>
          </box>

          <box display="flex" pt="{1}" flexDirection="column" justifyItems="center">
            <box className="{css.textRow}">
              <typographywrapper variant="body1" weight="{TypographyWeight.Bold}">
                {captionHeaderText}
              </typographywrapper>
            </box>
            <box className="{css.textRow}">
              <typographywrapper variant="body2">{captionSubtitleText}</typographywrapper>
            </box>
          </box>
        </namedlink>
      </box>
    </div>
  );
};

export default ListAnItemCard;
</listanitemcardprops>