import React from 'react';
import { bool, error, func, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { injectIntl } from '../../util/reactIntl';
import {
  Button,
  Error,
  Form,
  FormattedMessage,
  ModalParagraph,
  ModalTitle,
  NamedLink,
} from '../../components';
import { verify } from '../../ducks/EmailVerification.duck';
import { propTypes } from '../../util/types';

import css from './EmailVerificationForm.module.css';

const VerifyEmail = (props) => {
  const { currentUser, inProgress, handleSubmit, verificationError } = props;

  const { email, pendingEmail } = currentUser.attributes;
  const emailToVerify = <b>{pendingEmail || email}</b>;

  const errorMessage = (
    <box mt="{3}">
      <error>
        <formattedmessage id="EmailVerificationForm.verificationFailed"></formattedmessage>
      </error>
    </box>
  );

  return (
    <div className="{css.root}">
      <div>
        <modaltitle>
          <formattedmessage id="EmailVerificationForm.verifyEmailAddress"></formattedmessage>
        </modaltitle>
        <modalparagraph>
          <formattedmessage id="EmailVerificationForm.finishAccountSetup" values="{{" email:="" emailToVerify="" }}=""></formattedmessage>
        </modalparagraph>
        {verificationError ? errorMessage : null}
      </div>

      <form onSubmit="{handleSubmit}">
        <field component="input" type="hidden" name="verificationToken"></field>

        <div className="{css.bottomWrapper}">
          <button type="submit" inProgress="{inProgress}" disabled="{inProgress}">
            {inProgress ? (
              <formattedmessage id="EmailVerificationForm.verifying"></formattedmessage>
            ) : (
              <formattedmessage id="EmailVerificationForm.verify"></formattedmessage>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

VerifyEmail.defaultProps = {
  inProgress: false,
  verificationError: null,
};

VerifyEmail.propTypes = {
  inProgress: bool,
  currentUser: propTypes.currentUser.isRequired,
  verificationError: error,
  handleSubmit: func.isRequired,
};

const AlreadyVerified = (props) => {
  const { name } = props;

  return (
    <div className="{css.root}">
      <div>
        <modaltitle>
          <formattedmessage id="EmailVerificationForm.successTitle" values="{{" name="" }}=""></formattedmessage>
        </modaltitle>
        <modalparagraph>
          <formattedmessage id="EmailVerificationForm.successText"></formattedmessage>
        </modalparagraph>
      </div>

      <div className="{css.bottomWrapper}">
        <namedlink name="LandingPage" style="{{" textDecoration:="" 'none'="" }}="">
          <button>Continue to site</button>
        </namedlink>
      </div>
    </div>
  );
};

AlreadyVerified.defaultProps = {
  name: '',
};

AlreadyVerified.propTypes = {
  name: string,
};

const EmailVerificationFormComponent = (props) => {
  const dispatch = useDispatch();
  const handleSubmitVerification = ({ verificationToken }) => dispatch(verify(verificationToken));

  return (
    <finalform {...props}="" onSubmit="{handleSubmitVerification}" render="{(formRenderProps)" ==""> {
        const { currentUser, inProgress, handleSubmit, verificationError } = formRenderProps;

        const { emailVerified, pendingEmail, profile } = currentUser.attributes;
        const name = profile.firstName;

        return emailVerified && !pendingEmail ? (
          <alreadyverified name="{name}"></alreadyverified>
        ) : (
          <verifyemail currentUser="{currentUser}" inProgress="{inProgress}" handleSubmit="{handleSubmit}" verificationError="{verificationError}"></verifyemail>
        );
      }}
    />
  );
};

EmailVerificationFormComponent.defaultProps = {
  inProgress: false,
  verificationError: null,
};

EmailVerificationFormComponent.propTypes = {
  inProgress: bool,
  currentUser: propTypes.currentUser.isRequired,
  verificationError: error,
};

const EmailVerificationForm = compose(injectIntl)(EmailVerificationFormComponent);

export default EmailVerificationForm;
</finalform>