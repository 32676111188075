import React, { FC } from 'react';
import { useFormState } from 'react-final-form';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { Button, IconArrowLeft, InlineTextButton } from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import css from './ISOModal.module.css';
import { Pane } from './ISOModal';

const BackButton: FC<{ onBackClick: () => void }> = (props) => {
  const { onBackClick } = props;
  return (
    <inlinetextbutton onClick="{onBackClick}">
      <box display="flex" alignItems="center" p="{1}">
        <iconarrowleft></iconarrowleft>
        <box style="{{" paddingLeft:="" '4px'="" }}="">Back</box>
      </box>
    </inlinetextbutton>
  );
};

const ISOFooter: FC<{
  pane: Pane;
  onBackClick?: () => void;
}> = (props) => {
  const { pane, onBackClick } = props;
  const { css: brandCss } = useShopConfig();
  const bgcolor = brandCss?.backgroundColor || 'white';
  const { invalid } = useFormState({ subscription: { invalid: true } });

  return (
    <box display="flex" alignItems="center" justifyContent="space-between" flexDirection="row-reverse" p="{1}" sx="{{" bgcolor="" }}="" pane="" needs="" to="" be="" added="" as="" a="" class="" for="" tracking="" clicks="" in="" heap="" className="{classNames(css.footer," pane)}="">
      <box p="{1}" display="flex">
        <button disabled="{invalid}" type="submit">
          {pane === Pane.Success ? 'Got It!' : 'Next'}
        </button>
      </box>
      {onBackClick ? <backbutton onBackClick="{onBackClick}"></backbutton> : null}
    </box>
  );
};

export default ISOFooter;
