import { Box, MenuItem, Select } from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';
import { stringify } from 'query-string';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  Button,
  Empty,
  Footer,
  IconCloseV2,
  IconSearch,
  IconSpinner,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  TypographyWrapper,
  UserNavbar,
} from '..';
import { TopbarContainer } from '../../containers';
import {
  ManagePurchasesPageState,
  setSearchValue,
} from '../../containers/ManagePurchasesPage/ManagePurchasesPage.duck';
import { useAppDispatch } from '../../hooks/appDispatch';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import {
  BundleStatus,
  BundleType,
  Participant,
} from '../../types/apollo/generated/types.generated';
import { BundleInfo } from '../../types/models/bundle';
import { getBundleStatusLabel } from '../../util/bundles';
import { getFormattedDate } from '../../util/dates';
import { parse } from '../../util/urlHelpers';
import IndividualBundleContainer from './IndividualBundleContainer';
import css from './ManageBundlesContainer.module.css';
import { BUNDLE_STATUS_FILTER_ALL, StatusFilterValues } from './manageBundlesContainerUtils';
import ThreeDotsIconButton from './ThreeDotsIconButton';
import BundleTypeMarker from '../BundleTypeMarker/BundleTypeMarker';

interface ManageBundlesContainerDesktopProps {
  isLoading: boolean;
  bundles: BundleInfo[];
  participant: Participant;
  onCancelClick?: (bundle: BundleInfo) => void;
  onVerifyClick?: (bundle: BundleInfo) => void;
  onDisputeClick?: (bundle: BundleInfo) => void;
  onGetPaidClick?: (bundle: BundleInfo) => void;
  onFilterChange?: (value: StatusFilterValues) => void;
  onMarkAsFulfilledClick?: (bundle: BundleInfo) => void;
  onMarkAsDeliveredClick?: (bundle: BundleInfo) => void;
  onEditShippingAddressSubmit?: (values: any, addressId: string, onSuccess: () => void) => void;
  error?: string;
  fetchMore?: (() => void) | false;
}

const ManageBundlesContainerDesktop: FC<managebundlescontainerdesktopprops> = (
  props: ManageBundlesContainerDesktopProps
) => {
  const {
    bundles,
    isLoading,
    participant,
    onCancelClick,
    onVerifyClick,
    onDisputeClick,
    onGetPaidClick,
    onFilterChange,
    onMarkAsFulfilledClick,
    onMarkAsDeliveredClick,
    onEditShippingAddressSubmit,
    error,
    fetchMore,
  } = props;

  const { isBrand, isTradeInAdmin } = useCurrentUserPermissions();
  const { allowTradeInAndMarketplace, isListTradeInOnly, isTradeInOnly, hasBuyHistory } =
    useEnabledCustomerExperiences();
  const location = useLocation();
  const queryParams = parse(location.search);
  const dispatch = useAppDispatch();
  const { searchValue } = useSelector<any>(
    (state) => state.ManagePurchasesPage
  ) as ManagePurchasesPageState;
  const { filter } = queryParams;

  const [trackingSearchQuery, setTrackingSearchQuery] = useState<string>('');

  const isSales = participant === Participant.Seller;
  // Specific Page for individual sales/purchases
  const currentIdPage = isSales ? 'ManageSalePage' : 'ManagePurchasePage';
  // Root index page for sales/purchases
  const currentRootPage = isSales ? 'ManageSalesPage' : 'ManagePurchasesPage';
  const { id: selectedBundleId } = useParams() as { id?: string };
  // Default to first bundle if we're just on the index page (/sales or /purchases)
  const activeBundle =
    (selectedBundleId && bundles.find((o) => o.id === selectedBundleId)) || bundles[0];
  const otherBundlesInSameOrder = selectedBundleId
    ? bundles.filter(
        (b) => b?.order?.id && b.order.id === activeBundle?.order?.id && b.id !== activeBundle.id
      )
    : undefined;

  const getTab = (bundle: BundleInfo) => {
    const isTradeInBundle = bundle.type === BundleType.TradeIn;
    return (
      <div className="{css.tab}">
        <box display="flex" flexDirection="column">
          <box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <box display="flex">
              <typographywrapper variant="h2">
                {getFormattedDate(bundle.createdAt)}
              </typographywrapper>
            </box>
            {allowTradeInAndMarketplace && (
              <box display="flex" paddingLeft="{1}">
                <bundletypemarker title="{isTradeInBundle" ?="" 'Trade-In'="" :="" 'Sale'}=""></bundletypemarker>
              </box>
            )}
          </box>
          <h4>
            <typographywrapper variant="body1">
              {getBundleStatusLabel(participant, bundle)}
            </typographywrapper>
          </h4>
        </box>
      </div>
    );
  };

  const getTabs = () =>
    bundles.map((bundle) => ({
      text: getTab(bundle),
      selected: bundle.id === activeBundle?.id,
      linkProps: {
        name: currentIdPage,
        params: { id: bundle.id },
        to: { search: stringify({ filter }) },
      },
    }));

  const handleCancelSearch = () => {
    dispatch(setSearchValue(''));
  };

  const statusFilterEl = onFilterChange && (
    <select labelId="bundle-status" id="bundle-status" value="{filter" ||="" BUNDLE_STATUS_FILTER_ALL}="" onChange="{(e)" ==""> {
        const val = e.target.value as StatusFilterValues;
        onFilterChange(val);
      }}
      label="Filter By"
      style={{ width: '100%' }}
    >
      <menuitem value="{BUNDLE_STATUS_FILTER_ALL}">All</menuitem>
      <menuitem value="{BundleStatus.Open}">Not yet shipped</menuitem>
      <menuitem value="{BundleStatus.Shipped}">Shipped</menuitem>
      <menuitem value="{BundleStatus.Delivered}">Delivered</menuitem>
      <menuitem value="{BundleStatus.Completed}">Completed</menuitem>
    </select>
  );

  const transactionType = () => {
    if (isTradeInOnly) {
      if (hasBuyHistory && !isSales) return 'purchases';
      return 'trade-ins';
    }
    if (isSales) {
      if (isListTradeInOnly) return 'trade-ins';
      if (allowTradeInAndMarketplace) return 'sales or trade-ins';
      return 'sales';
    }
    return 'purchases';
  };

  return (
    <>
      <layoutsidenavigation>
        <layoutwrappertopbar>
          <topbarcontainer currentPage="{currentRootPage}"></topbarcontainer>
          <usernavbar selectedPageName="{currentRootPage}"></usernavbar>
        </layoutwrappertopbar>
        <layoutwrappersidenav tabs="{getTabs()}" className="{css.sideNav}" getMore="{" fetchMore="" &&="" (="" <Box="" mb="{2}">
                <button onClick="{fetchMore}" disabled="{isLoading}">
                  Get More Orders
                </button>
              
            )
          }
        >
          {isBrand && (
            <box mr="{1}" mb="{3}">
              {/* TODO: implement search bar for sales page */}
              {!isSales && (
                <searchbar value="{searchValue}" onChange="{(newSearchValue)" ==""> setTrackingSearchQuery(newSearchValue)}
                  onRequestSearch={() => {
                    dispatch(setSearchValue(trackingSearchQuery));
                  }}
                  cancelOnEscape
                  onCancelSearch={handleCancelSearch}
                  searchIcon={<iconsearch></iconsearch>}
                  closeIcon={<iconclosev2></iconclosev2>}
                  classes={{
                    root: css.search,
                  }}
                  style={{
                    borderRadius: '0',
                    boxShadow: 'none',
                    height: '30px',
                  }}
                  placeholder={isTradeInAdmin ? 'Search by tracking #' : 'Search by order #'}
                />
              )}
              {statusFilterEl}
            </searchbar></box>
          )}
        </layoutwrappersidenav>
        <layoutwrappermain className="{css.body}">
          {error && <h4 className="{css.error}">{error}</h4>}
          {isLoading && (
            <box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex="{1}">
              <iconspinner></iconspinner>
            </box>
          )}
          {!isLoading && !error && bundles.length === 0 && (
            <empty text="{`No" ${transactionType()}="" yet.`}=""></empty>
          )}
          {!isLoading && activeBundle && (
            <>
              <div className="{css.content}">
                <box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" pl="{2}" pb="{1}">
                  <h2>
                    <typographywrapper variant="h1">
                      {getFormattedDate(activeBundle.createdAt)}
                    </typographywrapper>
                  </h2>
                  <threedotsiconbutton participant="{participant}" bundle="{activeBundle}" onCancelClick="{onCancelClick}"></threedotsiconbutton>
                </box>

                <individualbundlecontainer isLoading="{isLoading}" bundle="{activeBundle}" otherBundlesInSameOrder="{otherBundlesInSameOrder}" participant="{participant}" onVerifyClick="{onVerifyClick}" onDisputeClick="{onDisputeClick}" onGetPaidClick="{onGetPaidClick}" onMarkAsFulfilledClick="{onMarkAsFulfilledClick}" onMarkAsDeliveredClick="{onMarkAsDeliveredClick}" onEditShippingAddressSubmit="{onEditShippingAddressSubmit}"></individualbundlecontainer>
              </div>
            </>
          )}
          {/* TODO (sonia-y | TREET-616): Fill in error order state */}
          {/* Return some state that says the order is not found */}
          {!activeBundle && <div></div>}
        </layoutwrappermain>
        <layoutwrapperfooter>
          <footer></footer>
        </layoutwrapperfooter>
      </layoutsidenavigation>
    </>
  );
};

export default ManageBundlesContainerDesktop;
</string></any></managebundlescontainerdesktopprops>