import Box from '@material-ui/core/Box';
import isEqual from 'lodash/isEqual';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { IconCheckCircleFilled, IconSpinner, TypographyWrapper } from '..';
import { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { defaultTreetStyles } from '../../shopConfig/config';
import css from './AutoSave.module.css';

interface AutoSaveProps {
  values: any;
  onSave: (values: any) => Promise<void>;
  debounceTime: number;
}

// Based on https://gist.github.com/wyattjoh/f65047d58d5ee9cbe01b805aedb9b07a
const AutoSave: FC<autosaveprops> = (props) => {
  const { values: oldValues, onSave, debounceTime } = props;

  const [timeoutRef, setTimeoutRef] = useState<any |="" null="">(null);
  const [values, setValues] = useState(oldValues);
  const [promise, setPromise] = useState<promise<void> | null>(null);
  const [isUpdateSaved, setIsUpdateSaved] = useState(false);

  const save = useCallback(async () => {
    if (promise) await promise;

    const areValuesSame = isEqual(values, oldValues);
    if (!areValuesSame) {
      setIsUpdateSaved(false);
      setValues(oldValues);
      const saving = onSave(oldValues);
      setPromise(saving);
      await saving;
      setPromise(null);
      setIsUpdateSaved(true);
    }
  }, [onSave, oldValues]);

  useEffect(() => {
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }
    setTimeoutRef(setTimeout(save, debounceTime));
  }, [debounceTime, oldValues]);

  const haveValuesChanged = !isEqual(values, oldValues);

  return (
    <box display="flex" alignItems="center" px="{2}">
      {haveValuesChanged && (
        <>
          <iconspinner className="{css.icon}"></iconspinner>
          <typographywrapper variant="body1" weight="{TypographyWeight.Bold}">
            Saving...
          </typographywrapper>
        </>
      )}
      {!haveValuesChanged && isUpdateSaved && (
        <>
          <iconcheckcirclefilled className="{css.icon}" color="{defaultTreetStyles.green60}"></iconcheckcirclefilled>
          <typographywrapper variant="body1" weight="{TypographyWeight.Bold}" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.green80="" }="" }}="">
            Saved!
          </typographywrapper>
        </>
      )}
    </box>
  );
};

export default AutoSave;
</promise<void></any></autosaveprops></void>