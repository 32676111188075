import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Form, FormattedMessage, RangeSlider } from '../../components';

import css from './PriceFilterForm.module.css';

const DEBOUNCE_WAIT_TIME = 400;

// Helper function to parse value for min handle
// Value needs to be between slider's minimum value and current maximum value
const parseMin = (min, currentMax) => (value) => {
  const parsedValue = Number.parseInt(value, 10);
  if (Number.isNaN(parsedValue)) {
    return '';
  }
  return parsedValue < min ? min : parsedValue > currentMax ? currentMax : parsedValue;
};

// Helper function to parse value for max handle
// Value needs to be between slider's max value and current minimum value
const parseMax = (max, currentMin) => (value) => {
  const parsedValue = Number.parseInt(value, 10);
  if (Number.isNaN(parsedValue)) {
    return '';
  }
  return parsedValue < currentMin ? currentMin : parsedValue > max ? max : parsedValue;
};

// PriceFilterForm component
const PriceFilterFormComponent = (props) => {
  const { liveEdit, onChange, onSubmit, onCancel, onClear, ...rest } = props;

  if (liveEdit && !onChange) {
    throw new Error('PriceFilterForm: if liveEdit is true you need to provide onChange function');
  }

  if (!liveEdit && !(onCancel && onClear && onSubmit)) {
    throw new Error(
      'PriceFilterForm: if liveEdit is false you need to provide onCancel, onClear, and onSubmit functions'
    );
  }

  const handleChange = debounce(
    (formState) => {
      if (formState.dirty) {
        const { minPrice, maxPrice, ...restValues } = formState.values;
        onChange({
          minPrice: minPrice === '' ? rest.min : minPrice,
          maxPrice: maxPrice === '' ? rest.max : maxPrice,
          ...restValues,
        });
      }
    },
    DEBOUNCE_WAIT_TIME,
    { leading: false, trailing: true }
  );

  const handleSubmit = (values) => {
    const { minPrice, maxPrice, ...restValues } = values;
    return onSubmit({
      minPrice: minPrice === '' ? rest.min : minPrice,
      maxPrice: maxPrice === '' ? rest.max : maxPrice,
      ...restValues,
    });
  };

  const formCallbacks = liveEdit
    ? { onSubmit: () => null }
    : { onSubmit: handleSubmit, onCancel, onClear };
  return (
    <finalform {...rest}="" {...formCallbacks}="" render="{(formRenderProps)" ==""> {
        const {
          form,
          /* eslint-disable-next-line @typescript-eslint/no-shadow */
          handleSubmit,
          id,
          showAsPopup,
          /* eslint-disable-next-line @typescript-eslint/no-shadow */
          onClear,
          /* eslint-disable-next-line @typescript-eslint/no-shadow */
          onCancel,
          isOpen,
          contentRef,
          style,
          intl,
          values,
          min,
          max,
          step,
        } = formRenderProps;
        const { minPrice: minPriceRaw, maxPrice: maxPriceRaw } = values;
        const minPrice = typeof minPriceRaw !== 'string' ? minPriceRaw : min;
        const maxPrice = typeof maxPriceRaw !== 'string' ? maxPriceRaw : max;

        const handleCancel = () => {
          // reset the final form to initialValues
          form.reset();
          onCancel();
        };

        const clear = intl.formatMessage({ id: 'PriceFilterForm.clear' });
        const cancel = intl.formatMessage({ id: 'PriceFilterForm.cancel' });
        const submit = intl.formatMessage({ id: 'PriceFilterForm.submit' });

        const classes = classNames(css.root, {
          [css.popup]: showAsPopup,
          [css.isOpenAsPopup]: showAsPopup && isOpen,
          [css.plain]: !showAsPopup,
          [css.isOpen]: !showAsPopup && isOpen,
        });

        return (
          <form className="{classes}" onSubmit="{handleSubmit}" tabIndex="0" contentRef="{contentRef}" style="{{" minWidth:="" '300px',="" ...style="" }}="">
            <div className="{css.contentWrapper}">
              <span className="{css.label}">
                <formattedmessage id="PriceFilterForm.label"></formattedmessage>
              </span>
              <div className="{css.inputsWrapper}">
                <field className="{css.minPrice}" id="{`${id}.minPrice`}" name="minPrice" component="input" type="number" placeholder="{min}" min="{min}" max="{max}" step="{step}" parse="{parseMin(min," maxPrice)}=""></field>
                <span className="{css.priceSeparator}">-</span>
                <field className="{css.maxPrice}" id="{`${id}.maxPrice`}" name="maxPrice" component="input" type="number" placeholder="{max}" min="{min}" max="{max}" step="{step}" parse="{parseMax(max," minPrice)}=""></field>
              </div>
            </div>

            <div className="{css.sliderWrapper}">
              <rangeslider min="{min}" max="{max}" step="{step}" handles="{[minPrice," maxPrice]}="" onChange="{(handles)" ==""> {
                  form.change('minPrice', handles[0]);
                  form.change('maxPrice', handles[1]);
                }}
              />
            </rangeslider></div>

            {liveEdit ? (
              <formspy onChange="{handleChange}" subscription="{{" values:="" true,="" dirty:="" true="" }}=""></formspy>
            ) : (
              <div className="{css.buttonsWrapper}">
                <button className="{css.clearButton}" type="button" onClick="{onClear}">
                  {clear}
                </button>
                <button className="{css.cancelButton}" type="button" onClick="{handleCancel}">
                  {cancel}
                </button>
                <button className="{css.submitButton}" type="submit">
                  {submit}
                </button>
              </div>
            )}
          </form>
        );
      }}
    />
  );
};

PriceFilterFormComponent.defaultProps = {
  liveEdit: false,
  showAsPopup: false,
  isOpen: false,
  contentRef: null,
  style: null,
  min: 0,
  step: 1,
  onCancel: null,
  onChange: null,
  onClear: null,
  onSubmit: null,
};

PriceFilterFormComponent.propTypes = {
  id: string.isRequired,
  liveEdit: bool,
  showAsPopup: bool,
  onCancel: func,
  onChange: func,
  onClear: func,
  onSubmit: func,
  isOpen: bool,
  contentRef: func,
  style: object,
  min: number,
  max: number.isRequired,
  step: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

const PriceFilterForm = injectIntl(PriceFilterFormComponent);

export default PriceFilterForm;
</finalform>