import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { useShopCss } from '../../hooks/useShopCss';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';

interface BundleTypeMarkerProps {
  title: string;
}

const BundleTypeMarker: FC<bundletypemarkerprops> = (props) => {
  const { title } = props;
  const shopCss = useShopCss();
  const { backgroundColor } = shopCss.primaryButton;
  return (
    <box borderRadius="30px" border="{`1px" solid="" ${backgroundColor}`}="" maxWidth="max-content" maxHeight="15px" display="flex" alignItems="center" justifyContent="center" padding="6px">
      <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" fontSize:="" '9px',="" textTransform:="" 'uppercase',="" display:="" 'inline-block',="" fontWeight:="" 800,="" letterSpacing:="" '0.5px',="" },="" }}="">
        {title}
      </typographywrapper>
    </box>
  );
};

export default BundleTypeMarker;
</bundletypemarkerprops>