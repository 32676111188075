import React, { FC } from 'react';
import {
  Button,
  CalendarCell,
  CalendarGrid,
  DateInput,
  DateRange,
  DateSegment,
  Dialog,
  Group,
  Heading,
  Label,
  Popover,
  RangeCalendar,
  DateRangePicker as ReactAriaDateRangePicker,
} from 'react-aria-components';
import { Box } from '@material-ui/core';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import { IconArrowDown, IconArrowLeft, IconArrowRight } from '..';
import './DateRangePicker.css';

const BUTTON_STYLES = {
  cursor: 'pointer',
  border: 'none',
};

interface DateRangePickerProps {
  value: DateRange | undefined;
  onChange: (newValue: DateRange) => void;
  label?: string;
}

const DateRangePicker: FC<daterangepickerprops> = (props) => {
  const { label, value, onChange } = props;

  return (
    <reactariadaterangepicker value="{value}" onChange="{onChange}">
      {label && (
        <label>
          <typographywrapper variant="body2">{label}</typographywrapper>
        </label>
      )}
      <group>
        <dateinput slot="start">{(segment) => <datesegment segment="{segment}"></datesegment>}</dateinput>
        <box px="{1}">
          <span aria-hidden="true">–</span>
        </box>
        <dateinput slot="end">{(segment) => <datesegment segment="{segment}"></datesegment>}</dateinput>
        <button style="{BUTTON_STYLES}">
          <iconarrowdown></iconarrowdown>
        </button>
      </group>
      <popover>
        <dialog>
          <rangecalendar>
            <box display="flex" justifyContent="space-between" width="100%">
              <button slot="previous" style="{BUTTON_STYLES}">
                <iconarrowleft></iconarrowleft>
              </button>
              <heading></heading>
              <button slot="next" style="{BUTTON_STYLES}">
                <iconarrowright></iconarrowright>
              </button>
            </box>
            <calendargrid>{(date) => <calendarcell date="{date}"></calendarcell>}</calendargrid>
          </rangecalendar>
        </dialog>
      </popover>
    </reactariadaterangepicker>
  );
};

export default DateRangePicker;
</daterangepickerprops>