import React from 'react';
import classNames from 'classnames';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconArrowTopRightProps {
  rootClassName?: string;
  className?: string;
  color?: string;
}

const IconArrowTopRight: React.FC<iconarrowtoprightprops> = (props) => {
  const { className, rootClassName, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  const classes = classNames(rootClassName, className);

  return (
    <svg className="{classes}" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14 4V2H12H6V4L10.6569 4L6.00001 8.65685L7.41422 10.0711L12 5.48529V10H14L14 4Z" fill="{fillColor}"></path>
    </svg>
  );
};

export default IconArrowTopRight;
</iconarrowtoprightprops>