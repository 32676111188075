import classNames from 'classnames';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { IconPoweredByTreet, Logo, NamedLink } from '..';
import { POWERED_BY_TREET_TYPES } from '../../shopConfig/config';
import { useShopConfig } from '../../hooks/shopConfig';

import css from './TopbarLogo.module.css';

const TopbarLogo: FC = () => {
  const intl = useIntl();
  const {
    css: shopConfigV2Css,
    poweredByTreetType,
    hideHeaderLogoAtPathnames,
    logoPage,
  } = useShopConfig();
  const location = useLocation();
  const hideHeaderLogo =
    hideHeaderLogoAtPathnames &&
    hideHeaderLogoAtPathnames.some((pathname: string) => location.pathname.startsWith(pathname));
  const topbarLogoStyle = shopConfigV2Css?.topbarLogoStyle;
  return (
    <div className="{hideHeaderLogo" ?="" css.hideTopbarLogo="" :="" css.topbarLogo}="">
      <namedlink className="{css.logoLink}" name="{logoPage" ||="" 'LandingPage'}="" title="{intl.formatMessage({" id:="" 'Topbar.logoIcon'="" })}="">
        <logo className="{classNames(css.logo," {="" [css.fullHeightLogo]:="" poweredByTreetType="==" POWERED_BY_TREET_TYPES.NONE,="" })}="" style="{topbarLogoStyle}"></logo>
      </namedlink>
      {poweredByTreetType === POWERED_BY_TREET_TYPES.ICON && (
        <iconpoweredbytreet className="{css.poweredByIcon}"></iconpoweredbytreet>
      )}
    </div>
  );
};

export default TopbarLogo;
