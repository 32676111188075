import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconSocialMediaPinterestProps {
  className?: string;
}

const IconSocialMediaPinterest: FC<iconsocialmediapinterestprops> = (
  props: IconSocialMediaPinterestProps
) => {
  const { className } = props;
  const { iconColor } = useIconColor();

  return (
    <svg className="{className}" width="16" height="14" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 0q3.25 0 6.208 1.271t5.104 3.417 3.417 5.104T32 16q0 4.333-2.146 8.021t-5.833 5.833T16 32q-2.375 0-4.542-.625 1.208-1.958 1.625-3.458l1.125-4.375q.417.792 1.542 1.396t2.375.604q2.5 0 4.479-1.438t3.063-3.937 1.083-5.625q0-3.708-2.854-6.437t-7.271-2.729q-2.708 0-4.958.917T8.042 8.689t-2.104 3.208-.729 3.479q0 2.167.812 3.792t2.438 2.292q.292.125.5.021t.292-.396q.292-1.042.333-1.292.167-.458-.208-.875-1.083-1.208-1.083-3.125 0-3.167 2.188-5.437t5.729-2.271q3.125 0 4.875 1.708t1.75 4.458q0 2.292-.625 4.229t-1.792 3.104-2.667 1.167q-1.25 0-2.042-.917t-.5-2.167q.167-.583.438-1.5t.458-1.563.354-1.396.167-1.25q0-1.042-.542-1.708t-1.583-.667q-1.292 0-2.167 1.188t-.875 2.979q0 .667.104 1.292t.229.917l.125.292q-1.708 7.417-2.083 8.708-.333 1.583-.25 3.708-4.292-1.917-6.938-5.875T0 16Q0 9.375 4.687 4.688T15.999.001z" fillRule="evenodd" fill="{iconColor}"></path>
    </svg>
  );
};

export default IconSocialMediaPinterest;
</iconsocialmediapinterestprops>