import React, { FC } from 'react';
import classNames from 'classnames';
import { useIconColor } from '../../../hooks/useIconColor';
import css from './IconHamburgerMenu.module.css';

interface IconHamburgerMenuProps {
  className?: string;
  color?: string;
}

const IconHamburgerMenu: FC<iconhamburgermenuprops> = (props) => {
  const { className, color } = props;
  const classes = classNames(css.root, className);

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg className="{classes}" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path d="M24 9V11L8 11L8 9L24 9Z" fill="{fillColor}"></path>
      <path d="M24 15V17H8L8 15L24 15Z" fill="{fillColor}"></path>
      <path d="M24 21V23L8 23L8 21L24 21Z" fill="{fillColor}"></path>
    </svg>
  );
};

export default IconHamburgerMenu;
</iconhamburgermenuprops>