import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { bool, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../shopConfig/config';
import SortByPlain from './SortByPlain';
import SortByPopup from './SortByPopup';
import { Feature } from '../../util/featureFlags';
import { sortConfigForFrenzy } from '../../util/frenzyHelpers';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { FrenzySortOptions } from '../../types/filters/filters';

const SortBy = (props) => {
  const { sort, showAsPopup, isConflictingFilterActive, intl, ...rest } = props;
  const isFrenzySearchEnabled = useFeatureFlags(Feature.FrenzySearch);
  const { searchApiFilters } = useSelector((state) => state.SearchPage);

  let componentProps;
  if (isFrenzySearchEnabled && !isEmpty(searchApiFilters)) {
    const { options, queryParamName } = sortConfigForFrenzy;
    const defaultValue = FrenzySortOptions.BestMatch;
    componentProps = {
      urlParam: queryParamName,
      label: intl.formatMessage({ id: 'SortBy.heading' }),
      options,
      initialValue: sort || defaultValue,
      ...rest,
    };
  } else {
    const { relevanceKey, queryParamName } = config.custom.sortConfig;

    const options = config.custom.sortConfig.options.map((option) => {
      const isRelevance = option.key === relevanceKey;
      return {
        ...option,
        disabled:
          (isRelevance && !isConflictingFilterActive) ||
          (!isRelevance && isConflictingFilterActive),
      };
    });
    const defaultValue = 'pub_updatedAt';

    componentProps = {
      urlParam: queryParamName,
      label: intl.formatMessage({ id: 'SortBy.heading' }),
      options,
      initialValue: isConflictingFilterActive ? relevanceKey : sort || defaultValue,
      ...rest,
    };
  }

  return showAsPopup ? <sortbypopup {...componentProps}=""></sortbypopup> : <sortbyplain {...componentProps}=""></sortbyplain>;
};

SortBy.defaultProps = {
  sort: null,
  showAsPopup: false,
};

SortBy.propTypes = {
  sort: string,
  showAsPopup: bool,
  isConflictingFilterActive: bool.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(SortBy);
