import React, { FC } from 'react';

interface IconElizabethSuzannDirectProps {
  className?: string;
}

const IconElizabethSuzannDirect: FC<iconelizabethsuzanndirectprops> = (
  props: IconElizabethSuzannDirectProps
) => {
  const { className } = props;

  return (
    <svg className="{className}" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 400 400">
      <circle fill="#6e5e2d" cx="200" cy="200" r="200"></circle>
      <g>
        <g>
          <path fill="#fff" d="m94.73,188.62l3.58-.39c3.48-.39,4.93-.97,5.03-1.55l11.8-55.71c.1-.68-.39-.77-5.42-1.35l-2.32-.29-.1-.58h21.86c11.03,0,18.09,5.9,18.09,12.86,0,6-4.35,13.73-20.21,14.22l-.1.29c12.28,1.93,17.31,7.74,17.31,14.7,0,11.32-9.09,18.38-26.02,18.38h-23.79l.29-.58Zm26.11-.39c12.09-.39,16.83-9.38,16.83-17.89,0-7.45-5.22-14.31-20.12-14.31h-1.64l-6.67,31.24,8.8,1.06,2.8-.1Zm19.63-48.26c0-6.29-3.97-10.15-13.35-10.15h-5.61l-5.32,24.95c2.8.39,5.51.77,8.22.77,10.93,0,16.05-6.77,16.05-15.57Z"></path>
          <path fill="#fff" d="m149.86,189.01l6-15.96,6.38-22.63-4.35-.39-6.96,11.03-.58-.29c4.93-9.96,8.99-14.02,12.67-14.02,1.84,0,2.9,3.39,2.9,6.38,0,2.71-1.74,8.99-5.32,18.57h.29c6.77-12.86,10.83-19.73,13.83-22.73,1.45-1.45,2.71-2.22,5.03-2.22,3.09,0,5.13,2.51,5.13,5.03,0,2.32-1.93,3.87-3.77,3.87-2.32,0-4.84-3-1.45-5.9l-2.51-1.16c-2.32,1.16-4.45,3.19-16.64,25.82l-5.71,15.09-4.93-.48Z"></path>
          <path fill="#fff" d="m199.56,184.07c0-2.42.87-4.55,3.97-12.48h-.19c-7.35,12.57-11.8,18.67-16.64,18.67-4.16,0-7.64-3.38-7.64-8.61,0-10.54,9.38-23.69,15.86-30.17,2.51-2.51,5.32-4.06,8.03-4.06,3.09,0,5.61,2.22,5.51,8.7h.1l2.8-9.28,4.84.39-7.25,20.99-5.8,18.47,4.35.48,7.06-11.03.58.29c-4.93,9.96-8.99,14.02-12.67,14.02-1.84,0-2.9-3.38-2.9-6.38Zm5.32-17.6c1.26-2.51,2.32-6.38,2.32-8.12,0-3.19-2.42-8.22-6.96-8.22-1.84,0-3.48.87-5.22,2.61-5.13,5.13-10.74,18.28-10.74,26.79,0,4.84,2.71,6.77,5.03,6.77,4.35,0,10.15-8.9,15.57-19.83Z"></path>
          <path fill="#fff" d="m241.15,186.68c0-1.06,4.16-10.64,7.25-18.67,2.03-5.32,5.03-13.15,5.03-14.6,0-1.93-1.74-3.39-3.87-3.39-2.9,0-12.67,11.99-21.28,30.27l-3.48,9.19-4.84-.48,5.51-15.96,6.87-22.63-4.35-.39-6.96,11.03-.58-.29c4.93-9.96,8.99-14.02,12.67-14.02,1.84,0,2.9,3.39,2.9,6.38,0,2.71-3,12.19-7.06,21.95l.58.19c5.51-10.64,13.44-21.76,16.83-25.15,2.13-2.22,4.26-3.19,6.48-3.19s5.51,3.09,5.51,6.29c0,2.51-2.42,8.12-5.13,14.8-2.32,5.71-7.45,18.57-7.45,18.57l4.64.58,7.25-11.12.58.29c-5.03,10.06-9.09,14.02-11.61,14.02-1.93,0-5.51-2.42-5.51-3.68Z"></path>
          <path fill="#fff" d="m282.63,184.07c0-2.71.87-4.55,3.97-12.48h-.19c-7.35,12.57-11.8,18.67-16.64,18.67-4.16,0-7.64-3.38-7.64-8.61,0-10.54,9.38-24.08,15.86-30.56,2.51-2.51,5.32-4.06,8.03-4.06,3.1,0,5.22,2.32,5.13,9.57h.19l7.64-26.79c.29-.97.29-2.22-.39-2.22l-9.77-.29.19-.58,16.54-1.64-10.45,32.98-8.9,28.63,4.35.48,7.06-10.93.58.29c-4.93,9.86-9,13.93-12.67,13.93-1.84,0-2.9-3.38-2.9-6.38Zm4.25-15.47c2.32-4.64,3.39-8.51,3.39-10.25,0-3.09-2.42-8.61-6.96-8.61-1.84,0-3.48.87-5.22,2.61-5.13,5.13-10.74,18.67-10.74,27.18,0,4.84,2.71,6.77,5.03,6.77,4.35,0,10.16-8.8,14.51-17.7Z"></path>
        </g>
        <g>
          <path fill="#fff" d="m95.7,273.18l3.58-.39c3.48-.39,4.93-.97,5.03-1.55l11.8-55.71c.1-.68-.39-.77-5.42-1.35l-2.32-.29-.1-.58h23.41c16.92,0,26.79,11.61,26.79,26.11,0,20.21-14.31,34.33-40.04,34.33h-23.02l.29-.58Zm56.29-36.17c0-13.64-6-22.82-21.47-22.82h-8.03l-12.28,57.84,10.83.77c20.7,0,30.95-16.83,30.95-35.78Z"></path>
          <path fill="#fff" d="m161.66,271.25c0-1.06,14.02-36.17,14.02-36.17l-4.83-.39-6.96,10.93-.58-.29c4.93-9.86,9-13.93,12.67-13.93,1.84,0,2.9,3.38,2.9,6.38,0,2.71-12.96,33.37-12.96,33.37l4.84.58,7.25-11.03.58.29c-5.03,9.96-9.09,13.93-11.61,13.93-1.93,0-5.32-2.42-5.32-3.68Zm17.02-52.81c0-2.42,1.93-4.93,3.68-4.93,1.93,0,3.19,1.35,3.19,3.19,0,2.52-1.93,5.03-3.77,5.03-1.93,0-3.1-1.35-3.1-3.29Z"></path>
          <path fill="#fff" d="m183.9,273.57l6-15.96,6.38-22.63-4.35-.39-6.96,11.03-.58-.29c4.93-9.96,9-14.02,12.67-14.02,1.84,0,2.9,3.39,2.9,6.38,0,2.71-1.74,8.99-5.32,18.57h.29c6.77-12.86,10.83-19.73,13.83-22.73,1.45-1.45,2.71-2.22,5.03-2.22,3.09,0,5.13,2.51,5.13,5.03,0,2.32-1.93,3.87-3.77,3.87-2.32,0-4.84-3-1.45-5.9l-2.51-1.16c-2.32,1.16-4.45,3.19-16.63,25.82l-5.71,15.09-4.93-.48Z"></path>
          <path fill="#fff" d="m216.49,261.58c0-14.6,11.51-30.08,22.15-30.08,4.84,0,8.71,2.51,8.71,6.09,0,6.38-8.12,10.06-24.18,12.57-.97,3.19-1.55,6.67-1.55,9.67,0,6.87,2.9,11.61,7.83,11.61s8.41-3,12.86-10.54l.68.29c-4.16,7.25-9.09,13.73-15.67,13.73-7.06,0-10.83-6.29-10.83-13.35Zm25.82-24.28c0-2.71-1.74-4.93-4.74-4.93-1.84,0-4.25,1.35-6.58,3.58-2.9,2.9-5.71,8.03-7.45,13.15l.19.1c14.6-2.32,18.57-5.42,18.57-11.9Z"></path>
          <path fill="#fff" d="m248.59,261.58c0-14.31,12.28-30.08,22.83-30.08,4.74,0,8.51,2.9,8.51,6.58,0,2.42-1.55,4.06-3.38,4.06-1.55,0-3.29-1.26-3.29-3.19,0-2.13,1.55-3.19,3.38-3.87-1.06-1.35-2.9-2.71-5.22-2.71-3.58,0-6.09,1.64-8.32,3.87-4.93,5.03-9.38,15.28-9.38,23.6,0,6.87,2.9,11.61,7.83,11.61s8.41-3,12.86-10.54l.68.29c-4.16,7.25-9.09,13.73-15.67,13.73-7.06,0-10.83-6.29-10.83-13.35Z"></path>
          <path fill="#fff" d="m279.92,270.96l10.64-35.3-6.87-.1.87-2.51,6.87-.1,4.06-13.15h4.74l-4.06,13.15,8.41.1-.77,2.61h-8.51l-10.73,35.49,5.13.58,6.96-11.03.58.29c-4.93,9.96-8.8,13.93-11.41,13.93-1.93,0-6.38-2.52-5.9-3.97Z"></path>
        </g>
      </g>
    </svg>
  );
};

export default IconElizabethSuzannDirect;
</iconelizabethsuzanndirectprops>