import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { ShippingLabelProvider } from '@prisma/client';
import {
  Button,
  ConditionalWrapper,
  ExternalLink,
  IconCashFilled,
  IconCheckCircleFilled,
  IconShippingLabelFilled,
  InlineTextButton,
  NamedLink,
} from '..';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import { getOwnListingsById } from '../../containers/ManageSalesPage/ManageSalesPage.duck';
import { useShopConfig, useShopConfigV2 } from '../../hooks/shopConfig';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import {
  BundleStatus,
  BundleType,
  ItemStatus,
  Participant,
} from '../../types/apollo/generated/types.generated';
import { BundleInfo } from '../../types/models/bundle';
import { CurrentUser } from '../../types/sharetribe/currentUser';
import { OwnListing } from '../../types/sharetribe/listing';
import { FulfillmentMethod } from '../../types/shopConfig/shopConfigV2';
import css from './BundleActions.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

interface SellerActionsProps {
  bundle: BundleInfo;
  onGetPaidClick?: (bundle: BundleInfo) => void;
  onMarkAsFulfilledClick?: (bundle: BundleInfo) => void;
}

const SellerActions: FC<selleractionsprops> = (props) => {
  const { bundle, onGetPaidClick, onMarkAsFulfilledClick } = props;
  const { fulfillment, id, isMarkedFulfilled, bundleItems } = bundle;

  const currentUser = useSelector<any>((state) => state.user.currentUser) as
    | CurrentUser
    | undefined;
  const { isBrand } = useCurrentUserPermissions();

  const firstListingId = bundleItems?.[0]?.listing.sharetribeListingId;
  const ownListings = useSelector<any>((state) =>
    getOwnListingsById(state, [new UUID(firstListingId)])
  ) as OwnListing[];

  // If the listing author has a defined fulfillmentMethod value, use that to control
  // the fulfillment settings. Otherwise, we will use the computed hasFulfillmentOffTreet flag
  // for brand direct items by default.
  const userFulfillmentMethod = currentUser?.attributes?.profile?.publicData?.fulfillmentMethod;
  const { defaultBrandFulfillmentMethod, css: shopCss } = useShopConfigV2();
  const fulfillmentMethod =
    userFulfillmentMethod || (isBrand ? defaultBrandFulfillmentMethod : FulfillmentMethod.Treet);

  // Ignore actions if a brand isn't fulfilling through Treet's dash
  if (fulfillmentMethod === FulfillmentMethod.Shopify) return null;

  const handlePrintShippingLabelClick = () => {
    const labelUrl = fulfillment?.labelURL || '';
    window.open(labelUrl, '_blank');
  };

  const hasShippingLabel = !!fulfillment?.labelURL;
  const canRecreateLabel =
    !!fulfillment && fulfillment.shippingLabelProvider !== ShippingLabelProvider.SUPER_CIRCLE;

  const handleClickWithPreventDefault =
    (onClickHandler: (bundle: BundleInfo) => void) => (e: React.MouseEvent<htmlbuttonelement>) => {
      e.preventDefault();
      onClickHandler(bundle);
    };

  if (bundle.status === BundleStatus.Open && !isMarkedFulfilled) {
    return (
      <>
        {(fulfillment || isBrand) && onMarkAsFulfilledClick && (
          <button className="{css.actionButton}" onClick="{handleClickWithPreventDefault(onMarkAsFulfilledClick)}">
            <iconcheckcirclefilled className="{css.actionIcon}" color="{shopCss?.primaryButton?.font?.color}"></iconcheckcirclefilled>
            {isBrand ? 'Mark as Fulfilled' : 'Mark as Shipped'}
          </button>
        )}
        <conditionalwrapper condition="{!hasShippingLabel}" wrapper="{(children)" ==""> (
            <namedlink name="GenerateShippingLabelPage" params="{{" id="" }}="" style="{{" textDecoration:="" 'none'="">
              {children}
            </namedlink>
          )}
        >
          <button className="{css.actionButton}" onClick="{!hasShippingLabel" ?="" undefined="" :="" handlePrintShippingLabelClick}="">
            <iconshippinglabelfilled className="{css.actionIcon}" color="{shopCss?.primaryButton?.font?.color}"></iconshippinglabelfilled>
            Print Shipping Label
          </button>
        </conditionalwrapper>
        {hasShippingLabel && canRecreateLabel && (
          <box display="flex" justifyContent="center" pt="{3}" pb="{1}">
            <namedlink name="GenerateShippingLabelPage" params="{{" id="" }}="" className="{css.link}">
              <typography variant="body1">Recreate Shipping Label</typography>
            </namedlink>
          </box>
        )}
      </>
    );
  }

  const hasVerifiedBundleItems = bundle.bundleItems.some(
    (bundleItem) => bundleItem.status === ItemStatus.Verified
  );
  const payoutOption = ownListings?.[0]?.attributes?.privateData?.payoutOption;

  if (
    bundle.status === BundleStatus.Resolved &&
    hasVerifiedBundleItems &&
    !payoutOption &&
    onGetPaidClick
  ) {
    return (
      <button className="{css.actionButton}" onClick="{handleClickWithPreventDefault(onGetPaidClick)}">
        <iconcashfilled className="{css.actionIcon}" color="inherit"></iconcashfilled>
        Get Paid!
      </button>
    );
  }

  return null;
};

interface BuyerActionsProps {
  bundle: BundleInfo;
  onDisputeClick?: (bundle: BundleInfo) => void;
  onVerifyClick?: (bundle: BundleInfo) => void;
  onMarkAsDeliveredClick?: (bundle: BundleInfo) => void;
}

const BuyerActions: FC<buyeractionsprops> = (props) => {
  const { bundle, onDisputeClick, onVerifyClick, onMarkAsDeliveredClick } = props;
  const { tryOnReturnLink } = useShopConfig();
  const { isTradeInAdmin } = useCurrentUserPermissions();

  const isTradeInBundle = bundle?.type === BundleType.TradeIn;
  const isDelivered = bundle.status === BundleStatus.Delivered;
  const isOpenOrShipped = [BundleStatus.Open, BundleStatus.Shipped].includes(bundle.status);
  const isTradeInAdminAndBundle = isTradeInAdmin && isTradeInBundle;
  const { isBrandDirect } = bundle;
  const shouldDisplayTryOnReturnLink = isBrandDirect && tryOnReturnLink;

  return (
    <box pt="4px">
      {isOpenOrShipped && isTradeInAdmin && isTradeInBundle && onMarkAsDeliveredClick && (
        <button className="{css.actionButton}" onClick="{()" ==""> onMarkAsDeliveredClick(bundle)}>
          <iconcheckcirclefilled className="{css.actionIcon}" color="inherit"></iconcheckcirclefilled>
          Mark as Delivered
        </button>
      )}
      {isDelivered && !isTradeInBundle && onVerifyClick && (
        <button className="{css.actionButton}" onClick="{()" ==""> onVerifyClick(bundle)}>
          <iconcheckcirclefilled className="{css.actionIcon}" color="inherit"></iconcheckcirclefilled>
          Verify Purchase
        </button>
      )}
      {isDelivered && onDisputeClick && isTradeInAdminAndBundle && (
        <button className="{css.actionButton}" onClick="{()" ==""> onDisputeClick(bundle)}>
          Issue Credit
        </button>
      )}

      {isDelivered && onDisputeClick && !isTradeInAdminAndBundle && (
        <box display="flex" justifyContent="center" mt="{2}" mb="{1}">
          {!shouldDisplayTryOnReturnLink && (
            <inlinetextbutton className="{css.link}" onClick="{()" ==""> onDisputeClick(bundle)}>
              File A Misrepresentation Claim
            </inlinetextbutton>
          )}
          {shouldDisplayTryOnReturnLink && (
            <externallink href="{tryOnReturnLink}" className="{css.link}">
              <typographywrapper variant="body1">Start Your Try On Return</typographywrapper>
            </externallink>
          )}
        </box>
      )}
    </box>
  );
};

interface BundleActionsProps {
  bundle: BundleInfo;
  participant: Participant;
  onVerifyClick?: (bundle: BundleInfo) => void;
  onDisputeClick?: (bundle: BundleInfo) => void;
  onGetPaidClick?: (bundle: BundleInfo) => void;
  onMarkAsFulfilledClick?: (bundle: BundleInfo) => void;
  onMarkAsDeliveredClick?: (bundle: BundleInfo) => void;
}

// TODO(SY | TREET-718): Refactor this to be used on the ManageSalesMobile page
const BundleActions: FC<bundleactionsprops> = (props) => {
  const {
    bundle,
    participant,
    onVerifyClick,
    onDisputeClick,
    onGetPaidClick,
    onMarkAsFulfilledClick,
    onMarkAsDeliveredClick,
  } = props;
  const isBuyer = participant === Participant.Buyer;
  const isSeller = participant === Participant.Seller;

  if (isSeller) {
    return (
      <selleractions bundle="{bundle}" onGetPaidClick="{onGetPaidClick}" onMarkAsFulfilledClick="{onMarkAsFulfilledClick}"></selleractions>
    );
  }
  if (isBuyer) {
    return (
      <buyeractions bundle="{bundle}" onDisputeClick="{onDisputeClick}" onVerifyClick="{onVerifyClick}" onMarkAsDeliveredClick="{onMarkAsDeliveredClick}"></buyeractions>
    );
  }

  return null;
};

export default BundleActions;
</bundleactionsprops></buyeractionsprops></htmlbuttonelement></any></any></selleractionsprops>