import React from 'react';
import classNames from 'classnames';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconArrowDownProps {
  rootClassName?: string;
  className?: string;
  color?: string;
}

const IconArrowDown: React.FC<iconarrowdownprops> = (props) => {
  const { className, rootClassName, color } = props;
  const classes = classNames(rootClassName, className);

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg className="{classes}" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M7.94975 11.364L3 6.41421L4.41421 5L7.94975 8.53553L11.4853 5L12.8995 6.41421L7.94975 11.364Z" fill="{fillColor}"></path>
    </svg>
  );
};

export default IconArrowDown;
</iconarrowdownprops>