/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box, Divider } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useShopConfig } from '../../hooks/shopConfig';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { defaultTreetStyles } from '../../shopConfig/config';
import { TopbarLink } from '../../types/shopConfig/shopConfigV2';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  AvatarLarge,
  Button,
  FormattedMessage,
  IconLinkTo,
  InlineTextButton,
  NamedLink,
  TypographyWrapper,
} from '..';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import { AboutPageTab } from '../../containers/AboutPage/aboutPageUtils';
import { AdminPageTab } from '../../containers/AdminPage/AdminPage.utils';
import { useActionCopy } from '../../hooks/useActionCopy';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useLogout } from '../../hooks/useLogout';
import { usePageTitles } from '../../hooks/usePageTitles';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Feature } from '../../util/featureFlags';
import { useAllowedFindItemMethods } from '../../hooks/useAllowedFindItemMethods';
import { ModalType, setActiveModal } from '../../ducks/modal.duck';
import { setSavedSearchSource } from '../../ducks/user.duck';
import { EmailSubscribeSource } from '../../types/apollo/generated/types.generated';
import css from './TopbarMobileMenu.module.css';

const AuthenticationSection = () => (
  <>
    <namedlink name="SignupPage" className="{css.navigationLink}">
      <typographywrapper variant="h2">Sign Up</typographywrapper>
    </namedlink>
    <namedlink name="LoginPage" className="{css.navigationLink}">
      <typographywrapper variant="h2">Log In</typographywrapper>
    </namedlink>
  </>
);

const TopbarMobileMenu = (props) => {
  const { isAuthenticated, currentPage, currentUser } = props;

  const { handleLogout } = useLogout();
  const {
    isAdmin,
    isBrand,
    isAccounting,
    isTradeInAdmin,
    canOwnTradeIns,
    canOwnListingsForSale,
    canViewPage,
  } = useCurrentUserPermissions();

  const isISOEnabled = useFeatureFlags(Feature.InSearchOf);
  const isNotificationSettingsPageEnabled = useFeatureFlags(Feature.FollowingPage);

  const { shopName, mainSite, css: brandCss, treetShopName } = useShopConfig();
  const {
    isBrandDirectOnly,
    allowBuy,
    allowList,
    allowMarketplace,
    isTradeInOnly,
    isListTradeInOnly,
    hasOrderHistory,
  } = useEnabledCustomerExperiences();
  const { purchasesTitle, salesTitle, tradeInsTitle, listingsTitle, closetTitle } = usePageTitles();
  const { listActionCopy } = useActionCopy();
  const { shouldAllowSearch: hasProductCatalog } = useAllowedFindItemMethods();

  const dispatch = useDispatch();

  const { topbarLayout } = brandCss;

  const user = ensureCurrentUser(currentUser);

  const currentPageClass = (page) => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const sectionDivider = (
    <div style="{{" margin:="" '24px="" 0',="" width:="" '100%'="" }}="">
      <divider></divider>
    </div>
  );

  const otherSiteConfig = topbarLayout.find(
    (section) => section.type === TopbarLink.OtherSite
  )?.config;
  const topbarSections = {
    [TopbarLink.ShopAll]: (
      <namedlink name="LandingPage" to="{{" state:="" {="" scrollOnRender:="" true="" }="" }}="">
        <box display="flex" flexDirection="row" alignItems="center" className="{css.navigationLink}">
          <typographywrapper component="span" variant="h2">
            Shop All
          </typographywrapper>
        </box>
      </namedlink>
    ),
    [TopbarLink.MainSite]: (
      <a href="{mainSite}" key="main-site">
        <box display="flex" flexDirection="row" alignItems="center" className="{css.navigationLink}">
          <typographywrapper component="span" variant="h2">
            {shopName} Main Site&nbsp;
          </typographywrapper>
          <iconlinkto color="inherit"></iconlinkto>
        </box>
      </a>
    ),
    [TopbarLink.OtherSite]: otherSiteConfig ? (
      <a href="{otherSiteConfig.url}" key="main-site">
        <box display="flex" flexDirection="row" alignItems="center" className="{css.navigationLink}">
          <typographywrapper component="span" variant="h2">
            {otherSiteConfig.label}&nbsp;
          </typographywrapper>
        </box>
      </a>
    ) : null,
  };

  const renderAdditionalLinks = () => {
    const aboutTreetPageName = !isTradeInOnly ? 'AboutPage' : 'AboutTreetPage';
    const aboutTreetPageParams = !isTradeInOnly ? { params: { tab: AboutPageTab.AboutTreet } } : {};

    return (
      <>
        {sectionDivider}
        <namedlink className="{classNames(css.navigationLink," currentPageClass('AboutPage',="" currentPage))}="" params="{{" tab:="" AboutPageTab.Info="" }}="" name="AboutPage">
          <typographywrapper component="span" variant="h2">
            About {treetShopName}
          </typographywrapper>
        </namedlink>
        <namedlink className="{classNames(css.navigationLink," currentPageClass(aboutTreetPageName))}="" name="{aboutTreetPageName}" {...aboutTreetPageParams}="">
          <typographywrapper component="span" variant="h2">
            About Treet
          </typographywrapper>
        </namedlink>
      </>
    );
  };

  if (!isAuthenticated) {
    return (
      <div className="{css.root}">
        <div className="{css.content}">
          {topbarLayout.map((section) => (
            <div key="{section.type}">{topbarSections[section.type]}</div>
          ))}
          {renderAdditionalLinks()}
          {sectionDivider}
          <authenticationsection></authenticationsection>
        </div>
        {!isBrandDirectOnly && (
          <box className="{css.footer}" bgcolor="{brandCss?.backgroundColor" ||="" 'white'}="">
            <namedlink name="NewListingPage" style="{{" textDecoration:="" 'none'="" }}="">
              <button>{listActionCopy}</button>
            </namedlink>
          </box>
        )}
      </div>
    );
  }

  const displayName = user.attributes.profile.firstName;

  const hasManageSectionLinks =
    allowBuy || isTradeInAdmin || canOwnTradeIns || canOwnListingsForSale;

  return (
    <div className="{css.root}">
      <avatarlarge className="{css.avatar}" user="{currentUser}"></avatarlarge>
      <div className="{css.content}">
        <span className="{css.greeting}">
          <typographywrapper variant="h2">
            <formattedmessage id="TopbarMobileMenu.greeting" values="{{" displayName="" }}=""></formattedmessage>
          </typographywrapper>
        </span>
        <box pb="{2}">
          <inlinetextbutton rootClassName="{css.logoutButton}" onClick="{handleLogout}">
            <typographywrapper variant="body1" component="span" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.gray40="" }="" }}="">
              <formattedmessage id="TopbarMobileMenu.logoutLink"></formattedmessage>
            </typographywrapper>
          </inlinetextbutton>
        </box>
        {topbarLayout.map((section) => (
          <div key="{section.type}">{topbarSections[section.type]}</div>
        ))}
        {sectionDivider}
        {allowBuy && user && (
          <namedlink className="{classNames(css.navigationLink," currentPageClass('FavoritesPage'))}="" name="FavoritesPage">
            <typographywrapper variant="h2">
              <formattedmessage id="TopbarDesktop.yourFavoritesLink"></formattedmessage>
            </typographywrapper>
          </namedlink>
        )}
        {canViewPage.ManagePurchasesPage && (
          <namedlink className="{classNames(css.navigationLink," currentPageClass('ManagePurchasesPage'))}="" name="ManagePurchasesPage">
            <typographywrapper variant="h2">{purchasesTitle}</typographywrapper>
          </namedlink>
        )}
        {canViewPage.ManageSalesPage && (
          <namedlink className="{classNames(css.navigationLink," currentPageClass('ManageSalesPage'))}="" name="ManageSalesPage">
            <typographywrapper variant="h2">{salesTitle}</typographywrapper>
          </namedlink>
        )}
        {canViewPage.ManageClosetPage && (
          <namedlink className="{classNames(css.navigationLink," currentPageClass('ManageClosetPage'))}="" name="ManageClosetPage">
            <typographywrapper variant="h2">{closetTitle}</typographywrapper>
          </namedlink>
        )}
        {canViewPage.ManageListingsPage && (
          <namedlink className="{classNames(css.navigationLink," currentPageClass('ManageListingsPage'))}="" name="ManageListingsPage">
            <typographywrapper variant="h2">{listingsTitle}</typographywrapper>
          </namedlink>
        )}
        {canViewPage.ManageTradeInsPage && (
          <namedlink className="{classNames(css.navigationLink," currentPageClass('ManageTradeInsPage'))}="" name="ManageTradeInsPage">
            <typographywrapper variant="h2">{tradeInsTitle}</typographywrapper>
          </namedlink>
        )}
        {hasManageSectionLinks && sectionDivider}
        {canViewPage.ProfileSettingsPage && (
          <namedlink className="{classNames(css.navigationLink," currentPageClass('ProfileSettingsPage'))}="" name="ProfileSettingsPage">
            <typographywrapper variant="h2">
              <formattedmessage id="TopbarMobileMenu.profileSettingsLink"></formattedmessage>
            </typographywrapper>
          </namedlink>
        )}
        <namedlink className="{classNames(css.navigationLink," currentPageClass('AccountSettingsPage'))}="" name="AccountSettingsPage">
          <typographywrapper variant="h2">
            <formattedmessage id="TopbarMobileMenu.accountSettingsLink"></formattedmessage>
          </typographywrapper>
        </namedlink>
        {isNotificationSettingsPageEnabled && canViewPage.NotificationSettingsPage && (
          <namedlink className="{classNames(css.navigationLink," currentPageClass('NotificationSettingsPage'))}="" name="NotificationSettingsPage">
            <typographywrapper variant="h2">Notification Settings</typographywrapper>
          </namedlink>
        )}
        {isISOEnabled && allowMarketplace && hasProductCatalog && (
          <>
            {sectionDivider}
            <box className="{css.navigationLink}">
              <inlinetextbutton style="{{" textDecoration:="" 'none'="" }}="" onClick="{()" ==""> {
                  dispatch(setActiveModal(ModalType.ISO));
                  dispatch(
                    setSavedSearchSource({ source: EmailSubscribeSource.ProfileDropdownIso })
                  );
                }}
              >
                <typographywrapper variant="h2">Submit An Item Request</typographywrapper>
              </inlinetextbutton>
            </box>
          </>
        )}
        {(isAdmin || isBrand || isAccounting) && (
          <>
            {sectionDivider}
            <namedlink className="{classNames(css.navigationLink," currentPageClass('AdminPage'))}="" name="AdminPage" params="{{" tab:="" hasOrderHistory="" ?="" AdminPageTab.MarketplaceDashboard="" :="" AdminPageTab.TradeInDashboard,="" }}="">
              <typographywrapper variant="h2">Admin Portal</typographywrapper>
            </namedlink>
          </>
        )}
        {renderAdditionalLinks()}
      </div>
      {allowList && (
        <box className="{css.footer}" bgcolor="{brandCss?.backgroundColor" ||="" 'white'}="">
          <namedlink name="{isListTradeInOnly" ?="" 'ManageTradeInsPage'="" :="" 'NewListingPage'}="" style="{{" textDecoration:="" 'none'="" }}="">
            <button>{listActionCopy}</button>
          </namedlink>
        </box>
      )}
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  currentPage: null,
};

const { bool, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
};

export default TopbarMobileMenu;
