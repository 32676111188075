import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconBillingProps {
  className?: string;
  color?: string;
}

const IconBilling: FC<iconbillingprops> = (props: IconBillingProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg className="{className}" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M26 6H6V26H26V6ZM4 4V28H28V4H4Z" fill="{fillColor}"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M22.009 10.025C21.903 9.81477 21.7752 9.64751 21.6257 9.52326C21.4762 9.399 21.3074 9.33688 21.1193 9.33688C20.9216 9.33688 20.777 9.37989 20.6854 9.46591C20.5986 9.55193 20.5552 9.65946 20.5552 9.78849C20.5552 9.92708 20.6082 10.0418 20.7143 10.1326C20.8252 10.2186 20.9675 10.2927 21.141 10.3548C21.3195 10.4169 21.5099 10.4862 21.7125 10.5627C21.9439 10.6439 22.1561 10.7515 22.349 10.8853C22.5467 11.0191 22.7034 11.1863 22.8192 11.3871C22.9397 11.5878 23 11.8339 23 12.1254C23 12.5316 22.8674 12.859 22.6022 13.1075C22.3418 13.356 21.9825 13.5113 21.5244 13.5734L21.5533 14.3333H20.6564L20.6854 13.5663C20.4298 13.5233 20.1911 13.442 19.9693 13.3225C19.7474 13.2031 19.5521 13.0573 19.3834 12.8853C19.2194 12.7132 19.0916 12.5292 19 12.3333L19.868 11.7311C20.0416 12.0322 20.2224 12.2592 20.4105 12.4121C20.5986 12.5603 20.8252 12.6344 21.0904 12.6344C21.5051 12.6344 21.7125 12.4695 21.7125 12.1397C21.7125 12.0059 21.6667 11.896 21.575 11.81C21.4834 11.724 21.358 11.6475 21.1989 11.5806C21.0398 11.5137 20.8614 11.442 20.6637 11.3656C20.5383 11.3178 20.396 11.2556 20.2369 11.1792C20.0826 11.0979 19.9331 10.9976 19.7884 10.8781C19.6486 10.7538 19.5304 10.6033 19.434 10.4265C19.3424 10.2497 19.2966 10.0394 19.2966 9.79566C19.2966 9.58538 19.3544 9.38945 19.4702 9.20784C19.5859 9.02146 19.7474 8.86376 19.9548 8.73473C20.1621 8.60569 20.4057 8.51728 20.6854 8.46949L20.6564 7.66663H21.5533L21.5244 8.47666C21.9005 8.53879 22.1947 8.66782 22.4069 8.86376C22.6239 9.0597 22.7975 9.27236 22.9277 9.50175L22.009 10.025ZM17 8H8V10H17V8ZM8 12H17V14H8V12ZM8 16H24V18H8V16ZM24 20H8V24H24V20Z" fill="{fillColor}"></path>
    </svg>
  );
};

export default IconBilling;
</iconbillingprops>