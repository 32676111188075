/* eslint-disable import/prefer-default-export */
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import MobileDetect from 'mobile-detect';

// TODO (SY|TREET-608): Refactor code to use this hook
export function useIsMobile() {
  // If no window information, use userAgent
  if (typeof window === 'undefined') {
    const userAgent = useSelector<any>((state) => state.initial.userAgent) as string;
    const md = new MobileDetect(userAgent);
    if (md.mobile()) {
      return true;
    }
  }

  // Otherwise, use screen width
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
}
</any>