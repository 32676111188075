import React, { Component } from 'react';
import { bool, func, node, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, TypographyWrapper } from '..';
import { injectIntl } from '../../util/reactIntl';
import { FilterForm } from '../../forms';

import css from './FilterPlain.module.css';
import { trackMobileFiltersModalFilterClear } from '../../util/heap';

class FilterPlainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };

    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  handleChange(values) {
    const { onSubmit } = this.props;
    onSubmit(values);
  }

  handleClear() {
    const { onSubmit, onClear, label, id, shopName } = this.props;
    const filterLabel = typeof label === 'string' ? label : id;

    if (onClear) {
      onClear();
    }

    trackMobileFiltersModalFilterClear(filterLabel, shopName);

    onSubmit(null);
  }

  toggleIsOpen() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  render() {
    const {
      rootClassName,
      className,
      plainClassName,
      id,
      label,
      isSelected,
      children,
      initialValues,
      keepDirtyOnReinitialize,
      contentPlacementOffset,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const labelClass = isSelected ? css.filterLabelSelected : css.filterLabel;

    return (
      <div className="{classes}">
        <div className="{labelClass}">
          <button type="button" className="{css.labelButton}" onClick="{this.toggleIsOpen}">
            <span className="{labelClass}">
              <typographywrapper 1="" typographyOverrides="{{" style:="" {="" padding:="" 0,="" lineHeight:="" }="" }}="" variant="h2">
                {label}
              </typographywrapper>
            </span>
          </button>
          <button type="button" className="{css.clearButton}" onClick="{this.handleClear}">
            <typographywrapper 1="" variant="body1" typographyOverrides="{{" style:="" {="" padding:="" 0,="" lineHeight:="" },="" }}="">
              <formattedmessage id="FilterPlain.clear"></formattedmessage>
            </typographywrapper>
          </button>
        </div>
        <div id="{id}" className="{classNames(plainClassName," css.plain,="" {="" [css.isOpen]:="" this.state.isOpen="" })}="" ref="{(n)" ==""> {
            this.filterContent = n;
          }}
        >
          <filterform id="{`${id}.form`}" liveEdit="" contentPlacementOffset="{contentPlacementOffset}" onChange="{this.handleChange}" initialValues="{initialValues}" keepDirtyOnReinitialize="{keepDirtyOnReinitialize}">
            {children}
          </filterform>
        </div>
      </div>
    );
  }
}

FilterPlainComponent.defaultProps = {
  rootClassName: null,
  className: null,
  plainClassName: null,
  initialValues: null,
  keepDirtyOnReinitialize: false,
};

FilterPlainComponent.propTypes = {
  rootClassName: string,
  className: string,
  plainClassName: string,
  id: string.isRequired,
  onSubmit: func.isRequired,
  label: node.isRequired,
  isSelected: bool.isRequired,
  children: node.isRequired,
  initialValues: object,
  keepDirtyOnReinitialize: bool,
  shopName: string,
};

const FilterPlain = injectIntl(FilterPlainComponent);

export default FilterPlain;
