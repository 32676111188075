import { Box, Grid } from '@material-ui/core';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useForm, useFormState } from 'react-final-form';
import isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';
import { Error, IconSpinner, TypographyWrapper } from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import { ProductEdge, Product as ShopifyProduct } from '../../types/shopify/product';
import { FindItemMethod } from '../../util/listings/listing';
import {
  buildAdditionalProductQueryFromTagsAndProductTypes,
  filterDefaultShopifyOptions,
} from '../../util/shopifyHelpers';
import AppContext from '../../context/AppContext';
import Product from './Product';
import { canProductBeShown, FIND_ITEM_METHOD, getProductVariantQuery } from './Shopify.utils';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { parse } from '../../util/urlHelpers';
import { fetchProductById, fetchProductsByTag, fetchShopifyVariantByQuery } from '../../util/api';
import { useLazyApi } from '../../hooks/useLazyApi';

const Loading: FC = () => (
  <box display="flex" justifyContent="center" pt="{2}">
    <iconspinner></iconspinner>
  </box>
);

interface SelectedItemProps {
  shopifyProduct: ShopifyProduct;
}

const SelectedItem: FC<selecteditemprops> = (props) => {
  const { shopifyProduct } = props;
  return (
    <>
      <typographywrapper variant="h2">Selected Item</typographywrapper>
      <box py="{2}">
        <product product="{shopifyProduct}" selectedProductId="{shopifyProduct.id}"></product>
      </box>
    </>
  );
};

const ShopifyProducts: FC = () => {
  const shopConfig = useShopConfig();
  const { additionalProductQuery, blockedProductTypes, blockedTags, allowedTags, shopId } =
    shopConfig;

  const { treetId } = useContext(AppContext);
  const form = useForm();
  const { values } = useFormState();
  const { allowTradeIn } = useEnabledCustomerExperiences();
  const { countryCode } = useBrandCountryConfig();
  const location = useLocation();
  const { productId } = parse(location.search);

  const { shopifyProduct } = values;
  const fullAdditionalProductQuery = buildAdditionalProductQueryFromTagsAndProductTypes(
    additionalProductQuery,
    blockedTags,
    blockedProductTypes,
    allowedTags
  );

  const { lazyQuery: lazyFetchProductById, data: productById } = useLazyApi(fetchProductById);
  const { lazyQuery: lazyFetchVariantByQuery, data: productVariantByQueryData } = useLazyApi(
    fetchShopifyVariantByQuery
  );

  const fetchProductsByTagUpdateQuery = (previousResult?: any, fetchMoreResult?: any) => ({
    ...previousResult,
    products: {
      ...previousResult?.products,
      pageInfo: {
        ...fetchMoreResult?.products.pageInfo,
      },
      edges: [
        ...(previousResult?.products.edges || []),
        ...(fetchMoreResult?.products.edges || []),
      ],
    },
  });

  const {
    lazyQuery: lazyFetchProductsByTag,
    loading: areProductsByTagLoading,
    data: productsByTag,
    error: productsByTagError,
  } = useLazyApi(fetchProductsByTag, fetchProductsByTagUpdateQuery);

  useEffect(() => {
    const productVariant = productVariantByQueryData?.productVariants?.edges?.[0]?.node;
    if (productVariant) {
      form.change('shopifyProductVariant', productVariant);
    }
  }, [productVariantByQueryData]);

  useEffect(() => {
    lazyFetchProductsByTag({
      treetId,
      query: fullAdditionalProductQuery,
      sortKey: 'TITLE',
    });
  }, []);

  // Need this use effect to ensure that the product
  // data is updated properly
  useEffect(() => {
    if (!productById) return;
    const { product } = productById;
    form.change('shopifyProduct', product);
  }, [productById]);

  useEffect(() => {
    if (productId) {
      lazyFetchProductById({
        treetId,
        countryCode,
        productId,
        shouldIncludeTradeInPriceMetafield: allowTradeIn,
      });
    }
  }, [productId]);

  const handleFetchMore = () => {
    const cursor = productsByTag?.products?.edges?.slice(-1)[0]?.cursor;

    lazyFetchProductsByTag({
      treetId,
      query: fullAdditionalProductQuery,
      cursor,
      sortKey: 'TITLE',
    });
  };

  const handleProductClick = async (product?: ShopifyProduct) => {
    lazyFetchProductById({
      treetId,
      countryCode,
      productId: product?.id,
      shouldIncludeTradeInPriceMetafield: allowTradeIn,
    });

    const productVariantOptions = filterDefaultShopifyOptions(product?.options);
    const hasNoVariantOptions = isEmpty(productVariantOptions);

    if (hasNoVariantOptions && product?.id) {
      const query = getProductVariantQuery([], {}, product.id);
      lazyFetchVariantByQuery({
        queryString: query,
        countryCode,
        subdomain: shopId,
      });
    }

    Object.keys(values)
      .filter((field) => field !== FIND_ITEM_METHOD && field !== 'shopifyProduct')
      .forEach((field) => form.change(field, undefined));

    form.change('shopifyProduct', product);
    form.change('findItemMethod', FindItemMethod.AllProducts);
  };

  const filteredProducts = productsByTag?.products?.edges?.filter((product: ProductEdge) =>
    canProductBeShown(product.node, shopConfig)
  );

  const sortedProducts = useMemo(
    () =>
      filteredProducts?.slice().sort((a: ProductEdge, b: ProductEdge) => {
        const hasImageA = a.node?.featuredImage?.originalSrc ? 1 : 0;
        const hasImageB = b.node?.featuredImage?.originalSrc ? 1 : 0;
        return hasImageB - hasImageA;
      }),
    [filteredProducts]
  );

  const hasNextPage = productsByTag?.products?.pageInfo?.hasNextPage;

  if (productsByTagError)
    return (
      <error>
        There was an error fetching products from the store. Please refresh the page or use a
        different method to find your item instead.
      </error>
    );

  if (areProductsByTagLoading && isEmpty(sortedProducts)) return <loading></loading>;

  if (!sortedProducts) return null;

  // Only show the highlighted one when it matches the selected one
  const shouldShowSelectedItem = productId && productById && productById?.product?.id === productId;

  return (
    <infinitescroll dataLength="{sortedProducts.length}" next="{handleFetchMore}" hasMore="{hasNextPage}" loader="{<Loading"></infinitescroll>}
      scrollableTarget="scrollableDiv"
    >
      {shouldShowSelectedItem && (
        <>
          <selecteditem shopifyProduct="{productById?.product}"></selecteditem>
          <typographywrapper variant="h2">Other {shopConfig.shopName} Items</typographywrapper>
        </>
      )}

      <grid This="" removes="" the="" horizontal="" scroll="" style="{{" margin:="" 0,="" width:="" '100%'="" }}="" container="">
        {sortedProducts.map((product: ProductEdge) => (
          <product product="{product.node}" selectedProductId="{shopifyProduct?.id}" onChange="{handleProductClick}" key="{product.node.id}"></product>
        ))}
      </grid>
    
  );
};

export default ShopifyProducts;
</selecteditemprops>