import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '..';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled } from '../../ducks/UI.duck';

interface PageWrapperProps {
  title: string;
  children: ReactNode;
  mainClassName?: string;
}

const PageWrapper: FC<pagewrapperprops> = (props: PageWrapperProps) => {
  const { children, title, mainClassName } = props;

  const scrollingDisabled = useSelector<any>((state) => isScrollingDisabled(state));

  return (
    <page title="{title}" scrollingDisabled="{scrollingDisabled}">
      <layoutsinglecolumn>
        <layoutwrappertopbar>
          <topbarcontainer></topbarcontainer>
        </layoutwrappertopbar>
        <layoutwrappermain className="{mainClassName}">{children}</layoutwrappermain>
        <layoutwrapperfooter>
          <footer></footer>
        </layoutwrapperfooter>
      </layoutsinglecolumn>
    </page>
  );
};

export default PageWrapper;
</any></pagewrapperprops>