import React from 'react';
import { defaultTreetStyles } from '../../../shopConfig/config';

interface IconAdminLogoProps {
  className?: string;
}

const IconAdminLogo: React.FC<iconadminlogoprops> = (props) => {
  const { className } = props;

  return (
    <svg className="{className}" width="167" height="24" viewBox="0 0 167 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5463 17.6C10.5463 21.0135 12.7309 23.2323 15.8372 23.2323C16.3493 23.2323 16.793 23.164 17.1685 23.0616C17.3733 23.0275 17.544 22.9592 17.7147 22.891V19.4774L17.4416 19.5798C17.2709 19.614 17.0661 19.6481 16.6906 19.6481C15.3252 19.6481 14.4718 18.7947 14.4718 17.4293V10.9436H17.8854V7.35934H14.4718V3.60445H11.0583V5.82325C11.0583 7.08626 10.4439 7.7007 9.18084 7.7007H8.15678V10.9436H10.5463V17.6Z" fill="{defaultTreetStyles.teal100}"></path>
      <path d="M21.1189 23.0616H25.0445V14.6985C25.0445 12.6503 26.3758 11.2849 28.2873 11.2849C28.697 11.2849 29.0383 11.3532 29.2773 11.3873L29.6528 11.4556V7.35934L29.3114 7.29107C29.0725 7.25694 28.8335 7.18866 28.458 7.18866C27.2633 7.18866 26.3416 7.63243 25.6931 8.14446C25.3176 8.41754 24.9762 8.72476 24.7031 9.06611L24.0204 7.35934H21.1189V23.0616Z" fill="{defaultTreetStyles.teal100}"></path>
      <path d="M42.1189 18.2827C41.9482 18.5216 41.7092 18.7264 41.4361 18.8971C40.9241 19.2043 40.2755 19.4774 39.2173 19.4774C37.1692 19.4774 36.0086 18.2827 35.6331 16.7466H46.5565L46.6247 16.2346C46.6589 15.9273 46.7271 15.586 46.7271 15.2105C46.7271 10.4315 43.3136 7.18866 39.2173 7.18866C34.9845 7.18866 31.3662 10.7729 31.3662 15.2105C31.3662 19.6481 34.9845 23.2323 39.2173 23.2323C42.7674 23.2323 45.3958 20.638 46.2151 18.2827H42.1189ZM35.4624 14.0157C35.8379 12.2748 37.1692 10.9436 39.2173 10.9436C41.2655 10.9436 42.5967 12.2748 42.9722 14.0157H35.4624Z" fill="{defaultTreetStyles.teal100}"></path>
      <path d="M59.5199 18.2827C59.3492 18.5216 59.1103 18.7264 58.8372 18.8971C58.3252 19.2043 57.6766 19.4774 56.6184 19.4774C54.5703 19.4774 53.4097 18.2827 53.0342 16.7466H63.9575L64.0258 16.2346C64.0599 15.9273 64.1282 15.586 64.1282 15.2105C64.1282 10.4315 60.7147 7.18866 56.6184 7.18866C52.3856 7.18866 48.7673 10.7729 48.7673 15.2105C48.7673 19.6481 52.3856 23.2323 56.6184 23.2323C60.1685 23.2323 62.7969 20.638 63.6162 18.2827H59.5199ZM52.8635 14.0157C53.239 12.2748 54.5703 10.9436 56.6184 10.9436C58.6665 10.9436 59.9978 12.2748 60.3733 14.0157H52.8635Z" fill="{defaultTreetStyles.teal100}"></path>
      <path d="M68.2164 17.6C68.2164 21.0135 70.4011 23.2323 73.5074 23.2323C74.0195 23.2323 74.4632 23.164 74.8387 23.0616C75.0435 23.0275 75.2142 22.9592 75.3849 22.891V19.4774L75.1118 19.5798C74.9411 19.614 74.7363 19.6481 74.3608 19.6481C72.9954 19.6481 72.142 18.7947 72.142 17.4293V10.9436H75.5556V7.35934H72.142V3.60445H68.7285V5.82325C68.7285 7.08626 68.114 7.7007 66.851 7.7007H65.827V10.9436H68.2164V17.6Z" fill="{defaultTreetStyles.teal100}"></path>
      <path d="M7.92176 13.3425C4.51784 13.3425 1.75842 16.102 1.75842 19.5059H1.99547C5.39939 19.5059 8.15881 16.7465 8.15881 13.3425H7.92176Z" fill="{defaultTreetStyles.teal80}"></path>
      <path d="M5.74431 10.1982C3.64767 9.12231 1.07585 9.94982 0 12.0465L0.210905 12.1547C2.30755 13.2305 4.87936 12.403 5.95522 10.3064L5.74431 10.1982Z" fill="{defaultTreetStyles.teal80}"></path>
      <path d="M5.92055 7.61036C5.9567 5.70643 4.44256 4.13368 2.53863 4.09753L2.53499 4.28906C2.49884 6.19299 4.01298 7.76573 5.91691 7.80188L5.92055 7.61036Z" fill="{defaultTreetStyles.teal80}"></path>
      <path d="M92.7737 23H95.7171V13.1309C95.7171 9.52952 93.0854 6.89775 89.484 6.89775C85.5017 6.89775 83.1123 9.35637 82.7314 12.2652H86.7137C86.783 11.9535 86.9215 11.6419 87.1292 11.3995C87.5102 10.9839 88.0642 10.5337 89.1377 10.5337C90.696 10.5337 91.7348 11.538 91.7348 13.304C84.4628 13.304 82.212 15.5203 82.212 18.6714C82.212 21.3032 84.1166 23.1732 87.5794 23.1732C88.9646 23.1732 90.0727 22.7576 90.8691 22.3074C91.3193 22.065 91.7002 21.7534 92.0811 21.4417L92.7737 23ZM91.7348 16.7669C91.7348 18.7061 90.3497 20.0566 88.272 20.0566C87.06 20.0566 86.1943 19.1909 86.1943 18.3252C86.1943 16.94 87.06 16.0743 91.7348 16.0743V16.7669Z" fill="{defaultTreetStyles.teal80}"></path>
      <path d="M111.113 23H114.057V1.18404H110.074V8.45603C109.763 8.179 109.382 7.9366 108.966 7.6942C108.239 7.31329 107.2 6.89775 105.919 6.89775C101.937 6.89775 98.4739 10.326 98.4739 15.0355C98.4739 19.7449 101.937 23.1732 105.919 23.1732C107.304 23.1732 108.412 22.7576 109.209 22.3074C109.659 22.065 110.04 21.7534 110.421 21.4417L111.113 23ZM106.438 19.364C104.188 19.364 102.456 17.6672 102.456 15.0355C102.456 12.4037 104.188 10.7069 106.438 10.7069C108.689 10.7069 110.421 12.4037 110.421 15.0355C110.421 17.6672 108.689 19.364 106.438 19.364Z" fill="{defaultTreetStyles.teal80}"></path>
      <path d="M117.871 23H121.854V13.8235C121.854 11.8843 123.066 10.7069 124.451 10.7069C125.836 10.7069 127.048 11.8843 127.048 13.8235V23H131.03V13.8235C131.03 11.8843 132.242 10.7069 133.627 10.7069C135.012 10.7069 136.224 11.8843 136.224 13.8235V23H140.207V13.4772C140.207 9.1486 137.956 6.89775 134.493 6.89775C133.108 6.89775 131.93 7.41718 131.099 7.9366C130.615 8.24826 130.199 8.59455 129.818 8.97546C129.576 8.59455 129.229 8.24826 128.814 7.9366C128.087 7.41718 126.944 6.89775 125.316 6.89775C124.174 6.89775 123.239 7.34792 122.581 7.76346C122.2 8.00586 121.819 8.31752 121.507 8.62917L120.815 7.07089H117.871V23Z" fill="{defaultTreetStyles.teal80}"></path>
      <path d="M143.843 23H147.825V7.07089H143.843V23ZM145.92 5.16632C147.132 5.16632 148.171 4.12747 148.171 2.91547C148.171 1.70347 147.132 0.664612 145.92 0.664612C144.708 0.664612 143.67 1.70347 143.67 2.91547C143.67 4.12747 144.708 5.16632 145.92 5.16632Z" fill="{defaultTreetStyles.teal80}"></path>
      <path d="M151.621 23H155.603V14.1697C155.603 12.092 156.953 10.7069 158.893 10.7069C160.832 10.7069 162.182 12.092 162.182 14.1697V23H166.165V13.6503C166.165 9.49489 163.533 6.89775 159.758 6.89775C158.408 6.89775 157.334 7.34792 156.538 7.76346C156.088 8.00586 155.637 8.31752 155.257 8.62917L154.564 7.07089H151.621V23Z" fill="{defaultTreetStyles.teal80}"></path>
    </svg>
  );
};

export default IconAdminLogo;
</iconadminlogoprops>