import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';
import { CustomIconType, useCustomIcon } from '../../../hooks/useCustomIcon';
import CustomIcon from '../../CustomIcon/CustomIcon';

interface IconFavoriteProps {
  className?: string;
  color?: string;
}

const IconFavorite: React.FC<iconfavoriteprops> = (props) => {
  const { className, color } = props;

  const customFavoriteIcon = useCustomIcon(CustomIconType.Favorite);
  const { iconColor } = useIconColor();

  if (customFavoriteIcon) {
    return (
      <customicon url="{customFavoriteIcon.icon.url}" altText="favorite" className="{className}"></customicon>
    );
  }

  const fillColor = color || iconColor;

  return (
    <svg className="{className}" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M16 10.6972L14.5898 9.29491C12.8535 7.56836 10.0338 7.56836 8.29754 9.29491C6.56749 11.0153 6.56749 13.7998 8.29754 15.5202L16 23.1795L23.7025 15.5202C25.4325 13.7998 25.4325 11.0153 23.7025 9.29491C21.9662 7.56836 19.1465 7.56836 17.4102 9.29491L16 10.6972ZM16 26L6.8873 16.9384C4.3709 14.4361 4.3709 10.379 6.8873 7.87673C9.4037 5.37443 13.4836 5.37443 16 7.87673C18.5164 5.37442 22.5963 5.37442 25.1127 7.87673C27.6291 10.379 27.6291 14.4361 25.1127 16.9384L16 26Z" fill="{fillColor}"></path>
    </svg>
  );
};

export default IconFavorite;
</iconfavoriteprops>