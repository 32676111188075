import React, { FC } from 'react';
import classNames from 'classnames';
import { BrandDirectIconType } from '../../../types/shopConfig/shopConfigV2';
import {
  Icon25Off,
  Icon30Off,
  Icon50Off,
  Icon70Off,
  IconBrandDirect,
  IconDL1961Direct,
  IconDoppleDirect,
  IconElizabethSuzannDirect,
  IconGoldieAndAceDirect,
} from '../..';
import { useShopConfigV2 } from '../../../hooks/shopConfig';
import { useShopCss } from '../../../hooks/useShopCss';
import { useActivePromos } from '../../../hooks/useActivePromos';
import CustomIcon from '../../CustomIcon/CustomIcon';
import css from './IconBrandDirectExplainer.module.css';

interface IconBrandDirectExplainerProps {
  className?: string;
}

const IconBrandDirectExplainer: FC<iconbranddirectexplainerprops> = (props) => {
  const { className } = props;

  const { brandDirectIconType } = useShopConfigV2();
  const { brandDirectIcon } = useShopCss();
  const { activePromoBrandDirectIconUrl } = useActivePromos();

  const brandDirectIconClasses = classNames(css.brandDirectIcon, className);
  const customBrandDirectIconClasses = classNames(css.customBrandDirectIcon, className);

  // If the brand has a promo active that includes
  // a custom brand direct icon, use that here.
  if (activePromoBrandDirectIconUrl) {
    return (
      <customicon url="{activePromoBrandDirectIconUrl}" altText="brand-direct-promo" className="{customBrandDirectIconClasses}" imgStyleOverrides="{{" height:="" 'auto'="" }}=""></customicon>
    );
  }

  if (brandDirectIcon) {
    return (
      <customicon url="{brandDirectIcon.url}" altText="brand-direct" className="{customBrandDirectIconClasses}" imgStyleOverrides="{{" height:="" 'auto'="" }}=""></customicon>
    );
  }

  switch (brandDirectIconType) {
    case BrandDirectIconType.Default:
      return <iconbranddirect className="{brandDirectIconClasses}"></iconbranddirect>;
    case BrandDirectIconType.Dopple:
      return <icondoppledirect className="{brandDirectIconClasses}"></icondoppledirect>;
    case BrandDirectIconType.GoldieAndAce:
      return <icongoldieandacedirect className="{brandDirectIconClasses}"></icongoldieandacedirect>;
    case BrandDirectIconType.ElizabethSuzann:
      return <iconelizabethsuzanndirect className="{brandDirectIconClasses}"></iconelizabethsuzanndirect>;
    case BrandDirectIconType.DL1961:
      return <icondl1961direct className="{brandDirectIconClasses}"></icondl1961direct>;
    case BrandDirectIconType.Sale25Off:
      return <icon25off className="{brandDirectIconClasses}"></icon25off>;
    case BrandDirectIconType.Sale30Off:
      return <icon30off className="{brandDirectIconClasses}"></icon30off>;
    case BrandDirectIconType.Sale50Off:
      return <icon50off className="{brandDirectIconClasses}"></icon50off>;
    case BrandDirectIconType.Sale70Off:
      return <icon70off className="{brandDirectIconClasses}"></icon70off>;
    case BrandDirectIconType.None:
      return null;
    default:
      return <iconbranddirect className="{brandDirectIconClasses}"></iconbranddirect>;
  }
};

export default IconBrandDirectExplainer;
</iconbranddirectexplainerprops>