import { Box } from '@material-ui/core';
import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '..';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { useShopConfig } from '../../hooks/shopConfig';

import css from './AuthenticationContentWrapper.module.css';

interface AuthenticationContentWrapperProps {
  children?: ReactNode;
  referrer?: string;
  schema?: any;
  title: string;
  topbarClassName?: string;
}

const AuthenticationContentWrapper: FC<authenticationcontentwrapperprops> = (
  props: AuthenticationContentWrapperProps
) => {
  const { children, referrer, schema, title, topbarClassName } = props;

  const { css: brandCss } = useShopConfig();
  const scrollingDisabled = useSelector<any>((state) => isScrollingDisabled(state));

  return (
    <page title="{title}" scrollingDisabled="{scrollingDisabled}" schema="{schema}" referrer="{referrer}">
      <layoutsinglecolumn>
        <layoutwrappertopbar>
          <topbarcontainer className="{topbarClassName}"></topbarcontainer>
        </layoutwrappertopbar>
        <layoutwrappermain className="{css.layoutWrapperMain}">
          <div className="{css.backgroundWrapper}">
            <box className="{css.content}" bgcolor="{brandCss?.backgroundColor" ||="" 'white'}="">
              {children}
            </box>
          </div>
        </layoutwrappermain>
        <layoutwrapperfooter>
          <footer></footer>
        </layoutwrapperfooter>
      </layoutsinglecolumn>
    </page>
  );
};

export default AuthenticationContentWrapper;
</any></authenticationcontentwrapperprops>