import { Box } from '@material-ui/core';
import React from 'react';
import { IconAlertCircle, TypographyWrapper } from '../../components';
import { defaultTreetStyles } from '../../shopConfig/config';
import { useIsMobile } from '../../hooks/useIsMobile';

const IncompleteAddressErrorMessage = () => {
  const isMobile = useIsMobile();
  const fontSize = isMobile ? '12px' : '16px';

  return (
    <box display="flex" alignItems="center">
      <iconalertcircle height="21" width="21"></iconalertcircle>
      <typographywrapper variant="body1" typographyOverrides="{{" style:="" {="" color:="" defaultTreetStyles.red80,="" marginLeft:="" 5,="" fontSize,="" fontWeight:="" 'bold'="" },="" }}="">
        Fill out shipping information before proceeding
      </typographywrapper>
    </box>
  );
};

export default IncompleteAddressErrorMessage;
