import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconUploadProps {
  className?: string;
  color?: string;
}

const IconUpload: React.FC<iconuploadprops> = (props) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg className="{className}" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path fillRule="evenodd" clipRule="evenodd" d="M4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16ZM16 26C10.4772 26 6 21.5228 6 16C6 10.4772 10.4772 6 16 6C21.5228 6 26 10.4772 26 16C26 21.5228 21.5228 26 16 26Z" fill="{fillColor}"></path>
        <path fillRule="evenodd" clipRule="evenodd" d="M11 14.9497L15.9497 10L20.8995 14.9497L19.4853 16.364L17 13.8787V22H15V13.7782L12.4142 16.364L11 14.9497Z" fill="{fillColor}"></path>
      </g>
    </svg>
  );
};

export default IconUpload;
</iconuploadprops>