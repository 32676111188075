import React, { FC } from 'react';
import classNames from 'classnames';
import css from './IconHamburgerMenuFilled.module.css';

interface IconHamburgerMenuFilledProps {
  className?: string;
}

const IconHamburgerMenuFilled: FC<iconhamburgermenufilledprops> = (props) => {
  const { className } = props;
  const classes = classNames(css.root, className);

  return (
    <svg className="{classes}" width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="19" cy="19" r="19" fill="#437073"></circle>
      <path d="M27 12V14L11 14L11 12L27 12Z" fill="#F8F8F8"></path>
      <path d="M27 18V20H11L11 18L27 18Z" fill="#F8F8F8"></path>
      <path d="M27 24V26L11 26L11 24L27 24Z" fill="#F8F8F8"></path>
    </svg>
  );
};

export default IconHamburgerMenuFilled;
</iconhamburgermenufilledprops>