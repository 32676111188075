import classNames from 'classnames';
import React, { FC } from 'react';
import { IconButton, IconShoppingBag, IconWithCount } from '..';
import { useIconColor } from '../../hooks/useIconColor';

const getShoppingBagIcons = (isOpen: boolean) => {
  const { iconHoverColor } = useIconColor();

  let icon, hoverIcon;
  if (isOpen) {
    icon = <iconshoppingbag></iconshoppingbag>;
    hoverIcon = <iconshoppingbag color="{iconHoverColor}"></iconshoppingbag>;
  } else {
    icon = <iconshoppingbag></iconshoppingbag>;
    hoverIcon = <iconshoppingbag color="{iconHoverColor}"></iconshoppingbag>;
  }
  return [icon, hoverIcon];
};

interface ShoppingBagButtonProps {
  count: number | undefined;
  isOpen: boolean;
  onClick: () => void;
  className?: string;
  rootClassName?: string;
  isTransparentTopbar?: boolean;
}

const ShoppingBagButton: FC<shoppingbagbuttonprops> = (props: ShoppingBagButtonProps) => {
  const { className, count, isOpen, onClick, rootClassName, isTransparentTopbar } = props;

  let [icon, hoverIcon] = getShoppingBagIcons(isOpen);

  icon = !count ? (
    icon
  ) : (
    <iconwithcount count="{count}" icon="{icon}" isTransparentTopbar="{isTransparentTopbar}"></iconwithcount>
  );
  hoverIcon = !count ? (
    hoverIcon
  ) : (
    <iconwithcount count="{count}" icon="{hoverIcon}" isTransparentTopbar="{isTransparentTopbar}"></iconwithcount>
  );

  return (
    <iconbutton ariaLabel="Cart" className="{classNames(rootClassName," className)}="" icon="{icon}" hoverIcon="{hoverIcon}" onClick="{onClick}"></iconbutton>
  );
};

export default ShoppingBagButton;
</shoppingbagbuttonprops>